import React from "react";
import { useRecoilValue } from "recoil";
import {
  PowderData,
  VolumeReductionData,
  Co2ePowderScenarioData,
  EnergyMaterialData,
  EnergySystemData,
  Co2eEnergyData,
  VolumeReductionInput,
  EnergPercentageInput,
  GreenPowderEnergyInput,
  CnpInput,
  RefreshRateScenarioInput,
  RefreshRateScenario
} from "../../../../../../atoms/Co2Polymer/ScenarioPolymer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import {
  CPolyModeOfTransportNode1,
  CPolyModeOfTransportNode2,
  CPolyModeOfTranspotation1,
  CPolyModeOfTranspotation2,
  TotalTraspotation,
} from "../../../../../../atoms/Co2Polymer/ScenarioPolymer";


const MaterialTableValues = ({ Socket }) => {
  const powder = useRecoilValue(PowderData);
  const volumeReductionS = useRecoilValue(VolumeReductionData);
  const co2EPowder = useRecoilValue(Co2ePowderScenarioData);
  const energyMaterial = useRecoilValue(EnergyMaterialData);
  const energySytem = useRecoilValue(EnergySystemData);
  const co2eEnergy = useRecoilValue(Co2eEnergyData);
  const cnpInput = useRecoilValue(CnpInput);
  const volumeReductionInput = useRecoilValue(VolumeReductionInput);
  const energyPercentageInput = useRecoilValue(EnergPercentageInput);
  const greenPowderEnergyInput = useRecoilValue(GreenPowderEnergyInput);
  const co2Transportaion = useRecoilValue(TotalTraspotation);
  const ModeOfTransportNode1 = useRecoilValue(CPolyModeOfTransportNode1);
  const ModeOfTransportNode2 = useRecoilValue(CPolyModeOfTransportNode2);
  const ModeOfTranspotation1 = useRecoilValue(CPolyModeOfTranspotation1);
  const ModeOfTranspotation2 = useRecoilValue(CPolyModeOfTranspotation2);
  const refreshRatePercentage = useRecoilValue(RefreshRateScenarioInput);
  const refreshRateScenario = useRecoilValue(RefreshRateScenario);
  return (
    <div className=" responsive-scroll">
      <div className="divTable">
        <div className="divTableBody">
          <div className="divTableRow">
            {/* <div className="divTableCell1-heading">Unit</div> */}
            <div className="divTableCell1-heading pr-3">Input</div>
            <div className="divTableCell1-heading pl-1">Current Scenario</div>
            <div className="divTableCell1-heading">
              <Tippy
                content={
                  <span>
                    Here you can be playful! We have estimated the status quo of
                    your carbon and costs, but let's see how certain changes
                    might impact your values!
                  </span>
                }
              >
                <span> Future Scenario</span>
              </Tippy>
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">CO2e kg/part</div> */}
            <div className="divTableCell1 text-capital pr-3">
              {cnpInput ? cnpInput : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {powder ? powder.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {powder ? powder.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading">&nbsp;</div>
            <div className="divTableCell1-heading">&nbsp;</div>
            <div className="divTableCell1-heading">&nbsp;</div>
            <div className="divTableCell1-heading">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">KG</div> */}
            <div className="divTableCell1 pr-3">
              {volumeReductionInput ? `${volumeReductionInput}%` : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {volumeReductionS ? volumeReductionS.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {volumeReductionS ? volumeReductionS.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">KG</div> */}
            <div className="divTableCell1 pr-3">
              {refreshRatePercentage ? `${refreshRatePercentage}%` : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {refreshRateScenario ? refreshRateScenario.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {refreshRateScenario ? refreshRateScenario.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1-special">Kg</div> */}
            <div className="divTableCell1 pr-3"></div>
            <div className="divTableCell1-special">
              {co2EPowder ? co2EPowder.today : "_ _ _ "}
            </div>
            <div className="divTableCell1-special">
              {co2EPowder ? co2EPowder.tomorrow : "_ _ _ "}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTableCell1">Percenatage</div> */}
            <div className="divTableCell1 pb-2 pr-3">
              {energyPercentageInput ? `${energyPercentageInput}%` : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {energyMaterial ? energyMaterial.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {energyMaterial ? energyMaterial.tomorrow : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">Green Energy</div> */}
            <div className="divTableCell1 text-capital pb-4 pr-3">
              {greenPowderEnergyInput ? greenPowderEnergyInput : "_ _ _ _"}{" "}
            </div>
            <div className="divTableCell1 pb-4">
              {energySytem ? energySytem.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pb-4">
              {energySytem ? energySytem.tomorrow : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTableCell1-heading p-0">&nbsp;</div> */}
            <div className="divTableCell1 pr-3">&nbsp;</div>
            <div className="divTableCell1 pb-3">
              {co2eEnergy ? co2eEnergy.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pb-3">
              {co2eEnergy ? co2eEnergy.tomorrow : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1 pb-4">{ModeOfTranspotation1}</div>
            <div className="divTableCell1 pl-3">
              {ModeOfTransportNode1 ? ModeOfTransportNode1.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pb-3">
              {ModeOfTransportNode1 ? ModeOfTransportNode1.tomorrow : "_ _ _ "}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1">{ModeOfTranspotation2}</div>
            <div className="divTableCell1 pl-3">
              {ModeOfTransportNode2 ? ModeOfTransportNode2.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pb-3">
              {ModeOfTransportNode2 ? ModeOfTransportNode2.tomorrow : "_ _ _ "}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1 pr-3">&nbsp;</div>
            <div className="divTableCell1 pl-3">
              {co2Transportaion ? co2Transportaion.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pb-3">
              {co2Transportaion ? co2Transportaion.tomorrow : "_ _ _ _"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTableValues;
