import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_PARAMERTER_NAME_BY_ID } from "../CCCarbonMetal/components/CarbonMetalProcess/components/getStepDetail";

export const useGetParameterName = (paremeter_id) => {
  const client = useApolloClient();
  const [machineName, setMachineName] = useState("");
  useEffect(() => {
    const getParameterName = () => {
      client
        .query({
          query: GET_PARAMERTER_NAME_BY_ID,
          variables: {
            paremeter_id: paremeter_id,
          },
        })
        .then(({ data }) => {
          setMachineName(data?.getClientParemeterNameById);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    getParameterName();
  }, []);
  return machineName;
};
