import React from "react";

const MatrixGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Cost Matrix</h3>
            <p>The matrix allows the possibility to use two selected variables from the bar above, define their range you want to have analysis on and then check cost per part for the impact due to combination of 02 variables.</p>
            <img src="/assets/matrix-guide-1.png" alt="" className="img-fluid" />
            <p>You can also input a specific target price and have it color coded. </p>
            <ul>
                <li>Yellow is the target Price</li>
                <li>Blue is below target price</li>
                <li>Red is above target price. </li>
            </ul>
        
        </>
    )
}

export default MatrixGuide;