import React from "react";

const SensitivityGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Sensitivity Graphs</h3>
            <p>The sensitivity graphs show impact of each variable on cost per part individually. </p>
            <img src="/assets/sensitivity-guide-1.png" alt="" className="img-fluid" />
            <p>You can change the range for your variable from the bar to specify the analysis and how one variable will impact the Cost per Part</p>
            <img src="/assets/sensitivity-guide-2.png" alt="" className="img-fluid" />
            <p>The values automatically update on range input. </p>
        </>
    )
}

export default SensitivityGuide;