import React, { useEffect } from "react";
import {
  TotalTraspotation,
  PowderAutomizationData,
  VolumePartReductionData,
  Co2ePowderScenarioMData,
  GreenEnergyMixData,
  InertGasReductionSMData,
  Co2TotalTodaySMData,
  Co2TotalTomorrowSMData,
  PowderAutomizationInput,
  VolumePartReductionInput,
  GreenEnergyMixInput,
} from "atoms/Co2Metal/ScenarioMetal";
import { useRecoilState, useRecoilValue } from "recoil";
import { Co2EmissionPowderDataM } from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { Co2ePerPartPMDataM } from "atoms/Co2Metal/PowderMfgAtoms";
import { Co2EmissionFactorDataM } from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { NoOfPartsDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  PartVolumeDataM,
  PartSupportVolumeDataM,
  MaterialDataM,
} from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  Co2EmissionBuildProcessDataM,
  Co2EmissionGasDataM,
} from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import {
  MaterialTransportationDataM,
  SystemTransportationDataM,
  CarbonEmissionFactorNode1DataM,
  CarbonEmissionFactorNode2DataM,
} from "atoms/Co2Metal/TransportationAtoms";
import { BuildTimeDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  CO2eModeOfTranspotation1,
  CO2eModeOfTranspotation2,
  CO2eModeOfTransportNode1,
  CO2eModeOfTransportNode2,
  CO2eEnergySMData
} from "atoms/Co2Metal/ScenarioMetal";
import SelectDropdown from "app/components/SelectDropdown";
import { LogisticsData, PercentageData } from "utils/selectDropdowns";
import { handleKeyPress } from "utils/validations";

const { getComputedValue } = useRoundOffValue();

const MaterialTableValues = ({ Socket }) => {
  const [powderAutomization, setPowderAutomization] = useRecoilState(
    PowderAutomizationData
  );
  const [volumePartReduction, setVolumePartReduction] = useRecoilState(
    VolumePartReductionData
  );
  const [co2ePowder, setCo2EPowder] = useRecoilState(Co2ePowderScenarioMData);
  const [greenEnergyMix, setGreenEnergyMix] =
    useRecoilState(GreenEnergyMixData);
  const [inertGasReduction, setInertGasReduction] = useRecoilState(
    InertGasReductionSMData
  );
  const [co2eEnergyTotal, setCo2eEnergyTotal] = useRecoilState(
    CO2eEnergySMData
  );

  const [totalTranpotation, setTotalTraspotation] =
    useRecoilState(TotalTraspotation);

  const [co2TotalToday, setCo2TotalToday] = useRecoilState(Co2TotalTodaySMData);
  const [co2TotalTomorrow, setCo2TotalTomorrow] = useRecoilState(
    Co2TotalTomorrowSMData
  );

  const co2EmissionPowder = useRecoilValue(Co2EmissionPowderDataM);
  const Co2ePerPartPM = useRecoilValue(Co2ePerPartPMDataM);
  const co2eEmissionFactor = useRecoilValue(Co2EmissionFactorDataM);
  const noOfPartsData = useRecoilValue(NoOfPartsDataM);
  const partVolume = useRecoilValue(PartVolumeDataM);
  const partSupportVolume = useRecoilValue(PartSupportVolumeDataM);
  const materialId = useRecoilValue(MaterialDataM);
  const co2EmissionBuildProcess = useRecoilValue(Co2EmissionBuildProcessDataM);
  const co2EmissionGas = useRecoilValue(Co2EmissionGasDataM);

  const [powderAtomizationState, setPowderAutomizationState] = useRecoilState(
    PowderAutomizationInput
  );
  const [volumerPartReductionState, setVolumePartReductionState] =
    useRecoilState(VolumePartReductionInput);
  const [greenEnergymixState, setGreenEnergyMixState] =
    useRecoilState(GreenEnergyMixInput);
  // mode of transportation
  const [modeOfTranspotation1, setModeOfTranspotation1] = useRecoilState(
    CO2eModeOfTranspotation1
  );
  const [modeOfTranspotation2, setModeOfTranspotation2] = useRecoilState(
    CO2eModeOfTranspotation2
  );
  const [modeOfTransportnode1, setmodeOfTransportnode1] = useRecoilState(
    CO2eModeOfTransportNode1
  );

  const [modeOfTransportnode2, setmodeOfTransportnode2] = useRecoilState(
    CO2eModeOfTransportNode2
  );

  const materialTransportaion = useRecoilValue(MaterialTransportationDataM);
  const systemTransportation = useRecoilValue(SystemTransportationDataM);
  const cefnone = useRecoilValue(CarbonEmissionFactorNode1DataM);
  const cefnTwo = useRecoilValue(CarbonEmissionFactorNode2DataM);
  const build_time = useRecoilValue(BuildTimeDataM);

  useEffect(() => {
    if (modeOfTranspotation1 && modeOfTranspotation2) {
      // // debugger;
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "MSA-MCT",
        input: {
          node1_mode: modeOfTranspotation1,
          node2_mode: modeOfTranspotation2,
          material_transportation: parseInt(materialTransportaion),
          system_transportation: parseInt(systemTransportation),
          carbon_emission_factor_node_1: +cefnone,
          carbon_emission_factor_node_2: +cefnTwo,
          // utilization: +staticDataOfMachine?.utilization?.value,
          build_time: +build_time,
          // setup_time: +staticDataOfMachine?.setup_time?.value,
          // cool_down_time_in_system:
          //   +staticDataOfMachine.cool_down_time_in_system.value, //not in metal
          // unpacking_time_per_job:
          //   +staticDataOfMachine?.unpacking_time_per_job?.value,
        },
      });

      Socket.on("MSA-MCT", function (data) {
        let value_node_1_today = data?.data?.transport_node_1_today.toFixed(3)
        let value_node_1_tomorrow = data?.data?.transport_node_1_tomorrow.toFixed(3)
        let value_node_2_today = data?.data?.transport_node_2_today.toFixed(3)

        let value_node_2_tomorrow = data?.data?.transport_node_2_tomorrow.toFixed(3)

        const transportNode1Today = value_node_1_today.slice(-1) === '0' ? data?.data?.transport_node_1_today.toFixed(5) : value_node_1_today
        const transportNode1Tomorrow = value_node_1_tomorrow.slice(-1) === '0' ? data?.data?.transport_node_1_tomorrow.toFixed(5) : value_node_1_tomorrow

        const transportNode2Today = value_node_2_today.slice(-1) === '0' ? data?.data?.transport_node_2_today.toFixed(5) : value_node_2_today
        const transportNode2Tomorrow = value_node_2_tomorrow.slice(-1) === '0' ? data?.data?.transport_node_2_tomorrow.toFixed(5) : value_node_2_tomorrow


        setmodeOfTransportnode1({
          today: transportNode1Today,
          tomorrow: transportNode1Tomorrow,
        });
        setmodeOfTransportnode2({
          today: transportNode2Today,
          tomorrow: transportNode2Tomorrow,
        });
        setTotalTraspotation({
          today: ((transportNode1Today.length > 5) || (transportNode2Today.length > 5)) ? data?.data?.transport_today.toFixed(5) : getComputedValue(data?.data?.transport_today),
          tomorrow: ((transportNode1Tomorrow.length > 5) || (transportNode2Tomorrow.length > 5)) ? data?.data?.transport_tomorrow.toFixed(5) : getComputedValue(data?.data?.transport_tomorrow),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeOfTranspotation1, modeOfTranspotation2]);
  // console.log("modeOfTransportnode1: ", modeOfTransportnode1)

  useEffect(() => {
    if (powderAtomizationState && volumerPartReductionState) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "MSA-MCP",
        input: {
          atomizedType: powderAtomizationState,
          co2_emission_powder: co2EmissionPowder,
          reduction_in_volume: volumerPartReductionState,
          powder_energy_per_part: Co2ePerPartPM,
          co2_emission_factor: co2eEmissionFactor,
          no_of_parts: noOfPartsData,
          part_volume: partVolume,
          part_support_volume: partSupportVolume,
          material_id: materialId,
        },
      });

      Socket.on("MSA-MCP", function (data) {

        const valuepatod = getComputedValue(
          data?.data?.powder_atomization_today
        );
        const valuepatom = getComputedValue(
          data?.data?.powder_atomization_tomorrow
        );
        const valuecptod = getComputedValue(data?.data?.co2e_powder_today);
        const valuecptom = getComputedValue(data?.data?.co2e_powder_tomorrow);
        const valuevptod = getComputedValue(
          data?.data?.volume_of_the_part_reduction_today
        );
        const valuevptom = getComputedValue(
          data?.data?.volume_of_the_part_reduction_tomorrow
        );
        setPowderAutomization({ today: valuepatod, tomorrow: valuepatom });
        setCo2EPowder({ today: valuecptod, tomorrow: valuecptom });
        setVolumePartReduction({ today: valuevptod, tomorrow: valuevptom });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderAtomizationState, volumerPartReductionState]);
  useEffect(() => {
    if (greenEnergymixState) {
      Socket.off("*");
      // 
      // console.log("greenEnergymixState", greenEnergymixState);
      // console.log("co2EmissionBuildProcess", co2EmissionBuildProcess[0]);

      Socket.emit("carbon-calculator", {
        key: "MSA-MCE",
        input: {
          isGreenEnergyMix: greenEnergymixState,
          co2_emission_build_process: co2EmissionBuildProcess,
        },
      });

      Socket.on("MSA-MCE", function (data) {

        const valuepatod = getComputedValue(data.data.green_energy_mix_today);
        const valuepatom = getComputedValue(
          data.data.green_energy_mix_tomorrow
        );
        setGreenEnergyMix({ today: valuepatod, tomorrow: greenEnergymixState === "yes" ? 0 : valuepatom });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greenEnergymixState]);
  useEffect(() => {
    if (co2EmissionGas && inertGasReduction?.tomorrow?.edited === false) {
      // debugger;
      Socket.off("*");



      Socket.emit("carbon-calculator", {
        key: "MSA-MCG",
        input: { co2_emission_gas: co2EmissionGas },
      });

      Socket.on("MSA-MCG", function (data) {

        const valuepatod = getComputedValue(
          data.data.inert_gas_reduction_today
        );
        const valuepatom = getComputedValue(
          data.data.inert_gas_reduction_tomorrow
        );
        setInertGasReduction({ today: valuepatod, tomorrow: { value: valuepatom } });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertGasReduction?.tomorrow?.edited]);

  useEffect(() => {
    if (
      co2ePowder !== undefined &&
      co2eEnergyTotal !== undefined &&
      totalTranpotation !== undefined
    ) {
      const value =
        parseFloat(co2ePowder.today) +
        parseFloat(co2eEnergyTotal?.today) +
        parseFloat(totalTranpotation.today);
      const value1 = getComputedValue(value);
      setCo2TotalToday(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2ePowder,
    co2eEnergyTotal,
    totalTranpotation,
  ]);
  useEffect(() => {
    if (
      co2ePowder !== undefined &&
      co2eEnergyTotal !== undefined &&
      totalTranpotation !== undefined
    ) {

      const value =
        parseFloat(co2ePowder.tomorrow) +
        parseFloat(co2eEnergyTotal?.tomorrow) +
        parseFloat(totalTranpotation.tomorrow);
      const value1 = getComputedValue(value);

      setCo2TotalTomorrow(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2ePowder,
    co2eEnergyTotal,
    totalTranpotation,
  ]);

  useEffect(() => {
    if (
      greenEnergyMix !== undefined &&
      inertGasReduction !== undefined
    ) {

      const value =
        parseFloat(inertGasReduction.today) +
        parseFloat(greenEnergyMix.today)
      const value1 = parseFloat(inertGasReduction.tomorrow?.value) +
        parseFloat(greenEnergyMix.tomorrow)
      const valueCo2EnergyToday = getComputedValue(value);
      const valueCo2EnergyTomorrow = getComputedValue(value1);

      setCo2eEnergyTotal({ today: valueCo2EnergyToday, tomorrow: greenEnergymixState === "yes" ? 0 : valueCo2EnergyTomorrow });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inertGasReduction,
    greenEnergyMix,
  ]);

  return (
    <div className=" responsive-scroll">
      <div className="divTable">
        <div className="divTableBody">
          <div className="divTableRow">
            {/* <div className="divTableCell1-heading">Unit</div> */}
            <div className="divTableCell1-heading">Input</div>
            <div className="divTableCell1-heading pl-1">Current Scenario</div>
            <div className="divTableCell1-heading">
              <TooltipTrippy
                tooltipMessage="Here you can be playful! We have estimated the status quo of
                    your carbon and costs, but let's see how certain changes
                    might impact your values!"
                fieldName="Future Scenario"
              />
            </div>
          </div>
          {/* <div className="divTableRow">
            <div className="divTableCell1">
              <select
                className="divTableCell1 dropdown-input"
                name="cars"
                id="cars"
                onChange={(e) => {
                  setPowderAutomizationState(e.target.value);
                }}
              >
                <option>Select</option>
                <option
                  value="GAS_ATOMIZED"
                  selected={
                    powderAtomizationState === "GAS_ATOMIZED" && "selected"
                  }
                >
                  Gas Atomized
                </option>
              </select>
            </div>
            <div className="divTableCell1 pl-3 ">
              {powderAutomization ? powderAutomization.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 ">
              {powderAutomization ? powderAutomization.tomorrow : "_ _ _ _"}
            </div>
          </div> 
          TODO commented as of now as per client requirement
          */}
          <div className="divTableRow">
            {/* <div className="divTable__Cells">kg</div> */}
            <div className="divTableCell1">
              <SelectDropdown Dataset={PercentageData}
                setState={setVolumePartReductionState}
                getState={volumerPartReductionState}
                styleclass='divTableCell1 dropdown-input'
                optionAvailable={false}
              />
            </div>
            <div className="divTableCell1 pl-3 ">
              {volumePartReduction ? volumePartReduction.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 ">
              {volumePartReduction ? volumePartReduction?.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1-special">kg</div> */}
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 pl-3 ">
              {co2ePowder ? co2ePowder.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {co2ePowder ? co2ePowder.tomorrow : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 p-0">&nbsp;</div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTable__Cells">Green Energy</div> */}
            <div className="divTableCell1">
              <select
                className="divTableCell1 dropdown-input"
                name="cars"
                id="cars"
                onChange={(e) => {
                  setGreenEnergyMixState(e.target.value);
                }}
              >
                <option>Select</option>
                <option
                  value="yes"
                  selected={greenEnergymixState === "yes" && "selected"}
                >
                  Yes
                </option>
                <option
                  value="no"
                  selected={greenEnergymixState === "no" && "selected"}
                >
                  No
                </option>
              </select>
            </div>
            <div className="divTableCell1 pl-3 ">
              {greenEnergyMix ? greenEnergyMix.today : "_ _ _ _ "}
            </div>
            <div className="divTableCell1 pl-3 ">
              {greenEnergyMix ? greenEnergyMix.tomorrow : "_ _ _ _ "}
            </div>
          </div>
          <div className="divTableRow ">
            {/* <div className="divTableCell1-heading p-0">&nbsp;</div> */}
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTable__Cells pl-3 position-top-0">
              {inertGasReduction ? inertGasReduction.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 p-0">
              <div className="input-section">
                <DebounceInput
                  type="number"
                  className="user-input"
                  debounceTimeout={500}
                  onChange={(e) => {
                    setInertGasReduction((prevState) => {
                      return {
                        ...prevState,
                        tomorrow: { value: +e.target.value, edited: true },
                      };
                    });
                  }}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => handleKeyPress(e)}
                  placeholder=""
                  value={inertGasReduction.tomorrow?.value}
                />
                {
                  inertGasReduction?.tomorrow?.edited &&
                  <img src="/assets/cross.png" alt="" className="cross-icon" onClick={() => {
                    setInertGasReduction((prevState) => {
                      return {
                        ...prevState,
                        tomorrow: { edited: false },
                      };
                    });
                  }} />
                }
                <OverrideAbleTip />
              </div>
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1-special">kg</div> */}
            <div className="divTableCell1 p-0">&nbsp;</div>
            <div className="divTableCell1 pl-3 ">
              {co2eEnergyTotal ? co2eEnergyTotal.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {co2eEnergyTotal ? co2eEnergyTotal.tomorrow : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1-heading">&nbsp;</div>
            <div className="divTableCell1-heading">&nbsp;</div>
            <div className="divTableCell1-heading">&nbsp;</div>
            <div className="divTableCell1-heading">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading  p-0">&nbsp;</div>
            <div className="divTableCell1-heading  p-0">&nbsp;</div>
            <div className="divTableCell1-heading  p-0">&nbsp;</div>
            <div className="divTableCell1-heading  p-0">&nbsp;</div>
          </div>


          <div className="divTableRow">
            {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
            <div className="divTableCell1">
              <SelectDropdown
                setState={setModeOfTranspotation1}
                getState={modeOfTranspotation1}
                Dataset={LogisticsData}
                styleclass="divTableCell1 dropdown-input"
              />

            </div>
            <div className="divTableCell1 pl-3">
              {modeOfTransportnode1 !== undefined
                ? modeOfTransportnode1.today
                : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {modeOfTransportnode1 !== undefined
                ? modeOfTransportnode1.tomorrow
                : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
            <div className="divTableCell1">
              <SelectDropdown
                setState={setModeOfTranspotation2}
                getState={modeOfTranspotation2}
                Dataset={LogisticsData}
                styleclass="divTableCell1 dropdown-input"
              />

            </div>
            <div className="divTableCell1 pl-3">
              {modeOfTransportnode2 !== undefined
                ? modeOfTransportnode2.today
                : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {modeOfTransportnode2 !== undefined
                ? modeOfTransportnode2.tomorrow
                : "_ _ _ _"}
            </div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1 pl-3">
              {totalTranpotation ? totalTranpotation.today : "_ _ _ _ "}
            </div>
            <div className="divTableCell1 pl-3 pb-0">
              {totalTranpotation ? totalTranpotation.tomorrow : "_ _ _ _ "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTableValues;
