import { atom } from "recoil";

export const CCMetalInertGasConsumptionDataM = atom({
  key: "CCMetalInertGasConsumptionDataM",
  default: undefined,
});

export const CCMetalInertGasTypeDataM = atom({
  key: "CCMetalInertGasTypeDataM",
  default: undefined,
});
export const CCMetalInertGasUsedEnergyDataM = atom({
  key: "CCMetalInertGasUsedEnergyDataM",
  default: undefined,
});
