import { configureStore, } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import accessReducer from './Access';
import ccstepsReducer from "./CCStepsState"
const store = configureStore({
  devTools: true,
  reducer: {
    Access: accessReducer,
    CCSteps:ccstepsReducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export const useAppDispatch = () => useDispatch()

export default store
