import React from 'react'

const PostProcessing = () => {
    return (
        <>
            <p className='input-heading' >Build time</p>
            <div className='data-field'>
                Build Time
            </div>
            <p className='input-heading' >Average power</p>
            <div className='data-field'>
                Average Power
            </div>
            <p className='input-heading' >Energy Used Per Job</p>
            <div className='data-field'>
                Energy Used Per Job
            </div>
            <p className='input-heading' >CO2e Emission Factor</p>
            <div className='data-field'>
                CO2e Emission Factor
            </div>
            <p className='input-heading' >CO2e Per Job</p>
            <div className='data-field'>
                CO2e Per Job
            </div>
            <p className='input-heading' >CO2e Per Part</p>
            <div className='data-field'>
                CO2e Per Part
            </div>
        </>
    )
}

export default PostProcessing