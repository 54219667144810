import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useRecoilState } from "recoil";
import Form from "react-bootstrap/Form";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { Spinner } from "react-bootstrap";
import { ProcessFlag } from "atoms/General";

import {
  SystemToCustomerDataM,
  PowderToCustomerDataM,
  NoOfPartsPerYearDataM,
  MaterialTransportationDataM,
  SystemTransportationDataM,
  CarbonEmissionFactorNode1DataM,
  CarbonEmissionFactorNode2DataM,
  Co2ePerYearDataM,
  Co2ePerPartTMDataM,
  PowderManuFactureDataM,
  PowderSiteDataM,
  MachineSiteDataM,
} from "atoms/Co2Metal/TransportationAtoms";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

const Transportation = ({ Socket }) => {
  const [powderManufacturer, setPowderManufacturer] = useRecoilState(
    PowderManuFactureDataM
  );
  const [powderSite, setPowderSite] = useRecoilState(PowderSiteDataM);
  const [machineSite, setMachineSite] = useRecoilState(MachineSiteDataM);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  const [systemToCustomer, setSystemToCustomer] = useRecoilState(
    SystemToCustomerDataM
  );
  const [materialTransportaion, setMaterialTransportaion] = useRecoilState(
    MaterialTransportationDataM
  );
  const [systemTransportation, setSystemTransportation] = useRecoilState(
    SystemTransportationDataM
  );
  const [powderToCustomer, setPowderToCustomer] = useRecoilState(
    PowderToCustomerDataM
  );
  const [cefnone, setCefnOne] = useRecoilState(CarbonEmissionFactorNode1DataM);
  const [cefnTwo, setCefnTwo] = useRecoilState(CarbonEmissionFactorNode2DataM);
  const [co2PerYear, setCo2PerYear] = useRecoilState(Co2ePerYearDataM);
  const [co2PerPart, setCo2PerPart] = useRecoilState(Co2ePerPartTMDataM);
  const [numberOfPartpy, setNoOfPartpy] = useRecoilState(NoOfPartsPerYearDataM);
  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
    loadersix: false,
  });

  useEffect(() => {
    if (powderManufacturer) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "DBCC-TPPS",
        input: { powder_manufacturer: powderManufacturer, technology_id: 1 },
      });

      Socket.on("DBCC-TPPS", function (data) {


        setPowderSite(data?.data?.value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderManufacturer]);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loadertwo: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-TPMS", input: "" });

    Socket.on("DBCC-TPMS", function (data) {


      setMachineSite(data?.data?.value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (powderToCustomer) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "DBCC-CEFN1",
        input: { powder_to_customer: powderToCustomer },
      });

      Socket.on("DBCC-CEFN1", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCefnOne(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderToCustomer]);
  useEffect(() => {
    if (systemToCustomer) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "DBCC-CEFN2",
        input: { system_to_customer: systemToCustomer },
      });

      Socket.on("DBCC-CEFN2", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCefnTwo(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemToCustomer]);
  useEffect(() => {
    if (materialTransportaion && cefnone && cefnTwo && systemTransportation) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "FOCC-TPCPY",
        input: {
          material_transportation: materialTransportaion,
          carbon_emission_factor_node_1: cefnone,
          carbon_emission_factor_node_2: cefnTwo,
          system_transportation: systemTransportation,
        },
      });

      Socket.on("FOCC-TPCPY", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2PerYear(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfive: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemTransportation, cefnone, cefnTwo, systemTransportation]);

  useEffect(() => {
    if (co2PerYear !== undefined && numberOfPartpy !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadersix: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-TPCPP",
        input: {
          co2e_per_year: co2PerYear,
          number_of_parts_per_year: numberOfPartpy,
        },
      });

      Socket.on("FOCC-TPCPP", function (data) {

        const value = getComputedValue(data?.data?.value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadersix: false,
          };
        });
        setCo2PerPart(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2PerYear, numberOfPartpy]);

  useEffect(() => {
    if (
      powderToCustomer === "" ||
      systemToCustomer === "" ||
      materialTransportaion === "" ||
      systemTransportation === "" ||
      cefnone === "" ||
      cefnTwo === "" ||
      co2PerYear === "" ||
      numberOfPartpy === "" ||
      co2PerPart === ""
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          Transportation: false,
        };
      });
    } else if (
      powderToCustomer !== undefined &&
      systemToCustomer !== undefined &&
      materialTransportaion !== undefined &&
      systemTransportation !== undefined &&
      cefnone !== undefined &&
      cefnTwo !== undefined &&
      co2PerYear !== undefined &&
      numberOfPartpy !== undefined &&
      co2PerPart !== undefined
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          Transportation: true,
        };
      });
    } else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          Transportation: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    materialTransportaion,
    cefnone,
    systemTransportation,
    powderToCustomer,
    systemToCustomer,
    co2PerYear,
    cefnTwo,
    numberOfPartpy,
    co2PerPart,
  ]);

  return (
    <>
      <p className="input-heading">Powder to customer (node 1) </p>

      <div className="input-section">
        <Form.Control
          onChange={(e) => setPowderToCustomer(e.target.value)}
          as="select"
          aria-label="Default Control example"
        >
          <option className="select-option">Please select method</option>
          <option
            value="Rail"
            selected={powderToCustomer === "Rail" && "selected"}
            className="select-option"
          >
            Rail
          </option>
          <option
            value="Road"
            selected={powderToCustomer === "Road" && "selected"}
            className="select-option"
          >
            Road
          </option>
          <option
            value="Air"
            selected={powderToCustomer === "Air" && "selected"}
            className="select-option"
          >
            Air
          </option>
          <option
            value="Ship"
            selected={powderToCustomer === "Ship" && "selected"}
            className="select-option"
          >
            Ship
          </option>
        </Form.Control>
        <img src="../assets/Accordionarrow.svg" alt="" className="edit-icon" />
      </div>

      <p className="input-heading">System to customer (node 2) </p>
      <div className="input-section">
        <Form.Control
          onChange={(e) => {
            setSystemToCustomer(e.target.value);
          }}
          as="select"
          aria-label="Default Control example"
        >
          <option className="select-option">Please select method</option>
          <option
            value="Rail"
            selected={systemToCustomer === "Rail" && "selected"}
            className="select-option"
          >
            Rail
          </option>
          <option
            value="Road"
            selected={systemToCustomer === "Road" && "selected"}
            className="select-option"
          >
            Road
          </option>
          <option
            value="Air"
            selected={systemToCustomer === "Air" && "selected"}
            className="select-option"
          >
            Air
          </option>
          <option
            value="Ship"
            selected={systemToCustomer === "Ship" && "selected"}
            className="select-option"
          >
            Ship
          </option>
        </Form.Control>
        <img src="../assets/Accordionarrow.svg" alt="" className="edit-icon" />
      </div>
      <p className="input-heading">
        <TooltipTrippy
          tooltipMessage="This refers to the distance the powder travels to
              reach your production site; for an estimation, you can use google
              maps setting the initial location to Maisach, Germany, and
              destination as your production facility to then insert the km in
              the input field here."
          fieldName="Powder logistics (km)"
        />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setMaterialTransportaion(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter the distance for powder logistics"
          value={materialTransportaion}
        />
      </div>
      <p className="input-heading">
        <TooltipTrippy
          tooltipMessage="This refers to the distance the system travels to
              reach your production site, for an estimation, you can use google
              maps setting the initial location to Maisach, Germany, and
              destination as your production facility to then insert the km in
              the input field here."
          fieldName="System logistics (km)"
        />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setSystemTransportation(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter the distance for system logistics"
          value={systemTransportation}
        />
      </div>

      <p className="input-heading">Carbon emission factor node 1 (kg/km)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCefnOne(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter carbon emission factor node 1"
          value={
            loader.loaderthree ? (
              <Spinner animation="border" size="sm" />
            ) : (
              cefnone
            )
          }
        />
        <TooltipTrippy
          addClass="edit-icon img-tooltip"
          fieldName={
            <img src="/assets/EditIcon.svg" alt="" className="edit-icon" />
          }
          tooltipMessage="Overridable field"
        />
      </div>

      <p className="input-heading">Carbon emission factor node 2 (kg/km)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCefnTwo(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter carbon emission factor node 2"
          value={
            loader.loaderfour ? (
              <Spinner animation="border" size="sm" />
            ) : (
              cefnTwo
            )
          }
        />
        <TooltipTrippy
          addClass="edit-icon img-tooltip"
          fieldName={
            <img src="/assets/EditIcon.svg" alt="" className="edit-icon" />
          }
          tooltipMessage="Overridable field"
        />{" "}
      </div>

      <p className="input-heading">CO2e per year (kg)</p>
      <div className="data-field">
        {loader.loaderfive ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2PerYear
        )}
      </div>
      <p className="input-heading">Number of parts per year</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setNoOfPartpy(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter number of parts per year"
          value={numberOfPartpy}
        />
      </div>
      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {loader.loadersix ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2PerPart
        )}
      </div>
    </>
  );
};

export default Transportation;
