import { atom } from "recoil";

export const CCPolyPowderPerBuildJobData = atom({
  key: "CCPolyPowderPerBuildJobData",
  default: 0,
});

export const CCPolyCo2ePerJobPMData = atom({
  key: "CCPolyCo2ePerJobPMData",
  default: undefined,
});
export const CCPolyCo2ePerPartPMData = atom({
  key: "CCPolyCo2ePerPartPMData",
  default: undefined,
});


export const CCPolyPowderLossDataM = atom({
  key: "CCPolyPowderLossDataM",
  default: undefined,
});
export const CCPolyPowderTypeDataM = atom({
  key: "CCPolyPowderTypeDataM",
  default: undefined,
});

export const CCPolyPowderEnergyJobPMDataM = atom({
  key: "CCPolyPowderEnergyJobPMDataM",
  default: undefined,
});
export const CCPolyPowderEnergyPartPMDataM = atom({
  key: "CCPolyPowderEnergyPartPMDataM",
  default: undefined,
});