import React, { useState } from "react";
import Navbar from "./Nav.jsx";
import MapSection from '../view/Map';
import { useLocation } from "react-router-dom";
import Banner from './Banner';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { toast } from "react-toastify";

const GET_REGIONS = gql`
  query GetRegionsByUserGroup($group_id : Int!){
    getRegionsByUserGroup(group_id : $group_id ){
        name
        region_id
        exchange_rate
        currency_symbol
      }
      
  }
`;
const Currency = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const calculator = query.get('calculator')
  var [currency, setCurrency] = useState('Select Currency')
  sessionStorage.setItem("currency", currency);
  const client = useApolloClient();
  let group_id = (+sessionStorage.getItem('group_id'));
  let [regionNamesWithCurrency] = useState([]);
  sessionStorage.setItem('reportStatus', 'false');

  React.useEffect(() => {

    client.query({
      query: GET_REGIONS,
      variables: { group_id },
      fetchPolicy: 'no-cache'
    }).then(res => {
      res.data.getRegionsByUserGroup.map((temp) => {
        regionNamesWithCurrency.push(temp.name + ' (' + temp.currency_symbol + ')')
        return temp.name;
      });
      const uniqueNames = Array.from(new Set(regionNamesWithCurrency));
      sessionStorage.setItem("CurrencyRegions", JSON.stringify(uniqueNames));
    }).catch(error => {
      if (error.graphQLErrors[0].extensions.exception.response.status === 401) {
        toast.error(error.graphQLErrors[0].extensions.exception.response.message)
        window.location.href = '/';
      }
    });
  });
  return (
    <React.Fragment>
      <Navbar currency={currency} />
      <Banner />
      <div className="row pad-50 fill" id="map">
        <div className="col p-0 deadcenter pt-5">
          <MapSection setCurrency={setCurrency} calculator={calculator} />
        </div>
      </div>
    </React.Fragment>
  )

}

export default Currency;
