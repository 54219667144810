import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import Form from "react-bootstrap/Form";
import { useRecoilState } from "recoil";
import {
  NumberOfPartsPerYearData,
  PowderSiteData,
  MachineSiteData,
  SystemToCustomerData,
  PowderToCustomerData,
  PowderManufacturerData,
  MaterialTransportationData,
  SystemTransportationData,
  CarbonEmissionFactorNode1Data,
  CarbonEmissionFactorNode2Data,
  Co2ePerYearData,
  Co2ePerPartTPData,
} from "atoms/Co2Polymer/TransportationAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from "atoms/General";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";

const Transportation = ({ Socket }) => {
  const [numberOfPartpy, setNumberOfPartspy] = useRecoilState(
    NumberOfPartsPerYearData
  );
  const [powderSite, setPowderSite] = useRecoilState(PowderSiteData);
  const [machineSite, setMachineSite] = useRecoilState(MachineSiteData);
  const [powderManufacturer, setPowderManufacturer] = useRecoilState(
    PowderManufacturerData
  );
  const [systemToCustomer, setSystemToCustomer] =
    useRecoilState(SystemToCustomerData);
  const [materialTransportaion, setMaterialTransportaion] = useRecoilState(
    MaterialTransportationData
  );
  const [systemTransportation, setSystemTransportation] = useRecoilState(
    SystemTransportationData
  );
  const [powderToCustomer, setPowderToCustomer] =
    useRecoilState(PowderToCustomerData);
  const [cefnone, setCefnOne] = useRecoilState(CarbonEmissionFactorNode1Data);
  const [cefnTwo, setCefnTwo] = useRecoilState(CarbonEmissionFactorNode2Data);
  const [co2PerYear, setCo2PerYear] = useRecoilState(Co2ePerYearData);
  const [co2PerPart, setCo2PerPart] = useRecoilState(Co2ePerPartTPData);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
    loadersix: false,
  });

  useEffect(() => {
    if (powderManufacturer) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "DBCC-TPPS",
        input: { powder_manufacturer: powderManufacturer, technology_id: 2 },
      });

      Socket.on("DBCC-TPPS", function (data) {


        setPowderSite(data?.data?.value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderManufacturer]);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loadertwo: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-TPMS", input: "" });

    Socket.on("DBCC-TPMS", function (data) {

      // const value = getComputedValue(data?.data?.value)

      setMachineSite(data?.data?.value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (powderToCustomer) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });



      Socket.emit("carbon-calculator", {
        key: "DBCC-CEFN1",
        input: { powder_to_customer: powderToCustomer },
      });

      Socket.on("DBCC-CEFN1", function (data) {


        setCefnOne(data?.data?.value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderToCustomer]);
  useEffect(() => {
    if (systemToCustomer) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "DBCC-CEFN2",
        input: { system_to_customer: systemToCustomer },
      });

      Socket.on("DBCC-CEFN2", function (data) {


        setCefnTwo(data?.data?.value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemToCustomer]);
  useEffect(() => {
    if (materialTransportaion && systemTransportation && cefnone && cefnTwo) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "FOCC-TPCPY",
        input: {
          material_transportation: materialTransportaion,
          system_transportation: systemTransportation,
          carbon_emission_factor_node_1: cefnone,
          carbon_emission_factor_node_2: cefnTwo,
        },
      });

      Socket.on("FOCC-TPCPY", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2PerYear(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfive: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    systemTransportation,
    materialTransportaion,
    systemTransportation,
    cefnone,
    cefnTwo,
  ]);

  useEffect(() => {
    if (co2PerYear !== undefined && numberOfPartpy) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadersix: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-TPCPP",
        input: {
          co2e_per_year: co2PerYear,
          number_of_parts_per_year: numberOfPartpy,
        },
      });

      Socket.on("FOCC-TPCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2PerPart(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadersix: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2PerYear, numberOfPartpy]);

  useEffect(() => {
    if (
      powderToCustomer === "" ||
      systemToCustomer === "" ||
      materialTransportaion === "" ||
      systemTransportation === "" ||
      cefnone === "" ||
      cefnTwo === "" ||
      co2PerYear === "" ||
      co2PerPart === ""
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          AMProcess: false,
        };
      });
    } else if (
      powderToCustomer !== undefined &&
      systemToCustomer !== undefined &&
      materialTransportaion !== undefined &&
      systemTransportation !== undefined &&
      cefnone !== undefined &&
      cefnTwo !== undefined &&
      co2PerYear !== undefined &&
      co2PerPart !== undefined
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          Transportation: true,
        };
      });
    } else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          Transportation: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    materialTransportaion,
    cefnone,
    systemTransportation,
    powderToCustomer,
    systemToCustomer,
    cefnTwo,
    co2PerYear,
    co2PerPart,
  ]);

  return (
    <>
      <p className="input-heading">Powder to customer </p>

      <div className="input-section">
        <Form.Control
          onChange={(e) => setPowderToCustomer(e.target.value)}
          as="select"
          aria-label="Default Control example"
        >
          <option className="select-option">Please select method</option>
          <option
            value="Rail"
            selected={powderToCustomer === "Rail" && "selected"}
            className="select-option"
          >
            Rail
          </option>
          <option
            value="Road"
            selected={powderToCustomer === "Road" && "selected"}
            className="select-option"
          >
            Road
          </option>
          <option
            value="Air"
            selected={powderToCustomer === "Air" && "selected"}
            className="select-option"
          >
            Air
          </option>
          <option
            value="Ship"
            selected={powderToCustomer === "Ship" && "selected"}
            className="select-option"
          >
            Ship
          </option>
        </Form.Control>
        <img src="../assets/Accordionarrow.svg" alt="" className="edit-icon" />
      </div>
      <p className="input-heading">System to customer </p>
      <div className="input-section">
        <Form.Control
          onChange={(e) => {
            setSystemToCustomer(e.target.value);
          }}
          as="select"
          aria-label="Default Control example"
        >
          <option className="select-option">Please select method</option>
          <option
            value="Rail"
            selected={systemToCustomer === "Rail" && "selected"}
            className="select-option"
          >
            Rail
          </option>
          <option
            value="Road"
            selected={systemToCustomer === "Road" && "selected"}
            className="select-option"
          >
            Road
          </option>
          <option
            value="Air"
            selected={systemToCustomer === "Air" && "selected"}
            className="select-option"
          >
            Air
          </option>
          <option
            value="Ship"
            selected={systemToCustomer === "Ship" && "selected"}
            className="select-option"
          >
            Ship
          </option>
        </Form.Control>
        <img src="../assets/Accordionarrow.svg" alt="" className="edit-icon" />
      </div>
      <p className="input-heading">
        <TooltipTrippy
          fieldName="Powder logistics (km)"
          tooltipMessage=" This refers to the distance the powder travels to
          reach your production site; for an estimation, you can use google
          maps setting the initial location to Maisach, Germany, and
          destination as your production facility to then insert the km in
          the input field here."
        />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setMaterialTransportaion(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter the distance for powder logistics"
          value={materialTransportaion}
        />
        {/* <p className="edit-icon value-unit">km</p> */}
      </div>
      <p className="input-heading">
        <TooltipTrippy
          fieldName="System logistics (km)"
          tooltipMessage=" This refers to the distance the system travels to
          reach your production site; for an estimation, you can use google
          maps setting the initial location to Maisach, Germany, and
          destination as your production facility to then insert the km in
          the input field here."
        />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setSystemTransportation(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter the distance for system logistics"
          value={systemTransportation}
        />
      </div>

      <p className="input-heading">Carbon emission factor node 1 (kg/km)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCefnOne(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={
            loader.loaderthree ? (
              <Spinner animation="border" size="sm" />
            ) : (
              cefnone
            )
          }
        />
        <OverrideAbleTip />
      </div>
      <p className="input-heading">Carbon emission factor node 2 (kg/km)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCefnTwo(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={
            loader.loaderfour ? (
              <Spinner animation="border" size="sm" />
            ) : (
              cefnTwo
            )
          }
        />
        <OverrideAbleTip />
      </div>
      <p className="input-heading">CO2e per year (kg) </p>
      <div className="data-field">
        {loader.loaderfive ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2PerYear
        )}
      </div>
      <p className="input-heading">Number of parts printed per year</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setNumberOfPartspy(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter number of parts printed per year"
          value={numberOfPartpy}
        />
      </div>
      <p className="input-heading">CO2e per part (kg) </p>
      <div className="data-field">
        {loader.loadersix ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2PerPart
        )}
      </div>
    </>
  );
};

export default Transportation;
