import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  BuildTimeDataM,
  NoOfPartsDataM,
  MachineDataM,
  MaterialDataM,
} from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  AverageMachinePowerDataM,
  EnergyBuildProcessDataM,
  EnergyPerPartDataM,
} from "atoms/Co2Metal/ElectricityConsumptionAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";

const ElectricityConsumption = ({ Socket }) => {
  const [averageMachinePower, setAverageMachinePower] = useRecoilState(
    AverageMachinePowerDataM
  );
  const [energyBuildProcess, setEnergyBuildProcess] = useRecoilState(
    EnergyBuildProcessDataM
  );
  const [energyPerPart, setEnergyPerPart] = useRecoilState(EnergyPerPartDataM);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  const buildTimeData = useRecoilValue(BuildTimeDataM);
  const noOfPartsData = useRecoilValue(NoOfPartsDataM);
  const machineId = useRecoilValue(MachineDataM);
  const materialId = useRecoilValue(MaterialDataM);
  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
  });
  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderone: true,
      };
    });


    Socket.emit("carbon-calculator", {
      key: "FOCC-ECAMP",
      input: { machine_id: machineId, material_id: materialId },
    });

    Socket.on("FOCC-ECAMP", function (data) {

      const value = getComputedValue(data?.data?.value);

      setAverageMachinePower(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (averageMachinePower !== null && buildTimeData) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-ECEBP",
        input: {
          average_machine_power: averageMachinePower,
          build_time: buildTimeData,
        },
      });

      Socket.on("FOCC-ECEBP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setEnergyBuildProcess(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageMachinePower, buildTimeData]);

  useEffect(() => {


    if (energyBuildProcess && noOfPartsData) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-ECEPP",
        input: {
          energy_build_process: energyBuildProcess,
          no_of_parts: noOfPartsData,
        },
      });

      Socket.on("FOCC-ECEPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setEnergyPerPart(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyBuildProcess, noOfPartsData]);

  useEffect(() => {
    if (
      averageMachinePower === "" ||
      energyBuildProcess === "" ||
      energyPerPart === ""
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          ElectricityConsumption: false,
        };
      });
    } else if (
      averageMachinePower !== undefined &&
      energyBuildProcess !== undefined &&
      energyPerPart !== undefined
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          ElectricityConsumption: true,
        };
      });
    } else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          ElectricityConsumption: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyPerPart, averageMachinePower, energyBuildProcess]);

  return (
    <>
      <p className="input-heading">Average machine power (W)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setAverageMachinePower(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter machine power"
          value={
            loader.loaderone ? (
              <Spinner animation="border" size="sm" />
            ) : (
              averageMachinePower
            )
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">Energy build process (kWh/job)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setEnergyBuildProcess(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please Enter energy build process"
          value={
            loader.loadertwo ? (
              <Spinner animation="border" size="sm" />
            ) : (
              energyBuildProcess
            )
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">Energy per part (kWh/part)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setEnergyPerPart(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please Enter energy per part"
          value={
            loader.loaderthree ? (
              <Spinner animation="border" size="sm" />
            ) : (
              energyPerPart
            )
          }
        />
        <OverrideAbleTip />
      </div>
    </>
  );
};

export default ElectricityConsumption;
