import React from "react";
import { BlobProvider } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import config from '../../config';
import { toast } from 'react-toastify';
import moment from 'moment'
import { NOTIFICATION_MESSAGES } from "./ConditionalEnums";

const axios = require('axios').default;

const styles = StyleSheet.create({
  page: { backgroundColor: 'white', color: 'black', fontSize: 14 },
  headerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: 10 },
  logo: { width: '18%', height: 50 },
  header: { width: '35%', fontWeight: 'bold', fontSize: 14 },
  address: { width: '25%', fontSize: 8 },
  detailsView: { padding: 20 },
  partInfoView: { display: 'flex', flexDirection: 'row' },
  colorPalletePP: { width: 10, height: 10, backgroundColor: '#7761cf' },
  colorPalleteSCPP: { width: 10, height: 10, backgroundColor: '#fcc342' },
  colorPalleteTCPP: { width: 10, height: 10, backgroundColor: '#00bff3' },
  w_100: { width: '100%' },
  w_90: { width: '90%' },
  w_50: { width: '50%' },
  w_40: { width: '40%' },
  w_33: { width: '33.3%' },
  w_30: { width: '30%' },
  w_20: { width: '20%' },
  w_25: { width: '25%' },
  w_15: { width: '15%' },
  w_10: { width: '10%' },
  d_flex: { display: 'flex' },
  row: { flexDirection: 'row' },
  para_font_size: { fontSize: '10px' },
  tableView: { fontSize: 12 },
  footerBox: { display: 'flex', justifyContent: 'end', alignItems: 'center' },
  footerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 10, position: 'absolute', bottom: -100, left: -50, width: '100%' },
});

const Doc = ({ formObj, image, currencySymbol, donutImage, typeDocument }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={[styles.w_100, styles.d_flex, styles.row, styles.headerView]}>

        <Image style={[styles.logo, styles.w_30]} src="/assets/logo.png" />
        <Text style={[styles.header, styles.w_30]}>Cost Report {formObj['machine_name'] ? formObj['machine_name'] : 'N/A'}</Text>
        <View style={{ width: '30%' }}>
          <Text size={8}>
            Additive Minds
          </Text>
          <Text size={8}>
            EOS GmbH
          </Text>
        </View>
      </View>
      <View style={styles.detailsView}>
        <View style={[styles.partInfoView, styles.tableView]}>
          <View style={{ width: '70%' }}>
            <View style={[styles.w_100, styles.d_flex, styles.row]}>
              <View style={[styles.w_50]}>
                <Text style={{ width: '30%', borderBottom: '1px solid black', fontSize: 15, fontWeight: 'bolder', color: '#00123d' }}>Part Info</Text>
              </View>
              <View style={[styles.w_50]}>
                <Text style={{ width: '50%', borderBottom: '1px solid black', fontSize: 15, fontWeight: 'bolder', color: '#00123d' }}>Job Details</Text>
              </View>

            </View>
            <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '94%' }]}></View>
            <View style={[styles.w_100, styles.d_flex, styles.row]}>
              <View style={[styles.w_50]}>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ fontSize: 11, fontWeight: 'bolder' }}>Part Volume [cm3]</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['part_volume'] && formObj['part_volume'].value}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ fontSize: 11, fontWeight: 'bolder' }}>Part Weight [kg]</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['part_weight'] ? parseFloat(formObj['part_weight'].value).toFixed(4) : 0}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ fontSize: 11, fontWeight: 'bolder' }}>Part Support Volume[cm3]</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['part_support_volume'] ? formObj['part_support_volume'].value : 0}</Text>
                </View>
                <View>
                  <Image style={{ width: '100%' }} src={donutImage ? donutImage : "/images/quijote1.jpg"} />
                </View>
                <View>
                  <View style={[styles.tableView]}>
                    <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 10 }]}>
                      <View style={[styles.w_30]}>
                        <Text style={{ fontSize: 8, fontWeight: 'bolder', color: '#7761cf' }}>Post Processing</Text>
                      </View>
                      <View style={[styles.w_15]}>
                        <View style={styles.colorPalletePP}></View>
                      </View>
                    </View>
                    <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 10 }]}>
                      <View style={[styles.w_30]}>
                        <Text style={{ fontSize: 8, fontWeight: 'bolder', color: '#fcc342' }}>System Cost Per Part</Text>
                      </View>
                      <View style={[styles.w_15]}>
                        <View style={styles.colorPalleteSCPP}></View>
                      </View>
                    </View>
                    <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 10 }]}>
                      <View style={[styles.w_30]}>
                        <Text style={{ fontSize: 8, fontWeight: 'bolder', color: '#00bff3' }}>Material Cost Per Part</Text>
                      </View>
                      <View style={[styles.w_15]}>
                        <View style={styles.colorPalleteTCPP}></View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.w_50]}>


                {['total_no_of_parts'] !== undefined ? (
                  <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px' }]}>
                    <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Parts per job</Text>
                    <Text style={{ fontSize: 8 }}>{formObj['total_no_of_parts'] ? formObj['total_no_of_parts'].value : 0}</Text>
                  </View>
                ) : (
                  <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px' }]}>
                    <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Parts per job</Text>
                    <Text style={{ fontSize: 8 }}>{formObj['part_per_job'] ? formObj['part_per_job'].value : 0}</Text>
                  </View>
                )}


                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Build time per job [h]</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['build_time'] ? formObj['build_time'].value : 0}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Set up time per job [h</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['setup_time'] ? formObj['setup_time'].value : 0}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Unpacking time per job [h]</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['unpacking_time_per_job'] ? formObj['unpacking_time_per_job'].value : 0}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Material</Text>
                  <Text style={{ fontSize: 8 }}> {formObj['material_name'] ? formObj['material_name'] : 'N/A'}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Parameter</Text>
                  <Text style={{ fontSize: 8 }}> {formObj['parameter_name_value'] ? formObj['parameter_name_value'] : 'N/A'}</Text>
                </View>
                <View style={[styles.d_flex, styles.row, styles.w_90, { justifyContent: 'space-between', marginBottom: '5px', marginTop: '6px' }]}>
                  <Text style={{ color: '#5f0d78', fontWeight: 'heavy', fontSize: 11 }}>Scrap rate</Text>
                  <Text style={{ fontSize: 8 }}>{formObj['scrape_rate'] ? formObj['scrape_rate'].value : 0}%</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ width: '30%', marginTop: '10px' }}>
            <Image style={{ width: '100%', height: '120px' }} src={image ? image : "/images/quijote1.jpg"} />
          </View>
        </View>
        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 15 }]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Pre Process</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Influencing factors</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>{currencySymbol}/h</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>h/job</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>cost / part</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20, styles.para_font_size]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Data Preparation</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Labor</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['data_preparation_currency_per_hour'] ? formObj['data_preparation_currency_per_hour'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['data_preparation_hour_per_job'] ? formObj['data_preparation_hour_per_job'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['data_preparation_cost_per_part'] ? formObj['data_preparation_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 15 }]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Build Process</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Influencing factors</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>{currencySymbol}/h</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>h/job</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>cost / part</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>AM System</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>build, set-up, cooling, unpacking time</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['system_cost_per_hour'] ? formObj['system_cost_per_hour'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['am_system_h_job'] ? formObj['am_system_h_job'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['am_system_cost_per_part'] ? formObj['am_system_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Manual Labor</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Manual, set-up and unpacking</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['manual_labor_currency_per_hour'] ? formObj['manual_labor_currency_per_hour'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['manual_labor_h_per_job'] ? formObj['manual_labor_h_per_job'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['manual_labor_cost_per_part'] ? formObj['manual_labor_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Operating Expenses</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Inert gas, power consumption</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['operating_expenses_currency_per_hour'] ? formObj['operating_expenses_currency_per_hour'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['build_time'] ? formObj['build_time'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['operating_expenses_cost_per_part'] ? formObj['operating_expenses_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 15 }]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Material</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Influencing factors</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>{currencySymbol}/Kg</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>kg/job</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>cost / part</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Material Cost</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Refreshment rates, batch</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['material_price'] ? formObj['material_price'].value : 0}t</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['material_cost_kg_per_job'] ? formObj['material_cost_kg_per_job'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['material_cost_per_part'] ? formObj['material_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 15 }]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Post-Processing</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Influencing factors</Text>
            </View>
            <View style={[styles.w_15]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>{currencySymbol}/Job</Text>
            </View>
            {/* <View style={[styles.w_20]}>
              <Text>€/Part</Text>
            </View> */}
            <View style={[styles.w_15]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>cost / part</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Powder removal & handling</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Powder handling including labor</Text>
            </View>
            <View style={[styles.w_15]}>
              <Text style={{ fontSize: 8 }}>{formObj['powder_removal_currency_per_job'] ? formObj['powder_removal_currency_per_job'].value : 0}</Text>
            </View>
            {/* <View style={[styles.w_20]}>
              <Text>{formObj['material_cost_kg_per_job'] ? formObj['material_cost_kg_per_job'].value : 0}</Text>
            </View> */}
            <View style={[styles.w_15]}>
              <Text style={{ fontSize: 8 }}>{formObj['powder_removal_cost_per_part'] ? formObj['powder_removal_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Post Processing</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Labor and other equipment</Text>
            </View>
            <View style={[styles.w_15]}>
              <Text style={{ fontSize: 8 }}>{formObj['post_processing_currency_per_job'] ? formObj['post_processing_currency_per_job'].value : 0}</Text>
            </View>
            {/* <View style={[styles.w_20]}>
              <Text>{formObj['material_cost_kg_per_job'] ? formObj['material_cost_kg_per_job'].value : 0}</Text>
            </View> */}
            <View style={[styles.w_15]}>
              <Text style={{ fontSize: 8 }}>{formObj['post_processing_cost_per_part'] ? formObj['post_processing_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 15 }]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Quality Assurance	`</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Influencing factors</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>{currencySymbol}/h</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>h/job</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>cost / part</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Laboratory</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Equipment, labor, consumption materials</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['laboratory_currency_per_job'] ? formObj['laboratory_currency_per_job'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['laboratory_currency_per_kg'] ? formObj['laboratory_currency_per_kg'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['laboratory_cost_per_part'] ? formObj['laboratory_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 15 }]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Overhead</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 12, fontWeight: 'bolder', color: '#00123d' }}>Influencing factors</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>{currencySymbol}/h</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>h/job</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 12, color: '#00123d' }}>cost / part</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row]}>
            <View style={[styles.w_20]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Overhead share</Text>
            </View>
            <View style={[styles.w_30]}>
              <Text style={{ fontSize: 8, fontWeight: 'bolder' }}>Plant management, facility costs</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}> {formObj['overhead_currency_per_job'] ? formObj['overhead_currency_per_job'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['overhead_h_per_part'] ? formObj['overhead_h_per_part'].value : 0}</Text>
            </View>
            <View style={[styles.w_10]}>
              <Text style={{ fontSize: 8 }}>{formObj['overhead_cost_per_part'] ? formObj['overhead_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 17, justifyContent: 'space-between', }]}>
            <View style={[styles.w_40]}>
              <Text style={{ fontSize: 16, fontWeight: 'bolder', color: '#00123d' }}>Total Cost per Part</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text style={{ fontSize: 16, fontWeight: 'bolder' }}>{formObj['total_cost_per_part'] ? formObj['total_cost_per_part'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%', marginTop: 2 }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row, { justifyContent: 'flex-end', }]}>

            <View style={[styles.w_20]}>
              <Text>Margin</Text>
            </View>
            <View style={[styles.w_20]}>
              <Text>{formObj['margin'] ? formObj['margin'].value : 0} %</Text>
            </View>
          </View>
        </View>

        <View style={[styles.tableView]}>
          <View style={[styles.w_100, styles.d_flex, styles.row, { marginTop: 10, justifyContent: 'space-between', }]}>
            <View style={[styles.w_40]}>
              <Text style={{ fontSize: 16, fontWeight: 'bolder', color: '#00123d' }}>Sales Price</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text style={{
                fontSize: 16
                , fontWeight: 'bolder'
              }}>{formObj['sale_price'] ? formObj['sale_price'].value : 0} {currencySymbol}</Text>
            </View>
          </View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%' }]}></View>
          <View style={[styles.d_flex, styles.row, { backgroundColor: 'black', height: 1, width: '100%', marginTop: 2 }]}></View>
          <View style={[styles.w_100, styles.d_flex, styles.row, { justifyContent: 'flex-start', marginTop: 2 }]}>

            {/* <View style={[styles.w_100]}>
              <Text style={{ fontSize: 10 }}>* Assumptions: EOS M 400, list price, depreciation period 5 years, interrest rate 2%, utilization 6500 h/year</Text>
            </View> */}
          </View>
        </View>


      </View>
      <View style={styles.footerBox}>
        <View style={styles.footerView}>
          {/* <Image src={donutImage} style={{
          width:'auto'}}
  /> */}
          <Image style={styles.logo} src="/images/quijote1.jpg" />
          <Text style={{ fontSize: 10, width: '20%', textAlign: 'right' }}>Created by: Additive Minds Printed: {new Date().toUTCString()}</Text>

        </View>
      </View>

    </Page>
  </Document>
);




function PdfGenerator({ formFields, refetchFormFields, image, symbol, donutImage, typeDocument }) {
  const [formFieldsObj, setFormFieldsObj] = React.useState()
  // console.log('Donut Image', donutImage);

  let temp = true;
  React.useEffect(() => {
  }, [])

  React.useEffect(() => {
    // Form Fields in PDF...
    // console.log('formFields', formFields)
    setFormFieldsObj(formFields)
  }, [formFields])

  // Form Fields in PDF...
  // console.log('formFieldsObj', formFieldsObj);

  // function saveDocumentToFile(doc, fileName) {
  //   const packer = new Packer();
  //   const mimeType ="application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  //   packer.toBlob(doc).then(blob => {
  //     const docblob = blob.slice(0, blob.size, mimeType);
  //     saveAs(docblob, fileName);
  //   });
  // }
  return (
    <div>
      {/* <div id="faq-header"> */}
      {(formFieldsObj && image && symbol && donutImage) && (

        <BlobProvider document={<Doc formObj={formFieldsObj} image={image} currencySymbol={symbol} donutImage={donutImage} typeDocument={typeDocument} />}>
          {({ blob, url, loading, error }) => {
            // Do whatever you need with blob here

            if (!loading && temp) {

              temp = false

              if (typeDocument === 0) {
                window.open(url, '_blank');
              } else if (typeDocument === 1) {
                let data = new FormData();
                data.append('file', blob)


                toast.info(NOTIFICATION_MESSAGES.WAIT_NOTIFY)
                axios.post(`${config.serviceUrl}client-data`, data,
                  {
                    headers: {
                      'accept': 'application/json',
                      'Accept-Language': 'en-US,en;q=0.8',
                      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    }
                  })
                  .then(function (response) {

                    if (response.data) {
                      toast.success(NOTIFICATION_MESSAGES.SUCCESS_NOTIFY)
                      window.open(`${config.serviceUrl}${response.data.data}`, '_blank');
                    }

                  })
                  .catch(function (error) {
                    console.log(error);
                  })
              }




              // if (typeDocument === 0) {
              //   window.open(url, '_blank');
              // } else if (typeDocument === 1) {
              //   // debugger

              // Specify file name
              // let fileName = "ReportSave.doc";
              // const mimeType ="application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              // const docblob = blob.slice(0, blob.size, mimeType);
              // saveAs(docblob, fileName);

              // // Create download link element
              // var downloadLink = document.createElement("a");

              // document.body.appendChild(downloadLink);
              // // debugger
              // if (navigator.msSaveOrOpenBlob) {
              //   navigator.msSaveOrOpenBlob(blob, filename);
              // } else {
              //   // var reader = new FileReader();

              //   // Read file into memory as UTF-8
              //   // reader.readAsText(blob, "UTF-8");

              //   // let tempFile = new File([blob], "XyzBlob", { type: "doc", });
              //   // Create a link to the file
              //   downloadLink.href = url;

              //   // Setting the file name
              //   downloadLink.download = filename;

              //   //triggering the function
              //   downloadLink.click();
              // }

              // document.body.removeChild(downloadLink);
              // }
            }
            return ''
          }}
        </BlobProvider>

        // <PDFDownloadLink document={<Doc formObj={formFieldsObj} image={image} currencySymbol={symbol} />} fileName={`${Date.now()}cost-report.pdf`} className="button2 early-break myorder-2" >
        //   {({ blob, url, loading, error }) => (loading ? 'Loading...' : 'Download pdf')}
        // </PDFDownloadLink>
      )}

      {/* </div> */}
      {/* </div> */}
    </div>

  )
}

export default PdfGenerator;