import { atom } from "recoil";

export const CCPolyAveragePowerData = atom({
  key: "CCPolyAveragePowerData",
  default: undefined,
});

export const CCPolyInertGasPerJobOneData = atom({
  key: "CCPolyInertGasPerJobOneData",
  default: undefined,
});
export const CCPolyInertGasPerJobTwoData = atom({
  key: "CCPolyInertGasPerJobTwoData",
  default: undefined,
});

export const CCPolyEnergyUsedPerJobData = atom({
  key: "CCPolyEnergyUsedPerJobData",
  default: undefined,
});

export const CCPolyCo2EmissionFactorData = atom({
  key: "CCPolyCo2EmissionFactorData",
  default: undefined,
});
export const CCPolyCo2ePerJobAPData = atom({
  key: "CCPolyCo2ePerJobAPData",
  default: undefined,
});
export const CCPolyCo2ePerPartAPData = atom({
  key: "CCPolyCo2ePerPartAPData",
  default: undefined,
});
