import { Text, View, } from '@react-pdf/renderer';
import React from 'react'

const Co2InPdf = ({ costOfCo2pp, styles, currency }) => {
    return (
        <>
            {
                costOfCo2pp &&
                <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    <Text style={styles.inputHeading}>
                        CO2e per part
                    </Text>
                    <Text style={styles.inputHeading}>
                        {costOfCo2pp ? `${costOfCo2pp} ${currency}` : 0}
                    </Text>
                </View>
            }
        </>
    )
}

export default Co2InPdf