import { atom } from "recoil";

export const CostData = atom({
  key: "CostData",
  default: {},
});

export const ButtonRenderState = atom({
  key: "ButtonRenderState",
  default: undefined,
});

export const CommonCarbonPricingPolyMetal = atom({
  key: "CommonCarbonPricingPolyMetal",
  default: {},
})


