export const CONDITIONALROUTES = {
  GENERATEDREPORT: "/calculator/generated-report",
  PREMIUM_REQUEST: "/premium-request",
  SELECTED_REGION_NAME_COST: "/calculator?regionName=",
  SELECTED_REGION_NAME_CARBON: "/carboncalculator?regionName=",
  VISUAL_ANALYTICS: '/calculator/visual-analytics/true',
  VISUAL_ANALYTICS_SAVED: '/calculator/visual-analytics/false',
};

export const SELECT_ROUTE = {
  CARBON: "carbon",
  COST: "cost",
};

export const STEPS = {
  ONE: "first",
  TWO: "two",
  THREE: "three",
};

export const TECHTYPE = {
  POLYMER: "Polymer",
  METAL: "Metal",
};

export const NOTIFICATION_MESSAGES = {
  WAIT_NOTIFY: "Kindly wait: word report is in processing.",
  SUCCESS_NOTIFY: "Report generated successfully in word.",
  PDF_WAIT_NOTIFY: "'Kindly Wait: PDF is in processing.'",
};

export const CALCULATOR_TYPE = {
  COST: "Cost",
  CARBON: "Carbon",
  FULL_SIMULATION: "Full Simulation",
};

export const DATAMATRIX = {
  REPRESNETER:
    " All emission factors have been taken for this first version from ADEME, The world bank, Global IPCC database",
};
