import React from "react";
import { useState } from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../components/Nav.jsx";
import Overview from "../Overview";
import LoginGuide from "../Guides/LoginGuide";
import UpdateGuide from "../Guides/UpdateGuide";
import ForgotGuide from "../Guides/ForgotGuide";
import CalcGuide from "../Guides/CalcGuide";
import RegionGuide from "../Guides/RegionGuide";
import WelcomeGuide from "../Guides/WelcomeGuide";
import CalcStepGuide from "../Guides/CalcStepGuide";
import FieldStepGuide from "../Guides/FieldStepGuide";
import HetFieldGuide from "../Guides/HetFieldGuide";
import OverrideGuide from "../Guides/OverrideGuide";
import AnalyticsGuide from "../Guides/AnalyticsGuide";
import FineTuneGuide from "../Guides/FineTuneGuide";
import CostGuide from "../Guides/CostGuide";
import CPPGuide from "../Guides/CPPGuide";
import SensitivityGuide from "../Guides/SensitivityGuide";
import MatrixGuide from "../Guides/MatrixGuide";
import ReportGenGuide from "../Guides/ReportGenGuide";
import MyReportGuide from "../Guides/MyReportGuide";
import InsightsGuide from "../Guides/InsightsGuide";

const contentType = {
    ALLOC_NONE: 0,
    OVERVIEW: 1,
    LOGIN_GUIDE: 3,
    UPDATE_GUIDE: 4,
    FORGOT_GUIDE: 5,
    CALC_GUIDE: 6,
    REGION_GUIDE: 7,
    WELCOME_GUIDE: 8,
    CALC_STEP_GUIDE: 9,
    FIELD_STEP_GUIDE: 10,
    HET_FIELD_GUIDE: 11,
    OVERRIDE_GUIDE: 12,
    ANALYTICS_GUIDE: 13,
    FINE_TUNE_GUIDE: 14,
    COST_GUIDE: 15,
    CPP_GUIDE: 16,
    SENSITVITY_GUIDE: 17,
    MATRIX_GUIDE: 18,
    REPORT_GEN_GUIDE: 19,
    MY_REPORT_GUIDE: 20,
    INSIGHTS_GUIDE: 21,
}

const Guide = () => {

    const [selectedContent, setSelectedContent] = useState(contentType.NONE);
    const [gettingStartedDropDown, setGettingStartedDropdown] = useState(false);
    const [howToDropDown, setHowToDropdown] = useState(false);
    const [calculatorDropDown, setCalculatorDropdown] = useState(false);
    const [anaylyticsDropDown, setAnalyticsDropdown] = useState(false);

    const renderGuideContent = () => {
        if (selectedContent === contentType.NONE) {
            return ""
        }
        if (selectedContent === contentType.OVERVIEW) {
            return <Overview />
        }
        if (selectedContent === contentType.LOGIN_GUIDE) {
            return <LoginGuide />
        }
        if (selectedContent === contentType.UPDATE_GUIDE) {
            return <UpdateGuide />
        }
        if (selectedContent === contentType.FORGOT_GUIDE) {
            return <ForgotGuide />
        }
        if (selectedContent === contentType.CALC_GUIDE) {
            return <CalcGuide />
        }
        if (selectedContent === contentType.REGION_GUIDE) {
            return <RegionGuide />
        }
        if (selectedContent === contentType.WELCOME_GUIDE) {
            return <WelcomeGuide />
        }
        if (selectedContent === contentType.CALC_STEP_GUIDE) {
            return <CalcStepGuide />
        }
        if (selectedContent === contentType.FIELD_STEP_GUIDE) {
            return <FieldStepGuide />
        }
        if (selectedContent === contentType.HET_FIELD_GUIDE) {
            return <HetFieldGuide />
        }
        if (selectedContent === contentType.OVERRIDE_GUIDE) {
            return <OverrideGuide />
        }
        if (selectedContent === contentType.ANALYTICS_GUIDE) {
            return <AnalyticsGuide />
        }
        if (selectedContent === contentType.FINE_TUNE_GUIDE) {
            return <FineTuneGuide />
        }
        if (selectedContent === contentType.COST_GUIDE) {
            return <CostGuide />
        }
        if (selectedContent === contentType.SENSITVITY_GUIDE) {
            return <SensitivityGuide />
        }
        if (selectedContent === contentType.MATRIX_GUIDE) {
            return <MatrixGuide />
        }
        if (selectedContent === contentType.REPORT_GEN_GUIDE) {
            return <ReportGenGuide />
        }
        if (selectedContent === contentType.MY_REPORT_GUIDE) {
            return <MyReportGuide />
        }
        else {
            return "ERROR"
        }
    }


    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <Navbar />
            <div className="row pad-50 ">
                <div className="col page-container">
                    <div id="faq-header">
                        <p className="t-4">HOW TO GUIDE</p>
                    </div>
                    <Row id="guide-container">
                        <Col md="4" id="guide-sidebar">
                            <div className="p-2 guide-item " onClick={() => {
                                setSelectedContent(contentType.OVERVIEW)
                            }}>
                                <div className="arrow-holder">
                                    {/* <img src="/assets/expand.png" alt="" className="scale-icon" />                     */}
                                </div>
                                <p>Overview</p>
                            </div>
                            <div className="p-2 guide-item" onClick={
                                () => {
                                    setGettingStartedDropdown(!gettingStartedDropDown);
                                }
                            }>
                                <div className="arrow-holder">
                                    <img className="scale-icon" src={` ${gettingStartedDropDown ? '/assets/expanded.png' : '/assets/expand.png'} `} alt="" />
                                </div>
                                <p>Getting Started</p>

                            </div>
                            <div className={`pl-4 sub-list ${gettingStartedDropDown ? 'expanded' : ''}`}>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setSelectedContent(contentType.LOGIN_GUIDE);
                                }}>
                                    <div className="arrow-holder"></div>
                                    <p>1. Login</p>
                                </div>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setSelectedContent(contentType.UPDATE_GUIDE);
                                }}>
                                    <div className="arrow-holder"></div>
                                    <p>2. Update Password</p>
                                </div>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setSelectedContent(contentType.FORGOT_GUIDE);
                                }}>
                                    <div className="arrow-holder"></div>
                                    <p>3. Forgot Password</p>
                                </div>
                            </div>
                            <div className="p-2 guide-item" onClick={
                                () => {
                                    setHowToDropdown(!howToDropDown);
                                }
                            }>
                                <div className="arrow-holder">
                                    <img className="scale-icon" src={` ${howToDropDown ? '/assets/expanded.png' : '/assets/expand.png'} `} alt="" />
                                </div>
                                <p>How To Guide</p>

                            </div>
                            <div className={`pl-4 sub-list ${howToDropDown ? 'expanded' : ''}`}>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setCalculatorDropdown(!calculatorDropDown)
                                    setSelectedContent(contentType.CALC_GUIDE)
                                }}>
                                    <div className="arrow-holder">
                                        <img src={` ${calculatorDropDown ? '/assets/expanded.png' : '/assets/expand.png'} `} alt="" className="scale-icon" />
                                    </div>
                                    <p>1. Calculator</p>
                                </div>
                                <div className={`pl-4 sub-list ${calculatorDropDown ? 'expanded' : ''}`}>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.REGION_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>a. Region Selection</p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.WELCOME_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>b. Welcome Screen</p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.CALC_STEP_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>c. Calculator Step Navigation </p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.FIELD_STEP_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>d. Text fields and Dropdowns </p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.HET_FIELD_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>e. Heterogeneous Layout Fields </p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.OVERRIDE_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>f. Override & Personalization </p>
                                    </div>
                                </div>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setAnalyticsDropdown(!anaylyticsDropDown)
                                    setSelectedContent(contentType.ANALYTICS_GUIDE);
                                }}>
                                    <div className="arrow-holder">
                                        <img src={` ${anaylyticsDropDown ? '/assets/expanded.png' : '/assets/expand.png'} `} alt="" className="scale-icon" />
                                    </div>
                                    <p>2. Visual Analytics</p>
                                </div>
                                <div className={`pl-4 sub-list ${anaylyticsDropDown ? 'expanded' : ''}`}>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.FINE_TUNE_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>a. Fine Tune Scenario</p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.COST_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>b. Cost Distribution & Build Cost Per Part</p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.SENSITVITY_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>c. Sensitivity Graphs </p>
                                    </div>
                                    <div className="p-2 pl-4 guide-item" onClick={() => {
                                        setSelectedContent(contentType.MATRIX_GUIDE)
                                    }}>
                                        <div className="arrow-holder"></div>
                                        <p>d. Cost Matrix </p>
                                    </div>
                                </div>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setSelectedContent(contentType.REPORT_GEN_GUIDE)
                                }}>
                                    <div className="arrow-holder"></div>
                                    <p>3. Report Generation</p>
                                </div>
                                <div className="p-2 pl-4 guide-item" onClick={() => {
                                    setSelectedContent(contentType.MY_REPORT_GUIDE)
                                }}>
                                    <div className="arrow-holder"></div>
                                    <p>4. My Reports</p>
                                </div>
                            </div>

                        </Col>
                        <Col md="8" id="guide-details">
                            {renderGuideContent()}
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
}

export default Guide;