import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  GetBuildTimeDataP,
  NoOfPartsData,
} from "atoms/Co2Polymer/MachineDetailsAtoms";
import {
  AveragePowerData,
  InertGasPerJobOneData,
  InertGasPerJobTwoData,
  EnergyUsedPerJobData,
  Co2EmissionFactorData,
  Co2ePerJobAPData,
  Co2ePerPartAPData,
} from "atoms/Co2Polymer/AMProcessAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { MachineData } from "atoms/Co2Polymer/MachineDetailsAtoms";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
const AmProcess = ({ Socket }) => {
  const [averagepower, setAveragePower] = useRecoilState(AveragePowerData);
  const [inertOne, setInertOne] = useRecoilState(InertGasPerJobOneData);
  const [inertTwo, setInertTwo] = useRecoilState(InertGasPerJobTwoData);
  const [energyupj, setEnergyupj] = useRecoilState(EnergyUsedPerJobData);
  const [co2ef, setCo2ef] = useRecoilState(Co2EmissionFactorData);
  const [co2pj, setCo2pj] = useRecoilState(Co2ePerJobAPData);
  const [co2pp, setCo2pp] = useRecoilState(Co2ePerPartAPData);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);
  const buildTimeData = useRecoilValue(GetBuildTimeDataP);
  const noOfPartsData = useRecoilValue(NoOfPartsData);
  const machineId = useRecoilValue(MachineData);
  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
    loadersix: false,
    loaderseven: false,
  });

  useEffect(() => {
    if (machineId) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true,
        };
      });

      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "DBCC-APAP",
        input: { machine_id: machineId },
      });

      Socket.on("DBCC-APAP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setAveragePower(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buildTimeData) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-APIGPJ1",
        input: { build_time: buildTimeData },
      });

      Socket.on("FOCC-APIGPJ1", function (data) {

        const value = getComputedValue(data?.data?.value);

        setInertOne(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inertOne) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-APIGPJ2",
        input: { inert_gas_per_job_1: inertOne },
      });

      Socket.on("FOCC-APIGPJ2", function (data) {

        const value = getComputedValue(data?.data?.value);

        setInertTwo(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertOne]);

  useEffect(() => {
    if (buildTimeData && averagepower && inertTwo) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "FOCC-APEUPJ",
        input: {
          average_power: averagepower,
          build_time: buildTimeData,
          inert_gas_per_job_2: inertTwo,
        },
      });

      Socket.on("FOCC-APEUPJ", function (data) {

        const value = getComputedValue(data?.data?.value);

        setEnergyupj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averagepower, buildTimeData, inertTwo]);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderfive: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-APCEF", input: "" });

    Socket.on("DBCC-APCEF", function (data) {

      const value = getComputedValue(data?.data?.value);

      setCo2ef(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (energyupj && co2ef) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadersix: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-APCPJ",
        input: { energy_used_per_job: energyupj, co2_fmission_factor: co2ef },
      });

      Socket.on("FOCC-APCPJ", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2pj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadersix: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyupj, co2ef]);

  useEffect(() => {
    if (co2pj && noOfPartsData) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderseven: true,
        };
      });
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-APCPP",
        input: { co2e_per_job_ap: co2pj, no_of_parts: noOfPartsData },
      });

      Socket.on("FOCC-APCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderseven: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2pj, noOfPartsData]);
  useEffect(() => {
    if (
      buildTimeData === "" ||
      averagepower === "" ||
      inertOne === "" ||
      inertTwo === "" ||
      co2pj === "" ||
      co2pp === "" ||
      energyupj === "" ||
      co2ef === ""
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          AMProcess: false,
        };
      });
    } else if (
      buildTimeData !== undefined &&
      averagepower !== undefined &&
      inertOne !== undefined &&
      inertTwo !== undefined &&
      energyupj !== undefined &&
      co2ef !== undefined &&
      co2pj !== undefined &&
      co2pp !== undefined
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          AMProcess: true,
        };
      });
    } else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          AMProcess: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inertOne,
    energyupj,
    inertTwo,
    buildTimeData,
    averagepower,
    co2ef,
    co2pj,
    co2pp,
  ]);

  return (
    <>
      <p className="input-heading">Build time (h)</p>
      <div className="data-field">
        {buildTimeData}
        {/* <p className="edit-icon value-unit">hr</p> */}
      </div>
      <p className="input-heading">Average power (kW)</p>
      <div className="data-field">
        {loader.loaderone ? (
          <Spinner animation="border" size="sm" />
        ) : (
          averagepower
        )}
        {/* <p className="edit-icon value-unit">kw</p> */}
      </div>
      <p className="input-heading">
        Inert gas per job (m<sup className="font-weight-bold">3</sup>/job)
      </p>
      <div className="data-field">
        {loader.loadertwo ? <Spinner animation="border" size="sm" /> : inertOne}
        {/* <p className="edit-icon value-unit pr-5">m3/job</p> */}
      </div>
      <p className="input-heading">Inert gas per job (kWh)</p>
      <div className="data-field">
        {loader.loaderthree ? (
          <Spinner animation="border" size="sm" />
        ) : (
          inertTwo
        )}
        {/* <p className="edit-icon value-unit">kwh</p> */}
      </div>

      <p className="input-heading">Energy used per job (kWh)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setEnergyupj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter energy used per job"
          value={
            loader.loaderfour ? (
              <Spinner animation="border" size="sm" />
            ) : (
              energyupj
            )
          }
        />
        <OverrideAbleTip />

      </div>
      <p className="input-heading">
        <TooltipTrippy fieldName="CO2e emission factor (kg/kWh)" tooltipMessage="Details on factors can be found under Data matrix tab" />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2ef(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please Enter co2e emission factor"
          value={
            loader.loaderfive ? <Spinner animation="border" size="sm" /> : co2ef
          }
        />
        <OverrideAbleTip />
      </div>
      <p className="input-heading">CO2e per job (kg)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2pj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter co2e per job"
          value={
            loader.loadersix ? <Spinner animation="border" size="sm" /> : co2pj
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {loader.loaderseven ? <Spinner animation="border" size="sm" /> : co2pp}
        {/* <p className="edit-icon value-unit">kg</p> */}
      </div>
    </>
  );
};

export default AmProcess;
