import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_STEP_DETAILS } from "./getStepDetail";

const MachineDetails = ({ StepID }) => {
  const client = useApolloClient();
  const [stepDetails, setStepDetails] = useState([]);

  useEffect(() => {
    const GetClientSteps = () => {
      client
        .query({
          query: GET_STEP_DETAILS,
          variables: { step_id: StepID },
        })
        .then(({ data }) => {

          setStepDetails(data.getFieldsByStepId);
        })
        .catch((error) => {
          console.log("error", error);
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
    };

    GetClientSteps();
  }, [stepDetails]);

  return (
    <>
      <p className="input-heading">Component MFG</p>
      <div className="data-field">Automatically Display</div>

      <p className="input-heading">Mode Of Transarent</p>
      <div className="data-field">Automatically Display</div>

      <p className="input-heading">Distance</p>
      <div className="data-field">Automatically Display</div>

      <p className="input-heading">Carbon Content Transportation</p>
      <div className="data-field">Automatically Display</div>

      <p className="input-heading">Component Assembly</p>
      <div className="data-field">Automatically Display</div>

      <p className="input-heading">Bound energy/system</p>
      <div className="data-field">Automatically Display</div>
    </>
  );
};

export default MachineDetails;
