import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Login from "./app/view/Login.jsx";
import ResetPassword from "./app/view/ResetPassword.jsx";
import RequestReset from "./app/view/RequestReset.jsx";
import UpdatePassword from "./app/view/UpdatePassword.jsx";
import RecoverPassword from "./app/view/recover-password";
import Calculator from "./app/view/Calculator.jsx";
import CalculatorProcess from "./app/components/CalculatorHeader";
import MyReport from "./app/view/MyReport";
import Contact from "./app/view/Contact";
import FAQ from "./app/view/FAQ";
import Guide from "./app/view/Guide";
import CCPolymerSteps from "app/view/CCPolymerSteps/index";
import CCCarbonMetal from "app/view/CCCarbonMetal";
import CCSteps from "./app/view/CCSteps";
import CCStepsMetal from "app/view/CCStepsMetal";
import MyScenario from "app/view/MyScenario";
import MyAnalytics from "app/view/MyAnalytics";
import Co2Scenario from "app/view/Co2Scenario";
import GeneratedReport from "app/view/GeneratedReport";
import "bootstrap/dist/css/bootstrap.min.css";
import DataMatrix from "app/view/DataMatrix";
//Redux implementation
import { Provider } from "react-redux";
import store from "./state/index";

import PdfGenerator from "./app/components/PdfGenerator";

import Analytics from "./app/view/Analytics";
import CostReport from "./app/view/CostReport";
import Currency from "./app/components/Currency";
import { ApolloProvider } from "react-apollo";
import { RecoilRoot } from "recoil";
import { setContext } from "apollo-link-context";
import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import config from "./config";
import PremiumRequestPage from "app/view/PremiumRequest";
const cache = new InMemoryCache();
//en-point for http link
const httpLink = new HttpLink({
  uri: config.proxyGraphQLUrl,
});
//end-point for webSocket link
const wsLink = new WebSocketLink({
  uri: config.proxyWSGraphQLUrl,
  options: {
    reconnect: true,
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("access_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});
const middlewareLink = authLink.concat(httpLink);
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  middlewareLink
);
//make client
const client = new ApolloClient({
  link: link,
  cache,
});

function App() {
  const signInStatus = sessionStorage.getItem("signInstatus");
  React.useEffect(() => {
    const signInflag = sessionStorage.getItem("signIn");
    if (signInflag) {
      setTimeout(() => {
        window.sessionStorage.clear();
        window.location.href = `/`;
      }, 28800000); //after 8 hour user session will expire
    }
  });

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <RecoilRoot>
          <Switch>
            <Route path="/" component={signInStatus ? Currency : Login} exact />
            <Route
              path="/region-select"
              component={signInStatus ? Currency : Login}
            />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/request-reset" component={RequestReset} />
            <Route
              path="/update-password"
              component={signInStatus ? UpdatePassword : Login}
            />
            <Route path="/recover-password" component={RecoverPassword} />
            <Route
              path="/premium-request"
              component={PremiumRequestPage}
              exact
            />

            <Route
              path="/carbon/co2-scenario/:id"
              component={Co2Scenario}
              exact
            />
            <Route
              path="/calculator/cost-report"
              component={CostReport}
              exact
            />
            <Route
              path="/calculator/generated-report"
              component={GeneratedReport}
              exact
            />

            <Route
              path="/calculator/visual-analytics/:savedlist"
              component={Analytics}
              exact
            />
            <Route
              path="/calculator/process"
              component={CalculatorProcess}
              exact
            />
            <Route path="/calculator" component={Calculator} />
            <Route path="/report" component={MyReport} />
            <Route path="/scenarios" component={MyScenario} />
            <Route path="/myanalytics" component={MyAnalytics} />
            <Route path="/faq" component={FAQ} />
            <Route path="/data-matrix" component={DataMatrix} />
            <Route path="/how-to-guide" component={Guide} />
            <Route path="/pdf" component={PdfGenerator} />
            {/* <Route path="/carboncalculator" component={CarbonCalculator} exact /> */}
            <Route
              path="/carboncalculator/polymer-steps"
              component={CCSteps}
              exact
            />
            <Route
              path="/carboncalculator/metal-steps"
              component={CCStepsMetal}
              exact
            />
            <Route
              path="/carboncalculator/carbon-polymer"
              component={CCPolymerSteps}
              exact
            />
            <Route
              path="/carboncalculator/carbon-metal"
              component={CCCarbonMetal}
              exact
            />
          </Switch>
        </RecoilRoot>
      </ApolloProvider>
    </Provider>
  );
}
export default App;
