import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { Spinner } from 'reactstrap';

const GenerateReportPop = ({ isOpen, setIsOpen, scenarios, setSelectedScenarioName, formFields }) => {
  const [reportLoader] = useState(false);

  const close = () => {
    setIsOpen(false);
  }

  const sceanrioTabPreview = (id, name) => {
    // debugger;
    document.getElementById(`${id}-scenario`).classList.add('scenario-tab-active');
    setSelectedScenarioName(scenarios[id]);
    sessionStorage.setItem('scenario_name', name);
    sessionStorage.setItem(`calculator_values_${name}`, JSON.stringify({ ...formFields[name] }));
  }



  return (
    <Modal
      className="custom-modal-dialog"
      dialogClassName="modal60w"
      show={isOpen}
    >
      <div id="popup-warning">
        <div id="popup-box">
          <div id="warning-details">
            <p className="warning-heading mb-3">
              <img src="../assets/report_black_icon.png" width="23" height="24" alt="" />
              <span className="report-tt">Report</span></p>
            <div id="sceanrio-btn">
              {scenarios.length > 0 && scenarios.map((scenario, index) => {
                return (
                  <a href={`./cost-report`} className="button3 download-button"
                    onClick={() => sceanrioTabPreview(index, scenario)}>
                    {reportLoader === 0 && <Spinner className="mr-1" size="sm" />}
                    <p>Scenario {index + 1} ({scenario})</p>
                  </a>
                )
              })
              }

              <div className="button3 gray ml-2">
                <p onClick={close}>Cancel</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default GenerateReportPop;