import React, { Component } from "react";
import Navbar from "../components/Nav.jsx";

class FAQ extends Component {
  render () { return ( 
            <React.Fragment>

                <Navbar />
                
                <div className="row pad-50 ">
                    <div className="col page-container">
                        <div id="faq-header">
                            <p className="t-4">FAQ</p>
                            <div className="searchboxdiv">
                                <input type="text" className="login-input" placeholder="Search" /> 
                                <img alt="" src="../assets/search.png" width="23" height="23"/>   
                            </div>
                        </div>
                        <div id="faq-container">
                            <div className="faq">
                                <div className="faq-title">
                                    <p>How to get started ?</p>
                                </div>
                                <div className="faq-body">
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium nibh. Nulla suscipit tellus ex, eget eleifend nunc scelerisque vitae. Morbi tincidunt fringilla urna, in cursus ligula pulvinar sit amet. Praesent faucibus tempor ornare. Nam ac pretium risus, quis tincidunt dui. Aliquam lobortis dolor eu ornare ullamcorper. Pellentesque ultrices mi sit amet vehicula pellentesque. Donec aliquam venenatis elit et egestas. Sed mattis sapien ac neque gravida varius. Phasellus et nunc nibh. Phasellus cursus ultrices suscipit. Praesent vel sollicitudin lorem. Ut aliquet elit at commodo vulputate. Morbi pretium leo a magna condimentum bibendum. Praesent dui neque, egestas vel tempor ac, tincidunt vel magna. Fusce faucibus, libero facilisis cursus volutpat, turpis tellus egestas diam, quis congue tortor sapien id felis. Duis non urna arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sodales auctor posuere. Cras blandit est purus. Vivamus mattis dignissim porta. Nam et accumsan eros. Phasellus malesuada tincidunt felis, sed lobortis lorem varius a. Vivamus varius turpis ut aliquet accumsan. Morbi commodo urna libero, quis sodales enim vehicula a. Nam eget ex dictum, varius ante non, convallis diam.</p>
                                </div>
                            </div>
                        
                            <div className="faq">
                                <div className="faq-title">
                                    <p>What is CPP ?</p>
                                </div>
                                <div className="faq-body">
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium nibh. Nulla suscipit tellus ex, eget eleifend nunc scelerisque vitae. Morbi tincidunt fringilla urna, in cursus ligula pulvinar sit amet. Praesent faucibus tempor ornare. Nam ac pretium risus, quis tincidunt dui. Aliquam lobortis dolor eu ornare ullamcorper. Pellentesque ultrices mi sit amet vehicula pellentesque. Donec aliquam venenatis elit et egestas. Sed mattis sapien ac neque gravida varius. Phasellus et nunc nibh. Phasellus cursus ultrices suscipit. Praesent vel sollicitudin lorem. Ut aliquet elit at commodo vulputate. Morbi pretium leo a magna condimentum bibendum. Praesent dui neque, egestas vel tempor ac, tincidunt vel magna. Fusce faucibus, libero facilisis cursus volutpat, turpis tellus egestas diam, quis congue tortor sapien id felis. Duis non urna arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sodales auctor posuere. Cras blandit est purus. Vivamus mattis dignissim porta. Nam et accumsan eros. Phasellus malesuada tincidunt felis, sed lobortis lorem varius a. Vivamus varius turpis ut aliquet accumsan. Morbi commodo urna libero, quis sodales enim vehicula a. Nam eget ex dictum, varius ante non, convallis diam.</p>
                                </div>
                            </div>
                        
                            <div className="faq">
                                <div className="faq-title">
                                    <p>Vestibulum non justo ?</p>
                                </div>
                                <div className="faq-body">
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium nibh. Nulla suscipit tellus ex, eget eleifend nunc scelerisque vitae. Morbi tincidunt fringilla urna, in cursus ligula pulvinar sit amet. Praesent faucibus tempor ornare. Nam ac pretium risus, quis tincidunt dui. Aliquam lobortis dolor eu ornare ullamcorper. Pellentesque ultrices mi sit amet vehicula pellentesque. Donec aliquam venenatis elit et egestas. Sed mattis sapien ac neque gravida varius. Phasellus et nunc nibh. Phasellus cursus ultrices suscipit. Praesent vel sollicitudin lorem. Ut aliquet elit at commodo vulputate. Morbi pretium leo a magna condimentum bibendum. Praesent dui neque, egestas vel tempor ac, tincidunt vel magna. Fusce faucibus, libero facilisis cursus volutpat, turpis tellus egestas diam, quis congue tortor sapien id felis. Duis non urna arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sodales auctor posuere. Cras blandit est purus. Vivamus mattis dignissim porta. Nam et accumsan eros. Phasellus malesuada tincidunt felis, sed lobortis lorem varius a. Vivamus varius turpis ut aliquet accumsan. Morbi commodo urna libero, quis sodales enim vehicula a. Nam eget ex dictum, varius ante non, convallis diam.</p>
                                </div>
                            </div>
                            <div className="faq">
                                <div className="faq-title">
                                    <p>Sed eget sem eget ? ?</p>
                                </div>
                                <div className="faq-body">
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium nibh. Nulla suscipit tellus ex, eget eleifend nunc scelerisque vitae. Morbi tincidunt fringilla urna, in cursus ligula pulvinar sit amet. Praesent faucibus tempor ornare. Nam ac pretium risus, quis tincidunt dui. Aliquam lobortis dolor eu ornare ullamcorper. Pellentesque ultrices mi sit amet vehicula pellentesque. Donec aliquam venenatis elit et egestas. Sed mattis sapien ac neque gravida varius. Phasellus et nunc nibh. Phasellus cursus ultrices suscipit. Praesent vel sollicitudin lorem. Ut aliquet elit at commodo vulputate. Morbi pretium leo a magna condimentum bibendum. Praesent dui neque, egestas vel tempor ac, tincidunt vel magna. Fusce faucibus, libero facilisis cursus volutpat, turpis tellus egestas diam, quis congue tortor sapien id felis. Duis non urna arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sodales auctor posuere. Cras blandit est purus. Vivamus mattis dignissim porta. Nam et accumsan eros. Phasellus malesuada tincidunt felis, sed lobortis lorem varius a. Vivamus varius turpis ut aliquet accumsan. Morbi commodo urna libero, quis sodales enim vehicula a. Nam eget ex dictum, varius ante non, convallis diam.</p>
                                </div>
                            </div>
                        
                        
                        </div>
                        
                        
                
                    </div>
                    
                </div>
            
            </React.Fragment>
        )
    }
}

export default FAQ;