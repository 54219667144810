import React from "react";

const UpdateGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Update Password</h3>
            <p>If the user requires they can update their password after they have logged in to the system. The update password feature can be accessed from dropdown menu that is shown on click on profile icon.</p>
            <img src="/assets/update-guide-1.png" alt="" className="img-fluid" />
            <p>Clicking on update password will take you to the update password page. On this page you can update your password to a new one by entering your old password along with it. </p>
            <img src="/assets/update-guide-2.png" alt="" className="img-fluid" />
        </>
    )
}
export default UpdateGuide;