import { atom } from "recoil";

export const CCMetalAverageMachinePowerDataM = atom({
  key: "CCMetalAverageMachinePowerDataM",
  default: undefined,
});

export const CCMetalEnergyBuildProcessDataM = atom({
  key: "CCMetalEnergyBuildProcessDataM",
  default: undefined,
});
export const CCMetalEnergyPerPartDataM = atom({
  key: "CCMetalEnergyPerPartDataM",
  default: undefined,
});
