import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  TotalCo2PerPartData,
  CostOfOneKGCo2Data,
  CostOfCo2PerPartFData,
  CppWithCo2Data,
} from "atoms/Co2Polymer/Co2ProcessAtoms";
import { Co2ePerPartPMData } from "atoms/Co2Polymer/PowderMfgAtoms";
import { Co2ePerPartAPData } from "atoms/Co2Polymer/AMProcessAtoms";
import { Co2ePerPartMPData } from "atoms/Co2Polymer/MaterialProcessingAtoms";
import { Co2ePerPartTPData } from "atoms/Co2Polymer/TransportationAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import config from "../../../../../config/index";

const Co2Process = ({ Socket }) => {
  const [regionID, setRegionID] = useState(0);
  const [totalCo2pp, setTotalCo2pp] = useRecoilState(TotalCo2PerPartData);
  const [costOneKGCo2, setCostOneKGCo2] = useRecoilState(CostOfOneKGCo2Data);
  const [costOfCo2pp, setCostOfCo2pp] = useRecoilState(CostOfCo2PerPartFData);
  const [cppWithCo2, setCppWithCo2] = useRecoilState(CppWithCo2Data);
  const co2PerPartPM = useRecoilValue(Co2ePerPartPMData);
  const co2PerPartAP = useRecoilValue(Co2ePerPartAPData);
  const co2PerPartMP = useRecoilValue(Co2ePerPartMPData);
  const co2PerPartTP = useRecoilValue(Co2ePerPartTPData);
  const { getComputedValue } = useRoundOffValue();
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
  });
  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);
  useEffect(() => {
    const getRegionId = () => {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      let array = {};
      array["region_id"] = { value: parseInt(obj?.region_id) };
      setRegionID(array);
    };
    getRegionId();
    return () => clearInterval;
  }, []);

  //   console.log("regionID: ", regionID?.region_id?.value);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderone: true,
      };
    });
    Socket.emit("carbon-calculator", {
      key: "DBCC-CPCPOKC",
      input: { technology_id: 2, region_id: regionID?.region_id?.value },
    });
    Socket.on("DBCC-CPCPOKC", function (data) {

      const value = getComputedValue(data?.data?.value);

      setCostOneKGCo2(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionID?.region_id?.value]);

  useEffect(() => {
    if (
      co2PerPartAP !== null &&
      co2PerPartAP !== undefined &&
      co2PerPartMP !== null &&
      co2PerPartMP !== undefined &&
      co2PerPartTP !== null &&
      co2PerPartTP !== undefined &&
      co2PerPartPM !== null &&
      co2PerPartPM !== undefined
    ) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPTCPP",
        input: {
          co2e_per_part_ap: co2PerPartAP,
          co2e_per_part_mp: co2PerPartMP,
          co2e_per_part_pm: co2PerPartPM,
          co2e_per_part_tmp: co2PerPartTP,
          technology_id: 2,
        },
      });

      Socket.on("FOCC-CPTCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setTotalCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2PerPartAP, co2PerPartMP, co2PerPartPM, co2PerPartTP]);

  useEffect(() => {
    if (totalCo2pp !== undefined && costOneKGCo2 !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCCPP",
        input: {
          total_co2e_per_part: totalCo2pp,
          cost_of_producing_one_kg_co2e: costOneKGCo2,
          technology_id: 2,
        },
      });

      Socket.on("FOCC-CPCCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCostOfCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCo2pp, costOneKGCo2]);

  useEffect(() => {
    if (costOfCo2pp !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCPPWC",
        input: { cost_of_co2e_per_part: costOfCo2pp },
      });

      Socket.on("FOCC-CPCPPWC", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCppWithCo2(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costOfCo2pp]);

  useEffect(() => {
    if (totalCo2pp != null && costOneKGCo2 != null && costOfCo2pp != null) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonPricing: true,
          ScenarioAnalysis: true,
        };
      });
    }
  }, [totalCo2pp, costOneKGCo2, costOfCo2pp]);

  return (
    <>
      <p className="input-heading">Total CO2e per part (kg)</p>
      <div className="data-field">
        {loader.loadertwo ? (
          <Spinner animation="border" size="sm" />
        ) : (
          totalCo2pp
        )}
        {/* <p className="edit-icon value-unit ">kg</p> */}
      </div>
      <p className="input-heading">
        Carbon shadow pricing factor  ({obj.currency_symbol}/kg) <a className="click-here" href={config.carbonShadowSuggestionUrl} target="_blank">(Click here for more details)</a>
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCostOneKGCo2(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter carbon shadow pricing factor"
          value={
            loader.loaderone ? (
              <Spinner animation="border" size="sm" />
            ) : (
              costOneKGCo2
            )
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">Cost of CO2e per part ({obj.currency_symbol})</p>
      <div className="data-field">
        {loader.loaderthree ? (
          <Spinner animation="border" size="sm" />
        ) : (
          costOfCo2pp
        )}
        {/* <p className="edit-icon value-unit ">€</p> */}
      </div>
      <p className="input-heading">
        <TooltipTrippy
          fieldName="CPP with CO2e"
          tooltipMessage="Why is it greyed out? This is linked with the Cost per
          Part (CPP) calculator since you
          selected only carbon footprint calculation. If you are interested
          in calculating CPP with CO2e, please choose full simulation for
          your next analysis."
        />
      </p>

      <div className="data-field lastfield">&nbsp;</div>
    </>
  );
};

export default Co2Process;
