import React, { useState, useEffect } from "react";
import Nav from "app/components/Nav";
import socketIOClient from "socket.io-client";
import constant from "../../../utils/constants";
import CarbonPolymerProcess from "./components/CarbonPolymerProcess";
import Scenario from "./components/Scenario";
import CurrentReports from "./components/CurrentReports";
import CarbonPolymerCalculator from "./components/CarbonPolymerCalculator";
import "../styles/styles.css";
import "../CarbonCalculator/components/styles.css";
import Analytics from "../Analytics";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState } from "recoil";
import { ProcessFlag } from "atoms/General";

const CCPolymerSteps = () => {
  const [step, setStep] = useState("process");
  const [socket, setSocket] = useState();
  const [StepID, setSetupID] = useState(0);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  useEffect(() => {
    const socketOb = new socketIOClient(constant.uri);
    const patch = require("socketio-wildcard")(socketIOClient.Manager);
    patch(socketOb);
    const socketObj = socketOb;
    setSocket(socketObj);
  }, []);

  // Get the Step Id from the  Carbon Polymer Process...
  const getCarbonPolyerStepID = (stepId) => {
    setSetupID(stepId);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Nav />
      <div className="row  ">
        <div className="col page-container px-0">
          <div className="ccsteps-container">
            <div className="ccsteps-section">
              <button
                onClick={() => setStep("process")}
                className={
                  step === "process"
                    ? "selection-button m-0"
                    : "selection-button unselected-btn m-0"
                }
              >
                Process
              </button>
              <button
                onClick={() => {
                  if (processFlag.Transportation) {
                    setStep("carbonpricing");
                  } else {
                    toast.error("Please fill all process details.");
                  }
                }}
                className={
                  step === "carbonpricing"
                    ? "selection-button"
                    : "selection-button unselected-btn"
                }
              >
                Carbon Pricing
              </button>
              <button
                onClick={() => {
                  if (processFlag.CarbonPricing) {
                    setStep("scenario");
                    setProcessFlag((prevState) => {
                      return {
                        ...prevState,
                        ScenarioAnalysis: true,
                      };
                    });
                  } else {
                    toast.error("Please fill all carbon pricing details.");
                  }
                }}
                className={
                  step === "scenario"
                    ? "selection-button"
                    : "selection-button unselected-btn"
                }
              >
                Scenarios
              </button>
              <button
                onClick={() => {
                  if (processFlag.ScenarioAnalysis) {
                    setStep("visual");
                  } else {
                    toast.error("Please fill all scenario analysis details.");
                  }
                }}
                className={
                  step === "visual"
                    ? "selection-button"
                    : "selection-button unselected-btn"
                }
              >
                Visual Analytics
              </button>
              <button
                onClick={() => {
                  if (processFlag.ScenarioAnalysis) {
                    setStep("reports");
                  } else {
                    toast.error("Please fill all visual analytics details.");
                  }
                }}
                className={
                  step === "reports"
                    ? "selection-button"
                    : step === "reportstwo"
                      ? "selection-button "
                      : "selection-button unselected-btn"
                }
              >
                Reports
              </button>
            </div>
            {step === "process" && (
              <CarbonPolymerProcess
                socket={socket}
                getCarbonPolyerStepID={getCarbonPolyerStepID}
                ScenarioState={() => setStep("carbonpricing")}
              />
            )}
            {step === "scenario" && (
              <Scenario
                socket={socket}
                ScenarioState={() => setStep("visual")}
              />
            )}
            {step === "visual" && (
              <Analytics
                ScenarioState={() => setStep("reports")}
                socket={socket}
              />
            )}
            {step === "reports" && (
              <CurrentReports
                socket={socket}
                ScenarioState={() => setStep("currentreports")}
              />
            )}
            {step === "carbonpricing" && (
              <CarbonPolymerCalculator
                socket={socket}
                StepID={StepID}
                ScenarioState={() => setStep("scenario")}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CCPolymerSteps;
