import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  TotalCarbonPerPartDataM,
  CostOfOneKGCo2DataM,
  CostOfCo2PerPartFDataM,
  CppWithCo2DataM,
} from "atoms/Co2Metal/Co2PricingMetal";
import {
  Co2EmissionBuildProcessDataM,
  Co2EmissionGasDataM,
  Co2EmissionPowderDataM,
  Co2EmissionTransportationDataM,
} from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from "atoms/General";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import { DebounceInput } from "react-debounce-input";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import config from "../../../../../config/index";


const Co2Pricing = ({ Socket }) => {
  const [regionID, setRegionID] = useState(0);
  const [totalCo2pp, setTotalCo2pp] = useRecoilState(TotalCarbonPerPartDataM);
  const [costOneKGCo2, setCostOneKGCo2] = useRecoilState(CostOfOneKGCo2DataM);
  const [costOfCo2pp, setCostOfCo2pp] = useRecoilState(CostOfCo2PerPartFDataM);
  const [cppWithCo2, setCppWithCo2] = useRecoilState(CppWithCo2DataM);
  const co2EmissionBuildProcess = useRecoilValue(Co2EmissionBuildProcessDataM);
  const co2EmissionPowder = useRecoilValue(Co2EmissionPowderDataM);
  const co2EmissionGas = useRecoilValue(Co2EmissionGasDataM);
  const co2EmissionTransportation = useRecoilValue(
    Co2EmissionTransportationDataM
  );
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);
  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
  });
  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);
  useEffect(() => {
    const getRegionId = () => {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      let array = {};
      array["region_id"] = { value: parseInt(obj?.region_id) };
      setRegionID(array);
    };
    getRegionId();
    return () => clearInterval;
  }, []);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loadertwo: true,
      };
    });
    Socket.emit("carbon-calculator", {
      key: "DBCC-CPCPOKC",
      input: { technology_id: 1, region_id: regionID?.region_id?.value },
    });

    Socket.on("DBCC-CPCPOKC", function (data) {
      const value = getComputedValue(data?.data?.value);
      setCostOneKGCo2(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionID?.region_id?.value]);

  useEffect(() => {
    if (
      co2EmissionBuildProcess !== undefined &&
      co2EmissionPowder !== undefined &&
      co2EmissionGas !== undefined &&
      co2EmissionTransportation !== undefined
    ) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "FOCC-CPTCPP",
        input: {
          co2_emission_build_process: co2EmissionBuildProcess,
          co2_emission_powder: co2EmissionPowder,
          co2_emission_gas: co2EmissionGas,
          co2_emission_transportation: co2EmissionTransportation,
          technology_id: 1,
        },
      });

      Socket.on("FOCC-CPTCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setTotalCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2EmissionBuildProcess,
    co2EmissionGas,
    co2EmissionPowder,
    co2EmissionTransportation,
    costOneKGCo2,
  ]);

  useEffect(() => {
    if (totalCo2pp !== undefined && costOneKGCo2 !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCCPP",
        input: {
          total_co2e_per_part: totalCo2pp,
          cost_of_producing_one_kg_co2e: costOneKGCo2,
          technology_id: 1,
        },
      });

      Socket.on("FOCC-CPCCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCostOfCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCo2pp, costOneKGCo2]);

  useEffect(() => {
    if (costOfCo2pp !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCPPWC",
        input: { cost_of_co2e_per_part: costOfCo2pp },
      });

      Socket.on("FOCC-CPCPPWC", function (data) {

        // const value = getComputedValue(data?.data?.value)

        setCppWithCo2(data?.data?.value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costOfCo2pp]);

  useEffect(() => {
    if (totalCo2pp != null && costOneKGCo2 != null && costOfCo2pp != null) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonPricing: true,
          ScenarioAnalysis: true,
        };
      });
    }
  }, [totalCo2pp, costOneKGCo2, costOfCo2pp]);

  return (
    <>
      <p className="input-heading">Total carbon per part (kg)</p>
      <div className="data-field">
        {loader.loaderone ? (
          <Spinner animation="border" size="sm" />
        ) : (
          totalCo2pp
        )}
      </div>
      <p className="input-heading">
        Carbon shadow pricing factor  ({obj.currency_symbol}/kg) <a className="click-here" href={config.carbonShadowSuggestionUrl} target="_blank">(Click here for more details)</a>

      </p>
      <div >
        {loader.loadertwo ? (
          <div className="data-field">
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <div className="input-section">
            <DebounceInput
              type="number"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setCostOneKGCo2(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              placeholder="Please enter carbon shadow pricing factor"
              value={
                loader.loaderone ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  costOneKGCo2
                )
              }
            />
            <OverrideAbleTip />
          </div>
        )}
      </div>
      <p className="input-heading">Cost of CO2e per part ({obj.currency_symbol})</p>
      <div className="data-field">
        {loader.loaderthree ? (
          <Spinner animation="border" size="sm" />
        ) : (
          costOfCo2pp
        )}
      </div>
      <p className="input-heading">
        <TooltipTrippy fieldName="CPP with CO2e" tooltipMessage="Why is it greyed out? This is linked with the Cost per
        Part (CPP) calculator since you selected only carbon footprint calculation. If you are interested in calculating CPP with CO2e, please choose 'full simulation' for your next analysis. " />

      </p>

      <div className="data-field lastfield">&nbsp;</div>
    </>
  );
};

export default Co2Pricing;
