import React from "react";

const FieldStepGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Text fields and Dropdowns</h3>
            <p>On the calculator you will find two types of input fields that are text fields or dropdown. Using
                the text fields you can add either strings or numeric values as per required. The dropdowns help
                you choose inputs for calculations like system, materials and parameters etc.
            </p>
            <img src="/assets/field-guide-1.png" alt="" className="img-fluid" />

        </>
    )
}

export default FieldStepGuide;