import React from "react";

const CalcGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Calculator</h3>
            <p>The calculator can be accessed via top menu by clicking on calculator item in the header. It is also the default item shown on login. </p>
            <img src="/assets/calculator-guide-1.png" alt="" className="img-fluid" />
        </>
    )
}

export default CalcGuide;