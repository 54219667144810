import React from "react";
import Navbar from "../components/Nav.jsx";
import config from '../../config';
import { useState } from "react";

const axios = require('axios').default;

const MyReport = () => {
    sessionStorage.setItem('reportStatus', 'false');
    const [reportsList, setReportsList] = useState([])
    const [count, setCount] = useState([]);
    const [isLoading, setLoading] = useState(false)
    let access_token = ''
    const pageSize = 10

    React.useEffect(() => {
        // access_token
        // console.log('access_token', access_token)
        getListOfReports(0)
        // eslint-disable-next-line
    }, [])
    const deleteReport = async (ReportId) => {
        access_token = sessionStorage.getItem('access_token');
        setLoading(true)

        await axios.delete(`${config.serviceUrl}report`, {
            params: {
                reportId: ReportId
            },
            headers: {
                'Authorization': access_token
            }
        })
            .then(function (response) {
                getListOfReports(0)
            })
            .catch(function (error) {
                console.log(error);
            })
        setLoading(false)

    }

    const getListOfReports = async (pageNo) => {
        access_token = sessionStorage.getItem('access_token');
        setLoading(true)
        await axios.get(`${config.serviceUrl}report`, {
            params: {
                pageSize: pageSize,
                pageNo: pageNo,
                isPaging: true
            },
            headers: {
                'Authorization': access_token
            }
        })
            .then(function (response) {
                setReportsList(response.data.data.reports)
                setCount(response.data.data.count)

            })
            .catch(function (error) {
                console.log(error);
            })
        setLoading(false)

    }

    function changePaginationClick(val) {

        getListOfReports(val || 0)
    }

    return (
        <React.Fragment>

            <Navbar />

            <div className="row pad-50 ">
                <div className="col page-container">
                    <p className="t-2">MY REPORTS</p>

                    {/* <ReactTable
                            data={data}
                            columns={columns}
                            defaultPageSize={2}
                            pageSizeOptions={[2, 4, 6]}
                        /> */}
                    {isLoading ?
                        'Loading...' :
                        <table className="table table-responsive-sm" id="report-table">
                            <thead>
                                <th>Report ID</th>
                                <th>Report Name</th>
                                <th>Date / Time</th>
                                <th>Type</th>
                                <th>Machine</th>
                                <th>Material</th>
                                <th className="center-text">Actions</th>
                            </thead>
                            <tbody>
                                {reportsList && reportsList.map((report, i) => {
                                    return (
                                        <tr>
                                            <td>{report.report_id}</td>
                                            <td>{report.report_name}</td>
                                            <td>{report.created_at.slice(0, 10)}</td>
                                            <td>{report.technology_name + ' ' + report.step_type_name}</td>
                                            <td>{report.machine_name}</td>
                                            <td>{report.material_name}</td>
                                            <td className="d-flex align-items-center justify-content-around"><a href={"./calculator/cost-report?id=" + report.report_id} className="center"><img src="../assets/eye.png" alt="" height="18" width="27" className="no-hide" /></a><button onClick={() => deleteReport(report.report_id)} className="center"><img src="../assets/trashicon.svg" alt="" className="no-hide" /></button></td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>}
                    <div className="center" >
                        {(count / pageSize) >= 1 &&
                            <div id="pagination">
                                <p><img src="../assets/first-page.png" alt="" width="15" height="18" /></p>
                                <p><img src="../assets/prev-page.png" alt="" width="11" height="18" className="m-r-10" /></p>

                                {Array.apply(1, Array(parseInt(count / pageSize) + 1)).map(function (x, i) {
                                    return (<p onClick={() => { changePaginationClick(i) }}>{i + 1}</p>)
                                })}

                                <p><img src="../assets/next-page.png" alt="" width="11" height="18" className="m-l-10" /></p>
                                <p><img src="../assets/last-page.png" alt="" width="15" height="18" /></p>
                            </div>
                        }

                    </div>

                </div>

            </div>

        </React.Fragment>
    )
}

export default MyReport;