
// sign-in reducer with default value as false and on every click the signedIn flag switches
  const userStates = (state = false, action) => {
    switch (action.type) {
        case 'SIGN_IN':
          return !state
        default:
          return state
      }
  }
  
  export default userStates;