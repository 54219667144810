import { atom } from "recoil";

export const CCPolyTotalCo2PerPartData = atom({
  key: "CCPolyTotalCo2PerPartData",
  default: undefined,
});

export const CCPolyCostOfOneKGCo2Data = atom({
  key: "CCPolyCostOfOneKGCo2Data",
  default: undefined,
});

export const CCPolyCostOfCo2PerPartFData = atom({
  key: "CCPolyCostOfCo2PerPartFData",
  default: undefined,
});

export const CCPolyCppWithCo2Data = atom({
  key: "CCPolyCppWithCo2Data",
  default: undefined,
});
