import { atom } from "recoil";

export const CCMetalTotalCarbonPerPartDataM = atom({
  key: "CCMetalTotalCarbonPerPartDataM",
  default: undefined,
});
export const CCMetalCostOfOneKGCo2DataM = atom({
  key: "CCMetalCostOfOneKGCo2DataM",
  default: undefined,
});
export const CCMetalCostOfCo2PerPartFDataM = atom({
  key: "CCMetalCostOfCo2PerPartFDataM",
  default: 0,
});
export const CCMetalCppWithCo2DataM = atom({
  key: "CCMetalCppWithCo2DataM",
  default: 0,
});
