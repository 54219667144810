import React, { useEffect } from "react";
import { useRecoilState, } from "recoil";
import {
  CCPolyPowderData,
  CCPolyVolumeReductionData,
  CCPolyCo2ePowderScenarioData,
  CCPolyEnergyMaterialData,
  CCPolyEnergySystemData,
  CCPolyCo2eEnergyData,
  CCPolyCo2TotalTodaySData,
  CCPolyCo2TotalTomorrowSData,
  CCPolyModeOfTransportNode1,
  CCPolyModeOfTransportNode2,
  CCPolyCarbonNeutralPowder,
  CCPolyVolumeReductionState,
  CCPolyEnergyPercentage,
  CCPolyGreenPowderEnergy,
  CCPolyModeOfTranspotation1,
  CCPolyModeOfTranspotation2,
  CCPolyTotalTraspotation,
  CCRefreshRateScenarioState,
  CCRefreshRateScenarioData
} from "atoms/CCPolymer/CCPolyScenario";

import SelectDropdown from "app/components/SelectDropdown";
import { PercentageData, YesNoData, LogisticsData } from 'utils/selectDropdowns';

import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { Spinner } from "react-bootstrap";

const MaterialTableValues = ({ socket, fieldObj }) => {
  const [powder, setPowder] = useRecoilState(CCPolyPowderData);
  const [volumeReductionS, setVolumeReductionS] = useRecoilState(CCPolyVolumeReductionData);
  const [co2EPowder, setCo2EPowder] = useRecoilState(CCPolyCo2ePowderScenarioData);

  const [energyMaterial, setEnergyMaterial] = useRecoilState(CCPolyEnergyMaterialData);
  const [energySytem, setEnergySystem] = useRecoilState(CCPolyEnergySystemData);
  const [co2eEnergy, setCo2eEnergy] = useRecoilState(CCPolyCo2eEnergyData);

  const [modeOfTransportnode1, setmodeOfTransportnode1] = useRecoilState(CCPolyModeOfTransportNode1);

  const [modeOfTransportnode2, setmodeOfTransportnode2] = useRecoilState(CCPolyModeOfTransportNode2);

  const [, setCo2TotalToday] = useRecoilState(CCPolyCo2TotalTodaySData);

  const [, setCo2TotalTomorrow] = useRecoilState(CCPolyCo2TotalTomorrowSData);

  const [cNp, setCNP] = useRecoilState(CCPolyCarbonNeutralPowder);
  const [volumeReduction, setVolumeReduction] = useRecoilState(CCPolyVolumeReductionState);

  const [energyPercentage, setEnergyPercentage] = useRecoilState(CCPolyEnergyPercentage);
  const [greenPowderEnergy, setGreenPowderEnergy] = useRecoilState(CCPolyGreenPowderEnergy);

  const [modeOfTranspotation1, setModeOfTranspotation1] = useRecoilState(CCPolyModeOfTranspotation1);
  const [modeOfTranspotation2, setModeOfTranspotation2] = useRecoilState(CCPolyModeOfTranspotation2);
  const [totalTranpotation, setTotalTraspotation] = useRecoilState(
    CCPolyTotalTraspotation
  );
  const [refreshRatePercentage, setRefreshRatePercentage] = useRecoilState(
    CCRefreshRateScenarioState
  );
  const [refreshRateScenario, setRefreshRateScenario] =
    useRecoilState(CCRefreshRateScenarioData);
  const co2PerPartPM = fieldObj?.co2PerPartPM;
  const co2PerPartMP = fieldObj?.co2PerPartMP;
  const co2PerPartAP = fieldObj?.co2PerPartAP;
  const co2Transportaion = fieldObj?.co2TotalTransportation;
  const materialTransportaion = fieldObj?.materialTransportaion;

  const systemTransportation = fieldObj?.systemTransportation;

  const cefnone = fieldObj?.cefnone;

  const cefnTwo = fieldObj?.cefnTwo;
  const { getComputedValue } = useRoundOffValue();
  const refreshRate = fieldObj.refreshRate
  const costData = fieldObj?.costData

  useEffect(() => {
    setCNP(fieldObj?.cNPInput)
    setVolumeReduction(fieldObj?.volumeReductionInput)
    setEnergyPercentage(fieldObj?.energyPercentageInput)
    setGreenPowderEnergy(fieldObj?.greenPowderEnergyInput)
    setModeOfTranspotation1(fieldObj?.modeOfTranspotation1)
    setModeOfTranspotation2(fieldObj?.modeOfTranspotation2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldObj])



  useEffect(() => {
    if (cNp && volumeReduction && co2PerPartPM) {


      socket.emit("carbon-calculator", {
        key: "PSA-PCP",
        input: {
          carbon_natural_powder: cNp,
          co2e_per_part_pm: +co2PerPartPM,
          reduction_in_volume: +volumeReduction,
        },
      });

      socket.on("PSA-PCP", function (data) {


        const valuepatod = getComputedValue(data?.data?.powder_today);
        const valuepatom = getComputedValue(data?.data?.powder_tomorrow);
        const valuecptod = getComputedValue(data?.data?.carbon_powder_today);
        const valuecptom = getComputedValue(data?.data?.carbon_powder_tomorrow);
        const valuevptod = getComputedValue(data?.data?.volume_reduction_today);
        const valuevptom = getComputedValue(
          data?.data?.volume_reduction_tomorrow
        );
        setPowder({ today: valuepatod, tomorrow: valuepatom });
        setCo2EPowder({ today: valuecptod, tomorrow: valuecptom });
        setVolumeReductionS({ today: valuevptod, tomorrow: valuevptom });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cNp, volumeReduction, co2PerPartPM]);

  useEffect(() => {
    if (modeOfTranspotation1 && modeOfTranspotation2) {


      socket.emit("carbon-calculator", {
        key: "PSA-PCT",
        input: {
          node1_mode: modeOfTranspotation1,
          node2_mode: modeOfTranspotation2,
          material_transportation: parseInt(materialTransportaion),
          system_transportation: parseInt(systemTransportation),
          carbon_emission_factor_node_1: +cefnone,
          carbon_emission_factor_node_2: +cefnTwo,
          utilization: +costData.utilization.value,
          build_time: +costData.build_time.value,
          setup_time: +costData.setup_time.value,
          cool_down_time_in_system: +costData.cool_down_time_in_system.value, //not in metal
          unpacking_time_per_job: +costData.unpacking_time_per_job.value,
        },
      });

      socket.on("PSA-PCT", function (data) {

        const transportNode1Today = getComputedValue(
          data.data.transport_node_1_today
        );
        const transportNode1Tomorrow = getComputedValue(
          data.data.transport_node_1_tomorrow
        );
        const transportNode2Today = getComputedValue(
          data.data.transport_node_2_today
        );
        const transportNode2Tomorrow = getComputedValue(
          data.data.transport_node_2_tomorrow
        );

        setmodeOfTransportnode1({
          today: transportNode1Today,
          tomorrow: transportNode1Tomorrow,
        });
        setmodeOfTransportnode2({
          today: transportNode2Today,
          tomorrow: transportNode2Tomorrow,
        });
        setTotalTraspotation({
          today: ((transportNode1Today.length > 5) || (transportNode2Today.length > 5)) ? data?.data?.transport_today.toFixed(5) : getComputedValue(data?.data?.transport_today),
          tomorrow: ((transportNode1Tomorrow.length > 5) || (transportNode2Tomorrow.length > 5)) ? data?.data?.transport_tomorrow.toFixed(5) : getComputedValue(data?.data?.transport_tomorrow),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeOfTranspotation1, modeOfTranspotation2]);

  useEffect(() => {
    if (energyPercentage && greenPowderEnergy) {

      socket.emit("carbon-calculator", {
        key: "PSA-PCE",
        input: {
          percentage: +energyPercentage,
          co2e_per_part_mp: +co2PerPartMP,
          co2e_per_part_ap: +co2PerPartAP,
          posibility_of_green_energy_mix: greenPowderEnergy,
        },
      });

      socket.on("PSA-PCE", function (data) {

        const valuepatod = getComputedValue(data.data.material_process_today);
        const valuepatom = getComputedValue(
          data.data.material_process_tomorrow
        );
        const valuecptod = getComputedValue(data.data.energy_system_today);
        const valuecptom = getComputedValue(data.data.energy_system_tomorrow);
        const valuevptod = getComputedValue(data.data.carbon_energy_today);
        const valuevptom = getComputedValue(data.data.carbon_energy_tomorrow);
        setEnergyMaterial({ today: valuepatod, tomorrow: valuepatom });
        setEnergySystem({ today: valuecptod, tomorrow: valuecptom });
        setCo2eEnergy({ today: valuevptod, tomorrow: greenPowderEnergy === "yes" ? 0 : valuevptom });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyPercentage, greenPowderEnergy]);
  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMaterial, energySytem, co2eEnergy]);
  useEffect(() => {

    if (
      co2EPowder !== undefined &&
      co2eEnergy !== undefined &&
      co2Transportaion !== undefined
    ) {
      const value =
        parseFloat(co2EPowder.today) +
        parseFloat(co2eEnergy.today) +
        parseFloat(co2Transportaion);
      const value1 = getComputedValue(value);

      setCo2TotalToday(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2EPowder, co2eEnergy, co2Transportaion]);
  useEffect(() => {

    if (
      co2EPowder !== undefined &&
      co2eEnergy !== undefined &&
      co2Transportaion !== undefined
    ) {
      const value =
        parseFloat(co2EPowder.tomorrow) +
        parseFloat(co2eEnergy.tomorrow) +
        parseFloat(co2Transportaion);
      const value1 = getComputedValue(value);


      setCo2TotalTomorrow(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2EPowder, co2eEnergy, co2Transportaion]);

  useEffect(() => {
    if (costData?.part_volume?.value && costData?.no_of_parts?.value && costData?.material_id?.value && costData?.build_height?.value && refreshRatePercentage && costData?.machine_id?.value && costData?.refreshing_rate?.value && co2PerPartPM) {
      socket.off("*");
      socket.emit("carbon-calculator", { key: "PSA-PCP-RR", input: { part_volume: costData?.part_volume?.value, no_of_parts: costData?.no_of_parts?.value, material_id: costData?.material_id?.value, build_height: costData?.build_height?.value, refresh_rate: refreshRatePercentage, machine_id: costData?.machine_id?.value, co2e_per_part_pm: co2PerPartPM, } })

      socket.on("PSA-PCP-RR", function (data) {
        const valuePresent = getComputedValue(data?.data?.current_scenario)
        const valueFuture = getComputedValue(data?.data?.future_scenario)
        setRefreshRateScenario({ today: valuePresent, tomorrow: valueFuture })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costData?.part_volume?.value, costData?.no_of_parts?.value, costData?.material_id?.value, costData?.build_height?.value, refreshRatePercentage, costData?.machine_id?.value])

  const refreshPercentageData = PercentageData
  useEffect(() => {
    if (refreshRate) {
      if (!PercentageData.some(item => item.value === refreshRate.toString())) {
        refreshPercentageData.push({
          value: `${refreshRate}`,
          name: `${refreshRate}%`
        })
        setRefreshRatePercentage(refreshRate)
      }
      else {
        setRefreshRatePercentage(refreshRate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshRate])

  useEffect(() => {
    if (powder && volumeReductionS && refreshRateScenario) {
      const minimum = Math.min(powder?.tomorrow, volumeReductionS?.tomorrow, refreshRateScenario?.tomorrow)
      setCo2EPowder((prevState) => {
        return {
          ...prevState,
          tomorrow: minimum
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powder, volumeReductionS, refreshRateScenario])

  return (
    <div className=" responsive-scroll">
      <div className="divTable">
        <div className="divTableBody">
          <div className="divTableRow">
            {/* <div className="divTableCell1-heading">Unit</div> */}
            <div className="divTableCell1-heading p-1">Input</div>
            <div className="divTableCell1-heading pl-2">Current Scenario</div>
            <div className="divTableCell1-heading">Future Scenario</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">CO2e kg/part</div> */}
            <div className="divTableCell1 p-0">
              <select
                className="dropdown-input"
                name="cars"
                id="cars"
                onChange={(e) => {
                  setCNP(e.target.value);
                }}
              >
                <option>Select</option>
                <option value="yes" selected={cNp === "yes" && "selected"}>Yes</option>
                <option value="no" selected={cNp === "no" && "selected"}>No</option>
              </select>
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {powder ? powder.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {powder?.tomorrow !== undefined ?
                powder?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTable__Cells">kg</div> */}
            <div className="divTableCell1 p-0">
              <SelectDropdown setState={setVolumeReduction} getState={volumeReduction} Dataset={PercentageData} styleclass="dropdown-input pt-3" />
            </div>
            <div className="divTableCell1 pl-3">
              {volumeReductionS ? volumeReductionS.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {volumeReductionS?.tomorrow !== undefined ?
                volumeReductionS?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow ">
            {/* <div className="divTable__Cells">kg</div> */}
            <div className="divTableCell1 pt-3 pl-0">
              <SelectDropdown Dataset={refreshPercentageData ? refreshPercentageData : PercentageData}
                getState={refreshRatePercentage}
                setState={setRefreshRatePercentage}
                styleclass='dropdown-input'
              />

            </div>
            <div className="divTableCell1 pl-3">
              {refreshRateScenario ? refreshRateScenario.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1">
              {refreshRateScenario ? refreshRateScenario.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTable__Cells">kg</div> */}
            <div className="divTable__Cells"></div>
            <div className="divTable__Cells pl-3">
              {co2EPowder ? co2EPowder.today : "_ _ _ "}
            </div>
            <div className="divTable__Cells pl-3">
              {co2EPowder?.tomorrow !== undefined ?
                co2EPowder?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">Percenatage</div> */}
            <div className="divTableCell1 p-0">
              <SelectDropdown setState={setEnergyPercentage} getState={energyPercentage} Dataset={PercentageData} styleclass="dropdown-input pt-3" />
            </div>
            <div className="divTableCell1 pl-3">
              {energyMaterial !== undefined ? energyMaterial?.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {energyMaterial?.tomorrow !== undefined ?
                energyMaterial?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>


          <div className="divTableRow">
            {/* <div className="divTableCell1">Green Energy</div> */}
            <div className="divTableCell1 p-0">
              <SelectDropdown setState={setGreenPowderEnergy} getState={greenPowderEnergy} Dataset={YesNoData} styleclass="dropdown-input mb-3" />
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {energySytem !== undefined ? energySytem.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {energySytem?.tomorrow !== undefined ?
                energySytem?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1 pl-3 position-top-0">
              {co2eEnergy !== undefined ? co2eEnergy.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {co2eEnergy?.tomorrow !== undefined ?
                co2eEnergy?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1 p-0">
              <SelectDropdown setState={setModeOfTranspotation1} getState={modeOfTranspotation1} Dataset={LogisticsData} styleclass="dropdown-input" />
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {modeOfTransportnode1 !== undefined
                ? modeOfTransportnode1.today
                : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {modeOfTransportnode1?.tomorrow !== undefined ?
                modeOfTransportnode1?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1 p-0">
              <SelectDropdown setState={setModeOfTranspotation2} getState={modeOfTranspotation2} Dataset={LogisticsData} styleclass="dropdown-input" />

            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {modeOfTransportnode2 !== undefined
                ? modeOfTransportnode2.today
                : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3 position-top-0">
              {modeOfTransportnode2?.tomorrow !== undefined ?
                modeOfTransportnode2?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1 pl-3">
              {totalTranpotation !== undefined
                ? totalTranpotation.today
                : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {totalTranpotation?.tomorrow !== undefined ?
                totalTranpotation?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTableValues;
