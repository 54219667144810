import { atom } from "recoil"

export const Co2EmissionFactorDataM = atom({
    key: "Co2EmissionFactorDataM",
    default: undefined
})
export const Co2EmissionBuildProcessDataM = atom({
    key: "Co2EmissionBuildProcessDataM",
    default: undefined
})
export const Co2EmissionPowderDataM = atom({
    key: "Co2EmissionPowderDataM",
    default: undefined
})
export const Co2EmissionGasDataM = atom({
    key: "Co2EmissionGasDataM",
    default: undefined
})
export const Co2EmissionTransportationDataM = atom({
    key: "Co2EmissionTransportationDataM",
    default: undefined
})