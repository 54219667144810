import React from 'react'
const SelectDropdown = ({ setState, getState, Dataset, styleclass, optionAvailable }) => {
    return (
        <select
            className={styleclass}
            name="cars"
            id="cars"
            value={getState}
            onChange={(e) => {
                setState(e.target.value);
            }}
        >
            {optionAvailable && <option>Select</option>}
            {
                Dataset.map((data, index) => {
                    return (
                        <option
                            value={data.value}
                            key={index}
                            selected={
                                getState === data.value && "selected"
                            }
                        >
                            {data.name}
                        </option>
                    )
                })
            }

        </select>
    )
}

export default SelectDropdown