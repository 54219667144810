import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import FirstReport from "./FirstReport";
import SecondReport from "./SecondReport";
import ThirdReport from "./ThirdReport";
import { useHistory } from "react-router-dom";
import { CONDITIONALROUTES, STEPS } from "app/components/ConditionalEnums";
import { CostData } from "atoms/CostToCarbonData";

const CurrentReports = ({ staticDataOfMachine, isHotSpotAvailable }) => {
  const [step, setStep] = useState("first");
  const [costData, setCostData] = useState()
  const history = useHistory();
  const updatedCostData = useRecoilValue(CostData)
  useEffect(() => {
    if (history.location.pathname === CONDITIONALROUTES.GENERATEDREPORT) {
      setCostData(updatedCostData);
    }
    else {
      setCostData(staticDataOfMachine);
    }
  }, [])
  console.log("updated cost data", updatedCostData)
  return (

    <>
      {
        costData !== undefined
        &&
        <>
          <div className="reports-section">
            {step === STEPS.ONE && (
              <FirstReport
                staticDataOfMachine={costData}
                ScenarioState={() => setStep(STEPS.TWO)}
              />
            )}
            {step === STEPS.TWO && (
              <SecondReport
                isWorkingPerJobEditable={true}
                staticDataOfMachine={costData}
                isHotSpotAvailable={isHotSpotAvailable}
                PreviousScenarioState={() => setStep(STEPS.ONE)}
                ScenarioState={() => setStep(STEPS.THREE)}
              />
            )}
            {step === STEPS.THREE && (
              <ThirdReport
                PreviousScenarioState={() => setStep(STEPS.TWO)}
                staticDataOfMachine={costData}
              />
            )}
          </div>

        </>

      }

    </>
  );
};

export default CurrentReports;
