import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    Co2PolyCo2EmissionFactorPricingData,
    Co2PolyCo2ePowderData,
    Co2PolyCo2eEnergyPricingData,
    Co2PolyCo2EmissionTransportationData
} from "atoms/Co2Polymer/Co2PolyCo2Pricing";
import { Co2ePerPartPMData } from "atoms/Co2Polymer/PowderMfgAtoms";
import { Co2ePerPartAPData } from "atoms/Co2Polymer/AMProcessAtoms";
import { Co2ePerPartMPData } from "atoms/Co2Polymer/MaterialProcessingAtoms";
import { Co2ePerPartTPData } from "atoms/Co2Polymer/TransportationAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";

const Co2PricingAnalysis = ({ Socket }) => {
    const [co2_emission_factor, setCo2_emission_factor] = useRecoilState(Co2PolyCo2EmissionFactorPricingData);
    const [co2_emission_powder, setCo2_emission_powder] = useRecoilState(
        Co2PolyCo2ePowderData
    );
    const [co2_emission_gas_energy, setCo2_emission_gas_energy] = useRecoilState(
        Co2PolyCo2eEnergyPricingData
    );
    const [co2_emission_transportation, setCo2_emission_transportation] = useRecoilState(Co2PolyCo2EmissionTransportationData);
    const co2PerPartPM = useRecoilValue(Co2ePerPartPMData);
    const co2PerPartAP = useRecoilValue(Co2ePerPartAPData);
    const co2PerPartMP = useRecoilValue(Co2ePerPartMPData);
    const co2PerPartTP = useRecoilValue(Co2ePerPartTPData);

    const { getComputedValue } = useRoundOffValue();

    useEffect(() => {
        const handleData = (data) => {
            const value = getComputedValue(data?.data?.value);
            setCo2_emission_factor(value);
        };
        Socket.emit("carbon-calculator", {
            key: "DBCC-CCPPCEF",
            input: ""
        });

        Socket.on("DBCC-CCPPCEF", handleData);

        // Cleanup the socket listener on component unmount
        return () => {
            Socket.off("DBCC-CCPPCEF", handleData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            co2PerPartPM !== null &&
            co2PerPartMP !== null

        ) {

            Socket.emit("carbon-calculator", {
                key: "FOCC-CCPPCEP-POLYMER",
                input: {
                    co2e_per_part_mp: co2PerPartMP,
                    co2e_per_part_pm: co2PerPartPM,
                },
            });

            Socket.on("FOCC-CCPPCEP-POLYMER", function (data) {
                const value = getComputedValue(data?.data?.value);

                setCo2_emission_powder(value);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2PerPartMP, co2PerPartPM]);

    useEffect(() => {
        if (co2PerPartAP) {

            Socket.emit("carbon-calculator", {
                key: "FOCC-CCPPCEG-POLYMER",
                input: {
                    co2e_per_part_ap: co2PerPartAP,
                },
            });

            Socket.on("FOCC-CCPPCEG-POLYMER", function (data) {
                const value = getComputedValue(data?.data?.value);
                setCo2_emission_gas_energy(value);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2PerPartAP]);
    useEffect(() => {
        if (co2PerPartAP) {

            Socket.emit("carbon-calculator", {
                key: "FOCC-CCPPCET-POLYMER",
                input: {
                    co2e_per_part_tmp: co2PerPartTP,
                },
            });

            Socket.on("FOCC-CCPPCET-POLYMER", function (data) {

                const value = getComputedValue(data?.data?.value);
                setCo2_emission_transportation(value);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2PerPartAP]);


    return (
        <>
            <p className="input-heading">CO2e emission factor (kg)</p>
            <div className="data-field">
                {co2_emission_factor}
                {/* <p className="edit-icon value-unit ">kg</p> */}
            </div>
            <p className="input-heading">
                CO2e powder (kg)
            </p>
            <div className="data-field">{co2_emission_powder}</div>
            <p className="input-heading">
                CO2e energy (kg)
            </p>
            <div className="data-field">{co2_emission_gas_energy}</div>
            <p className="input-heading">
                CO2 logistics (kg)
            </p>
            <div className="data-field">{co2_emission_transportation}</div>
        </>
    );
};

export default Co2PricingAnalysis;
