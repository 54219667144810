import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ProcessFlag } from "atoms/General";
import { useRecoilValue } from "recoil";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

import Co2polymerProcess from "./Co2polymerProcess";
import Co2PricingAnalysis from "./Co2PricingAnalysis";
import ArrowDown from "../CarbonPolymerProcess/components/ArrowDown";
const CarbonPolymerCalculator = ({ ScenarioState, StepID, socket }) => {
  // const socket = new socketIOClient(constant.uri);
  const [activeState, setActiveState] = useState(-1);
  const processFlag = useRecoilValue(ProcessFlag);

  const toggleState = (idnum) => {
    const id = idnum;
    if (activeState === +id) {
      setActiveState(null);
    } else {
      setActiveState(+id);
    }
  };

  return (
    <>
      <>
        {/* <ToastContainer /> */}
        <Accordion>
          <Card>
            <Card.Header className={activeState === 0 ? "active" : ""}>
              <div className="d-flex justify-content-between">
                <Accordion.Toggle
                  onClick={() => toggleState(0)}
                  className={activeState === 0 ? "active-color" : ""}
                  as={Button}
                  variant="link"
                  eventKey="0"
                  data-display="static"
                >
                  <TooltipTrippy
                    fieldName="Carbon Content Per Part"
                    tooltipMessage="All factors are taken from ADEME database, for more details on academic numbers we used VDI studies and referenced are under Data Collection Matrix."
                  />{" "}
                </Accordion.Toggle>
                <Accordion.Toggle
                  onClick={() => toggleState(0)}
                  id="0"
                  as={Button}
                  variant="link"
                  eventKey="0"
                >
                  <div
                    className={
                      activeState === 0 ? "arrow-box active" : "arrow-box"
                    }
                  >
                    <ArrowDown />
                  </div>
                </Accordion.Toggle>
              </div>
            </Card.Header>
            <Accordion.Collapse data-target="0" eventKey="0">
              <Card.Body>
                <Co2PricingAnalysis Socket={socket} StepID={StepID} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={activeState === 1 ? "active" : ""}>
              <div className="d-flex justify-content-between">
                <Accordion.Toggle
                  onClick={() => toggleState(1)}
                  className={activeState === 1 ? "active-color" : ""}
                  as={Button}
                  variant="link"
                  eventKey="1"
                  data-display="static"
                >
                  <TooltipTrippy
                    fieldName="Pricing Analysis"
                    tooltipMessage="All factors are taken from ADEME database, for more details on academic numbers we used VDI studies and referenced are under Data Collection Matrix."
                  />{" "}
                </Accordion.Toggle>
                <Accordion.Toggle
                  onClick={() => toggleState(1)}
                  id="1"
                  as={Button}
                  variant="link"
                  eventKey="1"
                >
                  <div
                    className={
                      activeState === 1 ? "arrow-box active" : "arrow-box"
                    }
                  >
                    <ArrowDown />
                  </div>
                </Accordion.Toggle>
              </div>
            </Card.Header>
            <Accordion.Collapse data-target="1" eventKey="1">
              <Card.Body>
                <Co2polymerProcess Socket={socket} StepID={StepID} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <button
          onClick={() => {
            if (processFlag.CarbonPricing) {
              ScenarioState();
            } else {
              toast.error("Please fill all carbon pricing details.");
            }
          }}
          className="selection-button step-button"
        >
          Proceed
        </button>
      </>
    </>
  );
};

export default CarbonPolymerCalculator;
