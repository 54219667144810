import { atom } from "recoil";

export const CCMetalCo2EmissionFactorDataM = atom({
  key: "CCMetalCo2EmissionFactorDataM",
  default: undefined,
});
export const CCMetalCo2EmissionBuildProcessDataM = atom({
  key: "CCMetalCo2EmissionBuildProcessDataM",
  default: undefined,
});
export const CCMetalCo2EmissionPowderDataM = atom({
  key: "CCMetalCo2EmissionPowderDataM",
  default: undefined,
});
export const CCMetalCo2EmissionGasDataM = atom({
  key: "CCMetalCo2EmissionGasDataM",
  default: undefined,
});
export const CCMetalCo2EmissionTransportationDataM = atom({
  key: "CCMetalCo2EmissionTransportationDataM",
  default: undefined,
});
