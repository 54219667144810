import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "./components/MaterialTable";
import MaterialTableValues from "./components/MaterialTableValues";
import { useRecoilValue } from "recoil";
import Dropdown from "react-bootstrap/Dropdown";
import { DebounceInput } from "react-debounce-input";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import {
  Co2TotalTodaySMData,
  Co2TotalTomorrowSMData,
  PowderAutomizationData,
  VolumePartReductionData,
  Co2ePowderScenarioMData,
  GreenEnergyMixData,
  InertGasReductionSMData,
  PowderAutomizationInput,
  VolumePartReductionInput,
  GreenEnergyMixInput,
  CO2eModeOfTransportNode1,
  CO2eModeOfTransportNode2,
  CO2eModeOfTranspotation1,
  CO2eModeOfTranspotation2,
  TotalTraspotation
} from "atoms/Co2Metal/ScenarioMetal";
import {
  Co2ePerPartTMDataM,
  Co2ePerYearDataM,
} from "atoms/Co2Metal/TransportationAtoms";
import {
  MachineNameDataM,
  MaterialNameDataM,
} from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  Co2ePerJobDataM,
  Co2ePerPartPMDataM,
} from "atoms/Co2Metal/PowderMfgAtoms";
import {
  InertGasConsumptionDataM,
  InertGasUsedEnergyDataM,
} from "atoms/Co2Metal/InertGasAtoms";
import {
  EnergyBuildProcessDataM,
  EnergyPerPartDataM,
} from "atoms/Co2Metal/ElectricityConsumptionAtoms";
import {
  Co2EmissionBuildProcessDataM,
} from "atoms/Co2Metal/CarbonContentPerPartAtoms";

import ReportsTwo from "../ReportsTwoMetal";
import PdfGenerator from "./components/PdfGenerator";
import { GET_CLIENT_EMAILS } from "../ProcessMetal/components/getStepDetail";
import { useApolloClient } from '@apollo/react-hooks';
import { HotspotArray } from "atoms/General";
import "tippy.js/dist/tippy.css"; // optional
import ConsultingTeam from "app/components/ConsultingTeam";

const ReportsMetal = ({ ScenarioState, socket }) => {
  const shiftSize = 7;
  const co2TotalToday = useRecoilValue(Co2TotalTodaySMData);
  const co2TotalTomorrow = useRecoilValue(Co2TotalTomorrowSMData);
  const powderAtomization = useRecoilValue(PowderAutomizationData);
  const powderAutomizationInput = useRecoilValue(PowderAutomizationInput);
  const volumePArtReduction = useRecoilValue(VolumePartReductionData);
  const volumePartReductionInput = useRecoilValue(VolumePartReductionInput);
  const co2ePowder = useRecoilValue(Co2ePowderScenarioMData);
  const greenenergymix = useRecoilValue(GreenEnergyMixData);
  const greenEnergyMixInput = useRecoilValue(GreenEnergyMixInput);
  const inertGasReduction = useRecoilValue(InertGasReductionSMData);
  const co2Transportation = useRecoilValue(Co2ePerPartTMDataM);
  const Tranpotation = useRecoilValue(TotalTraspotation)
  const [powder, setPowder] = useState();
  const [volume, setVolume] = useState();
  const [co2powder, setCO2ePowder] = useState();
  const [greenEnergyMix, setGreenEnergyMix] = useState();
  const [inertGas, setInertGas] = useState();
  const [transport, setTransport] = useState();
  const [savePdf, setSavePdf] = useState(false);
  const [donutImage, setDonutImage] = useState();
  const [typedocument, setTypeDocument] = useState();
  const MachineNameMD = useRecoilValue(MachineNameDataM);
  const MaterialNameMD = useRecoilValue(MaterialNameDataM);
  const co2ePerJobMFG = useRecoilValue(Co2ePerJobDataM);
  const co2ePerPartbMFG = useRecoilValue(Co2ePerPartPMDataM);
  const inertGasConsumptionIG = useRecoilValue(InertGasConsumptionDataM);
  const inertGaUSedEnergyIG = useRecoilValue(InertGasUsedEnergyDataM);
  const energyBuildProcessEC = useRecoilValue(EnergyBuildProcessDataM);
  const energyPerPartEC = useRecoilValue(EnergyPerPartDataM);
  const co2PerPartT = useRecoilValue(Co2ePerPartTMDataM);
  const co2PerYearT = useRecoilValue(Co2ePerYearDataM);
  const HotsspotArray = useRecoilValue(HotspotArray)
  const [hotSpotArray, setHotSpotArray] = useState([])
  const [highestIndexHotspot, setHighestIndexHotspot] = useState()
  const client = useApolloClient();
  const [clientSteps, setClientSteps] = useState([]);
  const [email, setEmail] = useState();
  const groupid = parseInt(sessionStorage.getItem("group_id"));
  const region = sessionStorage.getItem("currency");
  const [show, setShow] = useState(false);

  const [pdfName, setPdfName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const co2EmissionBuildProcessCC = useRecoilValue(
    Co2EmissionBuildProcessDataM
  );

  const ModeOfTransportNode1 = useRecoilValue(CO2eModeOfTransportNode1);
  const ModeOfTransportNode2 = useRecoilValue(CO2eModeOfTransportNode2);
  const ModeOfTranspotation1 = useRecoilValue(CO2eModeOfTranspotation1);
  const ModeOfTranspotation2 = useRecoilValue(CO2eModeOfTranspotation2);

  const DataSet = [
    {
      color: "#5DC0D3",
      title: co2powder
        ? ((co2powder / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2powder / co2TotalToday) * 100).toFixed(2)),
    },
    // TODO...
    // {
    //   color: "#9CEAEF",
    //   title: inertGas
    //     ? ((inertGas / co2TotalToday) * 100).toFixed(2) + "%"
    //     : "",
    //   value: parseFloat(((inertGas / co2TotalToday) * 100).toFixed(2)),
    // },
    {
      color: "#9CEAEF",
      title: greenEnergyMix && inertGas
        ? (((greenEnergyMix + inertGas) / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat((((greenEnergyMix + inertGas) / co2TotalToday) * 100).toFixed(2)),
    },

    {
      color: "#07BEB8",
      title: Tranpotation?.today
        ? ((Tranpotation?.today / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((Tranpotation?.today / co2TotalToday) * 100).toFixed(2)),
    },
  ];
  const initialDocumentState = () => {
    setTypeDocument(undefined);
    setPdfName("")
    setSavePdf(false)
  }
  useEffect(() => {
    client
      .query({
        query: GET_CLIENT_EMAILS,
        variables: { group_id: groupid },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getRegionsByUserGroup);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    for (let index = 0; index < clientSteps.length; index++) {
      if (clientSteps[index].name === region) {
        setEmail(clientSteps[index].email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSteps]);
  useEffect(() => {
    if (
      powderAtomization &&
      volumePArtReduction &&
      co2ePowder &&
      greenenergymix &&
      inertGasReduction
    ) {
      setPowder(parseFloat(powderAtomization.today));
      setCO2ePowder(parseFloat(co2ePowder.today));
      setVolume(parseFloat(volumePArtReduction.today));
      setInertGas(parseFloat(inertGasReduction.today));
      setGreenEnergyMix(parseFloat(greenenergymix.today));
      setTransport(parseFloat(co2Transportation));

      hotSpotArray.push(
        {
          "CO2e Powder": co2ePowder.today,
          "CO2e Energy": greenenergymix.today,
          "CO2e Logistics": co2Transportation
        }
      );
      setHotSpotArray(hotSpotArray);
    }
    const parent = document.getElementById("m2");
    const firstchild = parent.firstChild;
    firstchild.setAttribute("viewBox", "-25 -12 150 125");
    setTimeout(() => {

      const s = new XMLSerializer().serializeToString(firstchild);
      const encodedData = window.btoa(s);
      // Checking image encoded data base64....
      // console.log("data:image/svg+xml;base64," + encodedData);
      setDonutImage("data:image/svg+xml;base64," + encodedData);
      const canva = document.createElement("canvas");
      canva.width = 600;
      canva.height = 600;
      var ctx = canva.getContext("2d");
      const Imgsrc = "data:image/svg+xml;base64," + encodedData;
      const Img = new Image();
      Img.src = Imgsrc;
      Img.onload = function () {
        ctx.drawImage(Img, 0, 0);
        var pngsrc = canva.toDataURL("image/png");

        setDonutImage(pngsrc);
      };


    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    powderAtomization,
    volumePArtReduction,
    co2ePowder,
    greenenergymix,
    inertGasReduction,
  ]);
  useEffect(() => {
    if (hotSpotArray.length !== 0) {

      // const max = Math.max(...hotSpotArray);
      // const index = hotSpotArray.indexOf(max);
      let hightlightKey = ""
      hotSpotArray.forEach(item => hightlightKey = Object.keys(item).reduce((prevValue, currValue) => item[prevValue] > item[currValue] ? prevValue : currValue))

      setHighestIndexHotspot(hightlightKey);
    }
  }, [hotSpotArray]);
  const formObj = {
    powderatomization: {
      value: powderAtomization,
      name: "PowderAtomization",
    },
    powderatomizationinput: {
      value: powderAutomizationInput,
      name: "PowderAtomizationInput",
    },
    volumerpareduction: {
      value: volumePArtReduction,
      name: "volumereduction",
    },
    volumerpareductioninput: {
      value: volumePartReductionInput,
      name: "volumereductioninput",
    },
    co2epowder: {
      value: co2ePowder,
      name: "co2epowder",
    },
    GreenenergyMix: {
      value: greenenergymix,
      name: "greenenergymix",
    },
    GreenenergyMixInput: {
      value: greenEnergyMixInput,
      name: "greenEnergyMixInput",
    },
    inertGas: {
      value: inertGasReduction,
      name: "EnergySystem",
    },

    co2transportation: {
      value: co2Transportation,
      name: "co2transportation",
    },
    co2totaltoday: {
      value: co2TotalToday,
      name: "co2totaltoday",
    },
    co2totaltomorrow: {
      value: co2TotalTomorrow,
      name: "co2totalToco2TotalTomorrow",
    },
    machinename: {
      value: MachineNameMD,
      name: "Machine Name",
    },
    materialname: {
      value: MaterialNameMD,
      name: "Machine Name",
    },
    co2eperjob: {
      value: co2ePerJobMFG,
      name: "co2eperjob",
    },
    co2eperpartpmfg: {
      value: co2ePerPartbMFG,
      name: "co2eperpartpmfg",
    },
    inertgasconsumption: {
      value: inertGasConsumptionIG,
      name: "inertGasConsumptionIG",
    },
    inertgasusedenergy: {
      value: inertGaUSedEnergyIG,
      name: "inertGaUSedEnergyIG",
    },
    energybuildprocess: {
      value: energyBuildProcessEC,
      name: "energyBuildProcessEC",
    },
    energyperpart: {
      value: energyPerPartEC,
      name: "energyPerPartEC",
    },
    co2peryeart: {
      value: co2PerYearT,
      name: "co2PerYearT",
    },
    co2perpartt: {
      value: co2PerPartT,
      name: "co2PerPartT",
    },
    co2emissionbuildprocess: {
      value: co2EmissionBuildProcessCC,
      name: "co2EmissionBuildProcessCC",
    },

    ModeOfTransportNode1: {
      value: ModeOfTransportNode1,
      name: "ModeOfTransportNode1",
    },
    ModeOfTransportNode2: {
      value: ModeOfTransportNode2,
      name: "ModeOfTransportNode2",
    },
    ModeOfTranspotation1: {
      value: ModeOfTranspotation1,
      name: "ModeOfTranspotation1",
    },
    ModeOfTranspotation2: {
      value: ModeOfTranspotation2,
      name: "ModeOfTranspotation2",
    },


  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {typedocument === 0 ? "Enter pdf name" : "Enter word doc name"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setPdfName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter name"
              value={pdfName}
            />
            {/* <p className="edit-icon value-unit">kg</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="selection-button step-button w-50 m-0"
              onClick={handleClose}
            >
              Close
            </button>
            <Button
              disabled={pdfName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                setSavePdf(true);
                handleClose();
              }}
            >
              Download
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="reports-section">
        <div className="reports-detail-section">
          <Row className="m-0">
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">Machine Details</p>
                <div className="data-field">Machine:
                  <span className="black-color"> &nbsp;{MachineNameMD}</span>
                </div>
                <div className="data-field">Powder:
                  <span className="black-color"> &nbsp;{MaterialNameMD}</span>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">
                  Machine Manufacturing (N/A)
                </p>
                <div className="data-field">currently not available</div>
                <div className="data-field">currently not available</div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">
                  Powder Manufacturing{" "}
                </p>
                <div className="data-field">CO2e per job:
                  <span className="black-color"> &nbsp;{co2ePerJobMFG} (kgs)</span>
                </div>
                <div className="data-field">CO2e per part :
                  <span className="black-color"> &nbsp;{co2ePerPartbMFG} (kgs)</span>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">Inert Gas </p>
                <div className="data-field">Inert gas consumption:
                  <span className="black-color"> &nbsp;
                    {inertGasConsumptionIG} (m<sup className='font-weight-bold'>3</sup>/job)
                  </span>

                </div>
                <div className="data-field">Inert gas used energy:
                  <span className="black-color"> &nbsp;{inertGaUSedEnergyIG} (kWh)</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-0 report-detail-inner-spacing">
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">
                  Electricity Consumption
                </p>
                <div className="data-field">Energy build process:
                  <span className="black-color"> &nbsp;{energyBuildProcessEC} (kWh/job)</span>

                </div>
                <div className="data-field">Energy per part:
                  <span className="black-color"> &nbsp;{energyPerPartEC} (kWh/part)</span>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">Logistics</p>
                <div className="data-field">CO2e per part:
                  <span className="black-color"> &nbsp;{co2PerPartT} (kg)</span>

                </div>
                <div className="data-field">CO2e per year:
                  <span className="black-color"> &nbsp;{co2PerYearT} (kg)</span>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">
                  Post Processing (N/A)
                </p>
                <div className="data-field">currently not available</div>
                <div className="data-field">currently not available</div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="report-detailt-card">
                <p className="input-heading capitalize">
                  Used Powder - End of Life (N/A)
                </p>
                <div className="data-field">currently not available</div>
                <div className="data-field">currently not available</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="powder-container">
        <div className="powder-table-top">
          <p className="powder-heading">Scenario Analysis</p>
        </div>
        <div className="d-flex flex-row p-3">
          <MaterialTable Socket={socket} />
          <MaterialTableValues Socket={socket} />
        </div>
        <div className="powder-table-top total-bottom">
          <p className="powder-heading" style={{ textTransform: "none" }}>
            Total (kg)
          </p>
          <div className="d-flex ">
            <p className="powder-heading metal-report-tc">{co2TotalToday}</p>
            <p className="powder-heading metal-report-tf">{co2TotalTomorrow}</p>
          </div>
        </div>
      </div>


      <div className="graph-section width--mod">
        <div className="text-center">
          <p className="t-2">CO2e in % of kg per part</p>
          <p className="s-1 mb-0">Current Scenario</p>
        </div>
        <div
          id="m2"
          className="w-100 m-0 h-100 d-flex justify-content-center align-items-center"
        >
          <PieChart
            animationDuration={500}
            animationEasing="ease-out"
            // center={[50, 50]}
            width=" 300px"
            height="300px"
            data={DataSet}
            labelPosition={110}
            lengthAngle={360}
            lineWidth={40}
            paddingAngle={2}
            radius={PieChart.defaultProps.radius - shiftSize}
            startAngle={0}
            label={({ dataEntry }) => dataEntry.title}
            // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

            labelStyle={{
              fill: "black",
              strokeWidth: "10",
              fontSize: "5px",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="d-flex">
          <div className="color-icon bg-color5" />
          <p className="color-rep-value">CO2e Powder</p>
        </div>
        {/* TODO.. */}
        {/* <div className="d-flex">
          <div className="color-icon tomorrowBg4" />
          <p className="color-rep-value">CO2e Gas</p>
        </div> */}
        <div className="d-flex">
          <div className="color-icon bg-color7" />
          <p className="color-rep-value ">CO2e Energy</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color1" />
          <p className="color-rep-value m-0">CO2e Logistics</p>
        </div>
      </div>
      <ReportsTwo highestindex={highestIndexHotspot} />
      <div className="d-flex justify-content-center align-items-center">
        <ConsultingTeam navigate={email} />
        <Dropdown>
          {/* <Button
            className="selection-button step-button"
            id="dropdown-basic"
            onClick={() => {
              setTypeDocument(0);
              handleShow();
            }}
          >
            Download Report
          </Button> */}
          {/* TODO... */}

          <Dropdown.Toggle
            className="selection-button step-button"
            id="dropdown-basic"
          >
            Download Report
          </Dropdown.Toggle>

          {/* // TODO */}

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setTypeDocument(0);
                handleShow();
              }}
            >
              As Pdf
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setTypeDocument(1);
                handleShow();
              }}
            >
              As Word
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>


      {
        savePdf && (
          <PdfGenerator
            formFields={formObj}
            pdfName={pdfName}
            donutImage={donutImage}
            typeDocument={typedocument}
            HotsspotArray={HotsspotArray}
            highestIndex={highestIndexHotspot}
            initialDocumentState={initialDocumentState}
          />
        )}
    </>
  );
};

export default ReportsMetal;
