// import React from "react";
// import { useState } from "react"
import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import "./../../App.css";
// import config from './../config';


const ImageUpload = ({ setImage, defaultImageProp }) => {
  const [pictures, setPictures] = useState([]);
  const [defaultPictures, setDefaultPictures] = useState('');

  const onDrop = picture => {
    setImage(picture)

    setPictures([...pictures, picture]);
  };

  React.useEffect(() => {
    setDefaultPictures(defaultImageProp)
  }, [defaultImageProp])
  return (
    <ImageUploader
      withIcon={true}
      onChange={onDrop}
      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
      maxFileSize={5242880}
      // defaultImages={[defaultImageProp ? config.serviceUrl + defaultImageProp: '']}
      withPreview={true}
    />
  );
}

export default ImageUpload;