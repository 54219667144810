import React, { useEffect } from "react";
import Navbar from "../components/Nav.jsx";
import config from '../../config';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CONDITIONALROUTES } from 'app/components/ConditionalEnums';
const axios = require('axios').default;

const MyAnalytics = () => {
    const history = useHistory()
    const [analyticssList, setAnalyticsList] = useState([])
    const [analyticsData, setAnalyticsData] = useState({})
    const [count, setCount] = useState([]);
    const [isLoading, setLoading] = useState(false)
    let access_token = ''
    const pageSize = 10

    React.useEffect(() => {
        // Access Token for geting analytics...
        // console.log('access_token', access_token)
        getListOfAnalytics(0)
        if (sessionStorage.getItem("calculator_values_names")) {
            const calculator_values_multiple = JSON.parse(sessionStorage.getItem("calculator_values_names"))
            if (calculator_values_multiple?.length) {
                for (const item of calculator_values_multiple) {
                    sessionStorage.removeItem(`calculator_values_${item}`)
                }
                sessionStorage.removeItem(`calculator_values_multiple`)
            }
        }
        // eslint-disable-next-line
    }, [])
    const deleteAnalytic = async (AnalyticstId) => {
        access_token = sessionStorage.getItem('access_token');
        setLoading(true)

        await axios.delete(`${config.serviceUrl}client/analytics/delete`, {
            params: {
                id: AnalyticstId
            },
            headers: {
                'Authorization': access_token
            }
        })
            .then(function (response) {
                getListOfAnalytics(0)
            })
            .catch(function (error) {
                console.log(error);
            })
        setLoading(false)

    }

    const getListOfAnalytics = async (pageNo) => {
        access_token = sessionStorage.getItem('access_token');
        setLoading(true)
        await axios.get(`${config.serviceUrl}client/analytics/get`, {
            params: {
                pageSize: pageSize,
                pageNo: pageNo,
            },
            headers: {
                'Authorization': access_token
            }
        })
            .then(function (response) {
                setAnalyticsList(response.data.data.analytic)
                setCount(response.data.data.count)

            })
            .catch(function (error) {
                console.log(error);
            })
        setLoading(false)

    }

    const getAnalyticsById = async (AnalyticsId) => {
        try {

            access_token = sessionStorage.getItem('access_token');

            const response = await axios.default.get(`${config.serviceUrl}client/analytics/getbyid`, {
                params: {
                    id: AnalyticsId
                },
                headers: {
                    Authorization: access_token,

                }
            })
            // Analytics Data....
            // console.log("analytics", JSON.parse(response.data.analytic_data));
            setAnalyticsData(JSON.parse(response.data.analytic_data))
            sessionStorage.setItem('calculatorType', response?.data?.calculator_type)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (Object.keys(analyticsData).length !== 0) {
            // Carbon Check....
            // console.log("Carboncheck", parseInt(analyticsData?.Carbon))
            sessionStorage.setItem("scenario_name", "default");
            sessionStorage.setItem(
                "calculator_values_default",
                JSON.stringify(analyticsData?.formFields.Default)
            );
            sessionStorage.setItem(
                "calculator_values_Default",
                JSON.stringify(analyticsData?.formFields.Default)
            );
            const calculator_values_names = Object.keys(analyticsData?.formFields)
            sessionStorage.setItem("calculator_values_names", JSON.stringify(calculator_values_names))
            const fields = JSON.parse(analyticsData?.otherScenarios)
            if (!!Object.keys(fields)?.length) {
                for (const item of Object.keys(fields)) {
                    sessionStorage.setItem(
                        `calculator_values_${item}`,
                        JSON.stringify(fields[item])
                    );
                }
                sessionStorage.setItem(
                    "calculator_values_multiple",
                    analyticsData?.otherScenarios
                );
            }
            sessionStorage.setItem("technologyName", analyticsData?.technology_name)
            sessionStorage.setItem("all_currencies", JSON.stringify(analyticsData?.currencies))
            sessionStorage.setItem("currency", analyticsData?.currency)
            localStorage.setItem("CPP OC", analyticsData?.CPPOC)
            localStorage.setItem("metalCo2pp", analyticsData?.metalCo2pp)
            sessionStorage.setItem("stepsAndFields", JSON.stringify(analyticsData?.stepsAndFields))
            sessionStorage.setItem("group_id", analyticsData?.group_id)
            sessionStorage.setItem("signInstatus", analyticsData?.signInstatus)
            localStorage.setItem("costOneKGCo2", analyticsData.costOneKGCo2)
            sessionStorage.setItem("Carbon", (analyticsData?.Carbon))
            sessionStorage.setItem("Analytics_Route", CONDITIONALROUTES?.VISUAL_ANALYTICS_SAVED)
            history.push("/calculator/visual-analytics/false")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticsData])
    function changePaginationClick(val) {

        getListOfAnalytics(val || 0)
    }

    return (
        <React.Fragment>

            <Navbar />

            <div className="row pad-50 ">
                <div className="col page-container">
                    <p className="t-2">MY ANALYTICS</p>

                    {/* <ReactTable
                            data={data}
                            columns={columns}
                            defaultPageSize={2}
                            pageSizeOptions={[2, 4, 6]}
                        /> */}
                    {isLoading ?
                        'Loading...' :
                        <table className="table table-responsive-sm" id="report-table">
                            <thead>
                                <th>Report ID</th>
                                <th>Report Name</th>
                                <th>Date / Time</th>
                                <th>Type</th>
                                <th>Machine</th>
                                <th>Material</th>
                                <th className="center-text">Actions</th>
                            </thead>
                            <tbody>
                                {analyticssList && analyticssList.map((analytic, i) => {
                                    return (
                                        <tr>
                                            <td>{analytic.analytic_id}</td>
                                            <td>{analytic.analytic_name}</td>
                                            <td>{analytic.created_at.slice(0, 10)}</td>
                                            <td>{analytic.technology_name + ' ' + analytic.step_type_name}</td>
                                            <td>{analytic.machine_name}</td>
                                            <td>{analytic.material_name}</td>
                                            {/* <td className="d-flex align-items-center justify-content-around"><a href={"./visual-analytics"} className="center" onClick={}><img src="../assets/eye.png" alt="" height="18" width="27" className="no-hide" /></a><button onClick={()=>deleteanalytic(analytic.analytic_id)} className="center"><img src="../assets/trashicon.svg" alt=""  className="no-hide" /></button></td> */}
                                            <td className="d-flex align-items-center justify-content-around"><button className="center" onClick={() => getAnalyticsById(analytic.analytic_id)}><img src="../assets/eye.png" alt="" height="18" width="27" className="no-hide" /></button><button onClick={() => deleteAnalytic(analytic.analytic_id)} className="center"><img src="../assets/trashicon.svg" alt="" className="no-hide" /></button></td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>}
                    <div className="center" >
                        {(count / pageSize) >= 1 &&
                            <div id="pagination">
                                <p><img src="../assets/first-page.png" alt="" width="15" height="18" /></p>
                                <p><img src="../assets/prev-page.png" alt="" width="11" height="18" className="m-r-10" /></p>

                                {Array.apply(1, Array(parseInt(count / pageSize) + 1)).map(function (x, i) {
                                    return (<p onClick={() => { changePaginationClick(i) }}>{i + 1}</p>)
                                })}

                                <p><img src="../assets/next-page.png" alt="" width="11" height="18" className="m-l-10" /></p>
                                <p><img src="../assets/last-page.png" alt="" width="15" height="18" /></p>
                            </div>
                        }

                    </div>

                </div>

            </div>

        </React.Fragment>
    )
}

export default MyAnalytics;