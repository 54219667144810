import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Spinner } from "react-bootstrap";

import {
  CCMetalNoOfPartsDataM,
} from "atoms/CCCarbonMetal/CCMetalMachineDetails";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import {
  CCMetalAverageMachinePowerDataM,
  CCMetalEnergyBuildProcessDataM,
  CCMetalEnergyPerPartDataM,
} from "atoms/CCCarbonMetal/CCMetalElectricityConsumtion";

import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";

const ElectricityConsumption = ({ Socket, staticDataOfMachine }) => {
  const [averageMachinePower, setAverageMachinePower] = useRecoilState(
    CCMetalAverageMachinePowerDataM
  );
  const [energyBuildProcess, setEnergyBuildProcess] = useRecoilState(
    CCMetalEnergyBuildProcessDataM
  );
  const [energyPerPart, setEnergyPerPart] = useRecoilState(
    CCMetalEnergyPerPartDataM
  );
  const noOfPartsData = useRecoilValue(CCMetalNoOfPartsDataM);
  const { getComputedValue } = useRoundOffValue();
  const [, setProcessFlag] = useRecoilState(ProcessFlag);

  useEffect(() => {
    if (
      staticDataOfMachine.machine_id !== undefined &&
      staticDataOfMachine.material_id !== undefined
    ) {
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-ECAMP",
        input: {
          machine_id: +staticDataOfMachine.machine_id.value,
          material_id: +staticDataOfMachine.material_id.value,
        },
      });

      Socket.on("FOCC-ECAMP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setAverageMachinePower(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      averageMachinePower !== null &&
      staticDataOfMachine.build_time !== undefined
    ) {
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-ECEBP",
        input: {
          average_machine_power: +averageMachinePower,
          // staticDataOfMachine.average_machine_power.value,
          build_time: +staticDataOfMachine.build_time.value,
        },
      });

      Socket.on("FOCC-ECEBP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setEnergyBuildProcess(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageMachinePower]);

  useEffect(() => {
    if (energyBuildProcess && staticDataOfMachine.no_of_parts !== undefined) {
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-ECEPP",
        input: {
          energy_build_process: +energyBuildProcess,
          no_of_parts: +staticDataOfMachine.no_of_parts.value,
        },
      });

      Socket.on("FOCC-ECEPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setEnergyPerPart(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyBuildProcess, noOfPartsData]);

  useEffect(() => {
    if (
      averageMachinePower !== null &&
      energyBuildProcess !== null &&
      energyPerPart !== null
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          ElectricityConsumption: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageMachinePower, energyBuildProcess, energyPerPart]);

  return (
    <>
      <p className="input-heading">Average machine power (W)</p>

      {averageMachinePower !== undefined || averageMachinePower !== null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            // onChange={handleAverageMachinePower}
            onChange={(e) => {
              setAverageMachinePower(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={averageMachinePower}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          <Spinner animation="border" size="sm" />
        </div>
      )}

      <p className="input-heading">Energy build process (kWh/job)</p>

      {energyBuildProcess !== undefined || energyBuildProcess !== null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            onChange={(e) => {
              setEnergyBuildProcess(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={energyBuildProcess}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          <Spinner animation="border" size="sm" />
        </div>
      )}

      <p className="input-heading">Energy per part (kWh/part)</p>

      {energyPerPart !== undefined || energyPerPart != null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            onChange={(e) => {
              setEnergyPerPart(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={energyPerPart}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          <Spinner animation="border" size="sm" />
        </div>
      )}
    </>
  );
};

export default ElectricityConsumption;
