
export const useRoundOffValue = () => {
    const getComputedValue = (num) => {
        if (num === null) {
            return num
        }
        else {
            let number = num?.toFixed(3)
            const changedNum = parseFloat(number)
            return changedNum
        }
    }
    return {
        getComputedValue
    }
}