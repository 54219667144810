import { atom, selector } from "recoil";
import { CCPolyPowderToCustomerData } from "../CCPolyTransportation";
import { CCPolySystemToCustomerData } from "../CCPolyTransportation";

export const CCPolyPowderData = atom({
  key: "CCPolyPowderData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CCPolyModeOfTransportNode1 = atom({
  key: "CCPolyModeOfTransportNode1",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CCPolyModeOfTransportNode2 = atom({
  key: "CCPolyModeOfTransportNode2",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CCPolyVolumeReductionData = atom({
  key: "CCPolyVolumeReductionData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCRefreshRateScenarioData = atom({
  key: "CCRefreshRateScenarioData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCPolyCo2ePowderScenarioData = atom({
  key: "CCPolyCo2ePowderScenarioData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCPolyEnergyMaterialData = atom({
  key: "CCPolyEnergyMaterialData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCPolyEnergySystemData = atom({
  key: "CCPolyEnergySystemData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CCPolyCo2eEnergyData = atom({
  key: "CCPolyCo2eEnergyData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCPolyCo2eTransportationSData = atom({
  key: "CCPolyCo2eTransportationSData",
  default: undefined,
});

export const CCPolyCo2TotalTodaySData = atom({
  key: "CCPolyCo2TotalTodaySData",
  default: undefined,
});

export const CCPolyCo2TotalTomorrowSData = atom({
  key: "CCPolyCo2TotalTomorrowSData",
  default: undefined,
});

export const CCPolyTotalTraspotation = atom({
  key: "CCPolyTotalTraspotation",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
// /////////

export const CCPolyCarbonNeutralPowder = atom({
  key: "CCPolyCarbonNeutralPowder",
  default: "no",
});

export const CCPolyVolumeReductionState = atom({
  key: "CCPolyVolumeReductionState",
  default: "0",
});
export const CCRefreshRateScenarioState = atom({
  key: "CCRefreshRateScenarioState",
  default: "0",
});

export const CCPolyEnergyPercentage = atom({
  key: "CCPolyEnergyPercentage",
  default: "10",
});

export const CCPolyGreenPowderEnergy = atom({
  key: "CCPolyGreenPowderEnergy",
  default: "no",
});

export const CCPolyModeOfTranspotation1 = selector({
  key: "CCPolyModeOfTranspotation1",
  get: ({ get }) => String(get(CCPolyPowderToCustomerData)),
  set: ({ set }, newValue) => set(CCPolyPowderToCustomerData, newValue),
});

export const CCPolyModeOfTranspotation2 = selector({
  key: "CCPolyModeOfTranspotation2",
  get: ({ get }) => String(get(CCPolySystemToCustomerData)),
  set: ({ set }, newValue) => set(CCPolySystemToCustomerData, newValue),
});
