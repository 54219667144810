import React, { useEffect, } from "react";
import { useRecoilState } from "recoil";
import config from '../../../../../config';
import { HotspotArray } from "atoms/General";
import ReactHtmlParser from 'react-html-parser';
const axios = require("axios").default;
const ReportsTwo = ({ highestindex }) => {
  const pageSize = 3
  const access_token = sessionStorage.getItem("access_token");
  const [hotspotArray, setHotSpotArray] = useRecoilState(HotspotArray)
  const getHotSpotAnalysis = async (pageNo) => {
    try {
      const response = await axios.default.get(`${config.serviceUrl}hotspot/analysis/user/list`, {
        params: {
          pageSize: pageSize,
          pageNo: pageNo,
          technology_id: 2
        },
        headers: {
          Authorization: access_token,

        }
      })

      setHotSpotArray(response.data.data)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getHotSpotAnalysis(0)
  }, [])

  return (
    <div className="reports-section" >
      <div className="responsive-scroll borderForTable" >
        <div className="divTableReport">
          <div className="divTableBody">
            <div className="divTableRowTop">

              {
                hotspotArray.map((data, index) => {
                  return (
                    <div key={index} className={data.heading.includes(highestindex) && highestindex !== "" ? "divTableCellReportTop textAlignLeft hotspotBg" : "divTableCellReportTop textAlignLeft "}>{data.heading}</div>

                  )
                })
              }
            </div>
            <div className="divTableRow borders-table" >

              {
                hotspotArray.map((data, index) => {
                  return (
                    <div key={index} className="divTableCellReport report-two-text ">
                      {ReactHtmlParser(data.description)}

                    </div>
                  )
                })
              }
            </div>

            {/* <div className="divTableRowTop">
              <div className="divTableCellReportTop textAlignLeft">Co2e Powder</div>
              <div className="divTableCellReportTop textAlignLeft"> CO2e Energy <br />
                (Material processing, AM Processing)</div>
              <div className="divTableCellReportTop textAlignLeft"> CO2e logistics</div>
            </div>

            <div className="divTableRow">
              <div className="divTableCellReport report-two-text borderBottomForTableone">
                <ul>
                  <li>  Have you considered our carbon-neutral options?
                    (PA11 Arkema)</li>
                  <li>
                    Designing for AM with reduced volume? ( Link to DfAM)</li>
                  <li>
                    Align with additive minds team to have lower scrap
                    rate and higher quality on your production? ( Link to
                    AddMinds landing page)      </li>
                </ul>
              </div>
              <div className="divTableCellReport report-two-text">
                <ul>
                  <li> Designing for AM with reduced volume? Link to DfAM)</li>
                  <li>
                    Optimizing parameters for reduced build time?
                    (Link by Nameer)</li>
                  <li>
                    Optimized Nesting to have lower costs, build time and
                    energy consumption ( Link to data preparation training)</li>
                </ul>
              </div>
              <div className="divTableCellReport report-two-text borderBottomForTabletwo">
                <ul>
                  <li>  Less carbon - intensive for transportation?</li>
                  <li>
                    Link to Distributed Mfg.</li>
                  <li>
                    Plan ahead with our life cycle sol. Team to set up
                    powder delivery management ( Link? - Sean kelly) </li>
                </ul>
              </div>

            </div> */}

          </div>
        </div>
      </div>

    </div>
  );
};

export default ReportsTwo;
