import React, { useState, useEffect } from "react";
import Navbar from "../../components/Nav.jsx";
import socketIOClient from "socket.io-client";
import constant from "../../../utils/constants";
import Process from "./components/Process";
import Scenario from "./components/Scenario";
import Reports from "./components/Reports";
import ReportsTwo from "./components/ReportsTwo";
import CarbonPricingPolymer from "./components/CarbonPricingPolymer";
import "../styles/styles.css";
import "../CarbonCalculator/components/styles.css";
import { ToastContainer, toast } from "react-toastify";
import { ProcessFlag } from "atoms/General";
import { useRecoilState } from "recoil";

const CCSteps = () => {
  const [step, setStep] = useState("process");
  const [socket, setSocket] = useState();
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  useEffect(() => {
    const socketOb = new socketIOClient(constant.uri);
    const patch = require("socketio-wildcard")(socketIOClient.Manager);
    patch(socketOb);
    const socketObj = socketOb;
    setSocket(socketObj);
  }, []);
  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <div className="row  ">
        <div className="col page-container px-0">
          <div className="ccsteps-container">
            <div className="ccsteps-section">
              <button
                onClick={() => setStep("process")}
                className={
                  step === "process"
                    ? "selection-button m-0"
                    : "selection-button unselected-btn m-0"
                }
              >
                Process
              </button>
              <button
                onClick={() => {
                  if (processFlag.Transportation) {
                    setStep("carbonpricing");
                  } else {
                    toast.error("Please fill all process details.");
                  }
                }}
                className={
                  step === "carbonpricing"
                    ? "selection-button"
                    : "selection-button unselected-btn"
                }
              >
                Carbon Pricing
              </button>
              <button
                onClick={() => {
                  if (processFlag.CarbonPricing) {
                    setStep("scenario");
                    setProcessFlag((prevState) => {
                      return {
                        ...prevState,
                        ScenarioAnalysis: true,
                      };
                    });
                  } else {
                    toast.error("Please fill all  carbon pricing details.");
                  }
                }}
                className={
                  step === "scenario"
                    ? "selection-button"
                    : "selection-button unselected-btn"
                }
              >
                Scenarios
              </button>
              <button
                onClick={() => {
                  if (processFlag.Reports) {
                    setStep("reports");
                  } else {
                    toast.error("Please fill all scenario analysis details.");
                  }
                }}
                className={
                  step === "reports"
                    ? "selection-button"
                    : step === "reportstwo"
                      ? "selection-button "
                      : "selection-button unselected-btn"
                }
              >
                Reports
              </button>
            </div>
            {step === "process" && (
              <Process
                socket={socket}
                ScenarioState={() => setStep("carbonpricing")}
              />
            )}
            {step === "scenario" && (
              <Scenario
                socket={socket}
                ScenarioState={() => setStep("reports")}
              />
            )}
            {step === "reports" && (
              <Reports
                socket={socket}
                ScenarioState={() => setStep("reportstwo")}
              />
            )}
            {step === "reportstwo" && <ReportsTwo socket={socket} />}
            {step === "carbonpricing" && (
              <CarbonPricingPolymer
                socket={socket}
                ScenarioState={() => setStep("scenario")}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CCSteps;
