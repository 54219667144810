import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "./components/MaterialTable";
import MaterialTableValues from "./components/MaterialTableValues";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import config from "../../../../../config";
import {
  CCPolyCo2TotalTodaySData,
  CCPolyCo2TotalTomorrowSData,
  CCPolyVolumeReductionData,
  CCPolyEnergyMaterialData,
  CCPolyEnergySystemData,
  CCPolyCo2ePowderScenarioData,
  CCPolyCo2eEnergyData,
  CCPolyPowderData,
  CCPolyVolumeReductionState,
  CCPolyCarbonNeutralPowder,
  CCPolyEnergyPercentage,
  CCPolyGreenPowderEnergy,
  CCPolyModeOfTranspotation1,
  CCPolyModeOfTranspotation2,
  CCRefreshRateScenarioState
} from "atoms/CCPolymer/CCPolyScenario";
import { CCPolyCo2ePerPartPMData } from "atoms/CCPolymer/CCPolyPowderMFG";
import { CCPolyCo2ePerPartMPData } from "atoms/CCPolymer/CCPolyMaterialProcessing";
import { CCPolyCo2ePerPartAPData } from "atoms/CCPolymer/CCPolyAmProcess";

import { useGetMachineName } from "app/view/hooks/useGetMachineName";
import { useGetMaterialName } from "app/view/hooks/useGetMaterialById";
import {
  CCPolyCo2ePerPartTPData,
  CCPolyMaterialTransportationData,
  CCPolySystemTransportationData,
  CCPolyCarbonEmissionFactorNode1Data,
  CCPolyCarbonEmissionFactorNode2Data,
} from "atoms/CCPolymer/CCPolyTransportation";
import { DebounceInput } from "react-debounce-input";
import {
  technologyId,
  stepTypeId,
  technologyType,
  HighestIndexArray
} from "atoms/General/index.js";

const axios = require("axios").default;
const Scenario = ({ ScenarioState, socket }) => {
  const shiftSize = 7;
  const co2TotalToday = useRecoilValue(CCPolyCo2TotalTodaySData);
  const co2TotalTomorrow = useRecoilValue(CCPolyCo2TotalTomorrowSData);
  const cPowder = useRecoilValue(CCPolyPowderData);
  const volumeReduction = useRecoilValue(CCPolyVolumeReductionData);
  const energyMaterial = useRecoilValue(CCPolyEnergyMaterialData);
  const Powder = useRecoilValue(CCPolyCo2ePowderScenarioData);
  const Energy = useRecoilValue(CCPolyCo2eEnergyData);
  const EnergySystem = useRecoilValue(CCPolyEnergySystemData);
  const [powder, setPowder] = useState();
  const [co2ePowder, setCo2ePowder] = useState();
  const [co2eEnergy, setCo2eEnergy] = useState();
  const [EnergyMaterial, setEnergyMaterial] = useState();
  const [energySystem, setEnergySystem] = useState();
  const [VolumeReduction, setVolumeReduction] = useState();
  const co2TotalTransportation = useRecoilValue(CCPolyCo2ePerPartTPData);
  const techId = useRecoilValue(technologyId);
  const stepId = useRecoilValue(stepTypeId);
  const techName = useRecoilValue(technologyType);
  const costData = JSON.parse(localStorage.getItem("CostData"));
  const access_token = sessionStorage.getItem("access_token");
  const [hotSpotArray, setHotSpotArray] = useState([]);
  const [highestIndexArray, setHighestIndexArray] = useRecoilState(HighestIndexArray);

  //For scenario saving Data -->
  const co2PerPartPM = useRecoilValue(CCPolyCo2ePerPartPMData);
  const co2PerPartMP = useRecoilValue(CCPolyCo2ePerPartMPData);
  const co2PerPartAP = useRecoilValue(CCPolyCo2ePerPartAPData);
  const materialTransportaion = useRecoilValue(
    CCPolyMaterialTransportationData
  );

  const systemTransportation = useRecoilValue(CCPolySystemTransportationData);

  const cefnone = useRecoilValue(CCPolyCarbonEmissionFactorNode1Data);

  const cefnTwo = useRecoilValue(CCPolyCarbonEmissionFactorNode2Data);
  const cNPInput = useRecoilValue(CCPolyCarbonNeutralPowder)
  const volumeReductionInput = useRecoilValue(CCPolyVolumeReductionState)
  const energyPercentageInput = useRecoilValue(CCPolyEnergyPercentage)
  const greenPowderEnergyInput = useRecoilValue(CCPolyGreenPowderEnergy)
  const modeOfTranspotation1 = useRecoilValue(CCPolyModeOfTranspotation1)
  const modeOfTranspotation2 = useRecoilValue(CCPolyModeOfTranspotation2)
  //For scenaario saving data <--

  const [show, setShow] = useState(false);
  const [scenarioName, setScenarioName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const machineName = useGetMachineName(+costData?.machine_id?.value);
  const materialName = useGetMaterialName(+costData?.material_id?.value);
  const refreshRate = useRecoilValue(CCRefreshRateScenarioState)

  const DataSet = [
    {
      color: "#5DC0D3",
      title: co2ePowder
        ? ((co2ePowder / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2ePowder / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#9CEAEF",
      title: co2eEnergy
        ? ((co2eEnergy / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2eEnergy / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#07BEB8",
      title: co2TotalTransportation
        ? ((co2TotalTransportation / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(
        ((co2TotalTransportation / co2TotalToday) * 100).toFixed(2)
      ),
    },
  ];

  let arr = []
  useEffect(() => {
    if (
      cPowder !== undefined &&
      Powder !== undefined &&
      Energy !== undefined &&
      energyMaterial !== undefined &&
      co2TotalTransportation !== undefined &&
      EnergySystem !== undefined
    ) {
      setPowder(parseFloat(cPowder.today));
      setVolumeReduction(parseFloat(volumeReduction?.today));
      setCo2ePowder(parseFloat(Powder?.today));
      setCo2eEnergy(parseFloat(Energy?.today));
      setEnergyMaterial(parseFloat(energyMaterial?.today));
      setEnergySystem(parseFloat(EnergySystem?.today));
      arr.push(
        {
          "CO2e Powder": Powder.today,
          "CO2e Energy": Energy.today,
          "CO2e Logistics": co2TotalTransportation
        }
      );
      setHotSpotArray(arr);
      setHighestIndexArray(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cPowder,
    Powder,
    Energy,
    energyMaterial,
    co2TotalTransportation,
    EnergySystem,
  ]);

  const SaveScenario = async () => {
    toast.info("Please Wait...");
    const formFields = {
      technology_id: techId,
      step_type_id: stepId,
      technology_name: techName,
      machine_name: machineName,
      material_name: materialName,
      material_id: +costData?.material_id?.value,
      machine_id: +costData?.machine_id?.value,
      costData: costData,
      co2PerPartPM: co2PerPartPM,
      co2PerPartMP: co2PerPartMP,
      co2PerPartAP: co2PerPartAP,
      co2TotalTransportation: co2TotalTransportation,
      materialTransportaion: materialTransportaion,
      systemTransportation: systemTransportation,
      cefnone: cefnone,
      cefnTwo: cefnTwo,
      cNPInput: cNPInput,
      volumeReductionInput: volumeReductionInput,
      energyPercentageInput: energyPercentageInput,
      greenPowderEnergyInput: greenPowderEnergyInput,
      modeOfTranspotation1: modeOfTranspotation1,
      modeOfTranspotation2: modeOfTranspotation2,
      refreshRate: +refreshRate
    };
    const data = {
      scenario_data: JSON.stringify(formFields),
      scenario_name: scenarioName,
    };
    try {
      const response = await axios.post(
        `${config.serviceUrl}client/scenario/save`,
        data,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );

      if (response.data) {
        toast.success("Scenario saved Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter scenario name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setScenarioName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter scenario name"
              value={scenarioName}
            />
            {/* <p className="edit-icon value-unit">kg</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="selection-button step-button w-50 m-0"
              onClick={handleClose}
            >
              Close
            </button>
            <Button
              disabled={scenarioName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                SaveScenario();
                handleClose();
              }}
            >
              Save Scenario
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="powder-container">
        <div className="powder-table-top">
          <p className="powder-heading">Scenario Analysis</p>
        </div>
        <div className="d-flex flex-row p-3">
          <MaterialTable Socket={socket} />
          <MaterialTableValues Socket={socket} />
        </div>
        <div className="powder-table-top total-bottom">
          <p className="powder-heading" style={{ textTransform: "none" }}>
            Total (kg)
          </p>
          <div className="d-flex ">
            <p className="powder-heading pl-3">{co2TotalToday}</p>
            <p className="powder-heading pr-4 pl-5">{co2TotalTomorrow}</p>
          </div>
        </div>
      </div>

      <div className="graph-section">
        <div className="text-center">
          <p className="t-2">CO2e in % of kg per part</p>
          <p className="s-1 mb-0">Current Scenario</p>
        </div>
        <PieChart
          animationDuration={500}
          animationEasing="ease-out"
          // center={[50, 50]}
          width=" 300px"
          height="300px"
          data={DataSet}
          labelPosition={110}
          lengthAngle={360}
          lineWidth={40}
          paddingAngle={2}
          radius={PieChart.defaultProps.radius - shiftSize}
          startAngle={0}
          label={({ dataEntry }) => dataEntry.title}
          // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}
          labelStyle={{
            fill: "black",
            strokeWidth: "10",
            fontSize: "5px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
          }}
        />
      </div>

      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="d-flex">
          <div className="color-icon bg-color5" />
          <p className="color-rep-value">CO2e Powder</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color7 " />
          <p className="color-rep-value ">CO2e Energy</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color1 " />
          <p className="color-rep-value m-0">CO2e Logistics</p>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => ScenarioState()}
          className="selection-button step-button"
        >
          Visual Analytics
        </button>
        <button onClick={handleShow} className="selection-button step-button">
          Save My Scenario
        </button>
      </div>
    </>
  );
};

export default Scenario;
