import { atom } from "recoil";

export const CCPolyCo2EmissionFactorPricingData = atom({
    key: "CCPolyCo2EmissionFactorData",
    default: undefined,
});

export const CCPolyCo2ePowderData = atom({
    key: "CCPolyCo2ePowderData",
    default: undefined,
});

export const CCPolyCo2eEnergyPricingData = atom({
    key: "CCPolyCo2eEnergyData",
    default: undefined,
});

export const CCPolyCo2EmissionTransportationData = atom({
    key: "CCPolyCo2EmissionTransportationData",
    default: undefined,
});
