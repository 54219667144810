import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { useRecoilState, useRecoilValue } from "recoil";
import { CostData } from "atoms/CostToCarbonData";
import {
  CCPolyPowderPerBuildJobData,
  CCPolyCo2ePerJobPMData,
  CCPolyCo2ePerPartPMData,
} from "atoms/CCPolymer/CCPolyPowderMFG";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import { DebounceInput } from 'react-debounce-input';

const PowderMfg = ({ Socket }) => {
  const [powderpbj, setPowderpbj] = useRecoilState(CCPolyPowderPerBuildJobData);
  const [co2epbj, setCo2epbj] = useRecoilState(CCPolyCo2ePerJobPMData);
  const [co2epp, setCo2epp] = useRecoilState(CCPolyCo2ePerPartPMData);
  const { getComputedValue } = useRoundOffValue();
  const costdata = useRecoilValue(CostData);
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
  });
  const [, setProcessFlag] = useRecoilState(ProcessFlag);
  const costData = JSON.parse(localStorage.getItem("CostData"));

  // Getting Polymer Simulation Values for Powered PBJ

  useEffect(
    () => {
      if (
        costData.refreshing_rate !== undefined ||
        (null && costData.material_used !== undefined) ||
        null
      ) {
        Socket.off("*");
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: true,
          };
        });

        Socket.emit("carbon-calculator", {
          key: "FOCC-PMPPBJ",
          input: {
            material_used: costData.material_used.value,
            refreshing_rate: costData.refreshing_rate.value,
          },
        });

        Socket.on("FOCC-PMPPBJ", function (data) {

          const value = getComputedValue(data?.data?.value);
          setPowderpbj(value);
          setLoader((prevState) => {
            return {
              ...prevState,
              loaderone: false,
            };
          });
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Getting Polymer Simulation Values for Powered co2epbj

  useEffect(
    () => {
      if (
        powderpbj !== undefined ||
        (null && costData.material_id !== undefined) ||
        null
      ) {
        Socket.off("*");
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: true,
          };
        });

        Socket.emit("carbon-calculator", {
          key: "FOCC-PMCPJ",
          input: {
            material_id: costData.material_id.value,
            powder_per_build_job: powderpbj,
          },
        });

        Socket.on("FOCC-PMCPJ", function (data) {

          const value = getComputedValue(data?.data?.value);
          setCo2epbj(value);
          setLoader((prevState) => {
            return {
              ...prevState,
              loadertwo: false,
            };
          });
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [powderpbj]
  );

  // Getting Polymer Simulation Values for co2epp

  useEffect(
    () => {

      if (
        co2epbj !== undefined ||
        (null && costData.no_of_parts !== undefined) ||
        null
      ) {
        Socket.off("*");
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: true,
          };
        });

        Socket.emit("carbon-calculator", {
          key: "FOCC-PMCPP",
          input: {
            no_of_parts: costData.no_of_parts.value,
            co2e_per_job_pm: co2epbj,
          },
        });

        Socket.on("FOCC-PMCPP", function (data) {

          const value = getComputedValue(data?.data?.value);
          setCo2epp(value);
          setLoader((prevState) => {
            return {
              ...prevState,
              loaderthree: false,
            };
          });
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [co2epbj]
  );

  useEffect(() => {
    if (powderpbj != null && co2epbj != null && co2epp != null) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderpbj, co2epbj, co2epp]);

  return (
    <>
      <p className="input-heading"> Powder per build job (kg)</p>

      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setPowderpbj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={loader.loaderone ? (
            <Spinner animation="border" size="sm" />
          ) : (
            powderpbj
          )}
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per job (kg)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2epbj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={loader.loadertwo ? <Spinner animation="border" size="sm" /> : co2epbj}
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per part (kg)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2epp(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={loader.loaderthree ? <Spinner animation="border" size="sm" /> : co2epp}
        />
        <OverrideAbleTip />
      </div>
    </>
  );
};

export default PowderMfg;
