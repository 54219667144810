import { atom } from "recoil"

export const InertGasConsumptionDataM = atom({
    key: "InertGasConsumptionDataM",
    default: undefined
})

export const InertGasTypeDataM = atom({
    key: "InertGasTypeDataM",
    default: undefined
})
export const InertGasUsedEnergyDataM = atom({
    key: "InertGasUSedEnergyDataM",
    default: undefined
})

