import { atom, selector } from "recoil";

export const CCMetalMachineDataM = atom({
  key: "CCMetalMachineDataM",
  default: 0,
});

export const CCMetalMachineNameDataM = atom({
  key: "CCMetalMachineNameDataM",
  default: undefined,
});
export const CCMetalMaterialDataM = atom({
  key: "CCMetalMaterialDataM",
  default: 0,
});
export const CCMetalMaterialNameDataM = atom({
  key: "CCMetalMaterialNameDataM",
  default: 0,
});
export const CCMetalBuildTimeDataM = atom({
  key: "CCMetalBuildTimeDataM",
  default: undefined,
});
export const CCMetalPartVolumeDataM = atom({
  key: "CCMetalPartVolumeDataM",
  default: undefined,
});
export const CCMetalPartSupportVolumeDataM = atom({
  key: "CCMetalPartSupportVolumeDataM",
  default: undefined,
});
export const CCMetalNoOfPartsDataM = atom({
  key: "CCMetalNoOfPartsDataM",
  default: undefined,
});
export const CCMetalPowderUsedDataM = atom({
  key: "CCMetalPowderUsedDataM",
  default: undefined,
});

export const CCMetalGetNoOfPartsDataM = selector({
  key: "CCMetalGetNoOfPartsDataM",
  get: ({ get }) => get(CCMetalNoOfPartsDataM),
});
export const CCMetalGetBuildTimeDataM = selector({
  key: "CCMetalGetBuildTimeDataM",
  get: ({ get }) => get(CCMetalBuildTimeDataM),
});

export const CCMetalGetPowderUsedDataM = selector({
  key: "CCMetalGetPowderUsedDataM",
  get: ({ get }) => get(CCMetalPowderUsedDataM),
});
