import { atom } from "recoil"

export const AveragePowerData = atom({
    key: "AveragePowerData",
    default: undefined
})

export const InertGasPerJobOneData = atom({
    key: "InertGasPerJobOneData",
    default: undefined
})
export const InertGasPerJobTwoData = atom({
    key: "InertGasPerJobTwoData",
    default: undefined
})

export const EnergyUsedPerJobData = atom({
    key: "EnergyUsedPerJobData",
    default: undefined
})

export const Co2EmissionFactorData = atom({
    key: "Co2EmissionFactorData",
    default: undefined
})
export const Co2ePerJobAPData = atom({
    key: "Co2ePerJobAPData",
    default: undefined
})
export const Co2ePerPartAPData = atom({
    key: "Co2ePerPartAPData",
    default: undefined
})

