import { atom } from "recoil";

export const CCPolyNumberOfPartsPerYearData = atom({
  key: "CCPolyNumberOfPartsPerYearData",
  default: undefined,
});

export const CCPolyPowderSiteData = atom({
  key: "CCPolyPowderSiteData",
  default: undefined,
});
export const CCPolyMachineSiteData = atom({
  key: "CCPolyMachineSiteData",
  default: undefined,
});
export const CCPolyPowderManufacturerData = atom({
  key: "CCPolyPowderManufacturerData",
  default: undefined,
});
export const CCPolyMaterialTransportationData = atom({
  key: "CCPolyMaterialTransportationData",
  default: undefined,
});
export const CCPolySystemToCustomerData = atom({
  key: "CCPolySystemToCustomerData",
  default: undefined,
});
export const CCPolyPowderToCustomerData = atom({
  key: "CCPolyPowderToCustomerData",
  default: undefined,
});
export const CCPolySystemTransportationData = atom({
  key: "CCPolySystemTransportationData",
  default: undefined,
});
export const CCPolyCarbonEmissionFactorNode1Data = atom({
  key: "CCPolyCarbonEmissionFactorNode1Data",
  default: undefined,
});
export const CCPolyCarbonEmissionFactorNode2Data = atom({
  key: "CCPolyCarbonEmissionFactorNode2Data",
  default: undefined,
});
export const CCPolyCo2ePerYearData = atom({
  key: "CCPolyCo2ePerYearData",
  default: undefined,
});
export const CCPolyCo2ePerPartTPData = atom({
  key: "CCPolyCo2ePerPartTPData",
  default: undefined,
});
