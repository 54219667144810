import { atom } from "recoil";

export const NumberOfPartsPerYearData = atom({
  key: "NumberOfPartsPerYearData",
  default: undefined,
});

export const PowderSiteData = atom({
  key: "PowderSiteData",
  default: undefined,
});
export const MachineSiteData = atom({
  key: "MachineSiteData",
  default: undefined,
});
export const PowderManufacturerData = atom({
  key: "PowderManufacturerData",
  default: undefined,
});
export const MaterialTransportationData = atom({
  key: "MaterialTransportationData",
  default: undefined,
});
export const SystemToCustomerData = atom({
  key: "SystemToCustomerData",
  default: undefined,
});
export const PowderToCustomerData = atom({
  key: "PowderToCustomerData",
  default: undefined,
});
export const SystemTransportationData = atom({
  key: "SystemTransportationData",
  default: undefined,
});
export const CarbonEmissionFactorNode1Data = atom({
  key: "CarbonEmissionFactorNode1Data",
  default: undefined,
});
export const CarbonEmissionFactorNode2Data = atom({
  key: "CarbonEmissionFactorNode2Data",
  default: undefined,
});
export const Co2ePerYearData = atom({
  key: "Co2ePerYearData",
  default: undefined,
});
export const Co2ePerPartTPData = atom({
  key: "Co2ePerPartFData",
  default: undefined,
});
