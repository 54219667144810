import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_STEP_DETAILS, GET_CLIENT_MACHINE, GET_CLIENT_MATERIAL } from './getStepDetail';
import { useRecoilState } from "recoil"
import { MachineDataM, MaterialDataM, MachineNameDataM, MaterialNameDataM, BuildTimeDataM, PartVolumeDataM, PartSupportVolumeDataM, NoOfPartsDataM, PowderUsedDataM } from 'atoms/Co2Metal/MachineDetailsAtoms';
import { ProcessFlag } from 'atoms/General';
import { useRoundOffValue } from 'app/components/hooks/useRoundOffValue';
import { Spinner } from "react-bootstrap";
import constants from 'utils/constants';


const MachineDetails = (props) => {
    const { StepID, Socket } = props

    const client = useApolloClient();
    const [stepDetails, setStepDetails] = useState([])
    const [dropdownOne, setDropdownOne] = useState([])
    const [dropdownTwo, setDropdownTwo] = useState([])
    const [noofParts, setNoOfParts] = useRecoilState(NoOfPartsDataM)
    const [buildtime, setBuildTime] = useRecoilState(BuildTimeDataM)
    const [machineName, setMachineName] = useRecoilState(MachineNameDataM)
    const [materialName, setMaterialName] = useRecoilState(MaterialNameDataM)
    const [selectedMachine, setSelectedMachine] = useRecoilState(MachineDataM)
    const [selectedMaterial, setSelectedMaterial] = useRecoilState(MaterialDataM)
    const [partVolume, setPartVolume] = useRecoilState(PartVolumeDataM)
    const [partSupportVolume, setPartSupportVolume] = useRecoilState(PartSupportVolumeDataM)
    const [powderUsed, setPowderUsed] = useRecoilState(PowderUsedDataM)
    const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag)
    const { getComputedValue } = useRoundOffValue()
    const calculatorType = sessionStorage.getItem("calculatorType");


    let group_id = (+sessionStorage.getItem('group_id'));

    useEffect(() => {
        const GetClientSteps = () => {
            client.query({
                query: GET_STEP_DETAILS,
                variables: { step_id: StepID }
            }).then(({ data }) => {

                setStepDetails(data.getFieldsByStepId)
            }).catch(error => {
                console.log('error', error)
                // toast.error(error.graphQLErrors[0].messasge.message)
            });
        }

        const GetMachineDropdown = () => {

            client.query({
                query: constants.GET_MACHINES,
                variables: { technology_id: 1, calculator_type: calculatorType }
            }).then(({ data }) => {

                setDropdownOne(data.getClientMachineByTechnology)
            }).catch(error => {
                console.log('error', error)
                // toast.error(error.graphQLErrors[0].messasge.message)
            });

        }
        const GetMaterialDropdown = () => {
            if (selectedMachine !== 0) {
                client.query({
                    query: constants.GET_MATERIALS,
                    variables: { machine_id: selectedMachine, group_id: group_id, calculator_type: calculatorType }
                }).then(({ data }) => {

                    setDropdownTwo(data.getClientMaterialByMachineId)
                }).catch(error => {
                    console.log('error', error)
                    // toast.error(error.graphQLErrors[0].messasge.message)
                });
            }
        }
        GetClientSteps();
        GetMachineDropdown();
        GetMaterialDropdown()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMachine]);

    useEffect(() => {
        if (selectedMaterial && partVolume && noofParts && partSupportVolume) {
            Socket.off("*")

            Socket.emit("carbon-calculator", { key: "FOCC-MDPU", input: { material_id: selectedMaterial, part_volume: partVolume, no_of_parts: noofParts, part_support_volume: partSupportVolume } });
            Socket.on("FOCC-MDPU", function (data) {

                const value = getComputedValue(data?.data?.value)
                setPowderUsed(value);
            });
        }
    }, [selectedMaterial, partVolume, noofParts, partSupportVolume])



    useEffect(() => {
        if (selectedMachine === "" || selectedMaterial === "" || buildtime === "" || partVolume === "" || noofParts === "" || partSupportVolume === "" || powderUsed === "") {
            setProcessFlag((prevState) => {
                return {
                    ...prevState,
                    MachineDetails: false,
                }
            })
        }
        else if (selectedMachine !== undefined && selectedMaterial !== undefined && buildtime !== undefined && partVolume !== undefined && noofParts !== undefined && partSupportVolume !== undefined && powderUsed !== undefined) {
            setProcessFlag((prevState) => {
                return {
                    ...prevState,
                    MachineDetails: true,
                }
            })
        }
        else {
            setProcessFlag((prevState) => {
                return {
                    ...prevState,
                    MachineDetails: false,
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildtime, noofParts, partVolume, selectedMachine, selectedMaterial, powderUsed, partSupportVolume])

    return (
        <>
            <p className='input-heading' >Machine</p>

            <div className='input-section' >
                <Form.Control onChange={(e) => {
                    const valueArray = e.target.value.split("&")
                    setSelectedMachine(parseInt(valueArray[0]));
                    setMachineName(valueArray[1]);
                }} as="select" aria-label="Default Control example">
                    <option className='select-option'>
                        Please select machine
                    </option>
                    {
                        stepDetails[0]?.name === "Machine" &&
                        dropdownOne.map((item, index) => {
                            return (
                                <option key={index} value={`${item?.machine_id}&${item?.name}`} selected={selectedMachine === item?.machine_id && "selected"} className='select-option'>
                                    {item?.name}
                                </option>
                            )
                        })

                    }

                </Form.Control>
                <img src="../assets/Accordionarrow.svg" alt="" className='edit-icon' />
            </div>
            <p className='input-heading' >Powder</p>

            <div className='input-section' >
                <Form.Control onChange={(e) => {
                    const valueArray = e.target.value.split("&")
                    setSelectedMaterial(parseInt(valueArray[0]))
                    setMaterialName(valueArray[1])
                }} as="select" aria-label="Default Control example">
                    <option className='select-option'>
                        Please select material
                    </option>
                    {
                        stepDetails[1]?.name === "Material" &&
                        dropdownTwo.map((item, index) => {
                            return (
                                <option key={index} value={`${item?.material_id}&${item?.name}`} selected={selectedMaterial === item?.material_id && "selected"} className='select-option'>
                                    {item?.name}
                                </option>
                            )
                        })

                    }

                </Form.Control>
                <img src="../assets/Accordionarrow.svg" alt="" className='edit-icon' />
            </div>
            <p className='input-heading' >Build time (h)</p>
            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setBuildTime(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter build time"
                    value={buildtime} />


            </div>
            <p className='input-heading' >Part volume (cm<sup className='font-weight-bold'>3</sup>)</p>
            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setPartVolume(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter part volume"
                    value={partVolume} />
                {/* <p className="edit-icon value-unit">cm3</p> */}


            </div>
            <p className='input-heading' >Part support volume (cm<sup className='font-weight-bold'>3</sup>)</p>
            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setPartSupportVolume(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter part support volume"
                    value={partSupportVolume} />

                {/* <p className="edit-icon value-unit">cm3</p> */}

            </div>
            <p className='input-heading' >Number of parts</p>

            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setNoOfParts(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter number of parts"
                    value={noofParts} />


            </div>
            <p className='input-heading' >Powder used (kg)</p>
            <div className="data-field">{
                powderUsed ?
                    powderUsed :
                    <Spinner animation="border" size="sm" />
            }
            </div>

        </>
    )
}

export default MachineDetails

