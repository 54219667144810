/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = { preemium: sessionStorage.getItem('allow') === 'flexible'? true: false, }

export const AccessSlice = createSlice({
  name: 'Access',
  initialState,
  reducers: {
    setAccess: (state, action) => {
      state.preemium = action.payload
    },
  },
})

// Actions
export const { setAccess } = AccessSlice.actions

export default AccessSlice.reducer
