import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/react-hooks";
import { Accordion, Card, Button } from "react-bootstrap";
import MachineDetails from "./components/MachineDetails";
import MachineManufacturing from "./components/MachineManufacturing";
import PowderMfg from "./components/PowderMfg";
import MaterialProcessing from "./components/MaterialProcessing";
import AmProcess from "./components/AmProcess";
import Transportation from "./components/Transportation";
import PostProcessing from "./components/PostProcessing";
import WastePowder from "./components/WastePowder";
import ArrowDown from "./components/ArrowDown";
import { Spinner } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import {
  technologyId,
  stepTypeId,
  technologyType,
} from "atoms/General/index.js";
import { toast } from "react-toastify";

import { ProcessFlag } from "atoms/General/index.js";
import { useRecoilState, useRecoilValue } from "recoil";
const Process = ({ ScenarioState, socket }) => {
  const GET_CLIENT_STEPS = gql`
    query ($technology_id: Int, $type_id: Int) {
      getClientSteps(technology_id: $technology_id, type_id: $type_id) {
        step_id
        name
        description
        selected_icon
        unselected_icon
        is_blocked
        step_type_id {
          step_type_id
          name
          description
          is_blocked
          is_premium
        }
        technology_id {
          technology_id
          name
        }
      }
    }
  `;
  const client = useApolloClient();
  const [clientSteps, setClientSteps] = useState([]);
  const [loader, setLoader] = useState(true);
  const [techId, setTechId] = useRecoilState(technologyId);
  const [steptypeId, setStepTypeId] = useRecoilState(stepTypeId);
  const [TechnologyType, settechnologyType] = useRecoilState(technologyType);
  const processFlag = useRecoilValue(ProcessFlag);
  useEffect(() => {
    setTechId(2);
    setStepTypeId(3);
    settechnologyType("CO2e Polymer");
    client
      .query({
        query: GET_CLIENT_STEPS,
        variables: { technology_id: 2, type_id: 3 },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getClientSteps);
        // console.log(data.getClientSteps);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [activeState, setActiveState] = useState(-1);
  const toggleState = (idnum) => {
    const id = idnum;
    if (activeState === +id) {
      setActiveState(null);
    } else {
      setActiveState(+id);
    }
  };
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" />{" "}
        </div>
      ) : (
        <>
          {" "}
          <Accordion>
            {clientSteps &&
              clientSteps.map((step, index) => {

                return (
                  <>
                    <Card key={index}>
                      {step?.step_id !== 67 && step?.step_id !== 86 && (
                        <Card.Header
                          className={activeState === index ? "active" : ""}
                        >
                          <div className="d-flex justify-content-between">
                            <Accordion.Toggle
                              onClick={() => toggleState(index)}
                              className={
                                activeState === index ? "active-color" : ""
                              }
                              as={Button}
                              variant="link"
                              eventKey={index === 0 ? "0" : index}
                              data-display="static"
                            >
                              {/* {step?.name} */}
                              {step?.name.includes("not") ? (
                                <Tippy
                                  key={index}
                                  content={<span>{step.name}</span>}
                                >
                                  <span>{step.name}</span>
                                </Tippy>
                              ) : step.name.includes("Powder Processing") ? (
                                <Tippy
                                  key={index}
                                  content={
                                    <span>
                                      After analyzing data we have noticed that the impact of powder processing is minimal with respect to CO2e emissions in the overall process when addressing powder processing, we consider the electricity consumption of an MQS.
                                    </span>
                                  }
                                >
                                  <span>{step.name}</span>
                                </Tippy>
                              ) : (
                                step.name
                              )}
                            </Accordion.Toggle>
                            <Accordion.Toggle
                              onClick={() => toggleState(index)}
                              id="0"
                              as={Button}
                              variant="link"
                              eventKey={index === 0 ? "0" : index}
                            >
                              <div
                                className={
                                  activeState === index
                                    ? "arrow-box active "
                                    : "arrow-box"
                                }
                              >
                                <ArrowDown />
                              </div>
                            </Accordion.Toggle>
                          </div>
                        </Card.Header>
                      )}
                      <Accordion.Collapse
                        data-target={index}
                        eventKey={index === 0 ? "0" : index}
                      >
                        <Card.Body>
                          {step?.step_id === 59 && activeState === index && (
                            <MachineDetails StepID={59} Socket={socket} />
                          )}
                          {step?.step_id === 60 &&
                            (activeState === index &&
                              processFlag.MachineManufacturing ? (
                              <MachineManufacturing StepID={60} />
                            ) : (
                              <p>Machine Manufacturing (currently not available)</p>
                            ))}
                          {step?.step_id === 61 &&
                            (activeState === index &&
                              processFlag.MachineDetails ? (
                              <PowderMfg Socket={socket} />
                            ) : (
                              <p>Add all machine details to view</p>
                            ))}
                          {step?.step_id === 62 &&
                            (activeState === index &&
                              processFlag.PowderManufacturing ? (
                              <MaterialProcessing Socket={socket} />
                            ) : (
                              <p>
                                Add all powder manufacturing details to view
                              </p>
                            ))}
                          {step?.step_id === 63 &&
                            (activeState === index &&
                              processFlag.MaterialProcessing ? (
                              <AmProcess Socket={socket} />
                            ) : (
                              <p>Add all material processing details to view</p>
                            ))}
                          {step?.step_id === 64 &&
                            (activeState === index && processFlag.AMProcess ? (
                              <Transportation Socket={socket} />
                            ) : (
                              <p>Add all Additive Manufacturing Process details to view</p>
                            ))}
                          {step?.step_id === 65 &&
                            (activeState === index &&
                              processFlag.PostProcessing ? (
                              <PostProcessing />
                            ) : (
                              <p>Post Processing (currently not available)</p>
                            ))}
                          {step?.step_id === 66 &&
                            (activeState === index &&
                              processFlag.WastePowder ? (
                              <WastePowder />
                            ) : (
                              <p>Used Powder - End of Life (currently not available)
                              </p>
                            ))}
                          {/* {step?.step_id === 67 &&
                                            (activeState === index &&
                                                <Co2Process Socket={socket} />)
                                        } */}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </>
                );
              })}
          </Accordion>
          <div className="d-flex justify-content-center pt-1 w-75 flex-column">
            <p className="bold warning_note">Please note that the EOS Cost and Carbon Calculator provides only rough estimates.  EOS assumes no liability for the information from this tool. For precise and reliable values, please consult with Additive Minds at EOS. Please see the Data Matrix to understand the basis for the calculation of the EOS Cost & Carbon Calculator.</p>
          </div>
          <button
            onClick={() => {
              if (processFlag.Transportation) {
                ScenarioState();
              } else {
                toast.error("Please fill all process details.");
              }
            }}
            className="selection-button step-button"
          >
            Proceed
          </button>
        </>
      )}
    </>
  );
};

export default Process;
