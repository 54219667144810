import React from "react";

const OverrideGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Override & Personalization</h3>
            <p>The override function is available to allow you to change calculated values to manually entered
                values. To use this function select edit icon next to the value you wish to change.
            </p>
            <img src="/assets/override-guide-1.png" alt="" className="img-fluid" />
            <p>Clicking on the override function will open a text field and you will be able to enter a desired
                value to the text field. </p>
            <img src="/assets/override-guide-2.png" alt="" className="img-fluid" />
            <p>Once you enter the value calculated numbers will update automatically. Previous value can be
                restored by clicking on cross icon. </p>
            <img src="/assets/override-guide-3.png" alt="" className="img-fluid" />
            <p>For a few fields there would be a save button when the edit button is selected. Entering a value
                and clicking on the save button allows you to save the value of the field as default
            </p>
            <img src="/assets/override-guide-4.png" alt="" className="img-fluid" />
            <p>To reset back to default values, you can click on the reset button next to the save button. </p>
            <img src="/assets/override-guide-5.png" alt="" className="img-fluid" />
        </>
    )
}

export default OverrideGuide;