import React from "react";
import { Modal } from 'react-bootstrap';

const BatchFillingPop = ({ isOpen, setIsOpen }) => {

    const close = () => {
        setIsOpen(false);
    }

    return (
        <Modal
            dialogClassName="modal60w"
            show={isOpen}
        >
            <div id="popup-warning">
                <div id="popup-box">
                    <div id="warning-details">
                        <table>
                            <tr>
                                <td>
                                    <img src="../assets/tooltip1.JPG" alt="" height="140" width="140" />
                                </td>
                                <td>
                                    <img src="../assets/tooltip2.png" alt="" height="140" width="140" />
                                </td>
                                <td>
                                    <img src="../assets/tooltip3.JPG" alt="" height="140" width="140" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="label-style">Best (80%)</p>
                                </td>
                                <td>
                                    <p className="label-style">Regular (50%)</p>
                                </td>
                                <td>
                                    <p className="label-style">Worst (30%)</p>
                                </td>
                            </tr>
                        </table>
                        <div id="btn-row2">
                            <div className="button3 gray close-popup">
                                <p onClick={close}>Cancel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default BatchFillingPop;