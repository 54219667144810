import { atom } from "recoil";

export const TotalCo2PerPartData = atom({
  key: "TotalCo2PerPartData",
  default: undefined,
});

export const CostOfOneKGCo2Data = atom({
  key: "CostOfOneKGCo2Data",
  default: undefined,
});

export const CostOfCo2PerPartFData = atom({
  key: "CostOfCo2PerPartFData",
  default: undefined,
});

export const CppWithCo2Data = atom({
  key: "CppWithCo2Data",
  default: undefined,
});
