import React, { useEffect } from "react";
import { useRecoilState, } from "recoil";
import {
    PowderData,
    VolumeReductionData,
    Co2ePowderScenarioData,
    EnergyMaterialData,
    EnergySystemData,
    Co2eEnergyData,
    Co2TotalTodaySData,
    Co2TotalTomorrowSData,
    VolumeReductionInput,
    EnergPercentageInput,
    GreenPowderEnergyInput,
    CnpInput,
    TotalTraspotation,
    CPolyModeOfTransportNode1,
    CPolyModeOfTransportNode2,
    CPolyModeOfTranspotation1,
    CPolyModeOfTranspotation2,
    RefreshRateScenarioInput,
    RefreshRateScenario
} from "atoms/Co2Polymer/ScenarioPolymer";
import SelectDropdown from "app/components/SelectDropdown";
import { PercentageData, YesNoData, LogisticsData } from 'utils/selectDropdowns';

import { Co2ePerPartPMData } from "atoms/Co2Polymer/PowderMfgAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const MaterialTableValues = ({ Socket, fieldObj }) => {
    const [powder, setPowder] = useRecoilState(PowderData);
    const [volumeReductionS, setVolumeReductionS] =
        useRecoilState(VolumeReductionData);
    const [co2EPowder, setCo2EPowder] = useRecoilState(Co2ePowderScenarioData);
    const [energyMaterial, setEnergyMaterial] =
        useRecoilState(EnergyMaterialData);
    const [energySytem, setEnergySystem] = useRecoilState(EnergySystemData);
    const [co2eEnergy, setCo2eEnergy] = useRecoilState(Co2eEnergyData);
    const [, setCo2TotalToday] = useRecoilState(Co2TotalTodaySData);
    const [, setCo2TotalTomorrow] = useRecoilState(
        Co2TotalTomorrowSData
    );
    const [cNp, setCNP] = useRecoilState(CnpInput);
    const [volumeReduction, setVolumeReduction] =
        useRecoilState(VolumeReductionInput);
    const [energyPercentage, setEnergyPercentage] =
        useRecoilState(EnergPercentageInput);
    const [greenPowderEnergy, setGreenPowderEnergy] = useRecoilState(
        GreenPowderEnergyInput
    );
    const [totalTranpotation, setTotalTraspotation] = useRecoilState(TotalTraspotation);
    const co2PerPartPM = fieldObj?.co2PerPartPM;
    const co2PerPartMP = fieldObj?.co2PerPartMP;
    const co2PerPartAP = fieldObj?.co2PerPartAP;
    const { getComputedValue } = useRoundOffValue();
    // mode of transportation
    const [modeOfTranspotation1, setModeOfTranspotation1] = useRecoilState(
        CPolyModeOfTranspotation1
    );
    const [modeOfTranspotation2, setModeOfTranspotation2] = useRecoilState(
        CPolyModeOfTranspotation2
    );
    const [modeOfTransportnode1, setmodeOfTransportnode1] = useRecoilState(
        CPolyModeOfTransportNode1
    );

    const [modeOfTransportnode2, setmodeOfTransportnode2] = useRecoilState(
        CPolyModeOfTransportNode2
    );
    const [refreshRatePercentage, setRefreshRatePercentage] =
        useRecoilState(RefreshRateScenarioInput);
    const [refreshRateScenario, setRefreshRateScenario] =
        useRecoilState(RefreshRateScenario);

    const materialTransportaion = fieldObj?.materialTransportaion;
    const systemTransportation = fieldObj?.systemTransportation;
    const cefnone = fieldObj?.cefnone;
    const cefnTwo = fieldObj?.cefnTwo;
    const build_time = fieldObj?.build_time;
    const noOfPartsData = fieldObj.noOfPartsData
    const materialId = fieldObj.material_id
    const machineId = fieldObj.machine_id
    const PartVolume = fieldObj.PartVolume
    const buildHeight = fieldObj.buildHeight
    const refreshRate = fieldObj.refreshRate

    useEffect(() => {
        setModeOfTranspotation1(fieldObj?.modeOfTranspotation1)
        setModeOfTranspotation2(fieldObj?.modeOfTranspotation2)
        setCNP(fieldObj?.cnpInput)
        setVolumeReduction(fieldObj?.volumeReductionInput)
        setEnergyPercentage(fieldObj?.energyPercentageInput)
        setGreenPowderEnergy(fieldObj?.greenPowderEnergyInput)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldObj])
    useEffect(() => {
        if (modeOfTranspotation1 && modeOfTranspotation2) {
            Socket.off("*");

            Socket.emit("carbon-calculator", {
                key: "MSA-MCT",
                input: {
                    node1_mode: modeOfTranspotation1,
                    node2_mode: modeOfTranspotation2,
                    material_transportation: +materialTransportaion,
                    system_transportation: +systemTransportation,
                    carbon_emission_factor_node_1: +cefnone,
                    carbon_emission_factor_node_2: +cefnTwo,
                    // utilization: +staticDataOfMachine?.utilization?.value,
                    build_time: +build_time,
                    // setup_time: +staticDataOfMachine?.setup_time?.value,
                    // cool_down_time_in_system:
                    //   +staticDataOfMachine.cool_down_time_in_system.value, //not in metal
                    // unpacking_time_per_job:
                    //   +staticDataOfMachine?.unpacking_time_per_job?.value,
                },
            });

            Socket.on("MSA-MCT", function (data) {


                const transportNode1Today = getComputedValue(
                    data?.data?.transport_node_1_today
                );
                const transportNode1Tomorrow = getComputedValue(
                    data?.data?.transport_node_1_tomorrow
                );
                const transportNode2Today = getComputedValue(
                    data?.data?.transport_node_2_today
                );
                const transportNode2Tomorrow = getComputedValue(
                    data?.data?.transport_node_2_tomorrow
                );
                // console.log("transportNode1Today: ", transportNode1Today);
                // console.log("transportNode1Tomorrow: ", transportNode1Tomorrow);
                // console.log("transportNode2Today: ", transportNode2Today);
                // console.log("transportNode2Tomorrow: ", transportNode2Tomorrow);

                setmodeOfTransportnode1({
                    today: transportNode1Today,
                    tomorrow: transportNode1Tomorrow,
                });
                setmodeOfTransportnode2({
                    today: transportNode2Today,
                    tomorrow: transportNode2Tomorrow,
                });
                setTotalTraspotation({
                    today: ((transportNode1Today.length > 5) || (transportNode2Today.length > 5)) ? data?.data?.transport_today.toFixed(5) : getComputedValue(data?.data?.transport_today),
                    tomorrow: ((transportNode1Tomorrow.length > 5) || (transportNode2Tomorrow.length > 5)) ? data?.data?.transport_tomorrow.toFixed(5) : getComputedValue(data?.data?.transport_tomorrow),
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modeOfTranspotation1, modeOfTranspotation2]);
    useEffect(() => {
        if (cNp && volumeReduction) {

            Socket.emit("carbon-calculator", {
                key: "PSA-PCP",
                input: {
                    carbon_natural_powder: cNp,
                    co2e_per_part_pm: co2PerPartPM,
                    reduction_in_volume: volumeReduction,
                },
            });

            Socket.on("PSA-PCP", function (data) {

                const valuepatod = getComputedValue(data.data.powder_today);
                const valuepatom = getComputedValue(data.data.powder_tomorrow);
                const valuecptod = getComputedValue(data.data.carbon_powder_today);
                const valuecptom = getComputedValue(data.data.carbon_powder_tomorrow);
                const valuevptod = getComputedValue(data.data.volume_reduction_today);
                const valuevptom = getComputedValue(
                    data.data.volume_reduction_tomorrow
                );
                setPowder({ today: valuepatod, tomorrow: valuepatom });
                setCo2EPowder({ today: valuecptod, tomorrow: valuecptom });
                setVolumeReductionS({ today: valuevptod, tomorrow: valuevptom });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cNp, volumeReduction, Co2ePerPartPMData]);
    useEffect(() => {
        if (energyPercentage && greenPowderEnergy) {

            Socket.emit("carbon-calculator", {
                key: "PSA-PCE",
                input: {
                    percentage: energyPercentage,
                    co2e_per_part_mp: co2PerPartMP,
                    co2e_per_part_ap: co2PerPartAP,
                    posibility_of_green_energy_mix: greenPowderEnergy,
                },
            });

            Socket.on("PSA-PCE", function (data) {

                const valuepatod = getComputedValue(data.data.material_process_today);
                const valuepatom = getComputedValue(
                    data.data.material_process_tomorrow
                );
                const valuecptod = getComputedValue(data.data.energy_system_today);
                const valuecptom = getComputedValue(data.data.energy_system_tomorrow);
                const valuevptod = getComputedValue(data.data.carbon_energy_today);
                const valuevptom = getComputedValue(data.data.carbon_energy_tomorrow);
                setEnergyMaterial({ today: valuepatod, tomorrow: valuepatom });
                setEnergySystem({ today: valuecptod, tomorrow: valuecptom });
                setCo2eEnergy({ today: valuevptod, tomorrow: valuevptom });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [energyPercentage, greenPowderEnergy]);

    useEffect(() => {

        if (
            co2EPowder !== undefined &&
            co2eEnergy !== undefined &&
            totalTranpotation?.today !== undefined
        ) {
            const value =
                parseFloat(co2EPowder.today) +
                parseFloat(co2eEnergy.today) +
                parseFloat(totalTranpotation?.today);
            const value1 = getComputedValue(value);

            setCo2TotalToday(value1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2EPowder, co2eEnergy, totalTranpotation]);
    useEffect(() => {

        if (
            co2EPowder !== undefined &&
            co2eEnergy !== undefined &&
            totalTranpotation?.tomorrow !== undefined
        ) {
            const value =
                parseFloat(co2EPowder.tomorrow) +
                parseFloat(co2eEnergy.tomorrow) +
                parseFloat(totalTranpotation?.tomorrow);
            const value1 = getComputedValue(value);


            setCo2TotalTomorrow(value1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2EPowder, co2eEnergy, totalTranpotation]);

    useEffect(() => {
        if (PartVolume && noOfPartsData && materialId && buildHeight && refreshRatePercentage && machineId && co2PerPartPM) {
            Socket.off("*");
            Socket.emit("carbon-calculator", { key: "PSA-PCP-RR", input: { part_volume: PartVolume, no_of_parts: noOfPartsData, material_id: materialId, build_height: buildHeight, refresh_rate: refreshRatePercentage, machine_id: machineId, co2e_per_part_pm: co2PerPartPM, } })

            Socket.on("PSA-PCP-RR", function (data) {
                const valuePresent = getComputedValue(data?.data?.current_scenario)
                const valueFuture = getComputedValue(data?.data?.future_scenario)
                setRefreshRateScenario({ today: valuePresent, tomorrow: valueFuture })
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PartVolume, noOfPartsData, materialId, buildHeight, refreshRatePercentage, machineId, co2PerPartPM])

    const refreshPercentageData = PercentageData
    useEffect(() => {
        if (refreshRate) {
            if (!PercentageData.some(item => item.value === refreshRate.toString())) {
                refreshPercentageData.push({
                    value: `${refreshRate}`,
                    name: `${refreshRate}%`
                })
                setRefreshRatePercentage(refreshRate)
            }
            else {
                setRefreshRatePercentage(refreshRate)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshRate])

    useEffect(() => {
        if (powder && volumeReductionS && refreshRateScenario) {
            const minimum = Math.min(powder?.tomorrow, volumeReductionS?.tomorrow, refreshRateScenario?.tomorrow)
            setCo2EPowder((prevState) => {
                return {
                    ...prevState,
                    tomorrow: minimum
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powder, volumeReductionS, refreshRateScenario])

    return (
        <div className=" responsive-scroll">
            <div className="divTable">
                <div className="divTableBody">
                    <div className="divTableRow">
                        {/* <div className="divTableCell1-heading">Unit</div> */}
                        <div className="divTableCell1-heading p-1">Input</div>
                        <div className="divTableCell1-heading pl-1">Current Scenario</div>
                        <div className="divTableCell1-heading">
                            <Tippy
                                content={
                                    <span>
                                        Here you can be playful! We have estimated the status quo of
                                        your carbon and costs, but let's see how certain changes
                                        might impact your values!
                                    </span>
                                }
                            >
                                <span> Future Scenario</span>
                            </Tippy>
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1">CO2e kg/part</div> */}
                        <div className="divTableCell1 p-0">
                            <select
                                className="dropdown-input"
                                name="cars"
                                id="cars"
                                onChange={(e) => {
                                    setCNP(e.target.value);
                                }}
                            >
                                <option>Select</option>
                                <option value="yes" selected={cNp === "yes" && "selected"}>
                                    Yes
                                </option>
                                <option value="no" selected={cNp === "no" && "selected"}>
                                    No
                                </option>
                            </select>
                        </div>
                        <div className="divTableCell1">
                            {powder ? powder.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1">
                            {powder ? powder.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTable__Cells">kg</div> */}
                        <div className="divTableCell1 p-0">

                            <SelectDropdown setState={setVolumeReduction} getState={volumeReduction} Dataset={PercentageData} styleclass="dropdown-input pt-3" />
                        </div>
                        <div className="divTableCell1">
                            {volumeReductionS ? volumeReductionS.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1">
                            {volumeReductionS ? volumeReductionS.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow ">
                        {/* <div className="divTable__Cells">kg</div> */}
                        <div className="divTableCell1 p-0">
                            <SelectDropdown Dataset={refreshPercentageData ? refreshPercentageData : PercentageData}
                                getState={refreshRatePercentage}
                                setState={setRefreshRatePercentage}
                                styleclass='dropdown-input'
                            />

                        </div>
                        <div className="divTableCell1 pl-3">
                            {refreshRateScenario ? refreshRateScenario.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1">
                            {refreshRateScenario ? refreshRateScenario.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1-special">kg</div> */}
                        <div className="divTable__Cells p-0"></div>
                        <div className="divTableCell1-special">
                            {co2EPowder ? co2EPowder.today : "_ _ _ "}
                        </div>
                        <div className="divTableCell1-special">
                            {co2EPowder ? co2EPowder.tomorrow : "_ _ _ "}
                        </div>
                    </div>

                    <div className="divTableRow">
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTable__Cells">Percenatage</div> */}
                        <div className="divTableCell1 p-0">

                            <SelectDropdown setState={setEnergyPercentage} getState={energyPercentage} Dataset={PercentageData} styleclass="dropdown-input pt-3" />

                        </div>
                        <div className="divTableCell1">
                            {energyMaterial !== undefined ? energyMaterial.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1">
                            {energyMaterial !== undefined ? energyMaterial.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1">Green Energy</div> */}
                        <div className="divTableCell1 p-0">

                            <SelectDropdown setState={setGreenPowderEnergy} getState={greenPowderEnergy} Dataset={YesNoData} styleclass="dropdown-input mb-3" />

                        </div>
                        <div className="divTableCell1">
                            {energySytem !== undefined ? energySytem.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1">
                            {energySytem !== undefined ? energySytem.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1-heading p-0">&nbsp;</div> */}
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-special">
                            {co2eEnergy !== undefined ? co2eEnergy.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1-special">
                            {co2eEnergy !== undefined ? co2eEnergy.tomorrow : "_ _ _ _"}
                        </div>
                    </div>

                    <div className="divTableRow">
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
                        <div className="divTableCell1 ">

                            <SelectDropdown setState={setModeOfTranspotation1} getState={modeOfTranspotation1} Dataset={LogisticsData} styleclass="dropdown-input" />

                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode1 !== undefined
                                ? modeOfTransportnode1.today
                                : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode1 !== undefined
                                ? modeOfTransportnode1.tomorrow
                                : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
                        <div className="divTableCell1">

                            <SelectDropdown setState={setModeOfTranspotation2} getState={modeOfTranspotation2} Dataset={LogisticsData} styleclass="dropdown-input" />

                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode2 !== undefined
                                ? modeOfTransportnode2.today
                                : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode2 !== undefined
                                ? modeOfTransportnode2.tomorrow
                                : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1">&nbsp;</div> */}
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1 pl-3">
                            {totalTranpotation !== undefined
                                ? totalTranpotation.today
                                : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {totalTranpotation !== undefined
                                ? totalTranpotation.tomorrow
                                : "_ _ _ _"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialTableValues;
