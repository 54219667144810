import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    CCPolyCo2EmissionFactorPricingData,
    CCPolyCo2ePowderData,
    CCPolyCo2eEnergyPricingData,
    CCPolyCo2EmissionTransportationData
} from "atoms/CCPolymer/CCPolyCo2Pricing";
import { CCPolyCo2ePerPartPMData } from "atoms/CCPolymer/CCPolyPowderMFG";
import { CCPolyCo2ePerPartAPData } from "atoms/CCPolymer/CCPolyAmProcess";
import { CCPolyCo2ePerPartMPData } from "atoms/CCPolymer/CCPolyMaterialProcessing";

import { CCPolyCo2ePerPartTPData } from "atoms/CCPolymer/CCPolyTransportation";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";

const Co2PricingAnalysis = ({ Socket, StepID }) => {
    const [co2_emission_factor, setCo2_emission_factor] = useRecoilState(CCPolyCo2EmissionFactorPricingData);
    const [co2_emission_powder, setCo2_emission_powder] = useRecoilState(
        CCPolyCo2ePowderData
    );
    const [co2_emission_gas_energy, setCo2_emission_gas_energy] = useRecoilState(
        CCPolyCo2eEnergyPricingData
    );
    const [co2_emission_transportation, setCo2_emission_transportation] = useRecoilState(CCPolyCo2EmissionTransportationData);
    const co2PerPartPM = useRecoilValue(CCPolyCo2ePerPartPMData);
    const co2PerPartAP = useRecoilValue(CCPolyCo2ePerPartAPData);
    const co2PerPartMP = useRecoilValue(CCPolyCo2ePerPartMPData);
    const co2PerPartTP = useRecoilValue(CCPolyCo2ePerPartTPData);

    const { getComputedValue } = useRoundOffValue();


    useEffect(() => {

        Socket.emit("carbon-calculator", {
            key: "DBCC-CCPPCEF",
        });
        Socket.on("DBCC-CCPPCEF", function (data) {

            const value = getComputedValue(data?.data?.value);
            setCo2_emission_factor(value)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            co2PerPartPM !== null &&
            co2PerPartMP !== null

        ) {

            Socket.emit("carbon-calculator", {
                key: "FOCC-CCPPCEP-POLYMER",
                input: {
                    co2e_per_part_mp: co2PerPartMP,
                    co2e_per_part_pm: co2PerPartPM,
                },
            });

            Socket.on("FOCC-CCPPCEP-POLYMER", function (data) {

                const value = getComputedValue(data?.data?.value);

                setCo2_emission_powder(value);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2PerPartMP, co2PerPartPM]);

    useEffect(() => {
        if (co2PerPartAP) {

            Socket.emit("carbon-calculator", {
                key: "FOCC-CCPPCEG-POLYMER",
                input: {
                    co2e_per_part_ap: co2PerPartAP,
                },
            });

            Socket.on("FOCC-CCPPCEG-POLYMER", function (data) {

                const value = getComputedValue(data?.data?.value);
                setCo2_emission_gas_energy(value);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2PerPartAP]);
    useEffect(() => {
        if (co2PerPartAP) {

            Socket.emit("carbon-calculator", {
                key: "FOCC-CCPPCET-POLYMER",
                input: {
                    co2e_per_part_tmp: co2PerPartTP,
                },
            });

            Socket.on("FOCC-CCPPCET-POLYMER", function (data) {

                const value = getComputedValue(data?.data?.value);
                setCo2_emission_transportation(value);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co2PerPartAP]);


    return (
        <>
            <p className="input-heading">CO2e emission factor (kg)</p>
            <div className="data-field">
                {co2_emission_factor}
                {/* <p className="edit-icon value-unit ">kg</p> */}
            </div>
            <p className="input-heading">
                CO2e powder (kg)
            </p>
            <div className="data-field">{co2_emission_powder}</div>
            <p className="input-heading">
                CO2e energy (kg)
            </p>
            <div className="data-field">{co2_emission_gas_energy}</div>
            <p className="input-heading">
                CO2 logistics (kg)
            </p>
            <div className="data-field">{co2_emission_transportation}</div>
        </>
    );
};

export default Co2PricingAnalysis;
