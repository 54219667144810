import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import FirstReport from "./FirstReport";
import SecondReport from "./SecondReport";
import ThirdReport from "./ThirdReport";
import { CONDITIONALROUTES, STEPS } from "app/components/ConditionalEnums";
import { CostData } from "atoms/CostToCarbonData";

const CurrentReports = ({ isHotSpotAvailable }) => {
  const [step, setStep] = useState("first");
  const [costData, setCostData] = useState()
  const history = useHistory();
  const updatedCostData = useRecoilValue(CostData)

  useEffect(() => {
    if (history.location.pathname === CONDITIONALROUTES.GENERATEDREPORT) {
      setCostData(updatedCostData);
    }
    else {
      setCostData(JSON.parse(localStorage.getItem("CostData")));

    }
  }, [])


  return (

    <>

      {
        costData !== undefined
        &&
        <>

          <div className="reports-section">
            {step === STEPS.ONE && (
              <FirstReport
                staticData={costData}
                ScenarioState={() => setStep(STEPS.TWO)}
              />
            )}
            {step === STEPS.TWO && (
              <SecondReport
                isHotSpotAvailable={isHotSpotAvailable}
                staticData={costData}
                isWorkingPerJobEditable={true}
                PreviousScenarioState={() => setStep(STEPS.ONE)}
                ScenarioState={() => setStep(STEPS.THREE)}
              />
            )}
            {step === STEPS.THREE && (
              <ThirdReport
                PreviousScenarioState={() => setStep(STEPS.TWO)}
                staticData={costData}
              />
            )}
          </div>

        </>

      }
    </>

  );
};

export default CurrentReports;
