import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { UserStates } from '../../utils/react-redux/actions/user_states';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAccess } from "state/hooks.js";

const GET_REGIONS = gql`
  query GetRegionsByUserGroup($group_id : Int!){
    getRegionsByUserGroup(group_id : $group_id ){
        name
        region_id
        exchange_rate
        currency_symbol
      }
      
  }
`;
let Nav = () => {    // react-redux isLogged flag
    const location = useLocation()
    const isLogged = useSelector((state) => state.signIn);
    const [scrolled, setScrolled] = React.useState(false);
    let Access = useAccess();

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])


    var CurrencyRegions = sessionStorage.getItem("CurrencyRegions");
    var Currencyreg = JSON.parse(CurrencyRegions);
    useEffect(() => {
        if (isLogged === true) {
            setSignInFlag(true);
        }
    }, [isLogged])
    React.useEffect(() => {
        // runs on location, i.e. route, change
        let reportCheck = sessionStorage.getItem('reportStatus');

        if (reportCheck === 'unsave') {
            // window.onbeforeunload = () => true
        }
    }, [location])
    // react-redux dispatch
    const dispatch = useDispatch();
    const signInStatus = sessionStorage.getItem('signInstatus');

    const [signInFlag, setSignInFlag] = useState(true);
    const client = useApolloClient();
    let group_id = (+sessionStorage.getItem('group_id'));
    let [regionNamesWithCurrency] = useState([]);

    React.useEffect(() => {
        client.query({
            query: GET_REGIONS,
            variables: { group_id },
            fetchPolicy: 'no-cache'
        }).then(res => {
            res.data.getRegionsByUserGroup.map((temp) => {
                regionNamesWithCurrency.push(temp.name + ' (' + temp.currency_symbol + ')')
                return temp.name;
            });
            const uniqueNames = Array.from(new Set(regionNamesWithCurrency));
            sessionStorage.setItem("CurrencyRegions", JSON.stringify(uniqueNames));

        }).catch(error => {
            console.log(error?.graphQLErrors[0]?.extensions);
            if (error?.graphQLErrors[0]?.extensions?.exception?.response?.status === 401 && sessionStorage.getItem('signInstatus') === 'true') {
                toast.error(error?.graphQLErrors[0].extensions?.exception?.response?.message)
                window.location.href = '/';
            }
        });
    }, [regionNamesWithCurrency, client, group_id]);

    //logout
    const logout = () => {
        if (isLogged === true) {
            // react-redux dispatch call userStates action which intern change the isLogged flag to converse
            dispatch(UserStates())
        }
        sessionStorage.setItem('signInstatus', 'false');
        setSignInFlag(false);
        window.sessionStorage.clear();
        sessionStorage.setItem('signIn', 'false');
        window.location.href = `/`
    };
    const renderDropdown = () => {
        if (Currencyreg) {
            return Currencyreg.map((steps) => {
                return (
                    <div key={steps} className="currency-item" onClick={() => {
                        var Curr = steps.substr(0, steps.indexOf(' (')); sessionStorage.setItem('currency', Curr); window.location.href = `/calculator?regionName=` + steps.replace(/ .*/, '');
                    }}>
                        <p>{steps}</p>
                    </div>
                )
            })
        }
    }
    var currency = sessionStorage.getItem('currency')

    return (
        <div style={{ position: "relative", minHeight: "80px" }}>
            <nav className={`${scrolled ? "scrolled navbar" : "navbar"} navbar-expand-lg`} >
                <a className="navbar-brand" href={'' + (signInStatus ? '/region-select' : '/')}>
                    <img id="logo" src="/logo.png" width="105" height="75" alt="logo" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span>
                        <img src="../assets/menu.png" alt="" width="28" height="28" />
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {signInStatus && signInFlag ?
                        <>
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item ">
                                    <a className="nav-link" href="/region-select?calculator=cost">CALCULATOR</a>
                                </li>
                                <li className="nav-item ">
                                    <a href="/myanalytics" className="nav-link"  >MY ANALYTICS</a>
                                </li>
                                <li className="nav-item ">
                                    <a href="/scenarios" className="nav-link"  >MY SCENARIOS</a>
                                </li>
                                <li className="nav-item ">
                                    <a href="/report" className="nav-link"  >MY REPORTS</a>
                                </li>
                                <li className="nav-item ">
                                    <a href="/how-to-guide" className="nav-link"  >HOW TO GUIDE</a>
                                </li>
                                <li className="nav-item ">
                                    <a href="/data-matrix" className="nav-link"  >DATA MATRIX</a>
                                </li>
                                {!Access &&
                                    <li className="nav-item ">
                                        <a href="/premium-request" className="nav-link"  >ACCESS PREMIUM</a>
                                    </li>}
                                {/* <li className="nav-item ">
                                    <a className="nav-link" href="/region-select?calculator=carbon">CARBON CALCULATOR</a>
                                </li> */}
                            </ul>

                            <ul className="navbar-nav ml-auto">

                                <li className="nav-item hidden" id="currency">
                                    <div>{currency ? currency : 'Select Currency'}</div>
                                </li>
                                <div id="currency-dropdown" >
                                    {renderDropdown()}
                                </div>
                                <li className="nav-item ">
                                    <div className="nav-link " id="account-icon"><img src="/assets/user.png" alt="user" /></div>
                                </li>
                                <div id="profile-dropdown">
                                    <div className="dd-item">
                                        <a href="/update-password">UPDATE PASSWORD</a>
                                    </div>
                                    <div className="dd-item" onClick={logout}>
                                        <p>LOG OUT</p>
                                    </div>
                                </div>
                            </ul>
                        </>
                        :
                        ''
                    }
                </div>
            </nav>
        </div>
    )
}

export default Nav;
