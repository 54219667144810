import { atom } from "recoil";

export const NoOfPartsPerYearDataM = atom({
  key: "NoOfPartsPerYearDataM",
  default: undefined,
});
export const MaterialTransportationDataM = atom({
  key: "MaterialTransportationDataM",
  default: undefined,
});
export const SystemToCustomerDataM = atom({
  key: "SystemToCustomerDataM",
  default: undefined,
});
export const PowderToCustomerDataM = atom({
  key: "PowderToCustomerDataM",
  default: "undefined",
});
export const SystemTransportationDataM = atom({
  key: "SystemTransportationDataM",
  default: undefined,
});
export const CarbonEmissionFactorNode1DataM = atom({
  key: "CarbonEmissionFactorNode1DataM",
  default: undefined,
});
export const CarbonEmissionFactorNode2DataM = atom({
  key: "CarbonEmissionFactorNode2DataM",
  default: undefined,
});
export const Co2ePerYearDataM = atom({
  key: "Co2ePerYearDataM",
  default: undefined,
});
export const Co2ePerPartTMDataM = atom({
  key: "Co2ePerPartTMataM",
  default: undefined,
});

export const PowderManuFactureDataM = atom({
  key: "PowderManuFactureDataM",
  default: undefined,
});
export const PowderSiteDataM = atom({
  key: "PowderSiteDataM",
  default: undefined,
});
export const MachineSiteDataM = atom({
  key: "MachineSiteDataM",
  default: undefined,
});
