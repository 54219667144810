import React from "react";
import { BlobProvider } from '@react-pdf/renderer';
import { Page, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { backgroundColor: 'white', color: 'black', fontSize: 14 },
  headerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: 10 },
  logo: { width: '20%', height: 50 },
  header: { width: '35%', fontWeight: 'bold', fontSize: 18 },
  address: { width: '25%', fontSize: 12 },
  detailsView: { padding: 20 },
  partInfoView: { display: 'flex', flexDirection: 'row' },
  colorPalletePP: { width: 10, height: 10, backgroundColor: '#7761cf' },
  colorPalleteSCPP: { width: 10, height: 10, backgroundColor: '#fcc342' },
  colorPalleteTCPP: { width: 10, height: 10, backgroundColor: '#00bff3' },
  image: { width: '50%' },
  w_100: { width: '100%' },
  w_90: { width: '90%' },
  w_50: { width: '50%' },
  w_40: { width: '40%' },
  w_33: { width: '33.3%' },
  w_30: { width: '30%' },
  w_20: { width: '20%' },
  w_25: { width: '25%' },
  w_15: { width: '15%' },
  w_10: { width: '10%' },
  d_flex: { display: 'flex' },
  row: { flexDirection: 'row' },
  para_font_size: { fontSize: '10px' },
  tableView: { fontSize: 12 },
  footerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 10, position: 'absolute', bottom: 0, left: 0, width: '100%' },
});

const Doc = ({ image }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.headerView}>
        <Image style={styles.logo} src="/assets/logo.png" />
      </View>
      <View style={styles.detailsView}>
        <Image src={image} />
      </View>
    </Page>
  </Document>
);




function BCPPPdfGenerator({ image }) {
  // debugger;
  return (
    <div>
      {(image) && (
        <BlobProvider document={<Doc image={image} />}>
          {({ blob, url, loading, error }) => {
            if (!loading) {

              window.open(url, '_blank');
            }

            return ''
          }}
        </BlobProvider>
      )}
    </div>

  )
}

export default BCPPPdfGenerator;