import { createSlice} from "@reduxjs/toolkit";

const initialState = {
noOfParts:0,
buildTime:0,

};

export const ccsteps = createSlice({
  name: "CCSteps",
  initialState,
  reducers: {
    setCCSteps: (state, action) => {
      state.noOfParts = action.payload.noOfParts;
      state.buildTime = action.payload.buildTime;
    },
  },
});

//Export actions and reducer

export const { setCCSteps } = ccsteps.actions;
export default ccsteps.reducer;
