import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/react-hooks";
import { Accordion, Card, Button } from "react-bootstrap";
import MachineDetails from "./components/MachineDetails";

import MachineManufacturing from "./components/MachineManufacturing";
import PowderMfg from "./components/PowderMfg";
import InertGas from "./components/InertGas";
import ElectricityConsumption from "./components/ElectricityConsumption";
import Transportation from "./components/Transportation";
import PostProcessing from "./components/PostProcessing";
import WastePowder from "./components/WastePowder";
import CarbonContentPerPart from "./components/CarbonContentPerPart";
import ArrowDown from "./components/ArrowDown";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { ProcessFlag } from "atoms/General/index.js";
import { technologyId, stepTypeId, technologyType } from "atoms/General";
import { ToastContainer, toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { GET_CLIENT_STEPS } from "./components/getStepDetail";

const ProcessMetal = ({ ScenarioState, socket }) => {
  // const [socket, setSocket] = useState()

  // useEffect(() => {
  //     const socketOb = new socketIOClient(constant.uri);
  //     const patch = require('socketio-wildcard')(socketIOClient.Manager);
  //     patch(socketOb)
  //     const socketObj = socketOb
  //     setSocket(socketObj)
  // }, [])

  const client = useApolloClient();
  const [clientSteps, setClientSteps] = useState([]);
  const [loader, setLoader] = useState(true);
  const [techId, setTechId] = useRecoilState(technologyId);
  const [steptypeId, setStepTypeId] = useRecoilState(stepTypeId);
  const [TechnologyType, settechnologyType] = useRecoilState(technologyType);
  const processFlag = useRecoilValue(ProcessFlag);

  useEffect(() => {
    setTechId(1);
    setStepTypeId(3);
    settechnologyType("CO2e Metal");

    client
      .query({
        query: GET_CLIENT_STEPS,
        variables: { technology_id: 1, type_id: 3 },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getClientSteps);
        // console.log(data.getClientSteps);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [activeState, setActiveState] = useState(-1);
  const toggleState = (idnum) => {
    const id = idnum;
    if (activeState === +id) {
      setActiveState(null);
    } else {
      setActiveState(+id);
    }
  };
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" />{" "}
        </div>
      ) : (
        <>
          {" "}
          <Accordion>
            {clientSteps &&
              clientSteps.map((step, index) => {
                return (
                  <Card key={index}>
                    {step?.step_id <= 56 && (
                      <Card.Header
                        className={activeState === index ? "active" : ""}
                      >
                        <div className="d-flex justify-content-between">
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            className={
                              activeState === index ? "active-color" : ""
                            }
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                            data-display="static"
                          >
                            {step?.name.includes("not") ? (
                              <Tippy
                                key={index}
                                content={
                                  <span>
                                    {step.name}
                                  </span>
                                }
                              >
                                <span>{step.name}</span>
                              </Tippy>
                            )
                              :
                              step?.step_id === 52 ? ( // Tooltip for inert gas
                                <Tippy
                                  key={index}
                                  content={
                                    <span>
                                      Assumes constant inert gas consumption based on installation conditions
                                    </span>
                                  }
                                >
                                  <span>{step.name}</span>
                                </Tippy>
                              )
                                :
                                (
                                  step.name
                                )}
                          </Accordion.Toggle>
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            id="0"
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                          >
                            <div
                              className={
                                activeState === index
                                  ? "arrow-box active"
                                  : "arrow-box"
                              }
                            >
                              <ArrowDown />
                            </div>
                          </Accordion.Toggle>
                        </div>
                      </Card.Header>
                    )}
                    <Accordion.Collapse
                      data-target={index}
                      eventKey={index === 0 ? "0" : index}
                    >
                      <Card.Body>
                        {step?.step_id === 49 && activeState === index && (
                          <MachineDetails StepID={49} Socket={socket} />
                        )}
                        {step?.step_id === 50 &&
                          (activeState === index &&
                            processFlag.MachineManufacturing ? (
                            <MachineManufacturing StepID={50} Socket={socket} />
                          ) : (
                            <p>Machine Manufacturing (currently not available)</p>
                          ))}
                        {step?.step_id === 51 &&
                          (activeState === index &&
                            processFlag.MachineDetails ? (
                            <PowderMfg Socket={socket} />
                          ) : (
                            <p>Add all machine details to view</p>
                          ))}
                        {step?.step_id === 52 &&
                          (activeState === index &&
                            processFlag.PowderManufacturing ? (
                            <InertGas Socket={socket} />
                          ) : (
                            <p>Add all powder manufacturing details to view</p>
                          ))}
                        {step?.step_id === 53 &&
                          (activeState === index && processFlag.InertGas ? (
                            <ElectricityConsumption Socket={socket} />
                          ) : (
                            <p>Add all inert gas details to view</p>
                          ))}
                        {step?.step_id === 54 &&
                          (activeState === index &&
                            processFlag.ElectricityConsumption ? (
                            <Transportation Socket={socket} />
                          ) : (
                            <p>
                              Add all electricity consumption details to view
                            </p>
                          ))}
                        {step?.step_id === 55 &&
                          (activeState === index &&
                            processFlag.PostProcessing ? (
                            <PostProcessing Socket={socket} />
                          ) : (
                            <p>Post Processing (currently not available)</p>
                          ))}
                        {step?.step_id === 56 &&
                          (activeState === index && processFlag.WastePowder ? (
                            <WastePowder Socket={socket} />
                          ) : (
                            <p>Used Powder - End of Life (currently not available)
                            </p>
                          ))}
                        {/* {step?.step_id === 57 &&
                          (activeState === index &&
                          processFlag.Transportation ? (
                            <CarbonContentPerPart Socket={socket} />
                          ) : (
                            <p>Add all Transportation details to view</p>
                          ))} */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
          <div className="d-flex justify-content-center pt-1 w-75 flex-column">
            <p className="bold warning_note">Please note that the EOS Cost and Carbon Calculator provides only rough estimates.  EOS assumes no liability for the information from this tool. For precise and reliable values, please consult with Additive Minds at EOS. Please see the Data Matrix to understand the basis for the calculation of the EOS Cost & Carbon Calculator.</p>
          </div>
          <button
            onClick={() => {
              if (processFlag.Transportation) {
                ScenarioState();
              } else {
                toast.error("Please fill all process details.");
              }
            }}
            className="selection-button step-button"
          >
            Proceed
          </button>
        </>
      )}
    </>
  );
};

export default ProcessMetal;
