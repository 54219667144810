import React from "react";

const MyReportGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>My Reports</h3>
            <p>The save button on a generated report allows you to save the report within the system. </p>
            <img src="/assets/my-report-guide-1.png" alt="" className="img-fluid" />
            <p>The saved report can be accessed in my reports section the link. </p>
            <img src="/assets/my-report-guide-2.png" alt="" className="img-fluid" />
            <p>The reports section allows user to view all past reports they have generated and will also allow for reports to be viewed again.</p>
            <img src="/assets/my-report-guide-3.png" alt="" className="img-fluid" />
        </>
    )
}

export default MyReportGuide;