import React from "react";

const WelcomeGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Welcome Screen</h3>
            <p>The welcome screen on the calculator allows you to select the technology in which you need to
                conduct calculation  </p>
            <img src="/assets/welcome-guide-1.png" alt="" className="img-fluid" />
            <p>After selecting your the technology, you can then select the calculation method by clicking on
                the respective buttons
            </p>
            <li>Estimation of cost would provide you a costing within +/-20% accuracy. </li>
            <li>Estimation of carbon footprint would provide the carbon footprint of your application</li>
            <li className="mb-3">Full simulation would provide a costing within +/-5% accuracy along with the carbon
                footprint of your application
            </li>
            <img src="/assets/welcome-guide-2.png" alt="" className="img-fluid" />
        </>
    )
}

export default WelcomeGuide;