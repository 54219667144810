import React, { useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useRecoilState } from "recoil";
import Form from "react-bootstrap/Form";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { Spinner } from "react-bootstrap";
import { ProcessFlag } from "atoms/General";
import {
  CCMetalSystemToCustomerDataM,
  CCMetalPowderToCustomerDataM,
  CCMetalMaterialTransportationDataM,
  CCMetalSystemTransportationDataM,
  CCMetalCarbonEmissionFactorNode1DataM,
  CCMetalCarbonEmissionFactorNode2DataM,
  CCMetalCo2ePerYearDataM,
  CCMetalCo2ePerPartTMDataM,
} from "atoms/CCCarbonMetal/CCMetalTransportation";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
const Transportation = ({ Socket, staticDataOfMachine }) => {
  const [systemToCustomer, setSystemToCustomer] = useRecoilState(
    CCMetalSystemToCustomerDataM
  );
  const [materialTransportaion, setMaterialTransportaion] = useRecoilState(
    CCMetalMaterialTransportationDataM
  );
  const [systemTransportation, setSystemTransportation] = useRecoilState(
    CCMetalSystemTransportationDataM
  );
  const [powderToCustomer, setPowderToCustomer] = useRecoilState(
    CCMetalPowderToCustomerDataM
  );
  const [cefnone, setCefnOne] = useRecoilState(
    CCMetalCarbonEmissionFactorNode1DataM
  );
  const [cefnTwo, setCefnTwo] = useRecoilState(
    CCMetalCarbonEmissionFactorNode2DataM
  );
  const [co2PerYear, setCo2PerYear] = useRecoilState(CCMetalCo2ePerYearDataM);
  const [co2PerPart, setCo2PerPart] = useRecoilState(CCMetalCo2ePerPartTMDataM);
  const [, setProcessFlag] = useRecoilState(ProcessFlag);
  const { getComputedValue } = useRoundOffValue();

  useEffect(() => {
    if (powderToCustomer) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "DBCC-CEFN1",
        input: {
          powder_to_customer: powderToCustomer,
        },
      });

      Socket.on("DBCC-CEFN1", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCefnOne(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderToCustomer]);

  useEffect(() => {
    if (systemToCustomer) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "DBCC-CEFN2",
        input: { system_to_customer: systemToCustomer },
      });

      Socket.on("DBCC-CEFN2", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCefnTwo(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemToCustomer]);

  useEffect(() => {
    if (materialTransportaion && cefnone && cefnTwo && systemTransportation) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "FOCC-TPCPY",
        input: {
          material_transportation: materialTransportaion,
          carbon_emission_factor_node_1: cefnone,
          carbon_emission_factor_node_2: cefnTwo,
          system_transportation: systemTransportation,
        },
      });

      Socket.on("FOCC-TPCPY", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2PerYear(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemTransportation, cefnone, cefnTwo, systemTransportation]);

  useEffect(() => {
    if (
      co2PerYear !== undefined &&
      staticDataOfMachine.build_time !== undefined &&
      staticDataOfMachine.setup_time !== undefined &&
      staticDataOfMachine.unpacking_time_per_job !== undefined &&
      staticDataOfMachine.technology_id !== undefined
    ) {
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-TPCPPMP",
        input: {
          co2e_per_year: co2PerYear,
          utilization: staticDataOfMachine.utilization.value,
          build_time: parseInt(staticDataOfMachine.build_time.value),
          setup_time: staticDataOfMachine.setup_time.value,
          unpacking_time_per_job: parseInt(
            staticDataOfMachine.unpacking_time_per_job.value
          ),
          technology_id: staticDataOfMachine.technology_id.value,
        },
      });

      Socket.on("FOCC-TPCPPMP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2PerPart(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2PerYear]);



  useEffect(() => {
    if (
      powderToCustomer !== null &&
      systemToCustomer !== null &&
      materialTransportaion !== null &&
      systemTransportation !== null &&
      cefnone !== null &&
      cefnTwo !== null &&
      co2PerYear !== null
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          Transportation: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    powderToCustomer,
    systemToCustomer,
    materialTransportaion,
    systemTransportation,
    cefnone,
    cefnTwo,
    co2PerYear,
  ]);
  return (
    <>
      <p className="input-heading">Powder to customer (node 1) </p>

      <div className="input-section">
        <Form.Control
          onChange={(e) => setPowderToCustomer(e.target.value)}
          as="select"
          aria-label="Default Control example"
        >
          <option className="select-option">Please select method</option>
          <option
            value="Rail"
            selected={powderToCustomer === "Rail" && "selected"}
            className="select-option"
          >
            Rail
          </option>
          <option
            value="Road"
            selected={powderToCustomer === "Road" && "selected"}
            className="select-option"
          >
            Road
          </option>
          <option
            value="Air"
            selected={powderToCustomer === "Air" && "selected"}
            className="select-option"
          >
            Air
          </option>
          <option
            value="Ship"
            selected={powderToCustomer === "Ship" && "selected"}
            className="select-option"
          >
            Ship
          </option>
        </Form.Control>
        <img src="../assets/Accordionarrow.svg" alt="" className="edit-icon" />
      </div>

      <p className="input-heading">System to customer (node 2) </p>
      <div className="input-section">
        <Form.Control
          onChange={(e) => {
            setSystemToCustomer(e.target.value);
          }}
          as="select"
          aria-label="Default Control example"
        >
          <option className="select-option">Please select method</option>
          <option
            value="Rail"
            selected={systemToCustomer === "Rail" && "selected"}
            className="select-option"
          >
            Rail
          </option>
          <option
            value="Road"
            selected={systemToCustomer === "Road" && "selected"}
            className="select-option"
          >
            Road
          </option>
          <option
            value="Air"
            selected={systemToCustomer === "Air" && "selected"}
            className="select-option"
          >
            Air
          </option>
          <option
            value="Ship"
            selected={systemToCustomer === "Ship" && "selected"}
            className="select-option"
          >
            Ship
          </option>
        </Form.Control>
        <img src="../assets/Accordionarrow.svg" alt="" className="edit-icon" />
      </div>

      <p className="input-heading">
        <TooltipTrippy
          tooltipMessage="This refers to the distance the powder travels to
              reach your production site; for an estimation, you can use google
              maps setting the initial location to Maisach, Germany, and
              destination as your production facility to then insert the km in
              the input field here."
          fieldName="Powder logistics (km)"
        />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setMaterialTransportaion(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter the distance for powder logistics"
          value={materialTransportaion}
        />
      </div>

      <p className="input-heading">
        {" "}
        <TooltipTrippy
          tooltipMessage="This refers to the distance the system travels to
              reach your production site, for an estimation, you can use google
              maps setting the initial location to Maisach, Germany, and
              destination as your production facility to then insert the km in
              the input field here."
          fieldName="System logistics (km)"
        />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setSystemTransportation(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter the distance for system logistics"
          value={systemTransportation}
        />
      </div>

      <p className="input-heading">Carbon emission factor node 1 (kg/km)</p>
      {cefnone !== undefined || cefnone != null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            onChange={(e) => {
              setCefnOne(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={cefnone}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          <Spinner animation="border" size="sm" />
        </div>
      )}

      <p className="input-heading">Carbon emission factor node 2 (kg/km)</p>

      {cefnTwo !== undefined || cefnTwo !== null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            onChange={(e) => {
              setCefnTwo(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={cefnTwo}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          <Spinner animation="border" size="sm" />
        </div>
      )}

      <p className="input-heading">CO2e per year (kg) </p>
      <div className="data-field">
        {co2PerYear !== undefined || co2PerYear !== null ? (
          co2PerYear
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>

      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {co2PerPart !== undefined || co2PerPart !== null ? (
          co2PerPart
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
    </>
  );
};

export default Transportation;
