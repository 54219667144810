import { atom, selector } from "recoil"

export const MachineDataM = atom({
    key: "MachineDataM",
    default: 0
})

export const MachineNameDataM = atom({
    key: "MachineNameDataM",
    default: undefined
})
export const MaterialDataM = atom({
    key: "MaterialDataM",
    default: 0
})
export const MaterialNameDataM = atom({
    key: "MaterialNameDataM",
    default: 0
})
export const BuildTimeDataM = atom({
    key: "BuildTimeDataM",
    default: undefined
})
export const PartVolumeDataM = atom({
    key: "PartVolumeDataM",
    default: undefined
})
export const PartSupportVolumeDataM = atom({
    key: "PartSupportVolumeDataM",
    default: undefined
})
export const NoOfPartsDataM = atom({
    key: "NoOfPartsDataM",
    default: undefined
})
export const PowderUsedDataM = atom({
    key: "PowderUsedDataM",
    default: undefined
})

export const GetNoOfPartsDataM = selector({
    key: "GetNoOfPartsDataM",
    get: ({ get }) => get(NoOfPartsDataM)
})
export const GetBuildTimeDataM = selector({
    key: "GetBuildTimeDataM",
    get: ({ get }) => get(BuildTimeDataM)
})

export const GetPowderUsedDataM = selector({
    key: "GetPowderUsedDataM",
    get: ({ get }) => get(PowderUsedDataM)
})