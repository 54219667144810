import React from "react";

const HetFieldGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Heterogeneous Layout Fields</h3>
            <p>For Full Simulation calculations you have the ability to add multiple parts to calculate cost for.
                Simply click on add more for heterogeneous layout.
            </p>
            <img src="/assets/layout-guide-1.png" alt="" className="img-fluid" />
            <p>This will allow you to add a new row for another part and you can add specific values to
                calculated for heterogeneous layout.</p>
            <img src="/assets/layout-guide-2.png" alt="" className="img-fluid" />

        </>
    )
}

export default HetFieldGuide;