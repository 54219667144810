import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Spinner } from "react-bootstrap";
import {
  CCMetalInertGasConsumptionDataM,
  CCMetalInertGasTypeDataM,
  CCMetalInertGasUsedEnergyDataM,
} from "atoms/CCCarbonMetal/CCMetalInert";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from "atoms/General";

const InertGas = ({ Socket, staticDataOfMachine }) => {
  const [inertGasConsumption, setInertGasConsumption] = useRecoilState(
    CCMetalInertGasConsumptionDataM
  );
  const [inertGasType, setInertGasType] = useRecoilState(
    CCMetalInertGasTypeDataM
  );
  const [InertGasUsed, setInertGasUsed] = useRecoilState(
    CCMetalInertGasUsedEnergyDataM
  );
  const { getComputedValue } = useRoundOffValue();
  const [, setProcessFlag] = useRecoilState(ProcessFlag);

  useEffect(() => {
    if (
      staticDataOfMachine.build_time !== undefined &&
      staticDataOfMachine.machine_id
    ) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "FOCC-IGIGC",
        input: {
          build_time: staticDataOfMachine.build_time.value,
          machine_id: staticDataOfMachine.machine_id.value,
        },
      });

      Socket.on("FOCC-IGIGC", function (data) {

        const value = getComputedValue(data?.data?.value);

        setInertGasConsumption(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Socket.off("*");

    Socket.emit("carbon-calculator", { key: "DBCC-IGIGT", input: "" });

    Socket.on("DBCC-IGIGT", function (data) {

      // const value = getComputedValue(data?.data?.value)

      setInertGasType(data?.data?.value);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inertGasConsumption !== null) {
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-IGIGUE",
        input: { inert_gas_consumption: inertGasConsumption },
      });

      Socket.on("FOCC-IGIGUE", function (data) {

        const value = getComputedValue(data?.data?.value);

        setInertGasUsed(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertGasConsumption]);

  useEffect(() => {
    if (
      inertGasConsumption !== null &&
      inertGasType !== null &&
      InertGasUsed !== null
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          InertGas: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertGasConsumption, inertGasType, InertGasUsed]);

  return (
    <>
      <p className="input-heading">
        Inert gas consumption (m<sup className="font-weight-bold">3</sup>/job)
      </p>
      <div className="data-field">
        {inertGasConsumption !== undefined || inertGasConsumption != null ? (
          inertGasConsumption
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
      {/* TODO... */}
      {/* <p className="input-heading">Inert gas type</p>
      <div className="data-field">
        {inertGasType != undefined || inertGasType != null ? (
          inertGasType
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div> */}

      <p className="input-heading">Inert gas used energy (kWh)</p>
      <div className="data-field">
        {InertGasUsed !== undefined || InertGasUsed != null ? (
          InertGasUsed
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
    </>
  );
};

export default InertGas;
