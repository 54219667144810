import React from 'react'

const WastePowder = () => {
  return (
    <>
      <p className='input-heading' >powder disposal</p>
            <div className='data-field'>
            Powder Disposal
            </div>
            <p className='input-heading' >carbon content disposal</p>
            <div className='data-field'>
            Carbon Content Disposal
            </div>
    </>
  )
}

export default WastePowder