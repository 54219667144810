import React from "react";
import { useGetMachineName } from "app/view/hooks/useGetMachineName";
import { useGetParameterName } from "app/view/hooks/useGetParameterName";
import { useGetMaterialName } from "app/view/hooks/useGetMaterialById";

const FirstReport = ({ staticDataOfMachine, ScenarioState }) => {
  const machineName = useGetMachineName(
    +staticDataOfMachine?.machine_id?.value
  );
  const materialName = useGetMaterialName(
    +staticDataOfMachine?.material_id?.value
  );
  const partWeight =
    staticDataOfMachine?.part_volume?.value *
    staticDataOfMachine?.material_density?.value;

  const parameterName = useGetParameterName(
    +staticDataOfMachine?.parameter_id?.value
  );

  const jobTime =
    +staticDataOfMachine?.setup_time?.value +
    +staticDataOfMachine?.build_time?.value +
    +staticDataOfMachine?.unpacking_time_per_job?.value;

  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);
  return (
    <div className="reports-section">
      {/* Part Of Information */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Part Information</p>
          <p className="powder-heading">{staticDataOfMachine?.part_name && staticDataOfMachine?.part_name?.value}</p>
        </div>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Material </p>
          </span>
          <span>
            <p className="output-heading">{materialName}</p>{" "}
          </span>
        </div>

        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Material price</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.material_price?.value !== undefined
                ? `${staticDataOfMachine?.material_price?.value} ${obj.currency_symbol}/kg`
                : "No Available"}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>

        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Part volume</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.part_volume?.value !== undefined ? (
                <>
                  {`${staticDataOfMachine?.part_volume?.value} cm`}
                  <sup>3</sup>
                </>
              ) : (
                "No Available"
              )}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Part weight</p>
          </span>
          <span>
            <p className="output-heading">
              {partWeight !== undefined
                ? `${partWeight.toFixed(2)} g`
                : "No Available"}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Part support volume</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.part_support_volume?.value ? (
                <>
                  {`${staticDataOfMachine?.part_support_volume?.value} cm`}
                  <sup>3</sup>
                </>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
      </div>
      {/* System Information */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">System Information</p>
          <p className="powder-heading">{machineName}</p>
        </div>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Initial investment</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.initial_investment?.value !== undefined ? (
                <>{`${staticDataOfMachine?.initial_investment?.value} ${obj.currency_symbol}`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>

        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Recurring Expense</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.recurring_expense?.value ? (
                <>{`${staticDataOfMachine?.recurring_expense?.value} ${obj.currency_symbol}/year`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Depreciation period</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.depreciation_period?.value ? (
                <>{`${staticDataOfMachine?.depreciation_period?.value} /year`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Interest rate</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.interest_rate?.value ? (
                <>{`${staticDataOfMachine?.interest_rate?.value} %`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Utilization</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.utilization?.value ? (
                <>{`${staticDataOfMachine?.utilization?.value} hours/year`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">CAPEX per year</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.capex_per_year?.value ? (
                <>{`${staticDataOfMachine?.capex_per_year?.value} ${obj.currency_symbol}/year`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">System cost per hour</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.system_cost_per_hour?.value ? (
                <>{`${staticDataOfMachine?.system_cost_per_hour?.value} ${obj.currency_symbol}/hour`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
      </div>
      {/* Job Details */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Job Details</p>
        </div>

        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Parts per job</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.no_of_parts?.value ? (
                <>{`${staticDataOfMachine?.no_of_parts?.value}`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Total job volume</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.total_part_volume?.value ? (
                <>
                  {`${staticDataOfMachine?.total_part_volume?.value} cm`}
                  <sup>3</sup>
                </>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Job time</p>
          </span>
          <span>
            <p className="output-heading">
              {jobTime ? <>{`${jobTime} h`}</> : "No Available"}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Parameter</p>
          </span>
          <span>
            <p className="output-heading">{parameterName}</p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Additional material losses</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.additional_material_losses?.value ? (
                <>{`${staticDataOfMachine?.additional_material_losses?.value} %`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
      </div>
      {/* Post Processing */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Post processing</p>
        </div>

        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Part removal cost per job</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.part_removal?.value ? (
                <>{`${staticDataOfMachine?.part_removal?.value} ${obj.currency_symbol}`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Support removal cost per job</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.support_removal?.value ? (
                <>{`${staticDataOfMachine?.support_removal?.value} ${obj.currency_symbol}`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Heat treatment per part </p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.heat_treatment?.value ? (
                <>{`${staticDataOfMachine?.heat_treatment?.value} ${obj.currency_symbol}`}</>
              ) : (
                "No Available"
              )}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Other post processing cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.other_post_processing_cost_per_part
                ?.value ? (
                <>{`${Math.abs(
                  staticDataOfMachine?.other_post_processing_cost_per_part
                    ?.value
                )} ${obj.currency_symbol}`}</>
              ) : (
                ""
              )}
            </p>{" "}
          </span>
        </div>
      </div>
      {/* Pagination */}{" "}
      <button
        onClick={() => ScenarioState()}
        className="selection-button step-button"
      >
        Next
      </button>
    </div>
  );
};

export default FirstReport;
