import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from 'app/view/CCStepsMetal/components/ReportsMetal/components/MaterialTable'
import MaterialTableValues from "./MaterialTableValues";
import { useRecoilValue } from "recoil";
import {
    Co2TotalTodaySMData,
    Co2TotalTomorrowSMData,
    PowderAutomizationData,
    VolumePartReductionData,
    Co2ePowderScenarioMData,
    GreenEnergyMixData,
    InertGasReductionSMData,
    TotalTraspotation,
    PowderAutomizationInput,
    VolumePartReductionInput,
    GreenEnergyMixInput,
    CO2eModeOfTranspotation1,
    CO2eModeOfTranspotation2,
} from "atoms/Co2Metal/ScenarioMetal";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import config from "../../../../../config"
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const axios = require("axios").default;


const Co2ScenarioMetal = ({ fieldObj, socket }) => {
    const shiftSize = 7;
    const co2TotalToday = useRecoilValue(Co2TotalTodaySMData);
    const co2TotalTomorrow = useRecoilValue(Co2TotalTomorrowSMData);
    const powderAtomization = useRecoilValue(PowderAutomizationData);
    const volumePArtReduction = useRecoilValue(VolumePartReductionData);
    const co2ePowder = useRecoilValue(Co2ePowderScenarioMData);
    const greenenergymix = useRecoilValue(GreenEnergyMixData);
    const inertGasReduction = useRecoilValue(InertGasReductionSMData);
    const co2Transportation = useRecoilValue(TotalTraspotation);


    const powderAutomizationInput = useRecoilValue(PowderAutomizationInput);
    const volumePartReductionInput = useRecoilValue(VolumePartReductionInput);
    const greenEnergyMixInput = useRecoilValue(GreenEnergyMixInput);
    const ModeOfTranspotation1 = useRecoilValue(CO2eModeOfTranspotation1);
    const ModeOfTranspotation2 = useRecoilValue(CO2eModeOfTranspotation2);

    const [powder, setPowder] = useState();
    const [volume, setVolume] = useState();
    const [co2powder, setCO2ePowder] = useState();
    const [greenEnergyMix, setGreenEnergyMix] = useState();
    const [inertGas, setInertGas] = useState();
    const [dataSet, setDataSet] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [scenarioName, setScenarioName] = useState("");

    const access_token = sessionStorage.getItem("access_token");


    useEffect(() => {
        if (co2TotalToday !== undefined && co2TotalTomorrow !== undefined && co2powder !== undefined && inertGas !== undefined && greenEnergyMix !== undefined && co2Transportation !== undefined) {
            setDataSet([
                {
                    color: "#5DC0D3",
                    title: co2powder
                        ? ((co2powder / co2TotalToday) * 100).toFixed(2) + "%"
                        : "",
                    value: parseFloat(((co2powder / co2TotalToday) * 100).toFixed(2)),
                },
                {
                    color: "#9CEAEF",
                    title: greenEnergyMix && inertGas
                        ? (((greenEnergyMix + inertGas) / co2TotalToday) * 100).toFixed(2) + "%"
                        : "",
                    value: parseFloat((((greenEnergyMix + inertGas) / co2TotalToday) * 100).toFixed(2)),
                },
                {
                    color: "#07BEB8",
                    title: co2Transportation.today
                        ? ((co2Transportation.today / co2TotalToday) * 100).toFixed(2) + "%"
                        : "",
                    value: parseFloat(((co2Transportation.today / co2TotalToday) * 100).toFixed(2)),
                },
            ]);
        }
    }, [co2TotalToday, co2TotalTomorrow, co2powder, greenEnergyMix, inertGas, co2Transportation])

    useEffect(() => {
        if (
            powderAtomization &&
            volumePArtReduction &&
            co2ePowder &&
            greenenergymix &&
            inertGasReduction
        ) {
            setPowder(parseFloat(powderAtomization.today));
            setCO2ePowder(parseFloat(co2ePowder.today));
            setVolume(parseFloat(volumePArtReduction.today));
            setInertGas(parseFloat(inertGasReduction.today));
            setGreenEnergyMix(parseFloat(greenenergymix.today));
        }
        const parent = document.getElementById("m2");
        const firstchild = parent.firstChild;
        firstchild.setAttribute("viewBox", "-25 -12 150 125");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        powderAtomization,
        volumePArtReduction,
        co2ePowder,
        greenenergymix,
        inertGasReduction,
    ]);

    const SaveScenario = async () => {

        toast.info("Please Wait...");
        const formFields = {
            technology_id: fieldObj?.technology_id,
            step_type_id: fieldObj?.step_type_id,
            technology_name: fieldObj?.technology_name,
            machine_name: fieldObj?.machine_name,
            material_name: fieldObj?.material_name,
            material_id: fieldObj?.material_id,
            machine_id: fieldObj?.machine_id,
            co2Transportation: fieldObj?.co2Transportation,
            co2EmissionPowder: fieldObj?.co2EmissionPowder,
            Co2ePerPartPM: fieldObj?.Co2ePerPartPM,
            PowderEnergyPartPM: fieldObj?.PowderEnergyPartPM,
            co2eEmissionFactor: fieldObj?.co2eEmissionFactor,
            noOfPartsData: fieldObj?.noOfPartsData,
            partVolume: fieldObj?.partVolume,
            partSupportVolume: fieldObj?.partSupportVolume,
            co2EmissionBuildProcess: fieldObj?.co2EmissionBuildProcess,
            co2EmissionGas: fieldObj?.co2EmissionGas,
            powderAutomizationInput: powderAutomizationInput,
            volumePartReductionInput: volumePartReductionInput,
            greenEnergyMixInput: greenEnergyMixInput,
            modeOfTranspotation1Input: ModeOfTranspotation1,
            modeOfTranspotation2Input: ModeOfTranspotation2,
            co2eTransportation: fieldObj?.co2eTransportation,
            materialTransportaion: fieldObj?.materialTransportaion,
            systemTransportation: fieldObj?.systemTransportation,
            cefnone: fieldObj?.cefnone,
            cefnTwo: fieldObj?.cefnTwo,
            build_time: fieldObj?.build_time,
            inertGasReduction_tomorrow: inertGasReduction?.tomorrow
        };

        const data = {
            scenario_data: JSON.stringify(formFields),
            scenario_name: scenarioName,
        };

        try {
            const response = await axios.post(
                `${config.serviceUrl}client/scenario/save`,
                data,
                {
                    headers: {
                        Authorization: access_token,
                    },
                }
            );

            if (response.data) {
                toast.success("Scenario saved Successfully");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter scenario name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-section">
                        <DebounceInput
                            type="text"
                            className="user-input"
                            debounceTimeout={500}
                            onChange={(e) => {
                                setScenarioName(e.target.value);
                            }}
                            onWheel={(e) => e.target.blur()}
                            placeholder="Please enter scenario name"
                            value={scenarioName}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <button
                            className="selection-button step-button w-50 m-0"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <Button
                            disabled={scenarioName.length === 0}
                            className="selection-button step-button w-50 mt-0"
                            onClick={() => {
                                if (scenarioName.length > 0) {
                                    SaveScenario();
                                    handleClose();
                                }
                            }}
                        >
                            Save Scenario
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <button
                onClick={handleShow}
                id="save-scenario"
                className="btn-position selection-button step-button d-flex justify-content-center"
            >
                <Tippy
                    content={
                        <span>
                            You can view your saved scenario by clicking My Scenarios Tab at
                            header.
                        </span>
                    }
                >
                    <span>Save My Scenario</span>
                </Tippy>
            </button>
            <div className="row  ">
                <div className="col page-container px-0">
                    <div className="ccsteps-container">
                        <div className="powder-container">
                            <div className="powder-table-top">
                                <p className="powder-heading">Scenario Analysis</p>
                            </div>
                            <div className="d-flex flex-row p-3">
                                <MaterialTable Socket={socket} />
                                <MaterialTableValues socket={socket} fieldObj={fieldObj} />
                            </div>
                            <div className="powder-table-top total-bottom">
                                <p className="powder-heading">
                                    Total <abbr className="text-lowercase">(kg)</abbr>
                                </p>
                                <div className="d-flex ">
                                    <p className="powder-heading pl-3">{co2TotalToday}</p>
                                    <p className="powder-heading pr-4 pl-5">{co2TotalTomorrow}</p>
                                </div>
                            </div>
                        </div>

                        <div className="graph-section width--mod">
                            <div className="text-center">
                                <p className="t-2">CO2e in % of kg per part</p>
                                <p className="s-1 mb-0">Current Scenario</p>
                            </div>
                            <div
                                id="m2"
                                className="w-100 m-0 h-100 d-flex justify-content-center align-items-center"
                            >
                                <PieChart
                                    animationDuration={500}
                                    animationEasing="ease-out"
                                    width=" 300px"
                                    height="300px"
                                    data={dataSet}
                                    labelPosition={110}
                                    lengthAngle={360}
                                    lineWidth={40}
                                    paddingAngle={2}
                                    radius={PieChart.defaultProps.radius - shiftSize}
                                    startAngle={0}
                                    label={({ dataEntry }) => dataEntry.title}
                                    // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

                                    labelStyle={{
                                        fill: "black",
                                        strokeWidth: "10",
                                        fontSize: "5px",
                                        fontFamily: "Open Sans, sans-serif",
                                        fontWeight: "600",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center pt-5">
                            <div className="d-flex">
                                <div className="color-icon bg-color5" />
                                <p className="color-rep-value">CO2e Powder</p>
                            </div>
                            <div className="d-flex">
                                <div className="color-icon  bg-color7" />
                                <p className="color-rep-value ">CO2e Energy</p>
                            </div>
                            <div className="d-flex">
                                <div className="color-icon bg-color1" />
                                <p className="color-rep-value m-0">CO2e Logistics</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <button onClick={handleShow} className="selection-button step-button">
                                <Tippy
                                    content={
                                        <span>
                                            You can view your saved scenario by clicking My Scenarios Tab at
                                            header.
                                        </span>
                                    }
                                >
                                    <span>Save My Scenario</span>
                                </Tippy>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Co2ScenarioMetal;
