import React from "react";

const FineTuneGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Fine Tune Scenario</h3>
            <p>The fine tune scenario section will load all the numbers calculated in the calculator to the
                default scenario. From there onwards you can create multiple scenarios by changing values as
                required and clicking the submit button to calculate</p>
            <img src="/assets/fine-tune-guide-1.png" alt="" className="img-fluid" />
            <p>The left panel (a) allows you to navigate between specific steps as in the calculator section, you can also use scroll bar on the right to go through the calculations.</p>
            <p>The top panel (c) allows for creation of more scenarios which will by load numbers from the default scenario. </p>
            <img src="/assets/fine-tune-guide-2.png" alt="" className="img-fluid" />
            <p>To create new scenario, first you have to name your scenario as desired. Once created the new scenario will be added to the top section. </p>
            <img src="/assets/fine-tune-guide-3.png" alt="" className="img-fluid" />
            <p>You can create a maximum of three scenarios and modify the inputs as desired. </p>
        </>
    )
}

export default FineTuneGuide;