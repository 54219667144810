import { get } from "jquery";
import { atom, selector } from "recoil";
import {
  PowderToCustomerData,
  SystemToCustomerData,
} from "../TransportationAtoms";

export const PowderData = atom({
  key: "PowderData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const VolumeReductionData = atom({
  key: "VolumeReductionData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const RefreshRateScenario = atom({
  key: "RefreshRateScenario",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const Co2ePowderScenarioData = atom({
  key: "Co2ePowderScenarioData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const EnergyMaterialData = atom({
  key: "EnergyMaterialData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const EnergySystemData = atom({
  key: "EnergySystemData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const Co2eEnergyData = atom({
  key: "Co2eEnergyData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const Co2eTransportationSData = atom({
  key: "Co2eTransportationSData",
  default: undefined,
});

export const Co2TotalTodaySData = atom({
  key: "Co2TotalTodaySData",
  default: undefined,
});

export const Co2TotalTomorrowSData = atom({
  key: "Co2TotalTomorrowSData",
  default: undefined,
});

export const VolumeReductionInput = atom({
  key: "VolumeReductionInput",
  default: "0",
});
export const RefreshRateScenarioInput = atom({
  key: "RefreshRateScenarioInput",
  default: "0",
});
export const CnpInput = atom({
  key: "CnpInput",
  default: "no",
});
export const EnergPercentageInput = atom({
  key: "EnergPercentageInput",
  default: "10",
});
export const GreenPowderEnergyInput = atom({
  key: "GreenPowderEnergyInput",
  default: "no",
});

export const TotalTraspotation = atom({
  key: "Co2PolymerTotalTraspotation",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
///////////////

export const CPolyModeOfTransportNode1 = atom({
  key: "CPolyModeOfTransportNode1",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CPolyModeOfTransportNode2 = atom({
  key: "CPolyModeOfTransportNode2",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CPolyModeOfTranspotation1 = selector({
  key: "CPolyModeOfTranspotation1",
  get: ({ get }) => String(get(PowderToCustomerData)),
  set: ({ set }, newValue) => set(PowderToCustomerData, newValue),
});

export const CPolyModeOfTranspotation2 = selector({
  key: "CPolyModeOfTranspotation2",
  get: ({ get }) => String(get(SystemToCustomerData)),
  set: ({ set }, newValue) => set(SystemToCustomerData, newValue),
});
