import React from 'react'

const ButtonTab = ({ ScenarioState, TabName }) => {
    return (
        <button
            onClick={() => ScenarioState()}
            className="selection-button step-button m-0"
        >
            {TabName}
        </button>
    )
}

export default ButtonTab