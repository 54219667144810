import React, { useState } from 'react'
import Navbar from "../components/Nav.jsx";
import MetalDataMatrix from './DataMatrixUtils/MetalDataMatrix.jsx';
import PolmerDataMatrix from './DataMatrixUtils/PolmerDataMatrix.jsx';
import { TECHTYPE } from 'app/components/ConditionalEnums/index.js';

const DataMatrix = () => {
    const [step, setStep] = useState(TECHTYPE.METAL);

    return (
        <>
            <Navbar />

            {
                step === TECHTYPE.METAL &&
                <MetalDataMatrix ScenarioState={() => setStep(TECHTYPE.POLYMER)}
                />
            }
            {
                step === TECHTYPE.POLYMER &&
                <PolmerDataMatrix ScenarioState={() => setStep(TECHTYPE.METAL)}
                />
            }

        </>
    )
}

export default DataMatrix