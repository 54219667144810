import { atom, selector } from "recoil";
import {
  CCMetalSystemToCustomerDataM,
  CCMetalPowderToCustomerDataM,
} from "../CCMetalTransportation";

export const CCMetalPowderAutomizationData = atom({
  key: "CCMetalPowderAutomizationData",
  default: 0,
});

export const CCMetalPowderAutomizationState = atom({
  key: "CCMetalPowderAutomizationState",
  default: "GAS_ATOMIZED",
});

export const CCMetalVolumePartReductionState = atom({
  key: "CCMetalVolumePartReductionState",
  default: "0",
});

export const CCMetalVolumePartReductionData = atom({
  key: "CCMetalVolumePartReductionData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCMetalCo2ePowderScenarioMData = atom({
  key: "CCMetalCo2ePowderScenarioMData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCMetalGreenEnergyMixData = atom({
  key: "CCMetalGreenEnergyMixData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCMetalGreenEnergyMixDataInput = atom({
  key: "CCMetalGreenEnergyMixDataInput",
  default: "no",
});
export const CCMetalInertGasReductionSMData = atom({
  key: "CCMetalInertGasReductionSMData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const CCMetalCo2ePowderMSData = atom({
  key: "CCMetalCo2ePowderMSData",
  default: undefined,
});
export const CCMetalCo2eTransportationSMData = atom({
  key: "CCMetalCo2eTransportationSMData",
  default: undefined,
});

export const CCMetalCo2TotalTodaySMData = atom({
  key: "CCMetalCo2TotalTodaySMData",
  default: undefined,
});

export const CCMetalCo2TotalTomorrowSMData = atom({
  key: "CCMetalCo2TotalTomorrowSMData",
  default: undefined,
});

export const CCMetalTotalTraspotation = atom({
  key: "CCMetalTotalTraspotation",
  default: undefined,
});

///////////////

export const CCMetalModeOfTransportNode1 = atom({
  key: "CCMetalModeOfTransportNode1",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CCMetalModeOfTransportNode2 = atom({
  key: "CCMetalModeOfTransportNode2",
  default: {
    today: 0,
    tomorrow: 0,
  },
});

export const CCMetalModeOfTranspotation1 = selector({
  key: "CCMetalModeOfTranspotation1",
  get: ({ get }) => String(get(CCMetalPowderToCustomerDataM)),
  set: ({ set }, newValue) => set(CCMetalPowderToCustomerDataM, newValue),
});

export const CCMetalModeOfTranspotation2 = selector({
  key: "CCMetalModeOfTranspotation2",
  get: ({ get }) => String(get(CCMetalSystemToCustomerDataM)),
  set: ({ set }, newValue) => set(CCMetalSystemToCustomerDataM, newValue),
});
