import React, { useEffect, useState } from "react";
import {
    PowderAutomizationData,
    VolumePartReductionData,
    Co2ePowderScenarioMData,
    GreenEnergyMixData,
    InertGasReductionSMData,
    Co2TotalTodaySMData,
    Co2TotalTomorrowSMData,
    PowderAutomizationInput,
    VolumePartReductionInput,
    GreenEnergyMixInput,
    TotalTraspotation
} from "atoms/Co2Metal/ScenarioMetal";
import { useRecoilState, } from "recoil";
import {
    CO2eModeOfTranspotation1,
    CO2eModeOfTranspotation2,
    CO2eModeOfTransportNode1,
    CO2eModeOfTransportNode2,
    CO2eEnergySMData
} from "atoms/Co2Metal/ScenarioMetal";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import SelectDropdown from "app/components/SelectDropdown";
import { PercentageData, YesNoData, LogisticsData } from 'utils/selectDropdowns';
import { DebounceInput } from "react-debounce-input";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import { handleKeyPress } from "utils/validations";
const { getComputedValue } = useRoundOffValue();

const MaterialTableValues = ({ socket, fieldObj }) => {
    const [powderAutomization, setPowderAutomization] = useRecoilState(
        PowderAutomizationData
    );
    const [volumePartReduction, setVolumePartReduction] = useRecoilState(
        VolumePartReductionData
    );
    const [co2ePowder, setCo2EPowder] = useRecoilState(Co2ePowderScenarioMData);
    const [greenEnergyMix, setGreenEnergyMix] =
        useRecoilState(GreenEnergyMixData);
    const [inertGasReduction, setInertGasReduction] = useRecoilState(
        InertGasReductionSMData
    );
    const [co2eEnergyTotal, setCo2eEnergyTotal] = useRecoilState(
        CO2eEnergySMData
    );
    const [co2TotalToday, setCo2TotalToday] = useRecoilState(Co2TotalTodaySMData);
    const [co2TotalTomorrow, setCo2TotalTomorrow] = useRecoilState(
        Co2TotalTomorrowSMData
    );
    const [totalTranpotation, setTotalTraspotation] =
        useRecoilState(TotalTraspotation);
    const co2EmissionPowder = fieldObj?.co2EmissionPowder;
    const Co2ePerPartPM = fieldObj?.Co2ePerPartPM;
    const co2eEmissionFactor = fieldObj?.co2eEmissionFactor;
    const noOfPartsData = fieldObj?.costData?.no_of_parts?.value;
    const partVolume = fieldObj?.costData?.part_volume?.value;
    const partSupportVolume = fieldObj?.costData?.part_support_volume?.value;
    const materialId = fieldObj?.material_id;
    const co2EmissionBuildProcess = fieldObj?.co2EmissionBuildProcess;
    const co2EmissionGas = fieldObj?.co2EmissionGas;
    const co2eTransportation = fieldObj?.co2Transportation;
    const inertGasReduction_tomorrow = fieldObj?.inertGasReduction_tomorrow
    // mode of transportation
    const [modeOfTranspotation1, setModeOfTranspotation1] = useRecoilState(
        CO2eModeOfTranspotation1
    );
    const [modeOfTranspotation2, setModeOfTranspotation2] = useRecoilState(
        CO2eModeOfTranspotation2
    );
    const [modeOfTransportnode1, setmodeOfTransportnode1] = useRecoilState(
        CO2eModeOfTransportNode1
    );

    const [modeOfTransportnode2, setmodeOfTransportnode2] = useRecoilState(
        CO2eModeOfTransportNode2
    );

    const materialTransportaion = fieldObj?.materialTransportaion;
    const systemTransportation = fieldObj?.systemTransportation;
    const cefnone = fieldObj?.cefnone;
    const cefnTwo = fieldObj?.cefnTwo;
    const build_time = fieldObj?.build_time;
    const [powderAtomizationState, setPowderAutomizationState] = useRecoilState(
        PowderAutomizationInput
    );
    const [volumerPartReductionState, setVolumePartReductionState] =
        useRecoilState(VolumePartReductionInput);
    const [greenEnergymixState, setGreenEnergyMixState] =
        useRecoilState(GreenEnergyMixInput);
    const [checkInertGasReduction, setCheckInertGasReduction] = useState(undefined)

    useEffect(() => {
        if (fieldObj) {
            setPowderAutomizationState(fieldObj?.powderAutomizationInput)
            setVolumePartReductionState(fieldObj?.volumePartReductionInput)
            setGreenEnergyMixState(fieldObj?.greenEnergyMixInput)
            setModeOfTranspotation1(fieldObj?.modeOfTranspotation1Input)
            setModeOfTranspotation2(fieldObj?.modeOfTranspotation2Input)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldObj])

    useEffect(() => {
        if (modeOfTranspotation1 && modeOfTranspotation1 !== "undefined" && modeOfTranspotation2 && modeOfTranspotation2 !== 'undefined') {
            socket.off("*");

            socket.emit("carbon-calculator", {
                key: "MSA-MCT",
                input: {
                    node1_mode: modeOfTranspotation1,
                    node2_mode: modeOfTranspotation2,
                    material_transportation: parseInt(materialTransportaion),
                    system_transportation: parseInt(systemTransportation),
                    carbon_emission_factor_node_1: +cefnone,
                    carbon_emission_factor_node_2: +cefnTwo,
                    // utilization: +staticDataOfMachine?.utilization?.value,
                    build_time: +build_time,
                    // setup_time: +staticDataOfMachine?.setup_time?.value,
                    // cool_down_time_in_system:
                    //   +staticDataOfMachine.cool_down_time_in_system.value, //not in metal
                    // unpacking_time_per_job:
                    //   +staticDataOfMachine?.unpacking_time_per_job?.value,
                },
            });

            socket.on("MSA-MCT", function (data) {
                const transportNode1Today = getComputedValue(
                    data?.data?.transport_node_1_today
                );
                const transportNode1Tomorrow = getComputedValue(
                    data?.data?.transport_node_1_tomorrow
                );
                const transportNode2Today = getComputedValue(
                    data?.data?.transport_node_2_today
                );
                const transportNode2Tomorrow = getComputedValue(
                    data?.data?.transport_node_2_tomorrow
                );
                // console.log("transportNode1Today: ", transportNode1Today);
                // console.log("transportNode1Tomorrow: ", transportNode1Tomorrow);
                // console.log("transportNode2Today: ", transportNode2Today);
                // console.log("transportNode2Tomorrow: ", transportNode2Tomorrow);

                setmodeOfTransportnode1({
                    today: transportNode1Today,
                    tomorrow: transportNode1Tomorrow,
                });
                setmodeOfTransportnode2({
                    today: transportNode2Today,
                    tomorrow: transportNode2Tomorrow,
                });
                setTotalTraspotation({
                    today: getComputedValue(data?.data?.transport_today),
                    tomorrow: getComputedValue(data?.data?.transport_tomorrow),
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modeOfTranspotation1, modeOfTranspotation2]);

    useEffect(() => {
        if (powderAtomizationState && volumerPartReductionState) {

            socket.emit("carbon-calculator", {
                key: "MSA-MCP",
                input: {
                    atomizedType: powderAtomizationState,
                    co2_emission_powder: parseFloat(co2EmissionPowder),
                    reduction_in_volume: parseFloat(volumerPartReductionState),
                    powder_energy_per_part: parseFloat(Co2ePerPartPM),
                    co2_emission_factor: parseFloat(co2eEmissionFactor),
                    no_of_parts: parseFloat(noOfPartsData),
                    part_volume: parseFloat(partVolume),
                    part_support_volume: parseFloat(partSupportVolume),
                    material_id: parseFloat(materialId),
                },
            });

            socket.on("MSA-MCP", function (data) {
                const valuepatod = getComputedValue(
                    data?.data?.powder_atomization_today
                );
                const valuepatom = getComputedValue(
                    data?.data?.powder_atomization_tomorrow
                );
                const valuecptod = getComputedValue(data?.data?.co2e_powder_today);
                const valuecptom = getComputedValue(data?.data?.co2e_powder_tomorrow);
                const valuevptod = getComputedValue(
                    data?.data?.volume_of_the_part_reduction_today
                );
                const valuevptom = getComputedValue(
                    data?.data?.volume_of_the_part_reduction_tomorrow
                );
                setPowderAutomization({ today: valuepatod, tomorrow: valuepatom });
                setCo2EPowder({ today: valuecptod, tomorrow: valuecptom });
                setVolumePartReduction({ today: valuevptod, tomorrow: valuevptom });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powderAtomizationState, volumerPartReductionState]);

    useEffect(() => {
        if (greenEnergymixState) {
            socket.emit("carbon-calculator", {
                key: "MSA-MCE",
                input: {
                    isGreenEnergyMix: greenEnergymixState,
                    co2_emission_build_process: co2EmissionBuildProcess,
                },
            });

            socket.on("MSA-MCE", function (data) {
                const valuepatod = getComputedValue(data.data.green_energy_mix_today);
                const valuepatom = getComputedValue(
                    data.data.green_energy_mix_tomorrow
                );
                setGreenEnergyMix({ today: valuepatod, tomorrow: valuepatom });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [greenEnergymixState]);

    useEffect(() => {
        if (co2EmissionGas && inertGasReduction?.tomorrow?.edited === false) {

            socket.emit("carbon-calculator", {
                key: "MSA-MCG",
                input: { co2_emission_gas: co2EmissionGas },
            });

            socket.on("MSA-MCG", function (data) {
                if (checkInertGasReduction === undefined) {
                    setCheckInertGasReduction(true)
                }
                const valuepatod = getComputedValue(
                    data.data.inert_gas_reduction_today
                );
                const valuepatom = getComputedValue(
                    data.data.inert_gas_reduction_tomorrow
                );
                setInertGasReduction({ today: valuepatod, tomorrow: { value: valuepatom } });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inertGasReduction?.tomorrow?.edited]);

    useEffect(() => {
        if (inertGasReduction_tomorrow?.edited && checkInertGasReduction) {
            setInertGasReduction((prevState) => {
                return {
                    ...prevState,
                    tomorrow: { value: inertGasReduction_tomorrow?.value, edited: true },
                };
            });
            setCheckInertGasReduction(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inertGasReduction_tomorrow, checkInertGasReduction])

    useEffect(() => {
        if (
            co2ePowder &&
            co2ePowder.today &&
            greenEnergyMix &&
            greenEnergyMix.today &&
            inertGasReduction &&
            inertGasReduction.today &&
            totalTranpotation &&
            totalTranpotation.today
        ) {
            const value =
                parseFloat(co2ePowder.today) +
                parseFloat(inertGasReduction.today) +
                parseFloat(greenEnergyMix.today) +
                parseFloat(totalTranpotation.today);
            const value1 = getComputedValue(value);
            setCo2TotalToday(value1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        co2ePowder,
        powderAutomization,
        inertGasReduction,
        volumePartReduction,
        greenEnergyMix,
        totalTranpotation,
    ]);
    useEffect(() => {
        if (
            co2ePowder &&
            co2ePowder.tomorrow &&
            greenEnergyMix &&
            greenEnergyMix.tomorrow &&
            inertGasReduction &&
            inertGasReduction.tomorrow &&
            inertGasReduction.tomorrow?.value &&
            totalTranpotation &&
            totalTranpotation.tomorrow
        ) {

            const value =
                parseFloat(co2ePowder.tomorrow) +
                parseFloat(inertGasReduction.tomorrow?.value) +
                parseFloat(greenEnergyMix.tomorrow) +
                parseFloat(totalTranpotation.tomorrow);
            const value1 = getComputedValue(value);

            setCo2TotalTomorrow(value1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        co2ePowder,
        powderAutomization,
        inertGasReduction,
        volumePartReduction,
        greenEnergyMix,
        totalTranpotation,
    ]);

    useEffect(() => {
        if (
            greenEnergyMix !== undefined &&
            inertGasReduction !== undefined
        ) {

            const value =
                parseFloat(inertGasReduction.today) +
                parseFloat(greenEnergyMix.today)
            const value1 = parseFloat(inertGasReduction.tomorrow?.value) +
                parseFloat(greenEnergyMix.tomorrow)
            const valueCo2EnergyToday = getComputedValue(value);
            const valueCo2EnergyTomorrow = getComputedValue(value1);

            setCo2eEnergyTotal({ today: valueCo2EnergyToday, tomorrow: greenEnergymixState === "yes" ? 0 : valueCo2EnergyTomorrow });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        inertGasReduction,
        greenEnergyMix,
    ]);

    return (
        <div className=" responsive-scroll">
            <div className="divTable">
                <div className="divTableBody">
                    <div className="divTableRow">
                        {/* <div className="divTableCell1-heading">Unit</div> */}
                        <div className="divTableCell1-heading p-1">Input</div>
                        <div className="divTableCell1-heading pl-2">Current Scenario</div>
                        <div className="divTableCell1-heading">
                            <Tippy
                                content={
                                    <span>
                                        Here you can be playful! We have estimated the status quo of
                                        your carbon and costs, but let's see how certain changes
                                        might impact your values!
                                    </span>
                                }
                            >
                                <span> Future Scenario</span>
                            </Tippy>
                        </div>
                    </div>
                    {/* <div className="divTableRow">
                        <div className="divTableCell1 p-0">

                            <SelectDropdown setState={setPowderAutomizationState} getState={powderAtomizationState} Dataset={AtomizationDataMetal} styleclass="dropdown-input" />
                        </div>
                        <div className="divTableCell1 pl-3 position-top-0">
                            {powderAutomization ? powderAutomization.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3 position-top-0">
                            {powderAutomization ? powderAutomization.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    TODO commented as of now as per client requirement
                    */}
                    <div className="divTableRow">
                        {/* <div className="divTable__Cells">kg</div> */}
                        <div className="divTableCell1 ">

                            <SelectDropdown setState={setVolumePartReductionState} getState={volumerPartReductionState} Dataset={PercentageData} styleclass="dropdown-input" />
                        </div>
                        <div className="divTableCell1 pl-3">
                            {volumePartReduction ? volumePartReduction.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {volumePartReduction ? volumePartReduction.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1-special">kg</div> */}
                        <div className="divTable__Cells p-0">&nbsp;</div>
                        <div className="divTableCell1 pl-3">
                            {co2ePowder ? co2ePowder.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {co2ePowder ? co2ePowder.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTable__Cells">Green Energy</div> */}
                        <div className="divTableCell1">

                            <SelectDropdown setState={setGreenEnergyMixState} getState={greenEnergymixState} Dataset={YesNoData} styleclass="dropdown-input" />

                        </div>
                        <div className="divTableCell1 pl-3">
                            {greenEnergyMix ? greenEnergyMix.today : "_ _ _ _ "}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {greenEnergyMix ? greenEnergyMix.tomorrow : "_ _ _ _ "}
                        </div>
                    </div>

                    {/* <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div> */}

                    <div className="divTableRow">
                        {/* <div className="divTableCell1-heading p-0">&nbsp;</div> */}
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTable__Cells pl-3 position-top-0">
                            {inertGasReduction ? inertGasReduction.today : "_ _ _ _"}
                        </div>
                        <div className="divTable__Cells">
                            <div className="input-section">
                                <DebounceInput
                                    type="number"
                                    className="user-input"
                                    debounceTimeout={500}
                                    onChange={(e) => {
                                        setInertGasReduction((prevState) => {
                                            return {
                                                ...prevState,
                                                tomorrow: { value: +e.target.value, edited: true },
                                            };
                                        });
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    placeholder=""
                                    value={inertGasReduction.tomorrow?.value}
                                />
                                {
                                    inertGasReduction?.tomorrow?.edited &&
                                    <img src="/assets/cross.png" alt="" className="cross-icon" onClick={() => {
                                        setInertGasReduction((prevState) => {
                                            return {
                                                ...prevState,
                                                tomorrow: { edited: false },
                                            };
                                        });
                                    }} />
                                }

                                <OverrideAbleTip />
                            </div>
                        </div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1-special">kg</div> */}
                        <div className="divTableCell1 p-0">&nbsp;</div>
                        <div className="divTableCell1 pl-3 ">
                            {co2eEnergyTotal ? co2eEnergyTotal.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {co2eEnergyTotal ? co2eEnergyTotal.tomorrow : "_ _ _ _"}
                        </div>
                    </div>

                    <div className="divTableRow">
                        <div className="divTableCell1-heading">&nbsp;</div>
                        <div className="divTableCell1-heading">&nbsp;</div>
                        <div className="divTableCell1-heading">&nbsp;</div>
                        <div className="divTableCell1-heading">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                        <div className="divTableCell1-heading p-0">&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
                        <div className="divTableCell1">

                            <SelectDropdown setState={setModeOfTranspotation1} getState={modeOfTranspotation1} Dataset={LogisticsData} styleclass="dropdown-input" />
                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode1 !== undefined
                                ? modeOfTransportnode1.today
                                : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode1 !== undefined
                                ? modeOfTransportnode1.tomorrow
                                : "_ _ _ _"}
                        </div>
                    </div>

                    <div className="divTableRow">
                        {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
                        <div className="divTableCell1">

                            <SelectDropdown setState={setModeOfTranspotation2} getState={modeOfTranspotation2} Dataset={LogisticsData} styleclass="dropdown-input" />

                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode2 !== undefined
                                ? modeOfTransportnode2.today
                                : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3">
                            {modeOfTransportnode2 !== undefined
                                ? modeOfTransportnode2.tomorrow
                                : "_ _ _ _"}
                        </div>
                    </div>

                    <div className="divTableRow">
                        {/* <div className="divTableCell1">&nbsp;</div> */}
                        <div className="divTableCell1">&nbsp;</div>
                        <div className="divTableCell1 pl-3">
                            {totalTranpotation ? totalTranpotation.today : "_ _ _ _ "}
                        </div>
                        <div className="divTableCell1 pl-3 pb-0">
                            {totalTranpotation ? totalTranpotation.tomorrow : "_ _ _ _ "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialTableValues;
