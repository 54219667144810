import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { DebounceInput } from "react-debounce-input";

import {
  CCPolyAveragePowerData,
  CCPolyInertGasPerJobOneData,
  CCPolyInertGasPerJobTwoData,
  CCPolyEnergyUsedPerJobData,
  CCPolyCo2EmissionFactorData,
  CCPolyCo2ePerJobAPData,
  CCPolyCo2ePerPartAPData,
} from "atoms/CCPolymer/CCPolyAmProcess";

import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

const AmProcess = ({ Socket, StepID }) => {
  const [averagepower, setAveragePower] = useRecoilState(
    CCPolyAveragePowerData
  );
  const [inertOne, setInertOne] = useRecoilState(CCPolyInertGasPerJobOneData);
  const [inertTwo, setInertTwo] = useRecoilState(CCPolyInertGasPerJobTwoData);
  const [energyupj, setEnergyupj] = useRecoilState(CCPolyEnergyUsedPerJobData);
  const [co2ef, setCo2ef] = useRecoilState(CCPolyCo2EmissionFactorData);
  const [co2pj, setCo2pj] = useRecoilState(CCPolyCo2ePerJobAPData);
  const [co2pp, setCo2pp] = useRecoilState(CCPolyCo2ePerPartAPData);

  const { getComputedValue } = useRoundOffValue();
  const [, setProcessFlag] = useRecoilState(ProcessFlag);
  const costData = JSON.parse(localStorage.getItem("CostData"));
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
    loadersix: false,
    loaderseven: false,
  });

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderone: true,
      };
    });
    Socket.emit("carbon-calculator", {
      key: "DBCC-APAP",
      input: { machine_id: costData.machine_id.value },
    });

    Socket.on("DBCC-APAP", function (data) {

      const value = getComputedValue(data?.data?.value);

      setAveragePower(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (costData.build_time.value !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-APIGPJ1",
        input: { build_time: costData.build_time.value, machine_id: costData.machine_id.value },
      });

      Socket.on("FOCC-APIGPJ1", function (data) {

        const value = getComputedValue(data?.data?.value);

        setInertOne(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inertOne) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-APIGPJ2",
        input: { inert_gas_per_job_1: inertOne, machine_id: costData.machine_id.value },
      });

      Socket.on("FOCC-APIGPJ2", function (data) {

        const value = getComputedValue(data?.data?.value);

        setInertTwo(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertOne]);

  useEffect(() => {
    if (
      costData.build_time.value !== undefined &&
      averagepower !== undefined &&
      inertTwo !== undefined
    ) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-APEUPJ",
        input: {
          average_power: averagepower,
          build_time: costData.build_time.value,
          inert_gas_per_job_2: inertTwo,
        },
      });

      Socket.on("FOCC-APEUPJ", function (data) {

        const value = getComputedValue(data?.data?.value);

        setEnergyupj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averagepower, costData.build_time.value, inertTwo]);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderfive: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-APCEF", input: "" });

    Socket.on("DBCC-APCEF", function (data) {

      const value = getComputedValue(data?.data?.value);

      setCo2ef(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (energyupj !== undefined && co2ef !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadersix: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-APCPJ",
        input: { energy_used_per_job: energyupj, co2_fmission_factor: co2ef },
      });

      Socket.on("FOCC-APCPJ", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2pj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadersix: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyupj, co2ef]);

  useEffect(() => {
    if (co2pj !== undefined && costData.no_of_parts.value !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderseven: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-APCPP",
        input: {
          co2e_per_job_ap: co2pj,
          no_of_parts: costData.no_of_parts.value,
        },
      });

      Socket.on("FOCC-APCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderseven: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2pj, costData.no_of_parts.value]);

  useEffect(() => {
    if (
      +costData?.build_time?.value != null &&
      averagepower != null &&
      inertOne != null &&
      inertTwo != null &&
      energyupj != null &&
      co2ef != null &&
      co2pj != null &&
      co2pp != null
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          AMProcess: true,
        };
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [+costData?.build_time?.value,
      averagepower,
      inertOne,
      inertTwo,
      energyupj,
      co2ef,
      co2pj,
      co2pp,
    ]);

  return (
    <>
      <p className="input-heading">Build time (h)</p>
      <div className="data-field">{+costData?.build_time?.value}</div>

      <p className="input-heading">Average power (kw)</p>
      <div className="data-field">
        {loader.loaderone ? (
          <Spinner animation="border" size="sm" />
        ) : (
          averagepower
        )}
      </div>

      <p className="input-heading">
        Inert gas per job (m<sup className="font-weight-bold">3</sup>/job)
      </p>
      <div className="data-field">
        {loader.loadertwo ? <Spinner animation="border" size="sm" /> : inertOne}
      </div>

      <p className="input-heading">Inert gas per job (kWh)</p>
      <div className="data-field">
        {loader.loaderthree ? (
          <Spinner animation="border" size="sm" />
        ) : (
          inertTwo
        )}
      </div>

      <p className="input-heading">Energy used per job (kWh)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setEnergyupj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={
            loader.loaderfour ? (
              <Spinner animation="border" size="sm" />
            ) : (
              energyupj
            )
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">
        <TooltipTrippy fieldName="CO2e emission factor (kg/kWh)" tooltipMessage="Details on factors can be found under Data matrix tab" />


      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2ef(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e emission factor"
          value={
            loader.loaderfive ? <Spinner animation="border" size="sm" /> : co2ef
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per job (kg)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2pj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={
            loader.loadersix ? <Spinner animation="border" size="sm" /> : co2pj
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {loader.loaderseven ? <Spinner animation="border" size="sm" /> : co2pp}
      </div>
    </>
  );
};

export default AmProcess;
