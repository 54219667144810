import React from 'react'
import ReactHtmlParser from 'react-html-parser';


const HotspotTable = ({ hotspotArray, hightlightKey }) => {
    return (
        <div className="reports-section" >
            <div className="responsive-scroll borderForTable" >
                <div className="divTableReport">
                    <div className="divTableBody">
                        <div className="divTableRowTop">

                            {
                                hotspotArray.map((data, index) => {

                                    return (
                                        <div key={index} className={data.heading.includes(hightlightKey) && hightlightKey !== "" ? "divTableCellReportTop textAlignLeft hotspotBg" : "divTableCellReportTop textAlignLeft "}>{data.heading}</div>

                                    )
                                })
                            }
                        </div>
                        <div className="divTableRow borders-table" >

                            {
                                hotspotArray.map((data, index) => {
                                    return (
                                        <div key={index} className="divTableCellReport report-two-text ">
                                            {ReactHtmlParser(data.description)}
                                        </div>
                                    )
                                })
                            }
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default HotspotTable