import React, { useState, useEffect } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { ProcessFlag } from "atoms/General/index.js";
import { useRecoilValue, useRecoilState } from "recoil";
import Co2Pricing from "./Co2Pricing";
import { ToastContainer, toast } from "react-toastify";
import ArrowDown from "../ProcessMetal/components/ArrowDown";
import CarbonContentPerPart from "../ProcessMetal/components/CarbonContentPerPart";
import { GET_CLIENT_STEPS } from "../ProcessMetal/components/getStepDetail";
import { useApolloClient } from "@apollo/react-hooks";
import { technologyId, stepTypeId, technologyType } from "atoms/General";
import Tippy from "@tippyjs/react";
import { Spinner } from "react-bootstrap";

const CarbonPricingMetal = ({ ScenarioState, socket }) => {
  // const socket = new socketIOClient(constant.uri);
  const client = useApolloClient();
  const [activeState, setActiveState] = useState(-1);
  const [clientSteps, setClientSteps] = useState([]);
  const [loader, setLoader] = useState(true);
  const [techId, setTechId] = useRecoilState(technologyId);
  const [steptypeId, setStepTypeId] = useRecoilState(stepTypeId);
  const [TechnologyType, settechnologyType] = useRecoilState(technologyType);
  const processFlag = useRecoilValue(ProcessFlag);

  useEffect(() => {
    setTechId(1);
    setStepTypeId(3);
    settechnologyType("CO2e Metal");

    client
      .query({
        query: GET_CLIENT_STEPS,
        variables: { technology_id: 1, type_id: 3 },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getClientSteps);
        // console.log(data.getClientSteps);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatedclientSteps = [...clientSteps.slice(-2)];

  const toggleState = (idnum) => {
    const id = idnum;
    if (activeState === +id) {
      setActiveState(null);
    } else {
      setActiveState(+id);
    }
  };

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" />{" "}
        </div>
      ) : (
        <>
          {" "}
          <Accordion>
            {updatedclientSteps &&
              updatedclientSteps.map((step, index) => {
                // console.log("steps: ", step);
                return (
                  <Card key={index}>
                    {step?.step_id > 56 && (
                      <Card.Header
                        className={activeState === index ? "active" : ""}
                      >
                        <div className="d-flex justify-content-between">
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            className={
                              activeState === index ? "active-color" : ""
                            }
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                            data-display="static"
                          >
                            {step?.name.includes("Pricing") ? (
                              <Tippy
                                key={index}
                                content={
                                  <span>
                                    All factors are taken from ADEME database,
                                    for more details on academic numbers we used
                                    VDI studies and referenced are under Data
                                    Collection Matrix.{" "}
                                  </span>
                                }
                              >
                                <span>{step.name}</span>
                              </Tippy>
                            ) : (
                              step.name
                            )}
                          </Accordion.Toggle>
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            id="0"
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                          >
                            <div
                              className={
                                activeState === index
                                  ? "arrow-box active"
                                  : "arrow-box"
                              }
                            >
                              <ArrowDown />
                            </div>
                          </Accordion.Toggle>
                        </div>
                      </Card.Header>
                    )}
                    <Accordion.Collapse
                      data-target={index}
                      eventKey={index === 0 ? "0" : index}
                    >
                      <Card.Body>
                        {step?.step_id === 57 && activeState === index && (
                          <CarbonContentPerPart Socket={socket} />
                        )}
                        {step?.step_id === 58 && activeState === index && (
                          <Co2Pricing Socket={socket} />
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
          <button
            onClick={() => {
              if (processFlag.CarbonPricing) {
                ScenarioState();
              } else {
                toast.error("Please fill all carbon pricing details.");
              }
            }}
            className="selection-button step-button"
          >
            Proceed
          </button>
        </>
      )}
    </>
  );

  // return (
  //   <>
  //     <>
  //       <Accordion defaultActiveKey="0">
  //         <Card>
  //           <Card.Header className={activeState === 0 ? "active" : ""}>
  //             <div className="d-flex justify-content-between">
  //               <Accordion.Toggle
  //                 onClick={() => toggleState(0)}
  //                 className={activeState === 0 ? "active-color" : ""}
  //                 as={Button}
  //                 variant="link"
  //                 eventKey="0"
  //                 data-display="static"
  //               >
  //                 Carbon Pricing
  //               </Accordion.Toggle>
  //               <Accordion.Toggle
  //                 onClick={() => toggleState(0)}
  //                 id="0"
  //                 as={Button}
  //                 variant="link"
  //                 eventKey="0"
  //               >
  //                 <div
  //                   className={
  //                     activeState === 0 ? "arrow-box active" : "arrow-box"
  //                   }
  //                 >
  //                   <ArrowDown />
  //                 </div>
  //               </Accordion.Toggle>
  //             </div>
  //             <div className="d-flex justify-content-between">
  //               <Accordion.Toggle
  //                 onClick={() => toggleState(0)}
  //                 className={activeState === 0 ? "active-color" : ""}
  //                 as={Button}
  //                 variant="link"
  //                 eventKey="0"
  //                 data-display="static"
  //               >
  //                 Carbon Content Per Part
  //               </Accordion.Toggle>
  //               <Accordion.Toggle
  //                 onClick={() => toggleState()}
  //                 id="0"
  //                 as={Button}
  //                 variant="link"
  //                 eventKey="0"
  //               >
  //                 <div
  //                   className={
  //                     activeState === 1 ? "arrow-box active" : "arrow-box"
  //                   }
  //                 >
  //                   <ArrowDown />
  //                 </div>
  //               </Accordion.Toggle>
  //             </div>
  //           </Card.Header>
  //           <Accordion.Collapse data-target="0" eventKey="0">
  //             <Card.Body>
  //               {/* {activeState == 0(<Co2Pricing Socket={socket} />)} */}

  //               {activeState == 0 && processFlag.Transportation ? (
  //                 <Co2Pricing Socket={socket} />
  //               ) : (
  //                 <p>Add all Transportation Carbon Content details to view</p>
  //               )}

  //               {activeState == 1 && processFlag.CarbonContentPP ? (
  //                 <CarbonContentPerPart Socket={socket} />
  //               ) : (
  //                 <p>Add all details to view</p>
  //               )}
  //             </Card.Body>
  //           </Accordion.Collapse>
  //         </Card>
  //       </Accordion>
  //       <button
  //         onClick={() => {
  //           if (processFlag.CarbonPricing) {
  //             ScenarioState();
  //           } else {
  //             toast.error("Please Fill All Carbon Pricing Detials");
  //           }
  //         }}
  //         className="selection-button step-button"
  //       >
  //         Proceed
  //       </button>
  //     </>
  //   </>
  // );
};

export default CarbonPricingMetal;
