import gql from 'graphql-tag';

export const GET_STEP_DETAILS = gql`
query($step_id:Int!) {
    getFieldsByStepId(step_id:$step_id){
      field_id
      name
      field_unit
      field_info
      field_type
      field_input_type
      keys
      formula_inputs
      field_data_type
      formula
      step_id{
        step_id
        name
      }
      data_reference
      is_override_enabled
      is_optional
      field_step_index
      renderer
      is_premium
      technology_id{
        technology_id
        name
        description
        
      }
      step_type_id{
        step_type_id
        name
      }
    }
  }
`
export const GET_CLIENT_MACHINE = gql`
query($technology_id:Int!, $calculator_type:String!) {
  getClientMachineByTechnology(technology_id:$technology_id, calculator_type: $calculator_type ){
    machine_id
    name
    is_data_avail_for_cost
    is_data_avail_for_carbon
  }
  }
`
export const GET_CLIENT_MATERIAL = gql`
query($machine_id:Int!,$group_id:Int!) {
    getClientMaterialByMachineId(machine_id:$machine_id,group_id:$group_id){
      name
      material_id
    }
  
  }
`
export const GET_PRODUCTION_SCENARIO = gql`
query($type:String!,$setupTime:Float!, $buildTime:Float!, $unpackingtime:Float!, $coolDownTime:Float!,$isOnlyWorkingHours:Boolean  ) {
  productionScheduling(type:$type,setupTime:$setupTime,buildTime:$buildTime,unpackingtime:$unpackingtime, coolDownTime:$coolDownTime,isOnlyWorkingHours:$isOnlyWorkingHours )
}
`
export const GET_PRODUCTION_SCENARIO_METAL = gql`
query($type:String!,$setupTime:Float!, $buildTime:Float!, $unpackingtime:Float!,$isOnlyWorkingHours:Boolean  ) {
  productionScheduling(type:$type,setupTime:$setupTime,buildTime:$buildTime,unpackingtime:$unpackingtime,isOnlyWorkingHours:$isOnlyWorkingHours  )
}
`

