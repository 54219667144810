import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { useRecoilState, useRecoilValue } from "recoil"
import { PowderLossDataM, Co2ePerJobDataM, Co2ePerPartPMDataM, PowderTypeDataM } from "../../../../../../atoms/Co2Metal/PowderMfgAtoms";
import { MaterialDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { PowderUsedDataM, NoOfPartsDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { DebounceInput } from 'react-debounce-input';
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from 'atoms/General';
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

const PowderMfg = ({ Socket }) => {
  const [powderLoss, setPowderLoss] = useRecoilState(PowderLossDataM);
  const [co2epj, setCo2epj] = useRecoilState(Co2ePerJobDataM);
  const [co2epp, setCo2epp] = useRecoilState(Co2ePerPartPMDataM);
  const [powdertype, setPowderType] = useRecoilState(PowderTypeDataM);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag)

  const powderUsedData = parseFloat(useRecoilValue(PowderUsedDataM))
  const noOfPartsData = parseFloat(useRecoilValue(NoOfPartsDataM))
  const materialId = parseFloat(useRecoilValue(MaterialDataM))
  const { getComputedValue } = useRoundOffValue()
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false

  })

  useEffect(() => {
    if (powderUsedData) {
      Socket.off("*")
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true
        }
      })
      Socket.emit("carbon-calculator", { key: "FOCC-PMPL", input: { powder_used: powderUsedData } });
      Socket.on("FOCC-PMPL", function (data) {
        const value = getComputedValue(data?.data?.value)

        setPowderLoss(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false
          }
        })
      });
    }
  }, [powderUsedData])



  useEffect(() => {
    if (powderUsedData && powderLoss && powdertype && materialId) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true
        }
      })

      // debugger
      Socket.emit("carbon-calculator", { key: "FOCC-CPJ-METAL", input: { powder_loss: powderLoss, powder_used: powderUsedData, material_id: materialId } });

      Socket.on("FOCC-CPJ-METAL", function (data) {

        const value = getComputedValue(data?.data?.value)

        setCo2epj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false
          }
        })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderLoss, powderUsedData, powdertype]);


  useEffect(() => {
    if (co2epj && noOfPartsData) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true
        }
      })

      Socket.emit("carbon-calculator", { key: "FOCC-PMPEPP", input: { no_of_parts: noOfPartsData, co2e_per_job: co2epj } });

      Socket.on("FOCC-PMPEPP", function (data) {
        console.log(data, 'FOCC-PMPEPP');

        const value = getComputedValue(data?.data?.value)

        setCo2epp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false
          }
        })
      });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2epj, noOfPartsData]);

  useEffect(() => {
    if (powdertype === "" || powderLoss === "" || co2epj === "" || co2epp === "") {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: false,
        }
      })
    }
    else if (powdertype !== undefined && powderLoss !== undefined && co2epj !== undefined && co2epp !== undefined) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: true,
        }
      })
    }
    else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: false,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2epp, co2epj, powdertype, powderLoss,])


  return (
    <>
      <p className="input-heading">Atomization type</p>
      <div className="data-field">
        gas atomized
        {/*  value to be displayed for powdertype */}
      </div>
      <p className="input-heading"><TooltipTrippy fieldName="Powder loss (kg)" tooltipMessage="Assumes a 20% powder loss for calculation. You can override this with a more accurate values if available" /></p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setPowderLoss(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter machine power"
          value={
            loader.loaderone ? (
              <Spinner animation="border" size="sm" />
            ) : (
              powderLoss
            )
          }
        />
        <OverrideAbleTip />
      </div>
      <p className="input-heading">CO2e per job (Kgs)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2epj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={co2epj !== undefined || co2epj !== null ? (
            co2epj
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        />
        <OverrideAbleTip />
      </div>
      <p className="input-heading"> CO2e per part (kgs)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2epp(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={co2epp !== undefined || co2epp !== null ? (
            co2epp
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        />
        <OverrideAbleTip />
      </div>

    </>
  );
};

export default PowderMfg;
