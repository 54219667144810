import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_MATERIAL_NAME_BY_ID } from "../CCCarbonMetal/components/CarbonMetalProcess/components/getStepDetail";

export const useGetMaterialName = (material_id) => {
    const client = useApolloClient();
    const [materialName, setMaterialName] = useState("");

    useEffect(() => {
        const getMaterialName = () => {
            client
                .query({
                    query: GET_MATERIAL_NAME_BY_ID,
                    variables: { material_id: material_id },
                })
                .then(({ data }) => {
                    // console.log("res: ", data);
                    setMaterialName(data.getMaterialNameById.name);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        getMaterialName();
    }, []);
    return materialName;
};
