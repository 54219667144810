import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "app/view/CCCarbonMetal/components/Scenario/components/MaterialTable";
import MaterialTableValues from "./MaterialTableValues";
import { useRecoilValue } from "recoil";
import {
    CCMetalCo2TotalTodaySMData,
    CCMetalCo2TotalTomorrowSMData,
    CCMetalPowderAutomizationData,
    CCMetalVolumePartReductionData,
    CCMetalCo2ePowderScenarioMData,
    CCMetalGreenEnergyMixData,
    CCMetalInertGasReductionSMData,
    CCMetalPowderAutomizationState,
    CCMetalVolumePartReductionState,
    CCMetalGreenEnergyMixDataInput,
    CCMetalModeOfTranspotation1,
    CCMetalModeOfTranspotation2
} from "atoms/CCCarbonMetal/CCMetalScenario";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import config from "../../../../../config"
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const axios = require("axios").default;


const CCScenarioMetal = ({ fieldObj, socket }) => {
    const shiftSize = 7;
    const co2TotalToday = useRecoilValue(CCMetalCo2TotalTodaySMData);
    const co2TotalTomorrow = useRecoilValue(CCMetalCo2TotalTomorrowSMData);
    const powderAtomization = useRecoilValue(CCMetalPowderAutomizationData);
    const volumePArtReduction = useRecoilValue(CCMetalVolumePartReductionData);
    const co2ePowder = useRecoilValue(CCMetalCo2ePowderScenarioMData);
    const greenenergymix = useRecoilValue(CCMetalGreenEnergyMixData);
    const inertGasReduction = useRecoilValue(CCMetalInertGasReductionSMData);
    const powderAtomizationInput = useRecoilValue(CCMetalPowderAutomizationState)
    const volumerPartReductionStateInput = useRecoilValue(CCMetalVolumePartReductionState)
    const greenEnergymixStateInput = useRecoilValue(CCMetalGreenEnergyMixDataInput)
    const modeOfTranspotation1Input = useRecoilValue(CCMetalModeOfTranspotation1)
    const modeOfTranspotation2Input = useRecoilValue(CCMetalModeOfTranspotation2)
    const co2Transportation = +fieldObj?.co2Transportation;
    const [valueState, setValueState] = useState({
        powder: null,
        volume: null,
        co2Powder: null,
        greenEnergyMix: null,
        inertGas: null,
        transport: null
    })


    const [dataSet, setDataSet] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [scenarioName, setScenarioName] = useState("");

    const access_token = sessionStorage.getItem("access_token");

    useEffect(() => {
        if (valueState.co2Powder !== undefined && valueState.transport !== undefined && valueState.greenEnergyMix !== undefined && valueState.inertGas !== undefined && co2TotalToday !== undefined) {
            setDataSet(
                [
                    {
                        color: "#5DC0D3",
                        title: valueState.co2Powder ? ((parseFloat(valueState.co2Powder) / parseFloat(co2TotalToday)) * 100).toFixed(2) + "%" : "",
                        value: parseFloat(((valueState.co2Powder / co2TotalToday) * 100).toFixed(2)),
                    },
                    {
                        color: " #9CEAEF",
                        title: valueState.greenEnergyMix && valueState?.inertGas ? (((valueState.greenEnergyMix + valueState?.inertGas) / (co2TotalToday) * 100).toFixed(2) + "%") : "",
                        value: parseFloat(((valueState.greenEnergyMix + valueState?.inertGas) / (co2TotalToday) * 100).toFixed(2)),
                    },
                    {
                        color: "#07BEB8",
                        title: valueState.transport ? ((parseFloat(valueState.transport) / parseFloat(co2TotalToday) * 100).toFixed(2) + "%") : "",
                        value: parseFloat(((valueState.transport) / (co2TotalToday) * 100).toFixed(2)),
                    },
                ]
            )
        }
    }, [co2TotalToday, valueState.co2Powder, valueState.transport, valueState.greenEnergyMix, valueState.inertGas])

    useEffect(() => {
        if (
            powderAtomization &&
            volumePArtReduction &&
            co2ePowder &&
            greenenergymix &&
            inertGasReduction
        ) {

            setValueState((prevState) => {
                return {
                    ...prevState,
                    powder: parseFloat(powderAtomization.today),
                    volume: parseFloat(volumePArtReduction.today),
                    co2Powder: parseFloat(co2ePowder.today),
                    greenEnergyMix: parseFloat(greenenergymix.today),
                    inertGas: parseFloat(inertGasReduction.today),
                    transport: parseFloat(co2Transportation)
                }
            })
        }
        const parent = document.getElementById("m2");
        const firstchild = parent.firstChild;
        firstchild.setAttribute("viewBox", "-25 -12 150 125");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        powderAtomization,
        volumePArtReduction,
        co2ePowder,
        greenenergymix,
        inertGasReduction,
    ]);

    const SaveScenario = async () => {
        toast.info("Please Wait...");
        const formFields = {
            "technology_id": fieldObj?.technology_id,
            "step_type_id": fieldObj?.step_type_id,
            "technology_name": fieldObj?.technology_name,
            "machine_name": fieldObj?.machine_name,
            "material_name": fieldObj?.material_name,
            "material_id": fieldObj?.material_id,
            "machine_id": fieldObj?.machine_id,
            "co2EmissionPowder": fieldObj?.co2EmissionPowder,
            "PowderEnergyPartPM": fieldObj?.PowderEnergyPartPM,
            "co2eEmissionFactor": fieldObj?.co2eEmissionFactor,
            "materialTransportaion": fieldObj?.materialTransportaion,
            "systemTransportation": fieldObj?.systemTransportation,
            "cefnone": fieldObj?.cefnone,
            "cefnTwo": fieldObj?.cefnTwo,
            "co2EmissionBuildProcess": fieldObj?.co2EmissionBuildProcess,
            "co2EmissionGas": fieldObj?.co2EmissionGas,
            "co2Transportation": co2Transportation,
            "costData": fieldObj?.costData,
            "powderAtomizationInput": powderAtomizationInput,
            "volumerPartReductionStateInput": volumerPartReductionStateInput,
            "CCMetalCo2ePerPartPMData": fieldObj?.CCMetalCo2ePerPartPMData,
            "greenEnergymixStateInput": greenEnergymixStateInput,
            "modeOfTranspotation1Input": modeOfTranspotation1Input,
            "modeOfTranspotation2Input": modeOfTranspotation2Input
        }

        const data = { scenario_data: JSON.stringify(formFields), scenario_name: scenarioName }
        try {
            const response = await axios.post(
                `${config.serviceUrl}client/scenario/save`,
                data,
                {
                    headers: {
                        Authorization: access_token,
                    },
                }
            );
            if (response.data) {
                toast.success("Scenario saved Successfully");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter scenario name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-section">
                        <DebounceInput
                            type="text"
                            className="user-input"
                            debounceTimeout={500}
                            onChange={(e) => {
                                setScenarioName(e.target.value);
                            }}
                            onWheel={(e) => e.target.blur()}
                            placeholder="Please enter scenario name"
                            value={scenarioName}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <button
                            className="selection-button step-button w-50 m-0"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <Button
                            disabled={scenarioName.length === 0}
                            className="selection-button step-button w-50 mt-0"
                            onClick={() => {
                                if (scenarioName.length > 0) {
                                    SaveScenario();
                                    handleClose();
                                }
                            }}
                        >
                            Save Scenario
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <button
                onClick={handleShow}
                id="save-scenario"
                className="btn-position selection-button step-button d-flex justify-content-center"
            >
                <Tippy
                    content={
                        <span>
                            You can view your saved scenario by clicking My Scenarios Tab at
                            header.
                        </span>
                    }
                >
                    <span>Save My Scenario</span>
                </Tippy>
            </button>
            <div className="row  ">
                <div className="col page-container px-0">
                    <div className="ccsteps-container">
                        <div className="powder-container">
                            <div className="powder-table-top">
                                <p className="powder-heading">Scenario Analysis</p>
                            </div>
                            <div className="d-flex flex-row p-3">
                                <MaterialTable Socket={socket} />
                                <MaterialTableValues
                                    fieldObj={fieldObj}
                                    socket={socket}
                                />
                            </div>
                            <div className="powder-table-top total-bottom ">
                                <p className="powder-heading " style={{ textTransform: "none" }}>Total (kg)</p>
                                <div className="d-flex ">
                                    <p className="powder-heading pl-3">{co2TotalToday}</p>
                                    <p className="powder-heading pr-4 pl-5 ">{co2TotalTomorrow}</p>
                                </div>
                            </div>
                        </div>

                        <div className="graph-section width--mod mt-0">
                            <div className="text-center">
                                <p className="t-2">CO2e in % of kg per part</p>
                                <p className="s-1 mb-0">Current Scenario</p>
                            </div>
                            <div
                                id="m2"
                                className="w-100 m-0 h-100 d-flex justify-content-center align-items-center"
                            >
                                <PieChart
                                    animationDuration={500}
                                    animationEasing="ease-out"
                                    // center={[50, 50]}
                                    width=" 300px"
                                    height="300px"
                                    data={dataSet}
                                    labelPosition={110}
                                    lengthAngle={360}
                                    lineWidth={40}
                                    paddingAngle={2}
                                    radius={PieChart.defaultProps.radius - shiftSize}
                                    startAngle={0}
                                    label={({ dataEntry }) => dataEntry.title}
                                    // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

                                    labelStyle={{
                                        fill: "black",
                                        strokeWidth: "10",
                                        fontSize: "5px",
                                        fontFamily: "Open Sans, sans-serif",
                                        fontWeight: "600",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center pt-5">
                            <div className="d-flex">
                                <div className="color-icon bg-color5" />
                                <p className="color-rep-value">CO2e Powder</p>
                            </div>
                            <div className="d-flex">
                                <div className="color-icon bg-color7" />
                                <p className="color-rep-value ">CO2e Energy</p>
                            </div>
                            <div className="d-flex">
                                <div className="color-icon bg-color1" />
                                <p className="color-rep-value m-0">CO2e Logistics</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <button onClick={handleShow} className="selection-button step-button">
                                <Tippy
                                    content={
                                        <span>
                                            You can view your saved scenario by clicking My Scenarios Tab at
                                            header.
                                        </span>
                                    }
                                >
                                    <span>Save My Scenario</span>
                                </Tippy>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CCScenarioMetal