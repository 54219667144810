import React from 'react'

const Co2InReports = ({ costOfCo2pp, currency }) => {
    return (
        <>
            {
                costOfCo2pp &&
                <>
                    <i className="pl-4 pr-4">
                        <hr />
                    </i>
                    <div className="d-flex justify-content-between pl-4 pr-4">
                        <span>
                            <p className="input-heading">CO2e per part</p>
                        </span>
                        <span>
                            <p className="output-heading">
                                {costOfCo2pp ? (
                                    <>{`${costOfCo2pp} ${currency}`}</>
                                ) : (
                                    "Not Available"
                                )}{" "}
                            </p>
                        </span>
                    </div>
                </>
            }
        </>
    )
}

export default Co2InReports