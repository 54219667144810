import React, { useState } from "react";
import Navbar from "../components/Nav.jsx";
import gql from 'graphql-tag';
import { useApolloClient} from '@apollo/react-hooks';
import { ToastContainer, toast } from 'react-toastify';

const AUTH_USER = gql`
query ClientForgetPassword($email: String! ){
    clientForgetPassword(email:$email)
}
`;
const Reset = () => {
    const [email, setEmail] = useState(''); // '' is the initial state for email value
    const client = useApolloClient();
    const [Loading ,setLoading ] =useState(false);
    //hooks for dealing errors
    const [emailError, setEmailError] = useState(''); 
    const emailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
    
    const validate = () => {
        let emailError = "";
        if(email.length === 0 ){
          emailError= "Email is required"
        }else if(emailRegex.test(email) !== true){
          emailError="Email Is Invalid"
        }else{
          emailError="";
        }
        
        if (emailError) {
          setEmailError(emailError);
          return false;
        }else{
          return true;
        }
      }
      //
      const sendResetEmail = async () => {
        const isValid = validate();
        if (isValid) {
          setLoading(true);
          setEmailError("");
            await client.query({
              query: AUTH_USER,
              variables: { email }
            }).then(res =>{
              sessionStorage.setItem('email', email);
              setEmail('');
              toast.success('Please Check Your Email and Click on the link')
              window.location.href = `/request-reset`
            }).catch((error) =>{
                toast.error(error.graphQLErrors[0].extensions.exception.response.error)
             }
            );
          }
          setLoading(false);
        }
    return ( 
            <React.Fragment>
                <ToastContainer/>
                <Navbar />
                <div className="row fill">
                    <div className="col-lg-6 login-container">
                        <div>
                            <p className="login-text">Enter email in order to send a forget 
                                password request. 
                            </p>
                            <p className="login-text">EMAIL</p>
                            <div className="text-box1">
                                <input type="email" className="login-input" value={email} onChange={(evt) => { 
                                  setEmailError('')
                                  setEmail(evt.target.value); }}
                                  
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      sendResetEmail();        
                                    }
                                  }}/>
                            </div>
                            <p className="errorMessage float-left">{emailError}</p>
                            <button className="button1" onClick={sendResetEmail} disabled={Boolean(Loading)}>
                            {Loading?  <><i class="fa fa-spinner fa-spin mr-2"></i><p>Loading</p></> : <p>Submit Request</p>}
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 coverimage image2"></div>
                </div>
        </React.Fragment>
    )
}

export default Reset;