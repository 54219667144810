import React, { useState } from "react";
import Navbar from "../components/Nav.jsx";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ToastContainer, toast } from 'react-toastify';

const AUTH_USER = gql`
    mutation ClientChangePassword($oldPassword: String! , $newPassword: String! ){
        clientChangePassword(oldPassword:$oldPassword,newPassword:$newPassword)
    }
`;

const ResetReq =()=> {
    const [oldPasswordError, setOldPasswordError] = useState(''); 
    const [passwordError, setPasswordError] = useState(''); 
    const [conformPasswordError, setConformPasswordError] = useState('');
    const [Loading ,setLoading ] =useState(false);
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30})");
    const [oldPassword, setOldPassword] = useState(''); 
    const [confirmPassword, setConfirmPassword] = useState(''); // '' is the initial state for email value
    const [newPassword, setPassword] = useState(''); // '' is the initial state for password value
    const [toggleMutation ] = useMutation(AUTH_USER);
    sessionStorage.setItem('reportStatus' , 'false');

    //check validation
    const validate = () => {
        let passError = "";
        let oldpassError = "";
        let conformPassError ="";
          if(oldPassword.length === 0){
            oldpassError="Old Password is required";
          }else{
            oldpassError="";
          }
          if(newPassword.length === 0 ){
            passError="Password is required";
          }else if(passwordRegex.test(newPassword) !== true){
            passError="Password should contain 1 uppercase, 1 lower case, 1 special character, 1 number"
          }else{
            passError="";
          }
          if(newPassword !== confirmPassword){
            conformPassError="Password and Confirm Password don't match";
          }else{
            conformPassError="";
          }
          if ( passError || oldpassError || conformPassError) {
            setPasswordError(passError);
            setConformPasswordError(conformPassError);
            setOldPasswordError(oldpassError)
            return false;
          }else{
            return true;
          }
        }
      //resetpassword code
      const resetPassword =  () => {
        const isValid = validate();
        if (isValid) {
          setLoading(true);
          setPasswordError("");
          setConformPasswordError("");
          toggleMutation({
            variables: { oldPassword ,newPassword },
            optimisticResponse: true,
          }).then(res => {
            toast.success("Password Updated Successfully");
          }).then(() =>{
            window.location.href = `/region-select`
          })
          .catch(error =>{
            if(error.graphQLErrors[0].extensions.exception.response.error === "Unauthorized"){
              toast.error(error.graphQLErrors[0].extensions.exception.response.message)
            }else{
              toast.error(error.graphQLErrors[0].extensions.exception.response.error)
            }
          });            
           setPassword('');
          setConfirmPassword('');
          setOldPassword('');
        }
        setLoading(false);
      };
      const nextKey = (next)=> {
        document.getElementById(next).focus();
      }
    return ( 
            <React.Fragment>
                <ToastContainer/>
                <Navbar />
                <div className="row fill">
                <div className="col-lg-6 login-container">
                    <div>
                        <p className="t-1">Update Password
                        </p>
                        <p className="login-text">OLD PASSWORD</p>
                        <div className="text-box1">
                            <input type="password" className="login-input" value={oldPassword} onChange={(evt) => { 
                                  setOldPasswordError('')
                                  setOldPassword(evt.target.value);}}
                                  
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      nextKey('pass-value');        
                                    }
                                  }}/>  
                        </div>
                        <p className="errorMessage ">{oldPasswordError}</p>                        
                        <p className="login-text">NEW PASSWORD</p>
                        <div className="text-box1">
                            <input type="password" id="pass-value" className="login-input" value={newPassword} onChange={(evt) => { 
                                  setPasswordError('')
                                  setPassword(evt.target.value);}}
                                  
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      nextKey('confirmPass-value');        
                                    }
                                  }}/>
                        </div>
                        <p className="errorMessage ">{passwordError}</p>
                        <p className="login-text">CONFIRM PASSWORD</p>
                        <div className="text-box1">
                            <input type="password"  id="confirmPass-value" className="login-input" value={confirmPassword} onChange={(evt) => { 
                                  setConformPasswordError('')
                                  setConfirmPassword(evt.target.value);}}
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      resetPassword();        
                                    }
                                  }}/>
                        </div>
                        <p className="errorMessage ">{conformPasswordError}</p>
                        <button className="button1" onClick={resetPassword} disabled={Boolean(Loading)}>
                            {Loading?  <><i class="fa fa-spinner fa-spin mr-2"></i><p>Loading</p></> : <p>Update Password</p>}
                        </button>
                    </div>
                </div>
                <div className="col-lg-6 coverimage image4"></div>
            </div>
        </React.Fragment>
    )
}

export default ResetReq;