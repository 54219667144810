import React, { useEffect, } from "react";
import config from '../../../../../config';
import { useRecoilState } from "recoil";
import { HotspotArray } from "atoms/General";
import ReactHtmlParser from 'react-html-parser';

const axios = require("axios").default;
const ReportsTwo = ({ highestindex }) => {
  const pageSize = 3
  const access_token = sessionStorage.getItem("access_token");
  const [hotspotArray, setHotSpotArray] = useRecoilState(HotspotArray)
  const getHotSpotAnalysis = async (pageNo) => {
    try {
      const response = await axios.default.get(`${config.serviceUrl}hotspot/analysis/user/list`, {
        params: {
          pageSize: pageSize,
          pageNo: pageNo,
          technology_id: 1
        },
        headers: {
          Authorization: access_token,

        }
      })

      setHotSpotArray(response.data.data)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getHotSpotAnalysis(0)
  }, [])
  // console.log("hotspot array", hotspotArray); Todo check response from api

  return (
    <div className="reports-section" >
      <div className="responsive-scroll borderForTable" >
        <div className="divTableReport">
          <div className="divTableBody">
            <div className="divTableRowTop">

              {
                hotspotArray.map((data, index) => {
                  return (
                    <div key={index} className={data.heading.includes(highestindex) && highestindex !== "" ? "divTableCellReportTop textAlignLeft hotspotBg" : "divTableCellReportTop textAlignLeft "}>{data.heading}</div>

                  )
                })
              }
            </div>
            <div className="divTableRow borders-table" >

              {
                hotspotArray.map((data, index) => {
                  return (
                    <div key={index} className="divTableCellReport report-two-text ">
                      {ReactHtmlParser(data.description)}
                    </div>
                  )
                })
              }
            </div>


          </div>
        </div>
      </div>

    </div>
  );
};

export default ReportsTwo;
