import { atom } from "recoil"

export const TotalCarbonPerPartDataM = atom({
    key: "TotalCarbonPerPartDataM",
    default: undefined
})
export const CostOfOneKGCo2DataM = atom({
    key: "CostOfOneKGCo2DataM",
    default: undefined
})
export const CostOfCo2PerPartFDataM = atom({
    key: "CostOfCo2PerPartFDataM",
    default: undefined
})
export const CppWithCo2DataM = atom({
    key: "CppWithCo2DataM",
    default: undefined
})