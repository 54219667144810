import React from "react";

const CalcStepGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Calculator Step Navigation</h3>
            <p>The cost calculator wizard will guide you through different steps of calculations. All the steps are shown in the top navigation bar. </p>
            <img src="/assets/step-nav-guide-1.png" alt="" className="img-fluid" />
            <p>You can hover over a step to get an overview of what it means. </p>
            <img src="/assets/step-nav-guide-2.png" alt="" className="img-fluid" />
            <p>Use next and back buttons for navigation through the calculator</p>
            <img src="/assets/step-nav-guide-3.png" alt="" className="img-fluid" />
            <p>You can also use top bar to navigate between steps but only once you have added information
                in all required fields. This means on each new step, the previous step is available via the top
                bar </p>
            <img src="/assets/step-nav-guide-4.png" alt="" className="img-fluid" />
        </>
    )
}

export default CalcStepGuide;