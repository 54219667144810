import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "../Scenario/components/MaterialTable";
import MaterialTableValues from "./components/MaterialTableValues";

import { useRecoilValue } from "recoil";
import PdfGenerator from "./components/Pdfgenerator";
import { GET_CLIENT_EMAILS } from "../Process/components/getStepDetail";
import { useApolloClient } from "@apollo/react-hooks";
import {
  Co2TotalTodaySData,
  Co2TotalTomorrowSData,
  VolumeReductionData,
  EnergyMaterialData,
  EnergySystemData,
  Co2ePowderScenarioData,
  Co2eEnergyData,
  PowderData,
  VolumeReductionInput,
  CnpInput,
  EnergPercentageInput,
  GreenPowderEnergyInput,
  RefreshRateScenarioInput,
  RefreshRateScenario
} from "atoms/Co2Polymer/ScenarioPolymer";
import {
  CPolyModeOfTransportNode1,
  CPolyModeOfTransportNode2,
  CPolyModeOfTranspotation1,
  CPolyModeOfTranspotation2,
  TotalTraspotation,
} from "atoms/Co2Polymer/ScenarioPolymer";
import {
  MachineNameData,
  MaterialNameData,
} from "atoms/Co2Polymer/MachineDetailsAtoms";
import {
  Co2ePerJobPMData,
  Co2ePerPartPMData,
} from "atoms/Co2Polymer/PowderMfgAtoms";
import {
  Co2ePerJobMPData,
  Co2ePerPartMPData,
} from "atoms/Co2Polymer/MaterialProcessingAtoms";
import {
  Co2ePerJobAPData,
  Co2ePerPartAPData,
} from "atoms/Co2Polymer/AMProcessAtoms";
import {
  Co2ePerPartTPData,
  Co2ePerYearData,
} from "atoms/Co2Polymer/TransportationAtoms";
import { HotspotArray } from "atoms/General";

import { useRef } from "react";
import ReportsTwo from "../ReportsTwo";
import Dropdown from "react-bootstrap/Dropdown";
import { DebounceInput } from "react-debounce-input";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import ConsultingTeam from "app/components/ConsultingTeam";

const Reports = ({ ScenarioState, socket }) => {
  const shiftSize = 7;
  const cPowder = useRecoilValue(PowderData);
  const co2TotalToday = useRecoilValue(Co2TotalTodaySData);
  const co2TotalTomorrow = useRecoilValue(Co2TotalTomorrowSData);
  const volumeReduction = useRecoilValue(VolumeReductionData);
  const energyMaterial = useRecoilValue(EnergyMaterialData);
  const Powder = useRecoilValue(Co2ePowderScenarioData);
  const Energy = useRecoilValue(Co2eEnergyData);
  const EnergySystem = useRecoilValue(EnergySystemData);
  const [powder, setPowder] = useState();
  const [co2ePowder, setCo2ePowder] = useState();
  const [co2eEnergy, setCo2eEnergy] = useState();
  const [EnergyMaterial, setEnergyMaterial] = useState();
  const [energySystem, setEnergySystem] = useState();
  const [VolumeReduction, setVolumeReduction] = useState();
  const [highestIndexHotspot, setHighestIndexHotspot] = useState();
  const co2TotalTransportation = useRecoilValue(Co2ePerPartTPData);
  const [donutImage, setDonutImage] = useState();
  const [typedocument, setTypeDocument] = useState();
  const MachineNameMD = useRecoilValue(MachineNameData);
  const MaterialNameMD = useRecoilValue(MaterialNameData);
  const co2PerJobPMFG = useRecoilValue(Co2ePerJobPMData);
  const co2PerPartPMFG = useRecoilValue(Co2ePerPartPMData);
  const co2PerJobMP = useRecoilValue(Co2ePerJobMPData);
  const co2PerPartMP = useRecoilValue(Co2ePerPartMPData);
  const co2PerJobAMP = useRecoilValue(Co2ePerJobAPData);
  const co2PerPartAMP = useRecoilValue(Co2ePerPartAPData);
  const co2PerYearTR = useRecoilValue(Co2ePerYearData);
  const co2PerPartTR = useRecoilValue(Co2ePerPartTPData);
  const cnpInput = useRecoilValue(CnpInput);
  const volumeReductionInput = useRecoilValue(VolumeReductionInput);
  const energyPercentageInput = useRecoilValue(EnergPercentageInput);
  const greenPowderEnergyInput = useRecoilValue(GreenPowderEnergyInput);
  const co2Transportaion = useRecoilValue(TotalTraspotation);
  const ModeOfTransportNode1 = useRecoilValue(CPolyModeOfTransportNode1);
  const ModeOfTransportNode2 = useRecoilValue(CPolyModeOfTransportNode2);
  const ModeOfTranspotation1 = useRecoilValue(CPolyModeOfTranspotation1);
  const ModeOfTranspotation2 = useRecoilValue(CPolyModeOfTranspotation2);
  const refreshRatePercentage = useRecoilValue(RefreshRateScenarioInput);
  const refreshRateScenario = useRecoilValue(RefreshRateScenario);
  const HotsspotArray = useRecoilValue(HotspotArray);

  const pdfRef = useRef(null);
  const [savePdf, setSavePdf] = useState(false);
  const [show, setShow] = useState(false);
  const [hotSpotArray, setHotSpotArray] = useState([]);

  const [pdfName, setPdfName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const client = useApolloClient();
  const [clientSteps, setClientSteps] = useState([]);
  const [email, setEmail] = useState();
  const groupid = parseInt(sessionStorage.getItem("group_id"));
  const region = sessionStorage.getItem("currency");
  const DataSet = [
    {
      color: "#5DC0D3",
      title: co2ePowder
        ? ((co2ePowder / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2ePowder / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#5FCCE1",
      title: co2eEnergy
        ? ((co2eEnergy / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2eEnergy / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#4EA0B0",
      title: co2Transportaion
        ? ((co2Transportaion?.today / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(
        ((co2Transportaion?.today / co2TotalToday) * 100).toFixed(2)
      ),
    },
  ];
  const initialDocumentState = () => {
    setTypeDocument(undefined);
    setPdfName("")
    setSavePdf(false)
  }

  const formObj = {
    powderdata: {
      value: cPowder,
      name: "Powderco2",
    },
    volumereductions: {
      value: volumeReduction,
      name: "volumereduction",
    },
    refreshRateScenario: {
      value: refreshRateScenario,
      name: "refreshRateScenario",
    },
    co2epowder: {
      value: Powder,
      name: "co2epowder",
    },
    energymaterial: {
      value: energyMaterial,
      name: "energymaterial",
    },
    energysystem: {
      value: EnergySystem,
      name: "EnergySystem",
    },
    co2eenergy: {
      value: Energy,
      name: "co2eenergy",
    },
    co2totaltransportation: {
      value: co2TotalTransportation,
      name: "co2totaltransportation",
    },
    co2totaltoday: {
      value: co2TotalToday,
      name: "co2totaltoday",
    },
    co2totaltomorrow: {
      value: co2TotalTomorrow,
      name: "co2totalToco2TotalTomorrow",
    },
    machinename: {
      value: MachineNameMD,
      name: "Machine Name",
    },
    materialname: {
      value: MaterialNameMD,
      name: "Machine Name",
    },
    co2perjobpmfg: {
      value: co2PerJobPMFG,
      name: "co2perjobpmfg",
    },
    co2perpartpmfg: {
      value: co2PerPartPMFG,
      name: "co2perjobpmfg",
    },
    co2perpartmp: {
      value: co2PerPartMP,
      name: "co2perpartmp",
    },
    co2perjobamp: {
      value: co2PerJobAMP,
      name: "co2perjobamp",
    },
    co2perpartamp: {
      value: co2PerPartAMP,
      name: "co2perpartamp",
    },
    co2peryeartr: {
      value: co2PerYearTR,
      name: "co2peryeartr",
    },
    co2perparttr: {
      value: co2PerPartTR,
      name: "co2perparttr",
    },
    cnpInput: {
      value: cnpInput,
      name: "cnpinput",
    },
    volumeReductionInput: {
      value: volumeReductionInput,
      name: "volumeReductionInput",
    },
    refreshRatePercentage: {
      value: refreshRatePercentage,
      name: "refreshRatePercentage",
    },
    energyPercentageInput: {
      value: energyPercentageInput,
      name: "energyPercentageInput",
    },
    greenPowderEnergyInput: {
      value: greenPowderEnergyInput,
      name: "greenPowderEnergyInput",
    },
    co2Transportaion: {
      value: co2Transportaion,
      name: co2Transportaion,
    },
    ModeOfTransportNode1: {
      value: ModeOfTransportNode1,
      name: ModeOfTransportNode1,
    },
    ModeOfTransportNode2: {
      value: ModeOfTransportNode2,
      name: ModeOfTransportNode2,
    },
    ModeOfTranspotation1: {
      value: ModeOfTranspotation1,
      name: ModeOfTranspotation1,
    },
    ModeOfTranspotation2: {
      value: ModeOfTranspotation2,
      name: ModeOfTranspotation2,
    },
  };

  useEffect(() => {
    client
      .query({
        query: GET_CLIENT_EMAILS,
        variables: { group_id: groupid },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getRegionsByUserGroup);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    for (let index = 0; index < clientSteps.length; index++) {
      if (clientSteps[index].name === region) {
        setEmail(clientSteps[index].email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSteps]);

  useEffect(() => {
    if (
      cPowder !== undefined &&
      Powder !== undefined &&
      Energy !== undefined &&
      energyMaterial !== undefined &&
      co2TotalTransportation !== undefined &&
      EnergySystem !== undefined
    ) {
      setPowder(parseFloat(cPowder?.today));
      setVolumeReduction(parseFloat(volumeReduction?.today));
      setCo2ePowder(parseFloat(Powder?.today));
      setCo2eEnergy(parseFloat(Energy?.today));
      setEnergyMaterial(parseFloat(energyMaterial?.today));
      setEnergySystem(parseFloat(EnergySystem?.today));
      hotSpotArray.push(
        {
          "CO2e Powder": Powder?.today,
          "CO2e Energy": Energy?.today,
          "CO2e Logistics": co2TotalTransportation
        }
      );
      setHotSpotArray(hotSpotArray);
    }
    const parent = document.getElementById("m2");
    const firstchild = parent.firstChild;
    firstchild.setAttribute("viewBox", "-25 -12 150 125");
    setTimeout(() => {

      console.log(firstchild);
      const s = new XMLSerializer().serializeToString(firstchild);
      const encodedData = window.btoa(s);
      console.log(encodedData);
      console.log("data:image/svg+xml;base64," + encodedData);
      setDonutImage("data:image/svg+xml;base64," + encodedData);
      const canva = document.createElement("canvas");
      canva.width = 600;
      canva.height = 600;
      var ctx = canva.getContext("2d");
      const Imgsrc = "data:image/svg+xml;base64," + encodedData;
      const Img = new Image();
      Img.src = Imgsrc;
      Img.onload = function () {
        ctx.drawImage(Img, 0, 0);
        var pngsrc = canva.toDataURL("image/png");

        setDonutImage(pngsrc);
      };


    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cPowder,
    Powder,
    Energy,
    energyMaterial,
    co2TotalTransportation,
    EnergySystem,
  ]);

  useEffect(() => {
    if (hotSpotArray.length !== 0) {


      let hightlightKey = ""
      hotSpotArray.forEach(item => hightlightKey = Object.keys(item).reduce((prevValue, currValue) => item[prevValue] > item[currValue] ? prevValue : currValue))

      setHighestIndexHotspot(hightlightKey);
    }
  }, [hotSpotArray]);


  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {typedocument === 0 ? "Enter pdf name" : "Enter word doc name"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setPdfName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter name"
              value={pdfName}
            />
            {/* <p className="edit-icon value-unit">kg</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="selection-button step-button w-50 m-0"
              onClick={handleClose}
            >
              Close
            </button>
            <Button
              disabled={pdfName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                setSavePdf(true);
                handleClose();
              }}
            >
              Download
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div
        id="check"
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        ref={pdfRef}
      >
        <div className="reports-section">
          <div className="reports-detail-section">
            <Row className="m-0">
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">Machine Details</p>
                  <div className="data-field">Machine:
                    <span className="black-color">&nbsp;{MachineNameMD}</span> </div>
                  <div className="data-field">Powder:
                    <span className="black-color">&nbsp;{MaterialNameMD}</span> </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">
                    Machine Manufacturing (N/A)
                  </p>
                  <div className="data-field">currently not available</div>
                  <div className="data-field">currently not available</div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">
                    Powder Manufacturing
                  </p>
                  <div className="data-field">CO2e per job:
                    <span className="black-color"> &nbsp;{co2PerJobPMFG} (kg)</span>
                  </div>
                  <div className="data-field">CO2e per part:
                    <span className="black-color"> &nbsp;{co2PerPartPMFG} (kg)</span></div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">Powder Processing </p>
                  <div className="data-field">CO2e per job: <span className="black-color">&nbsp; {co2PerJobMP} (kg)</span></div>
                  <div className="data-field">CO2e per part:  <span className="black-color">&nbsp; {co2PerPartMP} (kg)</span></div>
                </div>
              </Col>
            </Row>
            <Row className="m-0 report-detail-inner-spacing">
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">Additive Manufacturing Process</p>
                  <div className="data-field">CO2e per job:
                    <span className="black-color">&nbsp; {co2PerJobAMP} (kg)</span></div>
                  <div className="data-field">CO2e per part:
                    <span className="black-color"> &nbsp;{co2PerPartAMP} (kg)</span> </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">
                    Logistics Processing
                  </p>
                  <div className="data-field">CO2e per year: <span className="black-color">&nbsp; {co2PerYearTR} (kg)</span></div>
                  <div className="data-field">CO2e per part: <span className="black-color">&nbsp; {co2PerPartTR} (kg)</span></div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">
                    Post Processing (N/A)
                  </p>
                  <div className="data-field">currently not available</div>
                  <div className="data-field">currently not available</div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="report-detailt-card">
                  <p className="input-heading capitalize">
                    Used Powder - End of Life (N/A)
                  </p>
                  <div className="data-field">currently not available</div>
                  <div className="data-field">currently not available</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="powder-container">
          <div className="powder-table-top">
            <p className="powder-heading">Scenario Analysis</p>
          </div>
          <div className="d-flex flex-row p-3">
            <MaterialTable Socket={socket} />
            <MaterialTableValues Socket={socket} />
          </div>
          <div className="powder-table-top total-bottom">
            <p className="powder-heading">
              Total <abbr className="text-lowercase">(kg)</abbr>
            </p>
            <div className="d-flex pr-3">
              <p className="powder-heading pl-0 pr-5">{co2TotalToday}</p>
              <p className="powder-heading pr-0 pl-4">{co2TotalTomorrow}</p>
            </div>
          </div>
        </div>


        <div className="graph-section width--mod">
          <div className="text-center">
            <p className="t-2">CO2e in % of kg per part</p>
            <p className="s-1 mb-0">Current Scenario</p>
          </div>
          <div
            id="m2"
            className="w-100 h-100 d-flex justify-content-center align-items-center"
          >

            <PieChart
              animationDuration={500}
              animationEasing="ease-out"
              width=" 300px"
              height="300px"
              data={DataSet}
              labelPosition={110}
              lengthAngle={360}
              lineWidth={40}
              paddingAngle={2}
              radius={PieChart.defaultProps.radius - shiftSize}
              startAngle={0}
              label={({ dataEntry }) => dataEntry.title}
              // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

              labelStyle={{
                fill: "black",
                strokeWidth: "10",
                fontSize: "5px",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "600",
              }}
            />

          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center pt-5">
          <div className="d-flex">
            <div className="color-icon bg-color10" />
            <p className="color-rep-value">CO2e Powder</p>
          </div>
          <div className="d-flex">
            <div className="color-icon tomorrowBg4 " />
            <p className="color-rep-value ">CO2e Energy</p>
          </div>
          <div className="d-flex">
            <div className="color-icon bg-color8 " />
            <p className="color-rep-value m-0">CO2e Logistics</p>
          </div>
        </div>
        <ReportsTwo highestindex={highestIndexHotspot} />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <ConsultingTeam navigate={email} />
        <Dropdown>
          {/* <Button
            className="selection-button step-button"
            id="dropdown-basic"
            onClick={() => {
              setTypeDocument(0);
              handleShow();
            }}
          >
            Download Report
          </Button> */}
          <Dropdown.Toggle
            className="selection-button step-button"
            id="dropdown-basic"
          >
            Download Report
          </Dropdown.Toggle>

          {/* TODO */}

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setTypeDocument(0);
                handleShow();
              }}
            >
              As Pdf
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setTypeDocument(1);
                handleShow();
              }}
            >
              As Word
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {savePdf && (
        <PdfGenerator
          formFields={formObj}
          pdfName={pdfName}
          donutImage={donutImage}
          typeDocument={typedocument}
          HotsspotArray={HotsspotArray}
          highestIndex={highestIndexHotspot}
          initialDocumentState={initialDocumentState}
        />
      )}
    </>
  );
};

export default Reports;
