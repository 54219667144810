import { atom } from "recoil";

export const CCMetalPowderLossDataM = atom({
  key: "CCMetalPowderLossDataM",
  default: undefined,
});
export const CCMetalPowderTypeDataM = atom({
  key: "CCMetalPowderTypeDataM",
  default: 'GAS_ATOMIZED'
});

export const CCMetalCo2ePerJobPMDataM = atom({
  key: "CCMetalCo2ePerJobPMDataM",
  default: undefined,
});
export const CCMetalCo2ePerPartPMDataM = atom({
  key: "CCMetalCo2ePerPartPMDataM",
  default: undefined,
});
