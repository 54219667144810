import React from "react";

const ReportGenGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Report Generation</h3>
            <p>Report Generation can be accessed from two point in the calculator. </p>
            <ol>
                <li>From the calculator last step</li>
                <li>From visual analytics page</li>
            </ol>
            <img src="/assets/report-gen-guide-1.png" alt="" className="img-fluid" />
            <img src="/assets/report-gen-guide-2.png" alt="" className="img-fluid" />
            <p>Once the report generation screen opens up you have to add data to allow for final calculations and also add a desired image of the parts. </p>
            <img src="/assets/report-gen-guide-3.png" alt="" className="img-fluid" />
            <p>In order to download the report use the download button and choose a format to download the report in. </p>
            <img src="/assets/report-gen-guide-4.png" alt="" className="img-fluid" />
            
        </>
    )
}

export default ReportGenGuide;