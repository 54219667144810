import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "./components/MaterialTable";
import MaterialTableValues from "./components/MaterialTableValues";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  Co2TotalTodaySData,
  Co2TotalTomorrowSData,
  VolumeReductionData,
  EnergyMaterialData,
  EnergySystemData,
  Co2ePowderScenarioData,
  Co2eEnergyData,
  PowderData,
  VolumeReductionInput,
  EnergPercentageInput,
  GreenPowderEnergyInput,
  CnpInput,
  RefreshRateScenarioInput
} from "atoms/Co2Polymer/ScenarioPolymer";
import { TotalTraspotation } from "atoms/Co2Polymer/ScenarioPolymer";
import {
  MachineNameData,
  MaterialNameData,
} from "atoms/Co2Polymer/MachineDetailsAtoms";
import { Co2ePerPartPMData } from "atoms/Co2Polymer/PowderMfgAtoms";
import { Co2ePerPartMPData } from "atoms/Co2Polymer/MaterialProcessingAtoms";
import { Co2ePerPartAPData } from "atoms/Co2Polymer/AMProcessAtoms";
import {
  MaterialTransportationData,
  SystemTransportationData,
  CarbonEmissionFactorNode1Data,
  CarbonEmissionFactorNode2Data,
} from "atoms/Co2Polymer/TransportationAtoms";
import {
  CPolyModeOfTranspotation1,
  CPolyModeOfTranspotation2,
} from "atoms/Co2Polymer/ScenarioPolymer";
import { BuildTimeDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { Co2ePerPartTPData } from "atoms/Co2Polymer/TransportationAtoms";
import config from "../../../../../config";
import { ToastContainer, toast } from "react-toastify";
import {
  technologyId,
  stepTypeId,
  technologyType,
} from "atoms/General/index.js";
import {
  MachineData,
  MaterialData,
  PartVolumeData,
  BuildHeightData,
  NoOfPartsData
} from "atoms/Co2Polymer/MachineDetailsAtoms";
import { Modal, Button } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { ProcessFlag } from "atoms/General";


const axios = require("axios").default;

const Scenario = ({ ScenarioState, socket }) => {
  const shiftSize = 7;
  const co2TotalToday = useRecoilValue(Co2TotalTodaySData);
  const co2TotalTomorrow = useRecoilValue(Co2TotalTomorrowSData);
  const cPowder = useRecoilValue(PowderData);
  const volumeReduction = useRecoilValue(VolumeReductionData);
  const energyMaterial = useRecoilValue(EnergyMaterialData);
  const Powder = useRecoilValue(Co2ePowderScenarioData);
  const Energy = useRecoilValue(Co2eEnergyData);
  const EnergySystem = useRecoilValue(EnergySystemData);
  const [powder, setPowder] = useState();
  const [co2ePowder, setCo2ePowder] = useState();
  const [co2eEnergy, setCo2eEnergy] = useState();
  const [EnergyMaterial, setEnergyMaterial] = useState();
  const [energySystem, setEnergySystem] = useState();
  const [VolumeReduction, setVolumeReduction] = useState();
  const co2TotalTransportation = useRecoilValue(Co2ePerPartTPData);
  const machinename = useRecoilValue(MachineNameData);
  const materialname = useRecoilValue(MaterialNameData);
  const techId = useRecoilValue(technologyId);
  const stepId = useRecoilValue(stepTypeId);
  const techName = useRecoilValue(technologyType);
  const machineId = useRecoilValue(MachineData);
  const materialId = useRecoilValue(MaterialData);
  const [show, setShow] = useState(false);
  const [scenarioName, setScenarioName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const modeOfTranspotation1 = useRecoilValue(CPolyModeOfTranspotation1)
  const modeOfTranspotation2 = useRecoilValue(CPolyModeOfTranspotation2)
  const cnpInput = useRecoilValue(CnpInput);
  const volumeReductionInput = useRecoilValue(VolumeReductionInput);
  const energyPercentageInput = useRecoilValue(EnergPercentageInput);
  const greenPowderEnergyInput = useRecoilValue(GreenPowderEnergyInput);
  const co2PerPartPM = useRecoilValue(Co2ePerPartPMData);
  const co2PerPartMP = useRecoilValue(Co2ePerPartMPData);
  const co2PerPartAP = useRecoilValue(Co2ePerPartAPData);
  const materialTransportaion = useRecoilValue(MaterialTransportationData);
  const systemTransportation = useRecoilValue(SystemTransportationData);
  const cefnone = useRecoilValue(CarbonEmissionFactorNode1Data);
  const cefnTwo = useRecoilValue(CarbonEmissionFactorNode2Data);
  const build_time = useRecoilValue(BuildTimeDataM);
  const TotalTransportation = useRecoilValue(TotalTraspotation)
  const noOfPartsData = useRecoilValue(NoOfPartsData)
  const PartVolume = useRecoilValue(PartVolumeData)
  const buildHeight = useRecoilValue(BuildHeightData)
  const refreshRate = useRecoilValue(RefreshRateScenarioInput)
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  const DataSet = [
    {
      color: "#5DC0D3",
      title: co2ePowder
        ? ((co2ePowder / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2ePowder / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#9CEAEF",
      title: co2eEnergy
        ? ((co2eEnergy / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2eEnergy / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#07BEB8",
      title: TotalTransportation?.today
        ? ((TotalTransportation?.today / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(
        ((TotalTransportation?.today / co2TotalToday) * 100).toFixed(2)
      ),
    },
  ];
  useEffect(() => {
    if (
      cPowder !== undefined &&
      Powder !== undefined &&
      Energy !== undefined &&
      energyMaterial !== undefined &&
      co2TotalTransportation !== undefined &&
      EnergySystem !== undefined
    ) {
      setPowder(parseFloat(cPowder?.today));
      setVolumeReduction(parseFloat(volumeReduction?.today));
      setCo2ePowder(parseFloat(Powder?.today));
      setCo2eEnergy(parseFloat(Energy?.today));

      setEnergyMaterial(parseFloat(energyMaterial?.today));
      setEnergySystem(parseFloat(EnergySystem?.today));
    }
    const parent = document.getElementById("m2");
    const firstchild = parent.firstChild;
    firstchild.setAttribute("viewBox", "-25 -12 150 125");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cPowder,
    Powder,
    Energy,
    energyMaterial,
    co2TotalTransportation,
    EnergySystem,
  ]);
  useEffect(() => {
    if (
      cPowder !== undefined &&
      Powder !== undefined &&
      Energy !== undefined &&
      energyMaterial !== undefined &&
      co2TotalTransportation !== undefined &&
      EnergySystem !== undefined
    ) {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    powder,
    VolumeReduction,
    co2ePowder,
    co2eEnergy,
    EnergyMaterial,
    energySystem,
  ]);
  const access_token = sessionStorage.getItem("access_token");

  const SaveScenario = async () => {
    toast.info("Please Wait...");
    const formFields = {
      technology_id: techId,
      step_type_id: stepId,
      technology_name: techName,
      machine_name: machinename,
      material_name: materialname,
      material_id: materialId,
      machine_id: machineId,
      co2PerPartPM: co2PerPartPM,
      co2PerPartMP: co2PerPartMP,
      co2PerPartAP: co2PerPartAP,
      co2TotalTransportation: co2TotalTransportation,
      materialTransportaion: materialTransportaion,
      systemTransportation: systemTransportation,
      cefnone: cefnone,
      cefnTwo: cefnTwo,
      build_time: build_time,
      modeOfTranspotation1: modeOfTranspotation1,
      modeOfTranspotation2: modeOfTranspotation2,
      cnpInput: cnpInput,
      volumeReductionInput: volumeReductionInput,
      energyPercentageInput: energyPercentageInput,
      greenPowderEnergyInput: greenPowderEnergyInput,
      noOfPartsData: +noOfPartsData,
      PartVolume: +PartVolume,
      buildHeight: +buildHeight,
      refreshRate: +refreshRate
    };

    const data = {
      scenario_data: JSON.stringify(formFields),
      scenario_name: scenarioName,
    };

    try {
      const response = await axios.post(
        `${config.serviceUrl}client/scenario/save`,
        data,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );
      console.log(response);
      if (response.data) {
        toast.success("Scenario saved Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setProcessFlag((prevState) => {
      return {
        ...prevState,
        Reports: true,
      };
    });

  }, []);


  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter scenario name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setScenarioName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter scenario name"
              value={scenarioName}
            />

            {/* <p className="edit-icon value-unit">kg</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <Button
              className="selection-button step-button w-50 m-0"
              onClick={handleClose}
            >
              Close
            </Button>

            <Button
              disabled={scenarioName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                if (scenarioName.length > 0) {
                  SaveScenario();
                  handleClose();
                }
              }}
            >
              Save Scenario
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <button
        onClick={handleShow}
        id="save-scenario"
        className="btn-position selection-button step-button d-flex justify-content-center"
      >
        <Tippy
          content={
            <span>
              You can view your saved scenario by clicking My Scenarios Tab at
              header.
            </span>
          }
        >
          <span>Save My Scenario</span>
        </Tippy>
      </button>

      <div className="powder-container">
        <div className="powder-table-top">
          <p className="powder-heading">Scenario Analysis </p>
        </div>
        <div className="d-flex flex-row p-3">
          <MaterialTable Socket={socket} />
          <MaterialTableValues Socket={socket} />
        </div>
        <div className="powder-table-top total-bottom">
          <p className="powder-heading pr-2">
            Total <abbr className="text-lowercase">(kg)</abbr>
          </p>
          <div className="d-flex">
            <p className="powder-heading pl-3">{co2TotalToday}</p>
            <p className="powder-heading  pr-4 pl-5">{co2TotalTomorrow}</p>
          </div>
        </div>
      </div>

      <div className="graph-section width--mod">
        <div className="text-center">
          <p className="t-2">CO2e in % of kg per part</p>
          <p className="s-1 mb-0">Current Scenario</p>
        </div>
        <div
          id="m2"
          className="w-100 m-0 h-100 d-flex justify-content-center align-items-center"
        >
          <PieChart
            animationDuration={500}
            animationEasing="ease-out"
            width=" 300px"
            height="300px"
            data={DataSet}
            labelPosition={110}
            lengthAngle={360}
            lineWidth={40}
            paddingAngle={2}
            radius={PieChart.defaultProps.radius - shiftSize}
            startAngle={0}
            label={({ dataEntry }) => dataEntry.title}
            // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

            labelStyle={{
              fill: "black",
              strokeWidth: "10",
              fontSize: "5px",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="d-flex">
          <div className="color-icon bg-color5" />
          <p className="color-rep-value">CO2e Powder</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color7" />
          <p className="color-rep-value ">CO2e Energy</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color1" />
          <p className="color-rep-value m-0">CO2e Logistics</p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => ScenarioState()}
          className="selection-button step-button"
        >
          Generate Report
        </button>
        <button onClick={handleShow} className="selection-button step-button">
          <Tippy
            content={
              <span>
                You can view your saved scenario by clicking My Scenarios Tab at
                header.
              </span>
            }
          >
            <span>Save My Scenario</span>
          </Tippy>
        </button>
      </div>
    </>
  );
};

export default Scenario;
