import React from "react";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import { systemPowederLogistics, PowederLogistics } from "app/view/Constant/TooltipText";
const MaterialTable = () => {
  return (
    <div className="divTable">
      <div className="divTableBody">
        <div className="divTableRow">
          <div className="divTableCell1-heading">CO2e Powder</div>
        </div>
        {/* <div className="divTableRow">
          <div className="divTableCell1">
            Is there a possibility to change the atomization process to water
            instead of gas?
          </div>
        </div>
        TODO commented as of now as per client requirement
        */}
        <div className="divTableRow">
          <div className="divTableCell1">
            How much volume reduction in % do you think is possible with
            redesign?
          </div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-special">CO2e Powder (kg)</div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-heading p-0">&nbsp;</div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-heading">CO2e Energy</div>
        </div>
        {/* <div className="divTableRow">
                    <div className="divTableCell1">How much energy could you reduce under Material processing?</div>
                </div> */}
        <div className="divTableRow">
          <div className="divTableCell1">
            Is there a possibility to switch to a green energy mix?
          </div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-special">
            CO2e Energy (kg)
          </div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-heading p-0">&nbsp;</div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-heading">CO2e Logistics</div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1">
            <TooltipTrippy
              tooltipMessage={systemPowederLogistics}
              fieldName="Change the mode of logistics (node 1)"
            />
          </div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1">
            <TooltipTrippy
              tooltipMessage={PowederLogistics}
              fieldName="Change the mode of logistics (node 2)"
            />
          </div>
        </div>

        <div className="divTableRow">
          <div className="divTableCell1-special">CO2e Logistics (kg)</div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTable;
