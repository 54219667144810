import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "./components/MaterialTable";
import MaterialTableValues from "./components/MaterialTableValues";
import { useRecoilValue, useRecoilState } from "recoil";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { useGetMachineName } from "app/view/hooks/useGetMachineName";
import { useGetMaterialName } from "app/view/hooks/useGetMaterialById";
import {
  CCMetalCo2TotalTodaySMData,
  CCMetalCo2TotalTomorrowSMData,
  CCMetalPowderAutomizationData,
  CCMetalVolumePartReductionData,
  CCMetalCo2ePowderScenarioMData,
  CCMetalGreenEnergyMixData,
  CCMetalInertGasReductionSMData,
  CCMetalVolumePartReductionState,
  CCMetalGreenEnergyMixDataInput,
  CCMetalModeOfTranspotation1,
  CCMetalModeOfTranspotation2,
  CCMetalPowderAutomizationState,
  CCMetalTotalTraspotation
} from "atoms/CCCarbonMetal/CCMetalScenario";

import { CCMetalCo2ePerPartPMDataM } from "atoms/CCCarbonMetal/CCMetalPoweredMFG";

import { CCMetalCo2EmissionFactorDataM, CCMetalCo2EmissionPowderDataM, CCMetalCo2EmissionBuildProcessDataM, CCMetalCo2EmissionGasDataM } from "atoms/CCCarbonMetal/CCMetalCarbonContent";

import { technologyId, stepTypeId, technologyType, HighestIndexArray } from "atoms/General/index.js";

import config from "../../../../../config"
import { CCMetalCo2ePerPartTMDataM, CCMetalMaterialTransportationDataM, CCMetalSystemTransportationDataM, CCMetalCarbonEmissionFactorNode1DataM, CCMetalCarbonEmissionFactorNode2DataM, } from "atoms/CCCarbonMetal/CCMetalTransportation";
import { DebounceInput } from 'react-debounce-input';

const axios = require("axios").default;

const Scenario = ({ ScenarioState, socket, staticDataOfMachine }) => {
  const shiftSize = 7;
  const co2TotalToday = useRecoilValue(CCMetalCo2TotalTodaySMData);
  const co2TotalTomorrow = useRecoilValue(CCMetalCo2TotalTomorrowSMData);
  const powderAtomization = useRecoilValue(CCMetalPowderAutomizationData);
  const volumePArtReduction = useRecoilValue(CCMetalVolumePartReductionData);
  const co2ePowder = useRecoilValue(CCMetalCo2ePowderScenarioMData);
  const greenenergymix = useRecoilValue(CCMetalGreenEnergyMixData);
  const inertGasReduction = useRecoilValue(CCMetalInertGasReductionSMData);
  const co2Transportation = useRecoilValue(CCMetalCo2ePerPartTMDataM);
  const totalTranspotation = useRecoilValue(CCMetalTotalTraspotation)

  const techId = useRecoilValue(technologyId);
  const stepId = useRecoilValue(stepTypeId);
  const techName = useRecoilValue(technologyType);
  const [highestIndexArray, setHighestIndexArray] = useRecoilState(HighestIndexArray);

  const [powder, setPowder] = useState();
  const [volume, setVolume] = useState();
  const [co2powder, setCO2ePowder] = useState();
  const [greenEnergyMix, setGreenEnergyMix] = useState();
  const [inertGas, setInertGas] = useState();
  const [transport, setTransport] = useState();
  const [hotSpotArray, setHotSpotArray] = useState([]);

  const access_token = sessionStorage.getItem("access_token");
  // For scenario saving data -->
  const co2EmissionPowder = useRecoilValue(CCMetalCo2EmissionPowderDataM);
  const CCMetalCo2ePerPartPMData = useRecoilValue(CCMetalCo2ePerPartPMDataM);
  const co2eEmissionFactor = useRecoilValue(CCMetalCo2EmissionFactorDataM);
  const materialTransportaion = useRecoilValue(CCMetalMaterialTransportationDataM);
  const systemTransportation = useRecoilValue(CCMetalSystemTransportationDataM);
  const cefnone = useRecoilValue(CCMetalCarbonEmissionFactorNode1DataM);
  const cefnTwo = useRecoilValue(CCMetalCarbonEmissionFactorNode2DataM);
  const co2EmissionBuildProcess = useRecoilValue(CCMetalCo2EmissionBuildProcessDataM);
  const co2EmissionGas = useRecoilValue(CCMetalCo2EmissionGasDataM);
  const powderAtomizationInput = useRecoilValue(CCMetalPowderAutomizationState)
  const volumerPartReductionStateInput = useRecoilValue(CCMetalVolumePartReductionState)
  const greenEnergymixStateInput = useRecoilValue(CCMetalGreenEnergyMixDataInput)
  const modeOfTranspotation1Input = useRecoilValue(CCMetalModeOfTranspotation1)
  const modeOfTranspotation2Input = useRecoilValue(CCMetalModeOfTranspotation2)
  // For scenario saving data <--

  const [show, setShow] = useState(false);
  const [scenarioName, setScenarioName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const machineName = useGetMachineName(
    +staticDataOfMachine?.machine_id?.value
  );
  const materialName = useGetMaterialName(
    +staticDataOfMachine?.material_id?.value
  );


  const DataSet = [
    {
      color: "#5DC0D3",
      title: co2powder ? ((parseFloat(co2powder) / parseFloat(co2TotalToday)) * 100).toFixed(2) + "%" : "",
      value: parseFloat(((co2powder / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: " #9CEAEF",
      title: greenEnergyMix && inertGas ? (((greenEnergyMix + inertGas) / (co2TotalToday) * 100).toFixed(2) + "%") : "",
      value: parseFloat(((greenEnergyMix + inertGas) / (co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#07BEB8",
      title: transport ? ((parseFloat(transport) / parseFloat(co2TotalToday) * 100).toFixed(2) + "%") : "",
      value: parseFloat(((transport) / (co2TotalToday) * 100).toFixed(2)),
    },
    // TODO...
    // {
    //   color: "#3DCCC7",
    //   title: inertGas ? (((inertGas) / (co2TotalToday) * 100).toFixed(2) + "%") : "",
    //   value: parseFloat((((inertGas) / (co2TotalToday) * 100).toFixed(2))),
    // },

  ]
  let arr = []
  useEffect(() => {
    if (
      powderAtomization &&
      volumePArtReduction &&
      co2ePowder &&
      greenenergymix &&
      inertGasReduction
    ) {
      setPowder(parseFloat(powderAtomization.today));
      setCO2ePowder(parseFloat(co2ePowder.today));
      setVolume(parseFloat(volumePArtReduction.today));
      setInertGas(parseFloat(inertGasReduction.today));
      setGreenEnergyMix(parseFloat(greenenergymix.today));
      setTransport(parseFloat(co2Transportation));
      setTransport(parseFloat(co2Transportation));
      arr.push(
        {
          "CO2e Powder": co2ePowder.today,
          "CO2e Energy": inertGasReduction.today,
          "CO2e Logistics": totalTranspotation.today
        }
      );
      setHotSpotArray(arr);

      setHighestIndexArray(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    powderAtomization,
    volumePArtReduction,
    co2ePowder,
    greenenergymix,
    inertGasReduction,
  ]);

  const SaveScenario = async () => {
    toast.info("Please Wait...");
    const formFields = {
      "technology_id": techId,
      "step_type_id": stepId,
      "technology_name": techName,
      "machine_name": machineName,
      "material_name": materialName,
      "material_id": +staticDataOfMachine?.machine_id?.value,
      "machine_id": +staticDataOfMachine?.material_id?.value,
      "co2EmissionPowder": co2EmissionPowder,
      "CCMetalCo2ePerPartPMData": CCMetalCo2ePerPartPMData,
      "co2eEmissionFactor": co2eEmissionFactor,
      "materialTransportaion": materialTransportaion,
      "systemTransportation": systemTransportation,
      "cefnone": cefnone,
      "cefnTwo": cefnTwo,
      "co2EmissionBuildProcess": co2EmissionBuildProcess,
      "co2EmissionGas": co2EmissionGas,
      "co2Transportation": co2Transportation,
      "costData": staticDataOfMachine,
      "powderAtomizationInput": powderAtomizationInput,
      "volumerPartReductionStateInput": volumerPartReductionStateInput,
      "greenEnergymixStateInput": greenEnergymixStateInput,
      "modeOfTranspotation1Input": modeOfTranspotation1Input,
      "modeOfTranspotation2Input": modeOfTranspotation2Input
    }

    const data = { scenario_data: JSON.stringify(formFields), scenario_name: scenarioName }
    try {
      const response = await axios.post(
        `${config.serviceUrl}client/scenario/save`,
        data,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );
      if (response.data) {
        toast.success("Scenario saved Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter scenario name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setScenarioName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter scenario name"
              value={scenarioName}
            />
            {/* <p className="edit-icon value-unit">kg</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="selection-button step-button w-50 m-0"
              onClick={handleClose}
            >
              Close
            </button>
            <Button
              disabled={scenarioName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                SaveScenario();
                handleClose();
              }}
            >
              Save Scenario
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="powder-container">
        <div className="powder-table-top">
          <p className="powder-heading">Scenario Analysis</p>
        </div>
        <div className="d-flex flex-row p-3">
          <MaterialTable Socket={socket} />
          <MaterialTableValues
            staticDataOfMachine={staticDataOfMachine}
            Socket={socket}
          />
        </div>
        <div className="powder-table-top total-bottom ">
          <p className="powder-heading " style={{ textTransform: "none" }}>
            Total (kg)
          </p>
          <div className="d-flex ">
            <p className="powder-heading pl-3">{co2TotalToday}</p>
            <p className="powder-heading pr-4 pl-5 ">{co2TotalTomorrow}</p>
          </div>
        </div>
      </div>

      <div className="graph-section mt-0">
        <div className="text-center">
          <p className="t-2">CO2e in % of kg per part</p>
          <p className="s-1 mb-0">Current Scenario</p>
        </div>
        <PieChart
          animationDuration={500}
          animationEasing="ease-out"
          // center={[50, 50]}
          width=" 300px"
          height="300px"
          data={DataSet}
          labelPosition={110}
          lengthAngle={360}
          lineWidth={40}
          paddingAngle={2}
          radius={PieChart.defaultProps.radius - shiftSize}
          startAngle={0}
          label={({ dataEntry }) => dataEntry.title}
          // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

          labelStyle={{
            fill: "black",
            strokeWidth: "10",
            fontSize: "5px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
          }}
        />
      </div>

      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="d-flex">
          <div className="color-icon bg-color5" />
          <p className="color-rep-value">CO2e Powder</p>
        </div>
        {/* TODO */}
        {/* <div className="d-flex">
          <div className="color-icon metal-Logistics" />
          <p className="color-rep-value">Inert Gas</p>
        </div> */}
        <div className="d-flex">
          <div className="color-icon bg-color7" />
          <p className="color-rep-value ">CO2e Energy</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color1" />
          <p className="color-rep-value m-0">CO2e Logistics</p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => ScenarioState()}
          className="selection-button step-button"
        >
          Visual Analytics
        </button>
        <button onClick={handleShow} className="selection-button step-button">
          Save My Scenario
        </button>
      </div>
    </>
  );
};

export default Scenario;
