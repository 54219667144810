import React from "react";
import { useState } from "react";
import gql from 'graphql-tag';
import { useApolloClient} from '@apollo/react-hooks';

const CLIENT_TERM_CONDITIONS = gql`
    query clientAcceptTermsCondition {
        clientAcceptTermsCondition 
    }
`;

const Banner = () => {
    const client = useApolloClient();
    const [showAcceptAgreement, setShowAcceptAgreement] = useState(false);

    React.useEffect(() => {
        const val = sessionStorage.getItem('accept-agreement')
        if (val === 'true') {
            setShowAcceptAgreement(false)
        } else {
            setShowAcceptAgreement(true)
        }
    }, [])

    const bannerFunction = async _ => {
        
        await client.query({
            query: CLIENT_TERM_CONDITIONS
          }).then(res =>{
            if (res.data) {
                sessionStorage.setItem('accept-agreement', true);
                document.getElementById('banner').style.display = 'none'
                setShowAcceptAgreement(false)
            }
          })
        
    }


    return (
        <div className="row">
        {
            showAcceptAgreement ?
                    <div className="col" id="banner">
                        <p>Please agree to our <span><a href="/" className="semi-bold ">terms and conditions</a></span> to enable report saving feature</p>
                        <div id="agree-btn" onClick={() => bannerFunction()}>
                            <img src="../assets/tick.png" alt="" width="16" height="12" />
                            <p>I Accept</p>
                        </div>
                    </div>
                : ''
        }
        </div>
    )
}

export default Banner;