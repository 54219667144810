import React from 'react'
import ButtonTab from './ButtonTab'
import DataMatricCommonRepresenter from './DataMatricCommonRepresenter'
import { DATAMATRIX } from 'app/components/ConditionalEnums'

const MetalDataMatrix = ({ ScenarioState }) => {
    return (
        <div className="row pad-50 bg-light">
            <div className="col page-container px-0">
                <ButtonTab ScenarioState={ScenarioState} TabName="Switch to Polymer" />

                <p className="t-2 text-center">Metal</p>

                <div className='overflow-x-scroll'>
                    <table class="table table-bordered border-dark table-hover text-center text-justify bg-light table-responsive">
                        <caption className='ml-1'>{DATAMATRIX.REPRESNETER}</caption>
                        <thead>
                            <tr className='table-border'>
                                <th className='border border-dark' scope="col" colSpan={2}>Process Step</th>
                                <th className='border border-dark' scope="col" colSpan={5}>Sourcing</th>
                                <th className='border border-dark' scope="col" colSpan={5}>AM Process</th>
                                <th className='border border-dark' scope="col" colSpan={2} >Transportation</th>
                                <th className='border border-dark' scope="col" colSpan={2}>Post Processing</th>
                                <th className='border border-dark' scope="col" >Use Phase</th>
                                <th className='border border-dark' scope="col" colSpan={2}>End of Life</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='table-border'>
                                <td scope='row' ></td>
                                <td ></td>
                                <td className='font-weight-bold' colSpan={2} ><div>Powder & Material Sourcing</div> (inlcudes <div className='white_space-nowrap'>transportation)</div></td>
                                <td className='font-weight-bold' colSpan={2}> Machine Manufacturing</td>
                                <td className='font-weight-bold' >Inert Gas Sourcing</td>
                                <td className='font-weight-bold' >Inert Gas (Argon or Nitrogen)</td>
                                <td className='font-weight-bold' >Powder consumption</td>
                                <td className='font-weight-bold' >Powder loss</td>
                                <td className='font-weight-bold' >Powder Removal & Cleaning</td>
                                <td className='font-weight-bold' >Power</td>
                                <td className='font-weight-bold' colSpan={2} >EOS to Customer</td>
                                <td className='font-weight-bold' colSpan={2}>Sand Blasting / Heat Treatment etc.</td>
                                <td className='font-weight-bold' >What is the application used for</td>
                                <td className='font-weight-bold' colSpan={2}>Recylced, Incinerated </td>
                            </tr>

                            {/* TODO.... */}
                            {/* <tr className='table-border'>
                            <th scope='row' >Data Source</th>
                            <td className=''></td>
                            <td ></td>
                            <td >To be calculated based on Academic Papers or by an LCA Institute</td>
                            <td ></td>
                            <td >Sebastian Lepar (2018)</td>
                            <td >calculated under CPP, based on application measurements</td>
                            <td >Marcel Marggrander Peter Hofbauer sprechen! (M4-4)</td>
                            <td ></td>
                            <td >2018_Azevedo_Powder_Technology_Energy and material efficiency o steel powder metallurgy.pdf</td>
                            <td >Sebastian Lepar; VDI Studie</td>
                            <td >ADEME Database</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                        </tr> */}
                            {/* TODO... */}
                            {/* <tr className='table-border'>
                            <th scope='row' >Year</th>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td >2016 - 2018</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td >2018</td>
                            <td >2016 - 2018</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                        </tr> */}
                            {/* TODO.... */}
                            {/* <tr className='table-border'>
                            <th scope='row' >Unit</th>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td >Watt</td>
                            <td >Kg</td>
                            <td >% of part weight</td>
                            <td ></td>
                            <td ></td>
                            <td >Watt</td>
                            <td ></td>

                            <td ></td>
                            <td ></td>
                            <td ></td>
                        </tr> */}

                            <tr className='table-border'>
                                <td >Data Source</td>
                                <td ></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}>To be calculated based on Academic Papers or by an LCA Institute</td>
                                <td ></td>
                                <td >Data Sheet</td>
                                <td ><div>Calculated under CPP, based on </div><div className='white_space-nowrap'>application</div> <div className='white_space-nowrap'>measurements</div></td>
                                <td >Calculated under CPP, based on assumptions</td>
                                <td ></td>
                                <td >Data Sheet - check if RFS & Chiller is added in the Admin tool</td>
                                <td colSpan={2} >ADEME Database</td>
                                <td colSpan={2}></td>
                                <td ></td>
                                <td colSpan={2}></td>
                            </tr>
                            <tr className='table-border'>
                                <td >Unit</td>
                                <td ></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td ></td>
                                <td >Watt</td>
                                <td >Kg</td>
                                <td >% of part weight</td>
                                <td ></td>
                                <td >Watt</td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td ></td>
                                <td colSpan={2}></td>
                            </tr>
                            <tr className='table-border'>
                                <th scope='row' rowSpan={6} >M290</th>
                                <td >AlSi10Mg</td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td ></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >HX</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>


                            </tr>
                            <tr className='table-border'>
                                <td >Ti64</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td ></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2} ></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >IN718</td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2} ></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >316L</td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >MS1</td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <th scope='row' rowSpan={6} >M300-4</th>
                                <td >AlSi10Mg</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td ></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >HX</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td className='bg-green'></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >Ti64</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td ></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >IN718</td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green' rowSpan={5} ></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>
                            <tr className='table-border'>
                                <td >316L</td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >MS1</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <th scope='row' rowSpan={2} >M400</th>
                                <td >Scalmalloy© AlMg4.5Sc0.7Zr0.3</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >AlSi10Mg</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <th scope='row' rowSpan={6} >M400-4</th>
                                <td >AlSi10Mg</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green' rowSpan={3} >
                                </td>
                                <td ></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >HX</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >Ti64</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td ></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className=' bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >IN718</td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'> </td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >316L</td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td rowSpan={2} className="bg-green"></td>
                                <td rowSpan={2} className="bg-green"></td>
                                <td className=' bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray' colSpan={2}></td>
                            </tr>

                            <tr className='table-border'>
                                <td >MS1</td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray'></td>
                                <td className='bg-orange'></td>
                                <td className='bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow' colSpan={2}></td>
                                <td className='bg-blue' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray' colSpan={2}></td>
                            </tr>



                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-center'>
                    <DataMatricCommonRepresenter caption="Metal" />
                </div>
            </div>
        </div >
    )
}

export default MetalDataMatrix