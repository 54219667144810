import React, { useState } from "react";
import Navbar from "../components/Nav.jsx";
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { ToastContainer, toast } from 'react-toastify';

const AUTH_USER = gql`
query ClientForgetPassword($email: String! ){
    clientForgetPassword(email:$email)
}
`;

const ResetReq = () => {
  const client = useApolloClient();
  const [Loading, setLoading] = useState(false);
  let email = sessionStorage.getItem('email');
  var [timer, setTimer] = useState(59);

  const sendResetEmail = async () => {
    var x = setInterval(() => {
      if (timer === 1) {
        setTimer(59)
        setLoading(false);
        clearInterval(x);
      }
      else {
        setTimer(--timer)
      }
    }, 1000);
    setLoading(true);
    await client.query({
      query: AUTH_USER,
      variables: { email },
      fetchPolicy: 'no-cache',
    }).then(res => {
      toast.success('Please Check Your Email and Click on the link')
    }).catch((error) => {
      toast.error(error.graphQLErrors[0].extensions.exception.response.error)
    }
    );
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <div className="row fill">
        <div className="col-lg-6 login-container">
          <div>
            <p className="login-text">Your request has been submitted successfully. Please check your email to get a new password for your account.</p>
            <button className="button1" onClick={sendResetEmail} disabled={Boolean(Loading)}>
              {!Loading ? <p>Resend Email</p> : <p>00:{timer}</p>}
            </button>
          </div>
        </div>
        <div className="col-lg-6 coverimage image3"></div>
      </div>
    </React.Fragment>
  )
}

export default ResetReq;