import React, { useState } from 'react';
import MetalView from './Views/MetalView';
import PolymerView from './Views/PolymerView';
import { useAccess, useSetAccess } from 'state/hooks.js';
import { useHistory } from 'react-router-dom';
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import { CALCULATOR_TYPE } from '../ConditionalEnums';


const CustomModal = ({ typeModal, btnTitle, techData }) => {
  let Access = useAccess();
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(false);
  const history = useHistory();

  const openModal = () => {
    setIsOpen(true);
  };



  const renderPolymer = () => {
    return techData.map((technology) => {
      return (
        <React.Fragment key={technology.user_group_step_access_id}>
          {technology.technology_id.name === 'Polymer' && (
            <>
              <TooltipTrippy
                tooltipMessage="For estimation you do not need any external softwares. Estimation can provide with a +-20% accuracy cost per part details"
                fieldName={
                  <button
                    className={
                      'type-btn deadcenter selection-button m-0 mb-3 ' +
                      (key === 'select1' &&
                        technology.technology_id.name === 'Polymer' &&
                        ' type-selected')
                    }
                    onClick={async () => {

                      technology.technology_id.name === 'Polymer' &&
                        setKey('select1');

                      sessionStorage.setItem(
                        'calculatorType',
                        CALCULATOR_TYPE.COST
                      );

                      sessionStorage.setItem(
                        'technologyName',
                        technology.technology_id.name + ' (Estimation)'
                      );

                      sessionStorage.setItem(
                        'technology',
                        technology.technology_id.name
                      );
                      sessionStorage.setItem('stepTypeId', 1);

                      if (key !== '') {
                        window.location.href = `/calculator/process`;
                      }
                    }}
                  >
                    {/* {technology.technology_id.name === 'Polymer' && (
                    <img
                      src="/assets/polymer.png"
                      width="25"
                      height="25"
                      alt=""
                    />
                  )} */}
                    <p>Estimation of costs</p>
                  </button>
                }
              />

              <TooltipTrippy
                tooltipMessage="In order to calculate only the footprint of the application you can use this tab. For inputs you would need access to EOSPrint/PSW. The tab does not cover details on cost per part"
                fieldName={
                  <button
                    className="selection-button mb-3 ml-0"
                    onClick={() => {
                      sessionStorage.setItem(
                        'calculatorType',
                        CALCULATOR_TYPE.CARBON
                      );
                      history.push('/carboncalculator/polymer-steps');
                    }}
                  >
                    Estimation of carbon footprint
                  </button>
                }
              />

              <>
                {Access ? (

                  <TooltipTrippy
                    tooltipMessage="Full simulation provides a combined analysis on cost per part and carbon calculation with an accuracy of up to +-5%. You would need access to EOSPrint/PSW to use this tab to its full potential"
                    fieldName={
                      <button
                        className={
                          'type-btn deadcenter selection-button m-0 mb-3 ' +
                          (key === 'select2' &&
                            technology.technology_id.name === 'Polymer' &&
                            ' type-selected')
                        }
                        onClick={async () => {

                          sessionStorage.setItem(
                            'calculatorType',
                            CALCULATOR_TYPE.FULL_SIMULATION
                          );

                          technology.technology_id.name === 'Polymer' &&
                            setKey('select2');
                          sessionStorage.setItem(
                            'technologyName',
                            technology.technology_id.name + ' (Simulation)'
                          );
                          sessionStorage.setItem(
                            'technology',
                            technology.technology_id.name
                          );
                          sessionStorage.setItem('stepTypeId', 2);

                          if (key !== '') {
                            window.location.href = `/calculator/process`;
                          }
                        }}
                      >
                        {/* {technology.technology_id.name === 'Polymer' && (
                  <img
                    src="/assets/polymer.png"
                    width="25"
                    height="25"
                    alt=""
                  />
                )} */}
                        <p>Full simulation</p>
                      </button>
                    }
                  />

                )
                  :
                  (
                    <div className="freemium-bg" onClick={() => { }}>
                      <a href="/premium-request" className="premium">
                        Premium
                      </a>
                      <button
                        className={
                          'disable-btn  deadcenter selection-button m-0 mb-3 ' +
                          (key === 'select2' &&
                            technology.technology_id.name === 'Polymer' &&
                            ' type-selected')
                        }
                      >
                        {/* {technology.technology_id.name === 'Polymer' && (
                        <img
                          src="/assets/polymer.png"
                          width="25"
                          height="25"
                          alt=""
                        />
                      )} */}
                        <p>{technology.technology_id.name} (Simulation)</p>
                      </button>
                    </div>
                  )}
              </>
            </>
          )}
        </React.Fragment>
      );
    });
  };

  const renderMetal = () => {
    return techData.map((technology) => {
      return (
        <React.Fragment key={technology.user_group_step_access_id}>
          {technology.technology_id.name === 'Metal' && (
            <>
              <TooltipTrippy
                tooltipMessage="For estimation you do not need any external softwares. Estimation can provide with a +-20% accuracy cost per part details"
                fieldName={
                  <button
                    className={
                      'type-btn deadcenter selection-button m-0 mb-3 ' +
                      (key === 'select3' &&
                        technology.technology_id.name === 'Metal' &&
                        ' type-selected')
                    }
                    onClick={async () => {
                      technology.technology_id.name === 'Metal' &&
                        setKey('select3');

                      sessionStorage.setItem(
                        'calculatorType',
                        CALCULATOR_TYPE.COST
                      );

                      sessionStorage.setItem(
                        'technologyName',
                        technology.technology_id.name + ' (Estimation)'
                      );
                      sessionStorage.setItem(
                        'technology',
                        technology.technology_id.name
                      );
                      sessionStorage.setItem('stepTypeId', 1);

                      if (key !== '') {
                        window.location.href = `/calculator/process`;
                      }
                    }}
                  >
                    {/* {technology.technology_id.name === 'Metal' && (
                    <img src="/assets/metal.png" width="25" height="25" alt="" />
                  )} */}
                    <p>Estimation of costs</p>
                  </button>
                }
              />
              <TooltipTrippy
                tooltipMessage="In order to calculate only the footprint of the application you can use this tab. For inputs you would need access to EOSPrint/PSW. The tab does not cover details on cost per part"
                fieldName={
                  <button
                    className="selection-button ml-0 mb-3"
                    onClick={() => {

                      sessionStorage.setItem(
                        'calculatorType',
                        CALCULATOR_TYPE.CARBON
                      );
                      history.push('/carboncalculator/metal-steps');
                    }}
                  >
                    Estimation of carbon footprint
                  </button>
                }
              />

              <>
                {Access ? (
                  <TooltipTrippy
                    tooltipMessage="Full simulation provides a combined analysis on cost per part and carbon calculation with an accuracy of up to +-5%. You would need access to EOSPrint/PSW to use this tab to its full potential"
                    fieldName={
                      <button
                        className={
                          'type-btn deadcenter selection-button m-0 mb-3 ' +
                          (key === 'select4' &&
                            technology.technology_id.name === 'Metal' &&
                            ' type-selected')
                        }
                        onClick={async () => {
                          sessionStorage.setItem(
                            'calculatorType',
                            CALCULATOR_TYPE.FULL_SIMULATION
                          );
                          technology.technology_id.name === 'Metal' &&
                            setKey('select4');
                          sessionStorage.setItem(
                            'technologyName',
                            technology.technology_id.name + ' (Simulation)'
                          );
                          sessionStorage.setItem(
                            'technology',
                            technology.technology_id.name
                          );
                          sessionStorage.setItem('stepTypeId', 2);

                          if (key !== '') {
                            window.location.href = `/calculator/process`;
                          }
                        }}
                      >
                        {/* {technology.technology_id.name === 'Metal' && (
                      <img
                        src="/assets/metal.png"
                        width="25"
                        height="25"
                        alt=""
                      />
                    )} */}
                        <p>Full simulation</p>
                      </button>
                    }
                  />

                ) : (
                  <div className="freemium-bg" onClick={() => { }}>
                    <a href="/premium-request" className="premium">
                      Premium
                    </a>
                    <button
                      className={
                        'disable-btn  deadcenter selection-button m-0 mb-3 ' +
                        (key === 'select4' &&
                          technology.technology_id.name === 'Metal' &&
                          ' type-selected')
                      }
                    >
                      {/* {technology.technology_id.name === 'Metal' && (
                        <img
                          src="/assets/metal.png"
                          width="25"
                          height="25"
                          alt=""
                        />
                      )} */}
                      <p>{technology.technology_id.name} (Simulation)</p>
                    </button>
                  </div>
                )}
              </>
            </>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <button
        className="selection-button m-0"
        onClick={() => {
          openModal();
        }}
      >
        {btnTitle}
      </button>

      {isOpen && typeModal === 'Metal' && (
        <MetalView
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          renderTech={renderMetal()}
        />
      )}

      {isOpen && typeModal === 'Polymer' && (
        <PolymerView
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          renderTech={renderPolymer()}
        />
      )}
    </>
  );
};
export default CustomModal;
