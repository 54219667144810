import React from "react";
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';

const GET_NAMES = gql`
  query getNames($machine_id : Int, $material_id : Int , $parameter_id : Int){
    getNames(machine_id : $machine_id, material_id : $material_id , parameter_id : $parameter_id )

  }
`;

const GetNames = (data) => {
    const client = useApolloClient();

    React.useEffect(() => {

        if (data.formFields) {
            client.query({
                query: GET_NAMES,
                variables: {
                    machine_id: parseInt(data.formFields.machine_id.value),
                    material_id: parseInt(data.formFields.material_id.value),
                    parameter_id: parseInt(data.formFields.parameter_id.value)
                }
            }).then(res => {

                localStorage.setItem('getNames', JSON.stringify(res.data.getNames));
            }).catch(error => {
                console.log('error', error)
            });
        }
    })
    return (''
    )
}

export default GetNames;