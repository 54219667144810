import React from 'react';
import { Modal } from 'react-bootstrap';

const PolymerView = ({ isOpen, setIsOpen, renderTech }) => {

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Modal dialogClassName="modal60w" onHide={close} show={isOpen}>
      <Modal.Header className="border-0" closeButton>
        {/* <Modal.Title /> */}
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="row modal-flex mt-0" id="btn-row">
          {renderTech}

        </div>
      </Modal.Body>
      <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
  );
};
export default PolymerView;
