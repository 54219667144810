import { atom } from "recoil";

export const PowderPerBuildJobMaterialData = atom({
  key: "PowderPerBuildJobMaterialData",
  default: undefined,
});

export const TimeForProcessingData = atom({
  key: "TimeForProcessingData",
  default: undefined,
});
export const AverageEnergyData = atom({
  key: "AverageEnergyData",
  default: undefined,
});

export const CO2EmissionFactorData = atom({
  key: "CO2EmissionFactorData",
  default: undefined,
});

export const Co2ePerJobMPData = atom({
  key: "Co2ePerJobMPData",
  default: undefined,
});
export const Co2ePerPartMPData = atom({
  key: "Co2ePerPartMPData",
  default: undefined,
});
