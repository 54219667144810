import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_STEP_DETAILS } from "../CarbonPolymerProcess/components/getStepDetail";
import { DebounceInput } from "react-debounce-input";
import { CommonCarbonPricingPolyMetal } from "atoms/CostToCarbonData";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";

import {
  CCPolyTotalCo2PerPartData,
  CCPolyCostOfOneKGCo2Data,
  CCPolyCostOfCo2PerPartFData,
  CCPolyCppWithCo2Data,
} from "atoms/CCPolymer/CCPolyCo2Process";
import { CCPolyCo2ePerPartPMData } from "atoms/CCPolymer/CCPolyPowderMFG";
import { CCPolyCo2ePerPartAPData } from "atoms/CCPolymer/CCPolyAmProcess";
import { CCPolyCo2ePerPartMPData } from "atoms/CCPolymer/CCPolyMaterialProcessing";

import { CCPolyCo2ePerPartTPData } from "atoms/CCPolymer/CCPolyTransportation";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from "atoms/General";
import config from "../../../../../config/index";

const Co2polymerProcess = ({ Socket, StepID }) => {
  const client = useApolloClient();
  const [stepDetails, setStepDetails] = useState([]);
  const [totalCo2pp, setTotalCo2pp] = useRecoilState(CCPolyTotalCo2PerPartData);
  const [costOneKGCo2, setCostOneKGCo2] = useRecoilState(
    CCPolyCostOfOneKGCo2Data
  );
  const [costOfCo2pp, setCostOfCo2pp] = useRecoilState(
    CCPolyCostOfCo2PerPartFData
  );
  const [, setAllValueState] = useRecoilState(
    CommonCarbonPricingPolyMetal
  );
  const [, setProcessFlag] = useRecoilState(ProcessFlag);
  const [cppWithCo2, setCppWithCo2] = useRecoilState(CCPolyCppWithCo2Data);
  const co2PerPartPM = useRecoilValue(CCPolyCo2ePerPartPMData);
  const co2PerPartAP = useRecoilValue(CCPolyCo2ePerPartAPData);
  const co2PerPartMP = useRecoilValue(CCPolyCo2ePerPartMPData);
  const co2PerPartTP = useRecoilValue(CCPolyCo2ePerPartTPData);
  const { getComputedValue } = useRoundOffValue();
  const costData = JSON.parse(localStorage.getItem("CostData"));
  //Geting currency symbol
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);

  useEffect(() => {
    const GetClientSteps = () => {
      client
        .query({
          query: GET_STEP_DETAILS,
          variables: { step_id: StepID.step_id },
        })
        .then(({ data }) => {

          setStepDetails(data.getFieldsByStepId);
        })
        .catch((error) => {
          console.log("error", error);
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
    };

    GetClientSteps();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepDetails]);

  useEffect(() => {

    Socket.emit("carbon-calculator", {
      key: "DBCC-CPCPOKC",
      input: {
        technology_id: +costData?.technology_id?.value,
        region_id: +costData?.region_id?.value,
      },
    });
    Socket.on("DBCC-CPCPOKC", function (data) {

      const value = getComputedValue(data?.data?.value);

      window.localStorage.setItem("costOneKGCo2", value);

      setCostOneKGCo2(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      co2PerPartAP !== null &&
      co2PerPartAP !== undefined &&
      co2PerPartMP !== null &&
      co2PerPartMP !== undefined &&
      co2PerPartTP !== null &&
      co2PerPartTP !== undefined &&
      co2PerPartPM !== null &&
      co2PerPartPM !== undefined
    ) {

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPTCPP",
        input: {
          co2e_per_part_ap: co2PerPartAP,
          co2e_per_part_mp: co2PerPartMP,
          co2e_per_part_pm: co2PerPartPM,
          co2e_per_part_tmp: co2PerPartTP,
          technology_id: 2,
        },
      });

      Socket.on("FOCC-CPTCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setTotalCo2pp(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2PerPartAP, co2PerPartMP, co2PerPartPM, co2PerPartTP]);

  useEffect(() => {
    if (totalCo2pp && costOneKGCo2) {

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCCPP",
        input: {
          total_co2e_per_part: totalCo2pp,
          cost_of_producing_one_kg_co2e: costOneKGCo2,
          technology_id: 2,
        },
      });

      Socket.on("FOCC-CPCCPP", function (data) {

        const value = getComputedValue(data?.data?.value);
        window.localStorage.setItem("metalCo2pp", value);

        setCostOfCo2pp(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCo2pp, costOneKGCo2]);

  useEffect(() => {
    if (costOfCo2pp !== undefined) {

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCPPWC",
        input: {
          cost_of_co2e_per_part: costOfCo2pp,
          post_processing_cost_per_part: parseInt(
            costData.post_processing_cost_per_part.value
          ),
          material_cost_per_part: parseInt(
            costData.material_cost_per_part.value
          ),
          system_cost_per_part: parseInt(costData.system_cost_per_part.value),
        },
      });

      Socket.on("FOCC-CPCPPWC", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCppWithCo2(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costOfCo2pp]);

  useEffect(() => {
    if (
      totalCo2pp != null &&
      costOneKGCo2 != null &&
      costOfCo2pp != null &&
      cppWithCo2 != null
    ) {
      setAllValueState({
        totalCo2pp,
        costOneKGCo2,
        costOfCo2pp,
        cppWithCo2,
      });
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonPricing: true,
          ScenarioAnalysis: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCo2pp, costOneKGCo2, costOfCo2pp, cppWithCo2]);

  return (
    <>
      <p className="input-heading">Total CO2e per part (kg)</p>
      <div className="data-field">
        {totalCo2pp}
        {/* <p className="edit-icon value-unit ">kg</p> */}
      </div>
      <p className="input-heading">
        Carbon shadow pricing factor  ({obj.currency_symbol}/kg) <a className="click-here" href={config.carbonShadowSuggestionUrl} target="_blank" rel="noopener noreferrer" >(Click here for more details)</a>
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCostOneKGCo2(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={costOneKGCo2}
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">
        Cost of CO2e per part {`(${obj.currency_symbol})`}
      </p>
      <div className="data-field">{costOfCo2pp}</div>
      <p className="input-heading">
        CPP with CO2e {`(${obj.currency_symbol})`}
      </p>
      <div className="data-field">{cppWithCo2}</div>
    </>
  );
};

export default Co2polymerProcess;
