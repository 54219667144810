import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  TimeForProcessingData,
  AverageEnergyData,
  CO2EmissionFactorData,
  Co2ePerJobMPData,
  Co2ePerPartMPData,
} from "atoms/Co2Polymer/MaterialProcessingAtoms";
import {
  NoOfPartsData,
  MachineData,
} from "atoms/Co2Polymer/MachineDetailsAtoms";
import { PowderPerBuildJobData } from "atoms/Co2Polymer/PowderMfgAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
const MaterialProcessing = ({ Socket }) => {
  const [timeForp, setTimeforp] = useRecoilState(TimeForProcessingData);
  const [averageenergy, setAverageenergy] = useRecoilState(AverageEnergyData);
  const [co2ef, setCo2ef] = useRecoilState(CO2EmissionFactorData);
  const [co2pj, setCo2pj] = useRecoilState(Co2ePerJobMPData);
  const [co2pp, setCo2pp] = useRecoilState(Co2ePerPartMPData);
  const [, setProcessFlag] = useRecoilState(ProcessFlag);

  const noOfPartsData = useRecoilValue(NoOfPartsData);
  const powderPerBuildJob = useRecoilValue(PowderPerBuildJobData);
  const machineId = useRecoilValue(MachineData);
  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
  });
  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderone: true,
      };
    });
    console.log("powderPerBuildJob in swttimeforp", powderPerBuildJob);
    Socket.emit("carbon-calculator", { key: "DBCC-MPTFP", input: "" });

    Socket.on("DBCC-MPTFP", function (data) {
      const value = getComputedValue(data?.data?.value);

      setTimeforp(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeForp) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-MPAE",
        input: { time_for_processing: timeForp, machine_id: machineId },
      });

      Socket.on("FOCC-MPAE", function (data) {
        const value = getComputedValue(data?.data?.value);

        setAverageenergy(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeForp]);
  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderthree: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-MPCEF", input: "" });

    Socket.on("DBCC-MPCEF", function (data) {
      const value = getComputedValue(data?.data?.value);

      setCo2ef(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: false,
        };
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (averageenergy !== undefined && co2ef !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-MPCPJ",
        input: { average_energy: averageenergy, co2_emission_factor: co2ef },
      });

      Socket.on("FOCC-MPCPJ", function (data) {
        const value = getComputedValue(data?.data?.value);

        setCo2pj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageenergy, co2ef]);
  useEffect(() => {
    if (co2pj !== undefined && noOfPartsData !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-MPCPP",
        input: { co2e_per_job_mp: co2pj, no_of_parts: noOfPartsData },
      });

      Socket.on("FOCC-MPCPP", function (data) {
        console.log("Co2ppMP", data?.data?.value);
        const value = getComputedValue(data?.data?.value);

        setCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfive: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2pj, noOfPartsData]);

  useEffect(() => {
    if (
      powderPerBuildJob === "" ||
      timeForp === "" ||
      averageenergy === "" ||
      co2ef === "" ||
      co2pj === "" ||
      co2pp === ""
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          MaterialProcessing: false,
        };
      });
    } else if (
      powderPerBuildJob !== undefined &&
      timeForp !== undefined &&
      averageenergy !== undefined &&
      co2ef !== undefined &&
      co2pj !== undefined &&
      co2pp !== undefined
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          MaterialProcessing: true,
        };
      });
    } else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          MaterialProcessing: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageenergy, co2pj, co2ef, powderPerBuildJob, timeForp, co2pp]);

  return (
    <>

      <p className="input-heading"> <TooltipTrippy fieldName="Time for processing (h)" tooltipMessage="MQS is used" /> </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setTimeforp(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter time for processing"
          value={
            loader.loaderone ? (
              <Spinner animation="border" size="sm" />
            ) : (
              timeForp
            )
          }
        />
        <OverrideAbleTip />
      </div>


      <p className="input-heading">Average energy (kWh)</p>
      <div className="data-field">
        {loader.loadertwo ? (
          <Spinner animation="border" size="sm" />
        ) : (
          averageenergy
        )}
        {/* <p className="edit-icon value-unit">kwh</p> */}
      </div>
      <p className="input-heading">
        <TooltipTrippy fieldName="CO2e emission factor (kg/kWh)" tooltipMessage="Details on factors can be found under Data matrix tab" />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2ef(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e emission factor"
          value={
            loader.loaderthree ? (
              <Spinner animation="border" size="sm" />
            ) : (
              co2ef
            )
          }
        />
        <OverrideAbleTip />
      </div>
      {/* <div className="data-field">{co2ef}  <p className="edit-icon value-unit pr-5">kg/kwh</p></div> */}
      <p className="input-heading">CO2e per job (kg) </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2pj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={
            loader.loaderfour ? <Spinner animation="border" size="sm" /> : co2pj
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {loader.loaderfive ? <Spinner animation="border" size="sm" /> : co2pp}
      </div>
    </>
  );
};

export default MaterialProcessing;
