import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const TooltipTrippy = ({ tooltipMessage, fieldName, addClass }) => {
  return (
    <Tippy
      // allowHTML
      // html={IMAGE && <div>abc</div>}
      content={<span>{tooltipMessage}</span>}
    >
      <span className={`${addClass}`}>{fieldName}</span>
    </Tippy>
  );
};

export default TooltipTrippy;
