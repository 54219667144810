import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Spinner } from "react-bootstrap";
import { InertGasConsumptionDataM, InertGasTypeDataM, InertGasUsedEnergyDataM } from "atoms/Co2Metal/InertGasAtoms";
import { GetBuildTimeDataM, MachineDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from 'atoms/General';

const InertGas = ({ Socket }) => {
  const [inertGasConsumption, setInertGasConsumption] = useRecoilState(InertGasConsumptionDataM);
  const [inertGasType, setInertGasType] = useRecoilState(InertGasTypeDataM);
  const [InertGasUsed, setInertGasUsed] = useRecoilState(InertGasUsedEnergyDataM);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag)

  const { getComputedValue } = useRoundOffValue()
  const buildTime = useRecoilValue(GetBuildTimeDataM)
  const machineId = useRecoilValue(MachineDataM)
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,

  })
  useEffect(() => {
    if (buildTime) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true
        }
      })

      Socket.emit("carbon-calculator", { key: "FOCC-IGIGC", input: { build_time: buildTime, machine_id: machineId } });

      Socket.on("FOCC-IGIGC", function (data) {

        const value = getComputedValue(data?.data?.value)

        setInertGasConsumption(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false
          }
        })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildTime])

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loadertwo: true
      }
    })
    Socket.emit("carbon-calculator", { key: "DBCC-IGIGT", input: "" })

    Socket.on("DBCC-IGIGT", function (data) {

      // const value = getComputedValue(data?.data?.value)

      setInertGasType(data?.data?.value)
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: false
        }
      })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (inertGasConsumption !== null) {

      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true
        }
      })
      Socket.emit("carbon-calculator", { key: "FOCC-IGIGUE", input: { inert_gas_consumption: inertGasConsumption } })

      Socket.on("FOCC-IGIGUE", function (data) {

        const value = getComputedValue(data?.data?.value)

        setInertGasUsed(value)
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false
          }
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertGasConsumption])

  useEffect(() => {
    if (inertGasConsumption === "" || inertGasType === "" || InertGasUsed === "") {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          InertGas: false,
        }
      })
    }
    else if (inertGasConsumption !== undefined && inertGasType !== undefined && InertGasUsed !== undefined) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          InertGas: true,
        }
      })
    }
    else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          InertGas: false,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InertGasUsed, inertGasConsumption, inertGasType])


  return (
    <>

      <p className="input-heading">Inert gas consumption (m<sup className='font-weight-bold'>3</sup>/job)</p>
      <div className="data-field">{
        loader.loaderone
          ?
          <Spinner animation="border" size="sm" />
          :
          inertGasConsumption
      }
        {/* <p className="edit-icon value-unit pr-5">m3/job</p> */}

      </div>

      {/* TODO.. */}
      {/* <p className="input-heading">Inert gas type</p>
      <div className="data-field">{
        loader.loadertwo
          ?
          <Spinner animation="border" size="sm" />
          :
          inertGasType
      }
      </div> */}

      <p className="input-heading">Inert gas used energy (kWh)</p>
      <div className="data-field">{
        loader.loaderthree
          ?
          <Spinner animation="border" size="sm" />
          :
          InertGasUsed
      }
        {/* <p className="edit-icon value-unit">kwh</p> */}

      </div>

    </>
  );
};

export default InertGas;
