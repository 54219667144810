import React, { useState } from "react";
import PdfGenerator from "./components/Pdfgenerator";
import Dropdown from 'react-bootstrap/Dropdown';
import { useGetMachineName } from "app/view/hooks/useGetMachineName";
import { useGetParameterName } from "app/view/hooks/useGetParameterName";
import { useGetMaterialName } from "app/view/hooks/useGetMaterialById";
import { useRecoilValue } from "recoil"
import { CCPolyCostOfCo2PerPartFData } from "atoms/CCPolymer/CCPolyCo2Process";
import { WorkingHours, HighestIndexArray } from "atoms/General";
import { DebounceInput } from 'react-debounce-input';
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
const ThirdReport = ({ PreviousScenarioState, staticData, cppData, currency, visualDonut, jobsPerWeek, totalJobs, singleJobTime, machineImage, psImage, cppSum, hotspotArray, hightlightKey, isHotSpotAvailable }) => {
  const [savePdf, setSavePdf] = useState(false)
  const [typedocument, setTypeDocument] = useState()
  const costOfCo2pp = useRecoilValue(CCPolyCostOfCo2PerPartFData)
  const workingHours = useRecoilValue(WorkingHours)
  const highestIndexArray = useRecoilValue(HighestIndexArray)
  const materialName = useGetMaterialName(+staticData?.material_id?.value);
  const parameterName = useGetParameterName(+staticData?.parameter_id?.value);
  const machineName = useGetMachineName(
    +staticData?.machine_id?.value
  );

  const [show, setShow] = useState(false);

  const [pdfName, setPdfName] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const max = Math.max(...highestIndexArray);
  const highestIndex = highestIndexArray.indexOf(max);
  const initialDocumentState = () => {
    setTypeDocument(undefined);
    setPdfName("")
    setSavePdf(false)
  }
  const technology_name = sessionStorage.getItem("technologyName")
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{typedocument === 0 ? 'Enter pdf name' : "Enter word doc name"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setPdfName(e.target.value)
              }}
              onWheel={e => e.target.blur()}
              placeholder="Please enter name"
              value={pdfName} />
            {/* <p className="edit-icon value-unit">kg</p> */}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-center align-items-center w-100 '>
            <button className="selection-button step-button w-50 m-0" onClick={handleClose}>
              Close
            </button>
            <Button disabled={pdfName.length === 0} className="selection-button step-button w-50 mt-0" onClick={() => { setSavePdf(true); handleClose() }}
            >
              Download
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <div className="reports-section"> */}

      <div className="d-flex flex-row">
        <Dropdown>
          {/* <Button
            className="selection-button step-button"
            id="dropdown-basic"
            onClick={() => {
              setTypeDocument(0);
              handleShow();
            }}
          >
            Download Report
          </Button> */}
          <Dropdown.Toggle className="selection-button step-button " id="dropdown-basic" >
            Download Report
          </Dropdown.Toggle>

          <Dropdown.Menu >
            <Dropdown.Item onClick={() => { setTypeDocument(0); handleShow() }}>As Pdf</Dropdown.Item>
            <Dropdown.Item onClick={() => { setTypeDocument(1); handleShow() }}>As Word</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {
        savePdf && (
          <PdfGenerator
            formFields={staticData}
            pdfName={pdfName}
            typeDocument={typedocument}
            machineName={machineName}
            materialName={materialName}
            parameterName={parameterName}
            cppData={cppData}
            costOfCo2pp={costOfCo2pp}
            currency={currency}
            visualDonut={visualDonut}
            jobsPerWeek={jobsPerWeek}
            totalJobs={totalJobs}
            singleJobTime={singleJobTime}
            machineImage={machineImage}
            psImage={psImage}
            cppSum={cppSum}
            initialDocumentState={initialDocumentState}
            workingHours={workingHours}
            hotspotArray={hotspotArray}
            highestIndex={highestIndex}
            hightlightKey={hightlightKey}
            isHotSpotAvailable={isHotSpotAvailable}
            technology_name={technology_name}
          />
        )}
      {/* </div> */}
    </>
  );
};

export default ThirdReport;
