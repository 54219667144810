import React, { useState } from "react";
import MapChart from "../components/mapChart";
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import ReactTooltip from "react-tooltip";

const GET_REGIONS = gql`
  query GetRegionsByUserGroup($group_id : Int!){
    getRegionsByUserGroup(group_id : $group_id ){
        name
        region_id
        exchange_rate
        currency_symbol
      }
      
  }
`;

const Currency = ({ setCurrency, calculator }) => {
  const [loading, setloading] = useState(false);
  const client = useApolloClient();
  const [dataShow, setDataShow] = useState([]);
  let [regionNames] = useState([]);
  const [content, setContent] = useState("");
  let group_id = (+sessionStorage.getItem('group_id'));

  React.useEffect(() => {
    console.log("mapsection", calculator);
    client.query({
      query: GET_REGIONS,
      variables: { group_id }
    }).then(res => {
      setDataShow(res.data);
      res.data.getRegionsByUserGroup.map((temp) => {
        if (temp.name === "Korea") {
          regionNames.push("South Korea");
          regionNames.push("North Korea");
        } else {
          regionNames.push(temp.name);
        }
        return temp.name;
      });
      setloading(true)
    }).catch(error => {
      // toast.error(error.graphQLErrors[0].messasge.message)
    });
  });
  return (
    <React.Fragment >
      {loading ?
        <div id="map-wrapper" className="d-flex justify-content-center flex-column mb-2">
          <p className="primary-color h2 text-center">Please select your country of interest</p>
          <p className="mb-5"><strong>Please Note:</strong> Regions are divided into three each according to our EOS model which are EMEA, North America and APAC. You can select any country under the region and the pricing will be the same for e.g. it wouldn’t matter if you select Austria or Germany under region EMEA</p>
          <MapChart setTooltipContent={setContent} regionNames={regionNames} setCurrency={setCurrency} data={dataShow} calculator={calculator} />
          <ReactTooltip >{content} </ReactTooltip>
        </div>
        :
        // <div className="spinner-border text-secondary mt-n5"></div>
        <img className="mt-n5" src="./Spinner.svg" alt="loader" height="100px" />
      }
    </React.Fragment>
  )

}

export default Currency;