import { atom } from "recoil";

export const PowderPerBuildJobData = atom({
  key: "PowderPerBuildJobData",
  default: undefined,
});

export const Co2ePerJobPMData = atom({
  key: "Co2ePerJobData",
  default: undefined,
});
export const Co2ePerPartPMData = atom({
  key: "Co2ePerPartPMData",
  default: undefined,
});
