import React, { memo, useState } from "react";

import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";
import { CONDITIONALROUTES, SELECT_ROUTE } from "./ConditionalEnums";

const MapChart = ({ setTooltipContent, setCurrency, regionNames, calculator }) => {
  const [name, setname] = useState('');
  const [zoom, setZoom] = useState(1);
  const [coordinates, setCoordinates] = useState([1, 1]);


  const countryCheck = (name) => {
    return (regionNames.indexOf(name) > -1);
  }

  function handleMoveEnd(position) {
    // console.log("position end", position);
    setZoom(position.zoom)
    setCoordinates([0, 0]);
  }
  function handleMoveStart(position) {
    // console.log('move start', position)
    setCoordinates(position.coordinates);
  }

  // const europeanSet = new Set(['Germany', 'Austria', "United Kingdom", 'France', 'Sweden', 'Switzerland', 'Italy', 'Finland', 'Denmark'])
  // const americanSet = new Set(["United States of America", "Canada"])
  //TODO Fucntionality halted for now as data is not available

  return (
    <ComposableMap
      className="wrapperStyles"
      projection="geoEquirectangular"
      projectionConfig={{
        scale: 160
      }}
      data-tip=""
      width={980}
      height={451}
      style={{
        width: "100%",
        height: "74%",
      }}>
      <ZoomableGroup
        zoom={zoom}
        center={coordinates}
        onMoveEnd={handleMoveEnd}
        onMoveStart={handleMoveStart}
      >
        <Geographies geography={"features.json"}>
          {({ geographies }) =>
            geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  const { NAME, CONTINENT } = geo.properties;
                  if (NAME !== "Antarctica") {
                    setTooltipContent(`${NAME} — (${CONTINENT})`);
                  }
                  // Mouse Hover Geo Properties of regions...
                  // console.log(geo.properties)
                }}
                onClick={() => {
                  const { NAME, CONTINENT } = geo.properties;
                  setname(NAME);
                  // Selected Region....
                  // console.log(geo.properties)
                  if (countryCheck(CONTINENT) || countryCheck(NAME)) {
                    // if (CONTINENT === 'North America' || NAME === 'America') {
                    //   if (americanSet.has(NAME)) {
                    //     setCurrency(NAME);
                    //   }
                    //   else {
                    //     setCurrency(CONTINENT);
                    //   }
                    // } else if (CONTINENT === 'Europe' || NAME === 'Russia') {
                    //   if (europeanSet.has(NAME)) {
                    //     setCurrency(NAME);
                    //   }
                    //   else {
                    //     setCurrency(CONTINENT);
                    //   }
                    // } else if (NAME === "South Korea" || NAME === "North Korea") {
                    //   setCurrency("Korea");
                    // }
                    // else {
                    //   setCurrency(NAME);

                    // }
                    if (CONTINENT === 'North America' || NAME === 'America') {
                      setCurrency(CONTINENT);
                    } else if (CONTINENT === 'Europe' || NAME === 'Russia') {
                      setCurrency(CONTINENT);
                    } else if (NAME === "South Korea" || NAME === "North Korea") {
                      setCurrency("Korea");
                    }
                    else {
                      setCurrency(NAME);

                    }
                    if (calculator === SELECT_ROUTE.COST) { window.location.href = CONDITIONALROUTES.SELECTED_REGION_NAME_COST + NAME; }
                    else if (calculator === SELECT_ROUTE.CARBON) { window.location.href = CONDITIONALROUTES.SELECTED_REGION_NAME_CARBON + NAME; }
                    else {
                      window.location.href = CONDITIONALROUTES.SELECTED_REGION_NAME_COST + NAME;
                    }
                  }
                }}
                onMouseLeave={() => {
                  setTooltipContent("");
                }}
                style={{
                  default: {
                    fill:
                      (countryCheck(geo.properties.NAME) || countryCheck(geo.properties.CONTINENT))
                        ? (geo.properties.NAME === name) ? "#005f6a" : "#005059"
                        : (geo.properties.NAME === "Antarctica") ? "#EDEDEE" : "#9ea6b4",

                    stroke: (geo.properties.NAME === "Antarctica") ? "#EDEDEE" : "#ffffff",
                    strokeWidth: 0.65,
                    outline: "none",
                  },
                  hover: {
                    fill:
                      (countryCheck(geo.properties.NAME) || countryCheck(geo.properties.CONTINENT))
                        ? "#005f6a"
                        : (geo.properties.NAME === "Antarctica") ? "#EDEDEE" : "#525252",

                    stroke: (geo.properties.NAME === "Antarctica") ? "#EDEDEE" : "#ffffff",
                    strokeWidth: 1,
                    outline: "none",
                  },
                  pressed: {
                    fill: "#005f6a",
                    stroke: "#ffffff",
                    strokeWidth: 1,
                    outline: "none",
                  }
                }}
              />
            ))
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
};


export default memo(MapChart);