import { atom } from "recoil";

export const Co2PolyCo2EmissionFactorPricingData = atom({
    key: "Co2PolyCo2EmissionFactorData",
    default: undefined,
});

export const Co2PolyCo2ePowderData = atom({
    key: "Co2PolyCo2ePowderData",
    default: undefined,
});

export const Co2PolyCo2eEnergyPricingData = atom({
    key: "Co2PolyCo2eEnergyData",
    default: undefined,
});

export const Co2PolyCo2EmissionTransportationData = atom({
    key: "Co2PolyCo2EmissionTransportationData",
    default: undefined,
});
