import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Spinner } from "react-bootstrap";
import {
  CCMetalTotalCarbonPerPartDataM,
  CCMetalCostOfOneKGCo2DataM,
  CCMetalCostOfCo2PerPartFDataM,
  CCMetalCppWithCo2DataM,
} from "atoms/CCCarbonMetal/CCMetalPricing";
import { CommonCarbonPricingPolyMetal } from "atoms/CostToCarbonData";
import { DebounceInput } from "react-debounce-input";
import {
  CCMetalCo2EmissionBuildProcessDataM,
  CCMetalCo2EmissionGasDataM,
  CCMetalCo2EmissionPowderDataM,
  CCMetalCo2EmissionTransportationDataM,
} from "atoms/CCCarbonMetal/CCMetalCarbonContent";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import config from "../../../../../config/index";

const Co2Pricing = ({ Socket, staticDataOfMachine }) => {
  const [totalCo2pp, setTotalCo2pp] = useRecoilState(
    CCMetalTotalCarbonPerPartDataM
  );
  const [costOneKGCo2, setCostOneKGCo2] = useRecoilState(
    CCMetalCostOfOneKGCo2DataM
  );
  const [costOfCo2pp, setCostOfCo2pp] = useRecoilState(
    CCMetalCostOfCo2PerPartFDataM
  );
  const [cppWithCo2, setCppWithCo2] = useRecoilState(CCMetalCppWithCo2DataM);
  const co2EmissionBuildProcess = useRecoilValue(
    CCMetalCo2EmissionBuildProcessDataM
  );
  const co2EmissionPowder = useRecoilValue(CCMetalCo2EmissionPowderDataM);
  const co2EmissionGas = useRecoilValue(CCMetalCo2EmissionGasDataM);
  const co2EmissionTransportation = useRecoilValue(
    CCMetalCo2EmissionTransportationDataM
  );
  const [, setAllValueState] = useRecoilState(
    CommonCarbonPricingPolyMetal
  );
  const { getComputedValue } = useRoundOffValue();
  const [, setProcessFlag] = useRecoilState(ProcessFlag);
  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);


  useEffect(() => {
    Socket.off("*");

    Socket.emit("carbon-calculator", {
      key: "DBCC-CPCPOKC",
      input: {
        technology_id: +staticDataOfMachine?.technology_id?.value,
        region_id: +staticDataOfMachine?.region_id?.value,
      },
    });

    Socket.on("DBCC-CPCPOKC", function (data) {

      const value = getComputedValue(data?.data?.value);
      window.localStorage.setItem("costOneKGCo2", value);

      setCostOneKGCo2(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      co2EmissionBuildProcess !== undefined &&
      co2EmissionPowder !== undefined &&
      co2EmissionGas !== undefined &&
      co2EmissionTransportation !== undefined
    ) {
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-CPTCPP",
        input: {
          co2_emission_build_process: co2EmissionBuildProcess,
          co2_emission_powder: co2EmissionPowder,
          co2_emission_gas: co2EmissionGas,
          co2_emission_transportation: co2EmissionTransportation,
          technology_id: 1,
        },
      });

      Socket.on("FOCC-CPTCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setTotalCo2pp(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2EmissionBuildProcess,
    co2EmissionGas,
    co2EmissionPowder,
    co2EmissionTransportation,
    costOneKGCo2,
  ]);

  useEffect(() => {
    if (totalCo2pp !== undefined && costOneKGCo2 !== undefined) {
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCCPP",
        input: {
          total_co2e_per_part: totalCo2pp,
          cost_of_producing_one_kg_co2e: costOneKGCo2,
          technology_id: 1,
        },
      });

      Socket.on("FOCC-CPCCPP", function (data) {

        const value = getComputedValue(data?.data?.value);
        window.localStorage.setItem("metalCo2pp", value);

        setCostOfCo2pp(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCo2pp, costOneKGCo2]);

  useEffect(() => {
    if (
      costOfCo2pp !== undefined &&
      staticDataOfMachine.post_processing_cost_per_part !== undefined &&
      staticDataOfMachine.material_cost_per_part !== undefined &&
      staticDataOfMachine.system_cost_per_part &&
      staticDataOfMachine?.overhead_and_consumables
    ) {
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-CPCPPWC",
        input: {
          cost_of_co2e_per_part: costOfCo2pp,
          post_processing_cost_per_part: +(
            staticDataOfMachine.post_processing_cost_per_part.value
          ),
          material_cost_per_part: +(
            staticDataOfMachine.material_cost_per_part.value
          ),
          system_cost_per_part: +(
            staticDataOfMachine.system_cost_per_part.value
          ),
          overheads_and_consumeable: +staticDataOfMachine?.overhead_and_consumables?.value
        },
      });

      Socket.on("FOCC-CPCPPWC", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCppWithCo2(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costOfCo2pp, staticDataOfMachine?.overhead_and_consumables]);

  useEffect(() => {
    if (
      totalCo2pp !== null &&
      costOneKGCo2 !== null &&
      costOfCo2pp !== null &&
      cppWithCo2 !== null
    ) {
      setAllValueState({
        totalCo2pp,
        costOneKGCo2,
        costOfCo2pp,
        cppWithCo2,
      });
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonPricing: true,
          ScenarioAnalysis: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCo2pp, costOneKGCo2, costOfCo2pp, cppWithCo2]);

  return (
    <>
      <p className="input-heading">Total carbon per part (kg) </p>
      <div className="data-field">
        {totalCo2pp !== undefined || totalCo2pp !== null ? (
          totalCo2pp
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
      <p className="input-heading">
        Carbon shadow pricing factor  ({obj.currency_symbol}/kg) <a className="click-here" href={config.carbonShadowSuggestionUrl} target="_blank" rel="noopener noreferrer">(Click here for more details)</a>
      </p>
      {costOneKGCo2 !== undefined || costOneKGCo2 !== null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            onChange={(e) => {
              setCostOneKGCo2(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={costOneKGCo2}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          {" "}
          <Spinner animation="border" size="sm" />
        </div>
      )}

      <p className="input-heading">
        Cost of CO2e per part {`(${obj.currency_symbol})`}
      </p>
      <div className="data-field">
        {costOfCo2pp !== undefined || costOfCo2pp !== null ? (
          costOfCo2pp
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
      <p className="input-heading">
        CPP with CO2e {`(${obj.currency_symbol})`}
      </p>

      <div className="data-field">
        {cppWithCo2 !== undefined || cppWithCo2 !== null ? (
          cppWithCo2
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
    </>
  );
};

export default Co2Pricing;
