import React from 'react'

const ConsultingTeam = ({ navigate }) => {
    return (
        <a
            target="_blank"
            href={navigate}
            className="selection-button step-button mw-100 mr-4 text-white"
        >
            Get in Touch with the Consulting Team
        </a>
    )
}

export default ConsultingTeam