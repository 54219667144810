import gql from "graphql-tag";

export const GET_STEP_DETAILS = gql`
  query ($step_id: Int!) {
    getFieldsByStepId(step_id: $step_id) {
      field_id
      name
      field_unit
      field_info
      field_type
      field_input_type
      keys
      formula_inputs
      field_data_type
      formula
      step_id {
        step_id
        name
      }
      data_reference
      is_override_enabled
      is_optional
      field_step_index
      renderer
      is_premium
      technology_id {
        technology_id
        name
        description
      }
      step_type_id {
        step_type_id
        name
      }
    }
  }
`;
export const GET_CLIENT_MACHINE = gql`
  query ($technology_id: Int!) {
    getClientMachineByTechnology(technology_id: $technology_id) {
      machine_id
      name
    }
  }
`;

export const GET_MACHINE_NAME_BY_ID = gql`
  query($machine_id: Int!) {
    getMachineNameById(machine_id: $machine_id){
      name
    }
  }
`;
export const GET_MATERIAL_NAME_BY_ID = gql`
  query($material_id: Int!) {
    getMaterialNameById(material_id: $material_id){
      name
    }
  }
`;

export const GET_PARAMERTER_NAME_BY_ID = gql`
  query($paremeter_id: Int!){
    getClientParemeterNameById(paremeter_id: $paremeter_id)
  }
`

export const GET_CLIENT_MATERIAL = gql`
  query ($machine_id: Int!, $group_id: Int!) {
    getClientMaterialByMachineId(machine_id: $machine_id, group_id: $group_id) {
      name
      material_id
    }
  }
`;
