import React from "react";

const Overview = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Overview</h3>
            <p>The Cost per part calculator covers two aspects, the estimation and simulation (detailed
                calculation) for both the technologies. Estimation can be used for a quick guide on what the
                cost per part would be without any simulation (build time report), this however will come with
                its deviations and should only be used to get a rough idea of the costs</p>
            <p>Simulations on the other hand as the name suggests are based on a build simulation which
                feeds in the calculator to give quite accurate values. Care must be taken as here also some
                aspects of calculation are assumed and used as average values so exact calculation might vary
                a little. For this it is always recommended to discuss with our experts and guide a business case
                accordingly.
            </p>
        </>
    )
}

export default Overview;