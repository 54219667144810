import React from "react";

const ForgotGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Forgot Password</h3>
            <p>In case you forget your password you can use always reset your password via account recovery option on login page. </p>
            <img src="/assets/forgot-guide-1.png" alt="" className="img-fluid" />
            <p>Clicking on this option will take you to page where you can enter your email in order to get forget password request. </p>
            <img src="/assets/forgot-guide-2.png" alt="" className="img-fluid" />
            <p>You will receive an email with a reset password link once you submit this request. In case you don’t receive the request you can resend the email. </p>
            <img src="/assets/forgot-guide-3.png" alt="" className="img-fluid" />
            <p>The URL will take you to the page on which you can update password </p>
            <img src="/assets/forgot-guide-4.png" alt="" className="img-fluid" />        
        </>
    )
}

export default ForgotGuide;