import React, { useEffect, useState, useRef } from "react";
import {
  GET_PRODUCTION_SCENARIO,
  GET_PRODUCTION_SCENARIO_METAL,
} from "../../view/CCPolymerSteps/components/CarbonPolymerProcess/components/getStepDetail";
import { useApolloClient } from "@apollo/react-hooks";
import { JobsPerWeek } from "atoms/General";
import { useRecoilState } from "recoil";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { colorObj } from "utils/ProductionScheduleColors";
import { Spinner } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import { WorkingHoursData } from "utils/selectDropdowns";
import { WorkingHours } from "atoms/General";
import { useScreenshot } from 'use-react-screenshot'

const ProductionScenario = ({
  getjobsWeek,
  staticData,
  setStaticData,
  getTurnAroundTime,
  type,
  setPsImage
}) => {
  const client = useApolloClient();
  const [getJobsPerWeek, setGetJobsPerWeek] = useState([]);
  const [jobsPerWeek, setJobsPerWeek] = useRecoilState(JobsPerWeek);
  const [workingHours, setWorkingHours] = useRecoilState(WorkingHours)
  const [jobWeekFlag, setJobWeekFlag] = useState(false);
  const [psQuery, setPSQuery] = useState();
  const [loader, setLoader] = useState(true);
  const ref = useRef(null)
  const [image, takeScreenshot] = useScreenshot()

  useEffect(() => {
    if (type === "Polymer") {
      setPSQuery(GET_PRODUCTION_SCENARIO);
    } else {
      setPSQuery(GET_PRODUCTION_SCENARIO_METAL);
    }
    if (
      psQuery === GET_PRODUCTION_SCENARIO ||
      psQuery === GET_PRODUCTION_SCENARIO_METAL
    ) {
      client
        .query({
          query: psQuery,
          variables: staticData,
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          // console.log(data?.data?.productionScheduling?.productionScheduling, "Check 1"); TODO
          const schedules = data?.data?.productionScheduling?.productionScheduling || {}
          const jobsArray = Object.values(schedules)

          const singleJobsArray = jobsArray.map((jobs) => {
            return jobs.map(singleJob => {
              const hourRatio = Object.values(singleJob)[0].split("-");
              const hoursDiffernece = hourRatio[1] - hourRatio[0];
              return {
                key: Object.keys(singleJob)[0],
                value: hoursDiffernece,
                percentage: parseFloat(
                  ((hoursDiffernece / 24) * 100).toFixed(2)
                ),
                color: colorObj[Object.keys(singleJob)[0]],
              };
            })


          });
          // console.log(singleJobsArray, 'single jobs array') TODO
          setGetJobsPerWeek(singleJobsArray);
          setJobWeekFlag(true);
          getjobsWeek(data?.data?.productionScheduling?.totalNumberOfJobs);
          setJobsPerWeek(singleJobsArray);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error in process", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticData,]);

  useEffect(() => {
    setStaticData((prevState) => {
      return {
        ...prevState,
        isOnlyWorkingHours: workingHours === "true"
      }
    })
  }, [setStaticData, workingHours])


  const [singleJobTime, setSingleJobTime] = useState(0);

  useEffect(() => {
    let count = 0;
    if (jobWeekFlag) {
      getJobsPerWeek.forEach((JobInstance) => {
        JobInstance.forEach((data) => {
          if (data.key === "Setup time") {
            count = count + 1;
          }
          if (count === 1) {
            setSingleJobTime((singleJob) => singleJob + data.value);
          } else if (count > 1) return;
        });
        if (count > 1) return;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobWeekFlag]);
  getTurnAroundTime(singleJobTime);

  useEffect(() => {
    if (getJobsPerWeek.length && ref.current && !loader) {
      takeScreenshot(takeScreenshot(ref.current))
    }
  }, [getJobsPerWeek, loader])

  useEffect(() => {
    if (image && setPsImage) {
      setPsImage(image)
    }
  }, [image])

  return (
    <>
      <div className="d-flex w-100 justify-content-center align-items-center position-relative pt-3">
        {/* <p>Working hours: 9:00 to 17:00, from Monday to Friday</p> */}
        <p className="color-rep-value">
          Working hours: 9:00 to 17:00, from Monday to Friday
        </p>
        <SelectDropdown optionAvailable={false} setState={setWorkingHours} getState={workingHours} Dataset={WorkingHoursData} styleclass="dropdown-input p-0 ps-dropdown position-absolute" />

      </div>

      <div className="powder-container w-75" id="productionschedule" ref={ref}>
        <div className="powder-table-top">
          <div className="powder-heading d-flex flex-row m-auto p-0">
            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Mon</span>

            </div>
            <i className="divider"></i>
            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Tue</span>

            </div>
            <i className="divider"></i>

            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Wed</span>


            </div>
            <i className="divider"></i>
            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Thu</span>


            </div>
            <i className="divider"></i>
            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Fri</span>


            </div>
            <i className="divider"></i>
            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Sat</span>


            </div>
            <i className="divider"></i>
            <div className="d-flex justify-content-center align-items-center w-25 mb-2  flex-column  pr-2 pl-2 ps-day-container">
              <span>Sun</span>


            </div>
          </div>
        </div>
        <div className="d-flex flex-row mb-3 powder-details">
          {loader ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <Spinner animation="border" size="md" />
            </div>
          ) : (
            getJobsPerWeek.map((element, index) => {
              return (
                <>
                  <div className="weekday">
                    <div key={index} className="singleDaySchedule m-auto">
                      {element.map((dataInstance, index) => {

                        return (
                          <div
                            key={index}
                            style={{
                              backgroundColor: dataInstance.color,
                              width: `${dataInstance.percentage}%`,
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })
          )}
        </div>

        <div className="d-flex justify-content-center align-items-center pt-5 pb-2">
          <div className="d-flex">
            <div className="color-icon bg-unpackingTime" />
            <p className="color-rep-value">Unpacking Time:</p>
            <p className="color-rep-value">{staticData?.unpackingtime} h</p>

          </div>
          <div className="d-flex">
            <div className="color-icon bg-setupTime" />
            <p className="color-rep-value ">Setup Time:</p>
            <p className="color-rep-value">{staticData?.setupTime} h</p>

          </div>
          <div className="d-flex">
            <div className="color-icon bg-buildTime" />
            <p className="color-rep-value">Build Time:</p>
            <p className="color-rep-value">{staticData?.buildTime} h</p>

          </div>
          {
            type === "Polymer" &&
            <div className="d-flex">
              <div className="color-icon bg-coolDownTime" />
              <p className="color-rep-value ">Cool Down Time:</p>
              <p className="color-rep-value">{staticData?.coolDownTime} h</p>

            </div>}
        </div>
      </div>
    </>
  );
};

export default ProductionScenario;
