import React from 'react'

const DataMatricCommonRepresenter = ({ caption }) => {
    return (

        <table class="table table-bordered w-25 table-hover">
            <caption>{caption}</caption>

            <tbody>
                <tr>
                    <th className='border border-dark bg-green text-alignment pl-2' scope="col">Measured</th>
                </tr>
                <tr>
                    <th className='border border-dark text-alignment bg-yellow pl-2' scope="col">Academic papers/studies</th>
                </tr>
                <tr>
                    <th className='border border-dark bg-orange text-alignment pl-2' scope="col">Assumptions from EOS (inhouse)</th>
                </tr>
                <tr>
                    <th className='border border-dark text-alignment pl-2' scope="col">To be measured (according to prioritisation, e.g. from customer
                        projects)</th>
                </tr>
                <tr>
                    <th className='border border-dark bg-blue text-alignment pl-2' scope="col">Customer dependant</th>
                </tr>
                <tr>
                    <th className='bg-gray border border-dark text-alignment  pl-2' scope="col">Out of scope</th>
                </tr>
            </tbody>
        </table>
    )
}

export default DataMatricCommonRepresenter