import { atom } from "recoil"

export const PowderLossDataM = atom({
    key: "PowderLossDataM",
    default: undefined
})
export const PowderTypeDataM = atom({
    key: "PowderTypeDataM",
    default: 'GAS_ATOMIZED'
})

export const Co2ePerJobDataM = atom({
    key: "Co2ePerJobDataM",
    default: undefined
})
export const Co2ePerPartPMDataM = atom({
    key: "Co2ePerPartPMDataM",
    default: undefined
})

