export const AtomizationData = [
  {
    value: "GAS_ATOMIZED",
    name: "Gas Atomized",
  },
  {
    value: "WATER_ATOMIZED",
    name: "Water Atomized",
  },
];
export const AtomizationDataMetal = [
  {
    value: "GAS_ATOMIZED",
    name: "Gas Atomized",
  },
];

export const PercentageData = [
  {
    value: "0",
    name: "0%",
  },
  {
    value: "10",
    name: "10%",
  },
  {
    value: "20",
    name: "20%",
  },
  {
    value: "30",
    name: "30%",
  },
  {
    value: "50",
    name: "50%",
  },
];

export const YesNoData = [
  {
    value: "yes",
    name: "Yes",
  },
  {
    value: "no",
    name: "No",
  },
];

export const LogisticsData = [
  {
    value: "Rail",
    name: "Rail",
  },
  {
    value: "Road",
    name: "Road",
  },
  {
    value: "Air",
    name: "Air",
  },
  {
    value: "Ship",
    name: "Ship",
  },
];
export const WorkingHoursData = [
  {
    value: true,
    name: "One shift",
  },
  {
    value: false,
    name: "24/7 shift",
  },
];
