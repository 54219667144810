import { atom } from "recoil";

export const CCMetalNoOfPartsPerYearDataM = atom({
  key: "CCMetalNoOfPartsPerYearDataM",
  default: undefined,
});
export const CCMetalMaterialTransportationDataM = atom({
  key: "CCMetalMaterialTransportationDataM",
  default: undefined,
});
export const CCMetalSystemToCustomerDataM = atom({
  key: "CCMetalSystemToCustomerDataM",
  default: undefined,
});
export const CCMetalPowderToCustomerDataM = atom({
  key: "CCMetalPowderToCustomerDataM",
  default: undefined,
});
export const CCMetalSystemTransportationDataM = atom({
  key: "CCMetalSystemTransportationDataM",
  default: undefined,
});
export const CCMetalCarbonEmissionFactorNode1DataM = atom({
  key: "CCMetalCarbonEmissionFactorNode1DataM",
  default: undefined,
});
export const CCMetalCarbonEmissionFactorNode2DataM = atom({
  key: "CCMetalCarbonEmissionFactorNode2DataM",
  default: undefined,
});
export const CCMetalCo2ePerYearDataM = atom({
  key: "CCMetalCo2ePerYearDataM",
  default: undefined,
});
export const CCMetalCo2ePerPartTMDataM = atom({
  key: "CCMetalCo2ePerPartTMDataM",
  default: undefined,
});

export const CCMetalPowderManuFactureDataM = atom({
  key: "CCMetalPowderManuFactureDataM",
  default: undefined,
});
export const CCMetalPowderSiteDataM = atom({
  key: "CCMetalPowderSiteDataM",
  default: undefined,
});
export const CCMetalMachineSiteDataM = atom({
  key: "CCMetalMachineSiteDataM",
  default: undefined,
});
