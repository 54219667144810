import React from "react";

const AnalyticsGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Visual Analytics</h3>
            <p>The visual analytics page can be accessed by the last step of calculation by clicking on visual analytics button. </p>
            <img src="/assets/visual-guide-1.png" alt="" className="img-fluid" />
            <p>The visual analytics page will be shown for the numbers calculated.</p>
            <img src="/assets/visual-guide-2.png" alt="" className="img-fluid" />
            <p>The visual analytics page has two main sections the fine tune scenario section and the graph section.</p>
        </>
    )
}

export default AnalyticsGuide;