import React, { useEffect, useState } from "react"
import config from "../../../../../../../config";
const axios = require("axios").default;



export const useGetMachineImage = (machineId) => {
    const [machineImage, setMachineImage] = useState()

    useEffect(() => {
        const getMachineImage = async () => {
            try {
                const access_token = sessionStorage.getItem('access_token');

                const response = await axios.default.get(`${config.serviceUrl}machine/get/image`, {
                    params: {
                        id: machineId
                    },
                    headers: {
                        Authorization: access_token,

                    }
                })

                setMachineImage(response.data)
            } catch (error) {
                console.log("MAchineImage error", error);
            }
        }
        getMachineImage()
    }, [])
    return machineImage
}
