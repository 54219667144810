import React, { useEffect } from 'react'
import CurrentReportsPolymer from './CCPolymerSteps/components/CurrentReports'
import CurrentReportsMetal from './CCCarbonMetal/components/CurrentReports'
import { CONDITIONALROUTES, TECHTYPE } from 'app/components/ConditionalEnums';
import { ToastContainer } from "react-toastify";
import { VisualPageReload } from 'atoms/General';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CostData } from 'atoms/CostToCarbonData';
import Nav from "app/components/Nav";
import { Button } from 'react-bootstrap';

const GeneratedReport = () => {
    const [reload, setReload] = useRecoilState(VisualPageReload)
    const staticCostData = useRecoilValue(CostData)
    const savedRoute = sessionStorage?.getItem('Analytics_Route')
    console.log(savedRoute, 'savedRoute');

    const handlePreviousClick = () => {
        if (savedRoute) {
            window.location.href = CONDITIONALROUTES.VISUAL_ANALYTICS_SAVED
        }
        else {
            window.location.href = CONDITIONALROUTES.VISUAL_ANALYTICS
        }
    }


    useEffect(() => {
        setReload(true)
    }, []);
    const technology = sessionStorage.getItem("technology")
    return (
        <>
            <ToastContainer />
            <Nav />
            <div className="row  ">
                <div className="col page-container px-0">
                    <div className="">
                        <div>
                            <h3 className="graph-heading text-center">Cost Report</h3>
                            <div className="text-center prev-btn">
                                <Button className='selection-button step-button text-center' onClick={() => handlePreviousClick()}>Visual Analytics</Button>
                            </div>
                        </div>
                        {
                            technology === TECHTYPE.POLYMER
                                ?
                                <CurrentReportsPolymer isHotSpotAvailable={true} />
                                :
                                <CurrentReportsMetal isHotSpotAvailable={true} staticDataOfMachine={staticCostData} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default GeneratedReport