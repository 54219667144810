import React from "react";
import { BlobProvider } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Image, } from '@react-pdf/renderer';
import config from '../../../../../../../config';
import { toast } from 'react-toastify';
import { Font } from '@react-pdf/renderer'
import Html from 'react-pdf-html';
import { NOTIFICATION_MESSAGES } from "app/components/ConditionalEnums";
import { saveAs } from "file-saver";


const axios = require('axios').default;
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 800 }
    ]
});
const styles = StyleSheet.create({
    page: { backgroundColor: 'white', color: 'black', fontSize: 14, display: "flex", flexDirection: "column", width: "100%" },
    headerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: "10px 7% 10px 3%", alignItems: "center", width: "100%" },
    logo: { height: 50 },
    logoTwo: { height: 80 },
    header: { width: '35%', fontWeight: "ultrabold", fontSize: 20, textAlign: "center" },
    reportsSection: { width: '100%', display: "flex", justifyContent: "center", alignItems: "center" },
    reportsDetailsSection: { width: '100%', display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: "#fffff", paddingTop: 22.5, paddingBottom: 22.5, paddingLeft: 45, paddingRight: 45 },
    reportsDetailInner: {
        paddingTop: 22.5,
        display: "flex",
        flexDirection: "row",
    },
    detailsView: { padding: 15 },
    units: {
        fontWeight: "semibold",
        color: "#111",
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    dataField: {
        backgroundColor: "#f2f2f2",
        width: "100%",
        minWidth: 36,
        minHeight: 36,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: " center",
        borderRadius: 7.5,
        fontFamily: 'Helvetica-Bold',
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 9,
        padding: 12,
        marginBottom: 12,
        color: "#99a4b4",
        border: "none !important",
        boxShadow: "none !important",
    },
    inputHeading: {
        fontFamily: 'Helvetica-Bold',
        fontStyle: "normal",
        fontWeight: "heavy",
        fontSize: 12,
        textTransform: "capitalize",
        color: "#000",
        marginBottom: 6,
    },
    inputHeading2: {
        fontFamily: 'Helvetica-Bold',
        fontStyle: "normal",
        fontWeight: "heavy",
        fontSize: 12,
        textTransform: "capitalize",
        color: "#fff",
        marginBottom: 6,
    },
    powderContainer: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 30,
        backgroundColor: "#ffffff",
        marginTop: 30,
        borderRadius: 10,

    },
    powderTableTop: {
        width: "100%",
        backgroundColor: "#156481",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        display: "flex",
        flexDirection: "row",
        padding: "0 20",
    },
    powderHeading: {
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 16,
        textAlign: "left",
        textTransform: "capitalize",
        color: "#000",
        paddingTop: 10,
        paddingBottom: 10,
    },
    powderHeading2: {
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 16,
        textAlign: "left",
        textTransform: "capitalize",
        color: "#fff",
        paddingTop: 10,
        paddingBottom: 10,
    },
    totalBottom: {
        backgroundColor: "#156481",
        borderRadius: 10,
        marginBottom: 0,
        display: "flex",
        justifyContent: "space-between",
    },
    partsSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 140,
        marginBottom: 60,
    },
    dayBorder1: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12
    },
    dayBorder2: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12
    },
    partsHeading: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 28,
        color: "#252525",
    },
    partsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    dayContainerSpacing: {
        marginRight: 30,

    },
    dayContainer: {
        width: 200,
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10.5 6',
    },
    tomorrowBg1: {
        backgroundColor: "#4EA0B0",
    },
    tomorrowBg2: {
        backgroundColor: "#56AFC0",
    },
    tomorrowBg3: {
        backgroundColor: "#5DC0D3",
    },
    tomorrowBg4: {
        backgroundColor: "#5FCCE1",
    },
    tomorrowBg5: {
        backgroundColor: "#59D4EC"
    },
    todayBg1: {
        backgroundColor: "#156481",
    },
    todayBg2: {
        backgroundColor: "#1B7395"
    },
    todayBg3: {
        backgroundColor: "#1F7FA4"
    },
    todayBg4: {
        backgroundColor: "#1E88B0"
    },
    todayBg5: {
        backgroundColor: "#218DB7"
    },
    dayText: {
        fontFamily: "Helvetica-Bold",
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 12,
        color: "#252525",
    },
    color2: {
        color: "white"
    },
    color1: {
        color: "black"
    },
    graphSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: 140,
    },
    graphHeading: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 36,
        lineHeight: 49,
        textTransform: "capitalize",
        color: "#252525",
        paddingBottom: 60,
    },
    divTable: {
        display: "table",
        width: "100%",
    },
    divTableBody: {
        display: "flex",
        flexDirection: "column"
    },
    divTableRow: {
        display: "table-row"
    },
    divTableCell1Heading: {
        fontFamily: 'Helvetica-Bold',

        display: "table-cell",
        fontStyle: "normal",
        fontWeight: "heavy",
        fontSize: 15,
        textTransform: "capitalize",
        color: "#156481",

        minWidth: 100,
    },
    divTableCell1: {
        display: "table-cell",
        fontStyle: "normal",
        fontSize: 13,
        color: "#000",
        minWidth: 100,

    },
    responsiveScroll: {
        width: "50%"
    },
    colorIcon: {
        width: 16,
        height: 16,
        borderRadius: 3,
        marginRight: 8,
    },
    bgColor5: {
        background: '#07BEB8'
    },
    bgColor6: {
        background: '#68D8D6'
    },
    bgColor7: {
        background: ' #9CEAEF'
    },
    colorRepValue: {
        fontStyle: 'normal',
        fontWeight: "semibold",
        fontSize: 14,
        lineHeight: 19,
        textTransform: 'capitalize',
        color: '#99a4b4',
        marginRight: 40,
    },
    partInfoView: { display: 'flex', flexDirection: 'row' },
    colorPalletePP: { width: 10, height: 10, backgroundColor: '#7761cf' },
    colorPalleteSCPP: { width: 10, height: 10, backgroundColor: '#fcc342' },
    colorPalleteTCPP: { width: 10, height: 10, backgroundColor: '#00bff3' },
    p0: { padding: "0" },
    w_100: { width: '100%' },
    w_90: { width: '90%' },
    w_60: { width: '60%' },
    w_50: { width: '50%' },
    w_40: { width: '40%' },
    w_33: { width: '33.3%' },
    w_30: { width: '30%' },
    w_20: { width: '20%' },
    w_25: { width: '25%' },
    w_15: { width: '15%' },
    w_10: { width: '10%' },
    d_flex: { display: 'flex' },
    row: { flexDirection: 'row' },
    singlelinetext: { whiteSpace: "nowrap" },
    para_font_size: { fontSize: '10px' },
    tableView: { fontSize: 12 },
    footerBox: { display: 'flex', justifyContent: 'end', alignItems: 'center' },
    footerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 10, position: 'absolute', bottom: -100, left: -50, width: '100%' },
});
const styleSheet2 = {
    ul: {
        width: "80%"
    },
    li: {
        fontSize: 10
    }
}
const Doc = ({ formObj, image, currencySymbol, donutImage, typeDocument, pdfName, HotsspotArray, highestIndex }) => (
    <Document>
        <Page size="C3" style={styles.page}>
            <View style={[styles.w_100, styles.headerView]} fixed>
                <Image style={[styles.logo]} src="../assets/eosLogo.png" />
                <View style={[styles.w_40, { display: 'flex' }]}>
                    <Text style={{ fontWeight: "ultrabold", fontSize: 20, textAlign: "center", color: "#156481" }}>Carbon Calculator Report</Text>
                    <Text style={{ fontWeight: "ultrabold", fontSize: 20, textAlign: "center", color: "#156481" }}>{pdfName} </Text>
                </View>
                <Image style={[styles.logoTwo]} src="../assets/logo.png" />
            </View>

            <View style={{ display: "flex", flexDirection: "column", }}  >
                <View style={{ width: '90%', display: "flex", flexDirection: "column", backgroundColor: "#156481", paddingTop: 22.5, paddingBottom: 22.5, paddingLeft: 5, paddingRight: 5, borderRadius: 10, marginLeft: "45", marginTop: 60, marginBottom: 60, borderColor: "#156481", borderWidth: 3, }}>
                    <View style={{ display: "flex", flexDirection: "row", width: "100%", }} >
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]} >
                                Machine details
                            </Text>
                            <Text style={styles.dataField}>
                                Machine:&nbsp;
                                <Text style={styles.units}>
                                    {formObj.machinename.value ? formObj.machinename.value : 0}

                                </Text>
                            </Text>
                            <Text style={styles.dataField}>
                                Powder:&nbsp;
                                <Text style={styles.units}>
                                    {formObj.materialname.value ? formObj.materialname.value : 0}
                                </Text>
                            </Text>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View>
                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]}>Machine manufacturing</Text>
                                <Text style={styles.dataField}>
                                    currently not available
                                </Text>
                                <Text style={styles.dataField}>
                                    currently not available
                                </Text>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View >
                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]} >Powder manufacturing</Text>
                                <Text style={styles.dataField}>
                                    CO2e per job:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2perjobpmfg.value ? `${formObj.co2perjobpmfg.value} (kg)` : 0}

                                    </Text>
                                </Text>
                                <Text style={styles.dataField}>
                                    CO2e per part:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2perpartpmfg.value ? `${formObj.co2perpartpmfg.value} (kg)` : 0}
                                    </Text>
                                </Text>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View >
                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]} >Powder processing</Text>
                                <Text style={styles.dataField}>
                                    CO2e per job:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2perpartmp.value ? `${formObj.co2perpartmp.value} (kg)` : 0}
                                    </Text>
                                </Text>
                                <Text style={styles.dataField}>
                                    CO2e per part:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj?.co2perjobmp?.value ? `${formObj.co2perjobmp.value} (kg)` : 0}
                                    </Text>
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", width: "100%", }} >
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View >
                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]} >Additive Manufacturing Process</Text>
                                <Text style={styles.dataField}>
                                    CO2e per job:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2perjobamp.value ? `${formObj.co2perjobamp.value} (kg)` : 0}
                                    </Text>

                                </Text>
                                <Text style={styles.dataField}>
                                    CO2e per part:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2perpartamp.value ? `${formObj.co2perpartamp.value} (kg)` : 0}
                                    </Text>
                                </Text>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View>
                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading1]} >Logistics processing</Text>
                                <Text style={styles.dataField}>
                                    CO2e per year:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2peryeartr.value ? `${formObj.co2peryeartr.value} (kg)` : 0}
                                    </Text>
                                </Text>
                                <Text style={styles.dataField}>
                                    CO2e per part:&nbsp;
                                    <Text style={styles.units}>
                                        {formObj.co2perparttr.value ? `${formObj.co2perparttr.value} (kg)` : 0}
                                    </Text>
                                </Text>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View>
                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]} >Post processing </Text>
                                <Text style={styles.dataField}>
                                    currently not available
                                </Text>
                                <Text style={styles.dataField}>
                                    currently not available
                                </Text>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", width: "25%" }}>
                            <View>

                                <Text style={typeDocument === 0 ? [styles.inputHeading2] : [styles.inputHeading]} >Used powder-end of life</Text>
                                <Text style={styles.dataField}>
                                    currently not available
                                </Text>
                                <Text style={styles.dataField}>
                                    currently not available
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/* </View> */}

                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }}>
                    <View style={styles.powderContainer}>
                        <View style={[styles.powderTableTop, { borderColor: "#156481", borderWidth: 1, }]} >
                            <Text style={typeDocument === 0 ? [styles.powderHeading2] : [styles.powderHeading]}  >Scenario Analysis</Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "column", padding: "10 10 20 10", width: "100%", borderColor: "#156481", borderWidth: 1, }}>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1Heading, styles.w_40]}>CO2e powder</Text>
                                <View style={{ display: "flex", width: "60%", flexDirection: 'row', flex: '1', justifyContent: "flex-end" }}>
                                    {/* <Text style={[styles.divTableCell1Heading]}>Unit</Text> */}
                                    <Text style={[styles.divTableCell1Heading]}>Input</Text>
                                    <Text style={[styles.divTableCell1Heading, { paddingRight: 30 }]}>Current scenario</Text>
                                    <Text style={[styles.divTableCell1Heading, { paddingRight: 30 }]}>Future scenario</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40]}>Do you want to use carbon neutral powder in the future?</Text>
                                <View style={{ display: "flex", width: "60%", flexDirection: 'row', flex: '1', justifyContent: "space-between" }}>
                                    {/* <Text style={[styles.divTableCell1]}>CO2e kg/part</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 90 }]}>{formObj.cnpInput.value ? formObj.cnpInput.value : "Yes"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 50 }]}>{formObj.powderdata.value ? formObj.powderdata.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.powderdata.value ? formObj.powderdata.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40]}>How much volume reduction in % is possible with redesign?</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1]}>kg</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 90 }]}>{formObj.volumeReductionInput.value ? formObj.volumeReductionInput.value : "20%"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 50 }]}>{formObj.volumereductions.value ? formObj.volumereductions.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.volumereductions.value ? formObj.volumereductions.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40]}> What would the refresh rate be in the future?</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1]}>kg</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 90 }]}>{formObj.refreshRatePercentage.value ? formObj.refreshRatePercentage.value : "20%"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 50 }]}>{formObj.refreshRateScenario.value ? formObj.refreshRateScenario.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.refreshRateScenario.value ? formObj.refreshRateScenario.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40, { fontWeight: "heavy", fontFamily: "Helvetica-Bold" }]}>CO2e powder</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1]}>kg</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 90 }]}></Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 53 }]}>{formObj.co2epowder.value ? formObj.co2epowder.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.co2epowder.value ? formObj.co2epowder.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 20, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1Heading, styles.w_40]}>CO2e energy</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}>Unit</Text> */}
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40, { fontWeight: "heavy" }]}>How much energy could you reduce under material {"   "} processing?</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1,]}>Percentage</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 90 }]}>{formObj.energyPercentageInput.value ? formObj.energyPercentageInput.value : "10%"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 50 }]}>{formObj.energymaterial.value ? formObj.energymaterial.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.energymaterial.value ? formObj.energymaterial.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40]}>Is there a possibility to switch to a green energy mix?</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1]}>Green Energy</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 90 }]}>{formObj.greenPowderEnergyInput.value ? formObj.greenPowderEnergyInput.value : "Yes"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 48 }]}>{formObj.energysystem.value ? formObj.energysystem.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.energysystem.value ? formObj.energysystem.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40, { fontFamily: "Helvetica-Bold" }]}>CO2e energy</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    <Text style={[styles.divTableCell1, { opacity: 0 }]}></Text>
                                    {/* <Text style={[styles.divTableCell1,]}></Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 50 }]}>{formObj.co2eenergy.value ? formObj.co2eenergy.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.co2eenergy.value ? formObj.co2eenergy.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 20, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1Heading, styles.w_40]}>CO2e Logistics</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                    <Text style={[styles.divTableCell1Heading, { opacity: "0" }]}></Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40]}> Change the mode of logistics (node 1)</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1]}>Green Energy</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 85 }]}>{formObj.ModeOfTranspotation1.value ? formObj.ModeOfTranspotation1.value : "Yes"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 45 }]}>{formObj.ModeOfTransportNode1.value ? formObj.ModeOfTransportNode1.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.ModeOfTransportNode1.value ? formObj.ModeOfTransportNode1.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40]}>Change the mode of logistics (node 2)</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    {/* <Text style={[styles.divTableCell1]}>Green Energy</Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 85 }]}>{formObj.ModeOfTranspotation2.value ? formObj.ModeOfTranspotation2.value : "Yes"}</Text>
                                    <Text style={[styles.divTableCell1, { paddingLeft: 45 }]}>{formObj.ModeOfTransportNode2.value ? formObj.ModeOfTransportNode2.value.today : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.ModeOfTransportNode2.value ? formObj.ModeOfTransportNode2.value.tomorrow : 0}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 5, justifyContent: "space-between", }}>
                                <Text style={[styles.divTableCell1, styles.w_40, { fontFamily: "Helvetica-Bold" }]}>CO2e Logistics</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", width: "60%", flexDirection: 'row', flex: '1' }}>
                                    <Text style={[styles.divTableCell1, { opacity: 0 }]}></Text>
                                    {/* <Text style={[styles.divTableCell1,]}></Text> */}
                                    <Text style={[styles.divTableCell1, { paddingLeft: 52 }]}>{formObj.co2totaltransportation.value ? formObj.co2totaltransportation.value : 0}</Text>
                                    <Text style={[styles.divTableCell1]}>{formObj.co2totaltransportation.value ? formObj.co2totaltransportation.value : 0}</Text>
                                </View>
                            </View>
                        </View>


                        <View style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            backgroundColor: "#156481",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            marginBottom: 0,
                            padding: "0 20",
                            borderColor: "#156481",
                            borderWidth: 1,
                        }}>
                            <Text style={typeDocument === 0 ? [styles.powderHeading2] : [styles.powderHeading]} >Total (kg)</Text>
                            <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "30%" }}>
                                <Text style={typeDocument === 0 ? [styles.powderHeading2, { width: 85, textAlign: "center", paddingRight: "40" }] : [styles.powderHeading, { width: 85, textAlign: "center", paddingRight: "40" }]} >{formObj.co2totaltoday.value ? formObj.co2totaltoday.value : "1234"}</Text>
                                <Text style={typeDocument === 0 ? [styles.powderHeading2, { width: 85, textAlign: "center", paddingRight: "40" }] : [styles.powderHeading, { width: 85, textAlign: "center", paddingRight: "40" }]} >{formObj.co2totaltomorrow.value ? formObj.co2totaltomorrow.value : "1234"}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/* <View style={{ position: "fixed", marginTop: "25vh", display: "flex", justifyContent: "center", alignItems: "center", width: "95%", marginLeft: '24', }}>
                    <Text>For more information or in case of any question, please contact Additive Minds or EOS sales team </Text>
                    <View style={{ position: "absolute", right: "20px", bottom: "2px" }}>
                        <Text >page 1 of 3</Text>
                    </View>
                </View> */}
                {/* 
                <View style={{
                    display: "flex", justifyContent: "center", alignItems: "center", width: '100%', marginTop: 20,
                }} break>
                    <Text style={[styles.partsHeading]} >CO2e kg/Part</Text>
                </View> */}
                {/* <View style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <View style={{ borderRadius: 10, marginBottom: 10, border: 'none', display: "flex", flexDirection: "row", padding: 10, marginRight: 30 }}>
                        <View style={{ display: "flex", flexDirection: "column", marginTop: 40, }}>
                            <View style={[styles.dayContainer, { padding: '9 0 9 50', }]}>
                                <Text style={[styles.dayText]}>
                                    CO2e Powder
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, { padding: '9 0 9 50', }]}>
                                <Text style={[styles.dayText]}>
                                    CO2e Energy
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, { padding: '9 0 9 50', }]}>
                                <Text style={[styles.dayText]}>
                                    CO2e Transportation
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, { padding: '9 0 9 50', }]}>
                                <Text style={[styles.dayText]}>
                                    Total (kg)
                                </Text>
                            </View>
                        </View>
                        <View style={[styles.dayContainerSpacing, { display: "flex", flexDirection: "column", marginTop: 20 }]}>
                            <View style={[styles.dayContainer, styles.todayBg1, styles.dayBorder1]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    Today
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.todayBg2]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2epowder.value?.today ? formObj.co2epowder.value?.today : 0}
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.todayBg3]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2eenergy.value?.today ? formObj.co2eenergy.value?.today : 0}
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.todayBg4,]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2totaltransportation.value ? formObj.co2totaltransportation.value : 0}

                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.dayBorder2, styles.todayBg5]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2totaltoday.value ? formObj.co2totaltoday.value : 0}

                                </Text>
                            </View>
                        </View>
                        <View style={[styles.dayContainerSpacing, { display: "flex", flexDirection: "column", marginTop: 20 }]}>
                            <View style={[styles.dayContainer, styles.tomorrowBg1, styles.dayBorder1]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    Tomorrow
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.tomorrowBg2]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2epowder.value?.tomorrow ? formObj.co2epowder.value?.tomorrow : 0}
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.tomorrowBg3]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2eenergy.value?.tomorrow ? formObj.co2eenergy.value?.tomorrow : 0}
                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.tomorrowBg4]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2totaltransportation.value ? formObj.co2totaltransportation.value : 0}

                                </Text>
                            </View>
                            <View style={[styles.dayContainer, styles.tomorrowBg5, styles.dayBorder2]}>
                                <Text style={[styles.dayText, styles.color2]}>
                                    {formObj.co2totaltomorrow.value ? formObj.co2totaltomorrow.value : 0}

                                </Text>
                            </View>
                        </View>
                    </View>

                </View> */}
                <View style={{
                    display: "flex", justifyContent: "center", alignItems: "center", width: '100%', marginTop: "25vh"
                }} >
                    <Text style={[styles.partsHeading]} >CO2e in % of kg per part</Text>
                    <Text style={{
                        fontStyle: "normal",
                        fontSize: 20,
                        color: "#252525",
                    }}>Current Scenario</Text>
                </View>

                <View style={{ display: "flex", alignItems: "center", }}>
                    <Image allowDangerousPaths style={{ width: 300, height: 300, margin: "20 0 50 0", overflow: "visible" }} src={donutImage ? donutImage : "../assets/logo.png"} alt="check" />
                </View>
                <View style={{ justifyContent: "space-between", display: "flex", width: typeDocument === 0 ? "50%" : "90%", flexDirection: "row", marginLeft: typeDocument === 0 ? "30%" : "0%", padding: typeDocument === 0 ? "0" : "0 20px", }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ width: 10, height: 10, backgroundColor: "#5DC0D3", borderRadius: 2, marginRight: 5 }}>

                        </View>
                        <Text style={{ fontSize: 14, }}>
                            CO2e Powder
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ width: 10, height: 10, backgroundColor: "#9CEAEF", borderRadius: 2, marginRight: 5 }}>

                        </View>
                        <Text style={{ fontSize: 14, }}>
                            CO2e Energy
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ width: 10, height: 10, backgroundColor: "#07BEB8", borderRadius: 2, marginRight: 5 }}>

                        </View>
                        <Text style={{ fontSize: 14, }}>
                            CO2e Logistics
                        </Text>
                    </View>
                </View>
                {/* <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30" }}>
                    <Image allowDangerousPaths style={{ width: "100%", padding: "0 10" }} src="../assets/table-info-img.png" alt="check" />
                </View> */}
                <View style={[styles.powderContainer, { marginLeft: "2%", }, typeDocument === 0 ? { border: 1, borderColor: "#000", paddingBottom: 0, width: "95%", borderTopLeftRadius: 10, borderTopRightRadius: 10, } : { border: 1, borderColor: "#000", paddingBottom: 0, width: "90%", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginRight: "5%" }]}>
                    <View style={[styles.powderTableTop, { justifyContent: "space-between", padding: 0, width: "100%" }]}>
                        {
                            HotsspotArray.map((data, index) => {
                                return (
                                    <Text key={index} style={{
                                        fontFamily: 'Open Sans',
                                        fontStyle: "normal",
                                        fontWeight: "semibold",
                                        fontSize: 10,
                                        textAlign: "left",
                                        textTransform: "capitalize",
                                        color: typeDocument === 0 ? "#fff" : "#000",
                                        padding: "10 10",
                                        borderTopLeftRadius: `${data.heading === "CO2e Powder" ? 10 : 0}`,
                                        borderTopRightRadius: `${data.heading === "CO2e Logistics" ? 10 : 0}`,
                                        // borderColor: "#000",
                                        // borderWidth: 1,
                                        width: "32%",
                                        backgroundColor: data.heading.includes(highestIndex) && highestIndex !== "" ? "#82D1E6" : "",
                                    }}>
                                        {data.heading}
                                    </Text>
                                )
                            })
                        }
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", }}>
                        {
                            HotsspotArray.map((data, index) => {


                                return (
                                    <View key={index} style={{
                                        textAlign: "left",
                                        // textTransform: "capitalize",
                                        // color: "#000",
                                        width: "33%",
                                        fontSize: 2,
                                        padding: "0 10",
                                        // borderLeftColor: "#000",
                                        // borderLeftWidth: index === 0 ? 0 : 1,
                                        paddingBottom: 10
                                    }}>
                                        <Html stylesheet={styleSheet2} >{data.description}</Html>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "20px" }}>
                    <Text style={[styles.divTableCell1, { width: typeDocument === 0 ? "95%" : "85%" }]}>
                        Please note that the EOS Cost and Carbon Calculator provides only rough estimates.  EOS assumes no liability for the information from this tool. For precise and reliable values, please consult with Additive Minds at EOS. Please see the Data Matrix to understand the basis for the calculation of the EOS Cost & Carbon Calculator.
                    </Text>
                </View>
                {/* <View style={{ position: "fixed", marginTop: "38vh", display: "flex", justifyContent: "center", alignItems: "center", width: "95%", marginLeft: '24', }}>
                    <Text>For more information or in case of any question, please contact Additive Minds or EOS sales team </Text>
                    <View style={{ position: "absolute", right: "20px", bottom: "2px" }}>
                        <Text >page 2 of 3</Text>
                    </View>
                </View> */}
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} break>
                    <Image allowDangerousPaths style={{ width: "100%", }} src="../assets/reportV3V4.png" alt="check" />
                    {/* <Text>For more information or in case of any question, please contact Additive Minds or EOS sales team </Text>
                    <View style={{ position: "absolute", right: "20px", bottom: "2px" }}>
                        <Text >page 3 of 3</Text>
                    </View> */}
                </View>

            </View>

            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `For more information or in case of any question, please contact Additive Minds or EOS sales team             page ${pageNumber} of ${totalPages}`
            )} fixed />
        </Page>
    </Document >
);




function PdfGenerator({ formFields, donutImage, typeDocument, pdfName, HotsspotArray, highestIndex, initialDocumentState }) {
    const [formFieldsObj, setFormFieldsObj] = React.useState()

    let temp = true;

    React.useEffect(() => {


    }, [formFieldsObj])

    React.useEffect(() => {

        setFormFieldsObj(formFields)
    }, [formFields])


    return (
        <div>
            {/* <div id="faq-header"> */}
            {(formFieldsObj && donutImage) && (

                <BlobProvider document={<Doc formObj={formFieldsObj} donutImage={donutImage} typeDocument={typeDocument} pdfName={pdfName} HotsspotArray={HotsspotArray} highestIndex={highestIndex} />}>
                    {({ blob, url, loading, error }) => {
                        // Do whatever you need with blob here
                        console.log("data:image/svg+xml;base64," + donutImage);

                        if (!loading && temp) {

                            temp = false

                            if (typeDocument === 0) {
                                window.open(url, '_blank');
                                initialDocumentState()
                            } else if (typeDocument === 1) {
                                let data = new FormData();
                                data.append('file', blob)


                                toast.info(NOTIFICATION_MESSAGES.WAIT_NOTIFY)
                                axios.post(`${config.serviceUrl}client-data`, data,
                                    {
                                        headers: {
                                            'accept': 'application/json',
                                            'Accept-Language': 'en-US,en;q=0.8',
                                            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                                        }
                                    })
                                    .then(function (response) {

                                        if (response.data) {
                                            toast.success(NOTIFICATION_MESSAGES.SUCCESS_NOTIFY)
                                            saveAs(`${config.serviceUrl}${response.data.data}`, `${pdfName}.docx`)
                                            initialDocumentState()
                                        }

                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    })
                            }





                        }
                        return ''
                    }}
                </BlobProvider>


            )}


        </div>

    )
}

export default PdfGenerator;