import React from "react";

const CostGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Cost Distribution & Build Cost Per Part</h3>
            <p>The cost distribution and build cost per part graphs show the cost for system, material and post processing in single or multiple scenario created</p>
            <img src="/assets/cost-guide-1.png" alt="" className="img-fluid" />
            <p>The build cost per part numbers are also shown in comparison to the scenarios created and is donated by color codes specified in legends. </p>
        </>
    )
}

export default CostGuide;