import React from 'react'

const WastePowder = () => {
  return (
    <>
      <p className='input-heading' >Powder Disposal</p>
      <div className='data-field'>
        Powder Disposal
      </div>
      <p className='input-heading' >Carbon Content Disposal</p>
      <div className='data-field'>
        Carbon Content Disposal
      </div>
    </>
  )
}

export default WastePowder