import React from 'react'

const PostProcessing = () => {
    return (
        <>
            <p className='input-heading' >Build time</p>
            <div className='data-field'>
            Build Time
            </div>
            <p className='input-heading' >average power</p>
            <div className='data-field'>
            Average Power
            </div>
            <p className='input-heading' >energy used per job</p>
            <div className='data-field'>
            Energy Used Per Job
            </div>
            <p className='input-heading' >CO2e emission factor</p>
            <div className='data-field'>
            CO2e Emission Factor
            </div>
            <p className='input-heading' >CO2e per job</p>
            <div className='data-field'>
            CO2e Per Job
            </div>
            <p className='input-heading' >CO2e per part</p>
            <div className='data-field'>
            CO2e Per Part
            </div>
        </>
    )
}

export default PostProcessing