import React from "react";

const RegionGuide = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <>
            <h3>Region Selection</h3>
            <p>The region selection page allows you to select the region you are in. The calculations are then
                done in your local currency. You can select a region by hovering over the green regions.
            </p>
            <img src="/assets/region-guide-1.png" alt="" className="img-fluid" />
            <p>Currency can also be selected from the currency selection dropdown which lists all the regions. </p>
            <img src="/assets/region-guide-2.png" alt="" className="img-fluid" />
        </>
    )
}

export default RegionGuide;