import { atom, selector } from "recoil";

export const MachineData = atom({
  key: "MachineData",
  default: 0
})
export const MachineNameData = atom({
  key: "MachineNameData",
  default: undefined
})
export const MaterialData = atom({
  key: "MaterialData",
  default: 0
})
export const MaterialNameData = atom({
  key: "MaterialNameData",
  default: 0
})
export const BuildTimeData = atom({
  key: "BuildTimeData",
  default: undefined,
});
export const PartVolumeData = atom({
  key: "PartVolumeData",
  default: undefined,
});
export const PartSupportVolumeData = atom({
  key: "PartSupportVolume",
  default: undefined,
});
export const NoOfPartsData = atom({
  key: "NoOfPartsData",
  default: undefined,
});

export const GetNoOfPartsDataP = selector({
  key: "GetNoOfPartsDataP",
  get: ({ get }) => get(NoOfPartsData),
});
export const GetBuildTimeDataP = selector({
  key: "GetBuildTimeDataP",
  get: ({ get }) => get(BuildTimeData),
});

export const BuildHeightData = atom({
  key: "BuildHeightData",
  default: undefined,
});
export const RefreshRateData = atom({
  key: "RefreshRateData",
  default: undefined,
});