import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const previewReportPdf = (hook, id = false, setFormHolder = false) => {
    hook(id);
    setTimeout(() => {
        const input = document.getElementById('form-holder');
        html2canvas(input)
        .then((canvas) => {
            let imgData = canvas.toDataURL('image/png');
            let imgWidth = 210; 
            let pageHeight = 295;  
            let imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;

            let pdf = new jsPDF('p', 'mm');
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            }
            window.open(pdf.output('bloburl'), '_blank');
            hook(false);
            setFormHolder && setFormHolder('form-holder-1');
            document.getElementById(`${id}-scenario`).classList.remove('scenario-tab-active');
        });       
    }, 1000);
  };


  export default previewReportPdf;