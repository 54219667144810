import { useCallback } from "react";
import { useAppDispatch } from "state";
import { useSelector } from "react-redux";
import { setAccess } from "./Access";
import { setCCSteps } from "./CCStepsState";


// use Access
export const useAccess = () => {
  return useSelector((state) => state.Access.preemium);
};

//Set access

export const useSetAccess = () => {
  const dispatch = useAppDispatch();
  const setAccessState = useCallback((access) => {
    sessionStorage.setItem('allow', access ? 'flexible' : 'fix')
    dispatch(setAccess(access));
    // eslint-disable-next-line
  }, []);
  return { setAccessState }
}

export const useSetCCSteps = () => {
  const dispatch = useAppDispatch();
  const setCCStepsState = (noOfParts, buildTime) => {
    dispatch(
      setCCSteps({
        noOfParts: noOfParts,
        buildTime: buildTime,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return { setCCStepsState }
}

export const useGetCCSteps = () => {
  return useSelector((state) => state.CCSteps)
}