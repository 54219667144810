import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { useRecoilState, useRecoilValue } from "recoil"
import { PowderPerBuildJobData, Co2ePerJobPMData, Co2ePerPartPMData } from "../../../../../../atoms/Co2Polymer/PowderMfgAtoms";
import { NoOfPartsData, MaterialData, PartVolumeData, BuildHeightData, RefreshRateData, MachineData } from "atoms/Co2Polymer/MachineDetailsAtoms";
import { ProcessFlag } from 'atoms/General';
import { DebounceInput } from "react-debounce-input";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";

const PowderMfg = ({ Socket }) => {
  const [powderpbj, setPowderpbj] = useRecoilState(PowderPerBuildJobData);
  const [co2epbj, setCo2epbj] = useRecoilState(Co2ePerJobPMData);
  const [co2epp, setCo2epp] = useRecoilState(Co2ePerPartPMData);
  const noOfPartsData = useRecoilValue(NoOfPartsData)
  const materialId = useRecoilValue(MaterialData)
  const machineId = useRecoilValue(MachineData)
  const [, setProcessFlag] = useRecoilState(ProcessFlag)
  const PartVolume = useRecoilValue(PartVolumeData)
  const buildHeight = useRecoilValue(BuildHeightData)
  const refreshRate = useRecoilValue(RefreshRateData)

  const [, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
  })
  const { getComputedValue } = useRoundOffValue()

  useEffect(() => {
    if (PartVolume && noOfPartsData && materialId && buildHeight && refreshRate && machineId) {
      Socket.off("*");
      Socket.emit("carbon-calculator", { key: "FOCC-PPBJ", input: { part_volume: PartVolume, no_of_parts: noOfPartsData, material_id: materialId, build_height: buildHeight, refresh_rate: refreshRate, machine_id: machineId } })

      Socket.on("FOCC-PPBJ", function (data) {
        const value = getComputedValue(data?.data?.value)
        setPowderpbj(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PartVolume, noOfPartsData, materialId, buildHeight, refreshRate, machineId])

  useEffect(() => {
    if (powderpbj !== undefined) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true
        }
      })
      Socket.off("*");

      Socket.emit("carbon-calculator", { key: "FOCC-PMCPJ", input: { powder_per_build_job: powderpbj, material_id: materialId } });

      Socket.on("FOCC-PMCPJ", function (data) {
        const value = getComputedValue(data?.data?.value)
        setCo2epbj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false
          }
        })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderpbj]);


  useEffect(() => {
    if (co2epbj !== undefined && noOfPartsData !== undefined) {
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true
        }
      })
      Socket.off("*");
      Socket.emit("carbon-calculator", { key: "FOCC-PMCPP", input: { co2e_per_job_pm: co2epbj, no_of_parts: noOfPartsData } });

      Socket.on("FOCC-PMCPP", function (data) {
        const value = getComputedValue(data?.data?.value)

        setCo2epp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false
          }
        })
      });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2epbj, noOfPartsData]);
  useEffect(() => {
    if (powderpbj === "" || co2epbj === "" || co2epp === "") {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: false,
        }
      })
    }
    else if (powderpbj !== undefined && co2epbj !== undefined && co2epp !== undefined) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: true,
        }
      })
    }
    else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: false,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2epp, powderpbj, co2epbj,])

  return (
    <>
      <p className="input-heading"> Powder per build job (kg)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setPowderpbj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e emission factor"
          value={
            powderpbj === undefined ?
              (
                <Spinner animation="border" size="sm" />
              ) : (
                powderpbj

              )
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">CO2e per job (kg)</p>
      <div className="data-field">
        {
          co2epbj ?
            co2epbj :
            <Spinner animation="border" size="sm" />
        }
      </div>
      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {
          co2epp ?
            co2epp :
            <Spinner animation="border" size="sm" />
        }
      </div>
    </>
  );
};

export default PowderMfg;
