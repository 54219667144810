import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_STEP_DETAILS } from './getStepDetail';
import { useRecoilState } from "recoil"
import { MachineData, MaterialData, BuildTimeData, PartVolumeData, NoOfPartsData, MaterialNameData, MachineNameData, BuildHeightData, RefreshRateData } from 'atoms/Co2Polymer/MachineDetailsAtoms';
import Spinner from 'react-bootstrap/Spinner';
import { ProcessFlag } from 'atoms/General';
import constants from 'utils/constants';
import { DebounceInput } from "react-debounce-input";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";

const MachineDetails = (props) => {


    const client = useApolloClient();
    const [stepDetails, setStepDetails] = useState([])
    const [dropdownOne, setDropdownOne] = useState([])
    const [dropdownTwo, setDropdownTwo] = useState([])
    const [noofParts, setNoOfParts] = useRecoilState(NoOfPartsData)
    const [buildtime, setBuildTime] = useRecoilState(BuildTimeData)
    const [selectedMachine, setSelectedMachine] = useRecoilState(MachineData)
    const [machineName, setMachineName] = useRecoilState(MachineNameData)
    const [materialName, setMaterialName] = useRecoilState(MaterialNameData)
    const [selectedMaterial, setSelectedMaterial] = useRecoilState(MaterialData)
    const [partVolume, setPartVolume] = useRecoilState(PartVolumeData)
    const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag)
    const [buildHeight, setBuildHeight] = useRecoilState(BuildHeightData)
    const [refreshRate, setRefreshRate] = useRecoilState(RefreshRateData)
    // const [partSupportVolume, setPartSupportVolume] = useRecoilState(PartSupportVolumeData)
    let group_id = (+sessionStorage.getItem('group_id'));
    const calculatorType = sessionStorage.getItem("calculatorType");
    const { getComputedValue } = useRoundOffValue();


    useEffect(() => {
        const GetClientSteps = () => {
            client.query({
                query: GET_STEP_DETAILS,
                variables: { step_id: props.StepID }
            }).then(({ data }) => {
                // console.log('res', data.getFieldsByStepId)
                setStepDetails(data.getFieldsByStepId)
            }).catch(error => {
                console.log('error', error)
                // toast.error(error.graphQLErrors[0].messasge.message)
            });
        }

        const GetMachineDropdown = () => {
            if (props.StepID === 59) {
                client.query({
                    query: constants.GET_MACHINES,
                    variables: { technology_id: 2, calculator_type: calculatorType }
                }).then(({ data }) => {
                    // console.log('Dropdown', data.getClientMachineByTechnology)
                    setDropdownOne(data.getClientMachineByTechnology)
                }).catch(error => {
                    console.log('error', error)
                    // toast.error(error.graphQLErrors[0].messasge.message)
                });
            }
        }
        const GetMaterialDropdown = () => {
            if (selectedMachine !== 0) {
                client.query({
                    query: constants.GET_MATERIALS,
                    variables: { machine_id: selectedMachine, group_id: group_id, calculator_type: calculatorType }
                }).then(({ data }) => {
                    // console.log('Dropdown two', data.getClientMaterialByMachineId)
                    setDropdownTwo(data.getClientMaterialByMachineId)
                }).catch(error => {
                    console.log('error', error)
                    // toast.error(error.graphQLErrors[0].messasge.message)
                });
            }
        }
        GetClientSteps();
        GetMachineDropdown();
        GetMaterialDropdown()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepDetails, selectedMachine, noofParts]);

    useEffect(() => {
        if (selectedMachine === "" || selectedMaterial === "" || buildtime === "" || partVolume === "" || noofParts === "") {
            setProcessFlag((prevState) => {
                return {
                    ...prevState,
                    MachineDetails: false,
                }
            })
        }
        else if (selectedMachine !== undefined && selectedMaterial !== undefined && buildtime !== undefined && partVolume !== undefined && noofParts !== undefined) {
            setProcessFlag((prevState) => {
                return {
                    ...prevState,
                    MachineDetails: true,
                }
            })
        }

        else {
            setProcessFlag((prevState) => {
                return {
                    ...prevState,
                    MachineDetails: false,
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildtime, noofParts, partVolume, selectedMachine, selectedMaterial,])

    useEffect(() => {
        if (selectedMaterial) {
            props.Socket.off("*");

            props.Socket.emit("calculator", { key: "DB-RR", input: { material_id: selectedMaterial.toString() } });

            props.Socket.on("DB-RR", function (data) {
                const value = getComputedValue(data?.data?.value);

                setRefreshRate(value);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMaterial]);

    return (
        <>
            <p className='input-heading' >Machine</p>

            <div className='input-section' >
                <Form.Control onChange={(e) => {
                    const valueArray = e.target.value.split("&")
                    setSelectedMachine(parseInt(valueArray[0]));
                    setMachineName(valueArray[1]);
                }} as="select" aria-label="Default Control example">
                    <option className='select-option'>
                        Please select machine
                    </option>
                    {
                        stepDetails[0]?.name === "Machine" ?
                            dropdownOne.map((item, index) => {
                                return (
                                    <option key={item?.name} value={`${item?.machine_id}&${item?.name}`} name={item?.name} selected={selectedMachine === item?.machine_id && "selected"} className='select-option'>
                                        {item?.name}
                                    </option>
                                )
                            })
                            :
                            <option className='select-option'>
                                <Spinner animation="border" size="sm" />
                            </option>
                    }

                </Form.Control>
                <img src="../assets/Accordionarrow.svg" alt="" className='edit-icon' />
            </div>
            <p className='input-heading' >Powder</p>

            <div className='input-section' >
                <Form.Control onChange={(e) => {
                    const valueArray = e.target.value.split("&")
                    setSelectedMaterial(parseInt(valueArray[0]))
                    setMaterialName(valueArray[1])
                }} as="select" aria-label="Default Control example">
                    <option className='select-option'>
                        Please select material
                    </option>
                    {
                        stepDetails[1]?.name === "Material" ?
                            dropdownTwo.map((item, index) => {
                                return (
                                    <option key={index} value={`${item?.material_id}&${item?.name}`} selected={selectedMaterial === item?.material_id && "selected"} className='select-option'>
                                        {item?.name}
                                    </option>
                                )
                            })
                            :
                            <option className='select-option'>
                                <Spinner animation="border" size="sm" />
                            </option>

                    }

                </Form.Control>
                <img src="../assets/Accordionarrow.svg" alt="" className='edit-icon' />
            </div>
            <p className='input-heading' >Build time (h)</p>
            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setBuildTime(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter build time"
                    value={buildtime} />


            </div>
            <p className='input-heading' >Part volume (cm<sup className='font-weight-bold'>3</sup>)</p>
            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setPartVolume(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter part volume"
                    value={partVolume} />
                {/* <p className="edit-icon value-unit">cm3</p> */}


            </div>
            <p className='input-heading' >Number of parts</p>

            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setNoOfParts(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter number of parts"
                    value={noofParts} />


            </div>
            <p className='input-heading' >Build height</p>

            <div className="input-section">
                <input
                    type="number"
                    className="user-input"
                    onChange={(e) => {
                        setBuildHeight(e.target.value)
                    }}
                    onWheel={e => e.target.blur()}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    placeholder="Please enter build height"
                    value={buildHeight} />


            </div>
            <p className='input-heading' >Refresh Rate</p>

            <div className="input-section">
                <DebounceInput
                    type="number"
                    className="user-input"
                    debounceTimeout={500}
                    onChange={(e) => {
                        setRefreshRate(e.target.value);
                    }}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    placeholder="Please enter refresh rate"
                    value={
                        !refreshRate ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            refreshRate
                        )
                    }
                />
                <OverrideAbleTip />
            </div>

        </>
    )
}

export default MachineDetails

