import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import config from '../../config';
import Navbar from "../components/Nav"
import Co2ScenarioPolymer from 'app/components/Co2Scenario/components/Polymer/index';
import Co2ScenarioMetal from 'app/components/Co2Scenario/components/Metal';
import CCScenarioMetal from 'app/components/Co2Scenario/components/CCMetal';
import CCScenarioPolymer from 'app/components/Co2Scenario/components/CCPolymer';
import socketIOClient from "socket.io-client";
import constant from "../../utils/constants";

const axios = require("axios").default;
const Co2Scenario = () => {
    let { id } = useParams();
    const access_token = sessionStorage.getItem("access_token");
    const [scenarioData, setScenarioData] = useState({})
    const [socket, setSocket] = useState();

    useEffect(() => {
        const socketOb = new socketIOClient(constant.uri);
        const patch = require("socketio-wildcard")(socketIOClient.Manager);
        patch(socketOb);
        const socketObj = socketOb;
        setSocket(socketObj);
    }, []);

    useEffect(() => {
        const getScenariobyid = async (Id) => {
            try {
                console.log("ID", Id);
                const response = await axios.default.get(`${config.serviceUrl}client/scenario/getbyid/`, {
                    params: {
                        id: Id
                    },
                    headers: {
                        Authorization: access_token,

                    }
                })
                // JSON Scenarios data for CO2...
                // console.log("scenarios", JSON.parse(response.data.scenario_data));
                setScenarioData(JSON.parse(response.data.scenario_data))
            } catch (error) {
                console.log(error);
            }
        }
        id && getScenariobyid(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Navbar />
            {
                scenarioData.technology_id === 2 &&
                scenarioData.step_type_id === 4 &&
                <CCScenarioMetal fieldObj={scenarioData} socket={socket} />
            }
            {
                scenarioData.technology_id === 2 &&
                scenarioData.step_type_id === 5 &&
                <CCScenarioPolymer fieldObj={scenarioData} socket={socket} />
            }
            {
                scenarioData.technology_id === 2 &&
                scenarioData.step_type_id === 3 &&

                <Co2ScenarioPolymer fieldObj={scenarioData} socket={socket} />

            }
            {
                scenarioData.technology_id === 1 &&
                scenarioData.step_type_id === 3 &&
                scenarioData &&

                <Co2ScenarioMetal fieldObj={scenarioData} socket={socket} />

            }
        </>
    )
}

export default Co2Scenario