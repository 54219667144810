import React, { useEffect } from "react";
import Navbar from "../components/Nav.jsx";
import config from '../../config';
import { useState } from "react";
const axios = require("axios").default;

const MyScenario = () => {
    const [ScenariosList, setScenariosList] = useState([])
    const [count, setCount] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const pageSize = 10
    const access_token = sessionStorage.getItem("access_token");

    const deleteScenario = async (scenarioId) => {
        setLoading(true)

        await axios.delete(`${config.serviceUrl}client/scenario/delete`, {
            params: {
                id: scenarioId
            },
            headers: {
                'Authorization': access_token
            }
        })
            .then(function (response) {
                getScenarios(0)
            })
            .catch(function (error) {
                console.log(error);
            })
        setLoading(false)

    }
    const getScenarios = async (pageNo) => {
        try {
            const response = await axios.default.get(`${config.serviceUrl}client/scenario/get`, {
                params: {
                    pageSize: pageSize,
                    pageNo: pageNo,
                },
                headers: {
                    Authorization: access_token,

                }
            })

            setScenariosList(response.data.data.scenario)
            setCount(response.data.data.count)

        } catch (error) {
            console.log(error);
        }
    }

    function changePaginationClick(val) {

        getScenarios(val || 0)
    }

    useEffect(() => {
        getScenarios(0)
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>

            <Navbar />

            <div className="row pad-50 ">
                <div className="col page-container">
                    <p className="t-2">MY Scenarios</p>

                    {/* <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={2}
                    pageSizeOptions={[2, 4, 6]}
                /> */}
                    {isLoading ?
                        'Loading...' :
                        <table className="table table-responsive-sm" id="report-table">
                            <thead>
                                <th>Scenario ID</th>
                                <th>Scenario Name</th>
                                <th>Date / Time</th>
                                <th>Type</th>
                                <th>Machine</th>
                                <th>Material</th>
                                <th className="center-text">Actions</th>
                            </thead>
                            <tbody>
                                {ScenariosList && ScenariosList.map((scenario, i) => {
                                    return (
                                        <tr>
                                            <td>{scenario.scenario_id}</td>
                                            <td>{scenario.scenario_name}</td>
                                            <td>{scenario.created_at.slice(0, 10)}</td>
                                            <td>{scenario.technology_name + ' ' + scenario.step_type_name}</td>
                                            <td>{scenario.machine_name}</td>
                                            <td>{scenario.material_name}</td>
                                            <td className="d-flex align-items-center justify-content-around"><a href={`/carbon/co2-scenario/ ${scenario.scenario_id}`} className="center"><img src="../assets/eye.png" alt="" height="18" width="27" className="no-hide" /></a><button onClick={() => deleteScenario(scenario.scenario_id)} className="center"><img src="../assets/trashicon.svg" alt="" className="no-hide" /></button></td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>}
                    <div className="center" >
                        {(count / pageSize) >= 1 &&
                            <div id="pagination">
                                <p><img src="../assets/first-page.png" alt="" width="15" height="18" /></p>
                                <p><img src="../assets/prev-page.png" alt="" width="11" height="18" className="m-r-10" /></p>

                                {Array.apply(1, Array(parseInt(count / pageSize) + 1)).map(function (x, i) {
                                    return (<p onClick={() => { changePaginationClick(i) }}>{i + 1}</p>)
                                })}

                                <p><img src="../assets/next-page.png" alt="" width="11" height="18" className="m-l-10" /></p>
                                <p><img src="../assets/last-page.png" alt="" width="15" height="18" /></p>
                            </div>
                        }

                    </div>

                </div>

            </div>

        </React.Fragment>
    )
}

export default MyScenario