import React from 'react'
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const OverrideAbleTip = () => {
    return (
        <Tippy
            content={<span>Overridable field</span>}
        >
            <span className="edit-icon img-tooltip"><img src="/assets/EditIcon.svg" alt="" className="edit-icon" /></span>
        </Tippy>
    )
}

export default OverrideAbleTip