import { atom } from "recoil"

export const AverageMachinePowerDataM = atom({
    key: "AverageMachinePowerDataM",
    default: undefined
})

export const EnergyBuildProcessDataM = atom({
    key: "EnergyBuildProcessDataM",
    default: undefined
})
export const EnergyPerPartDataM = atom({
    key: "EnergyPerPartDataM",
    default: undefined
})


