import React from "react";

const LoginGuide = () =>{
    sessionStorage.setItem('reportStatus' , 'false');
    return ( 
        <>
            <h3>Login</h3>
            <p>In order to login to the system you must first enter email address and password that you have received in your invite email. </p>
            <img src="/assets/login-guide-1.png" alt="" className="img-fluid" />
        </>
    )
}
export default LoginGuide;