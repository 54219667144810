/* eslint-disable no-useless-escape */
import { HetrogenousJobsHiddenFields } from "./sets";
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const validateName = (name) => {
  return name.length >= 3
};
export const validateUsername = (name) => {
  return name.length >= 8
};
export const validatePassword = (password) => {
  // const passRegex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
  // return passRegex.test(password);
  return (password.length >= 8 || password.length >= 30)
}

export const ValidatePhoneNo = (phone) => {
  const p = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/;
  return p.test(phone);
};
export const validateCode = (code) => {
  const c = /^([0-9]).{5}$/;
  return c.test(code);
};

export const handleKeyPress = (e) => {
  if (["e", "E", "+", "-"].includes(e.key)) {
    e.preventDefault();
  }
}

export const hideFieldForHetrogenousJobs = (hetrojobs, fieldKey) => {
  if (hetrojobs?.length > 0 && fieldKey.includes("cost_per_part")) {
    return !HetrogenousJobsHiddenFields.has(fieldKey);
  }
  return false;
};