import { atom } from "recoil";

export const CCPolyPowderPerBuildJobMaterialData = atom({
  key: "CCPolyPowderPerBuildJobMaterialData",
  default: undefined,
});

export const CCPolyTimeForProcessingData = atom({
  key: "CCPolyTimeForProcessingData",
  default: undefined,
});
export const CCPolyAverageEnergyData = atom({
  key: "CCPolyAverageEnergyData",
  default: undefined,
});

export const CCPolyCO2EmissionFactorData = atom({
  key: "CCPolyCO2EmissionFactorData",
  default: undefined,
});

export const CCPolyCo2ePerJobMPData = atom({
  key: "CCPolyCo2ePerJobMPData",
  default: undefined,
});
export const CCPolyCo2ePerPartMPData = atom({
  key: "CCPolyCo2ePerPartMPData",
  default: undefined,
});
