import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_MACHINE_NAME_BY_ID } from "../CCCarbonMetal/components/CarbonMetalProcess/components/getStepDetail";

export const useGetMachineName = (machine_id) => {
  const client = useApolloClient();
  const [machineName, setMachineName] = useState("");

  useEffect(() => {
    const getMachineName = () => {
      client
        .query({
          query: GET_MACHINE_NAME_BY_ID,
          variables: { machine_id: machine_id },
        })
        .then(({ data }) => {
          // console.log("res: ", data);
          setMachineName(data.getMachineNameById.name);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    getMachineName();
  }, []);
  return machineName;
};
