import React, { useState, useEffect } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useApolloClient } from "@apollo/react-hooks";
import socketIOClient from "socket.io-client";
import { useRecoilValue, useRecoilState } from "recoil";
import Co2Pricing from "./Co2Pricing";
import ArrowDown from "../CarbonMetalProcess/components/ArrowDown";
import { ProcessFlag } from "atoms/General";
import { ToastContainer, toast } from "react-toastify";
import { GET_CLIENT_STEPS } from "app/view/CCStepsMetal/components/ProcessMetal/components/getStepDetail";
import Tippy from "@tippyjs/react";
import CarbonContent from "../CarbonMetalProcess/components/CarbonContent"
import { Spinner } from "react-bootstrap";


const CarbonPricingMetal = ({ ScenarioState, socket, staticDataOfMachine }) => {
  // const socket = new socketIOClient(constant.uri);
  const client = useApolloClient();
  const [activeState, setActiveState] = useState(-1);
  const [clientSteps, setClientSteps] = useState([]);
  const [loader, setLoader] = useState(true);
  const processFlag = useRecoilValue(ProcessFlag);

  useEffect(() => {
    client
      .query({
        query: GET_CLIENT_STEPS,
        variables: { technology_id: 1, type_id: 4 },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getClientSteps);
        // console.log(data.getClientSteps);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatedclientSteps = [...clientSteps.slice(-2)];


  const toggleState = (idnum) => {
    const id = idnum;
    if (activeState === +id) {
      setActiveState(null);
    } else {
      setActiveState(+id);
    }
  };

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" />{" "}
        </div>
      ) : (
        <>
          <Accordion>
            {updatedclientSteps &&
              updatedclientSteps.map((step, index) => {

                return (
                  <Card key={index}>
                    {step?.step_id > 74 && (
                      <Card.Header
                        className={activeState === index ? "active" : ""}
                      >
                        <div className="d-flex justify-content-between">
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            className={
                              activeState === index ? "active-color" : ""
                            }
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                            data-display="static"
                          >
                            {step?.name.includes("Pricing") ? (
                              <Tippy
                                key={index}
                                content={
                                  <span>
                                    All factors are taken from ADEME database, for more details on academic numbers we used VDI studies and referenced are under Data Collection Matrix
                                  </span>
                                }
                              >
                                <span>{step.name}</span>
                              </Tippy>
                            ) : (
                              step.name
                            )}
                          </Accordion.Toggle>
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            id="0"
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                          >
                            <div
                              className={
                                activeState === index
                                  ? "arrow-box active"
                                  : "arrow-box"
                              }
                            >
                              <ArrowDown />
                            </div>
                          </Accordion.Toggle>
                        </div>
                      </Card.Header>
                    )}
                    <Accordion.Collapse
                      data-target={index}
                      eventKey={index === 0 ? "0" : index}
                    >
                      <Card.Body>
                        {step?.step_id === 75 && activeState === index && (
                          <CarbonContent staticDataOfMachine={staticDataOfMachine} Socket={socket} />
                        )}

                        {step?.step_id === 76 && (activeState === index && processFlag.CarbonContentPP ? (
                          <Co2Pricing staticDataOfMachine={staticDataOfMachine} Socket={socket} />
                        ) : <p>Fill all carbon content per part details to view</p>)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
          <button
            onClick={() => {
              if (processFlag.CarbonPricing) {
                ScenarioState();
              } else {
                toast.error("Please fill all carbon pricing details.");
              }
            }}
            className="selection-button step-button"
          >
            Proceed
          </button>
        </>
      )}
    </>
  );
};

export default CarbonPricingMetal;
