import React from "react";
import Navbar from "../components/Nav.jsx";
import { ToastContainer } from 'react-toastify';


const PremiumRequestPage = () => {

  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <div className="row fill">
        <div className="col-lg-6 login-container">
          <div>
            <p >We are happy you’re enjoying using the<span className="login-text m-0"> EOS Cost & Carbon Calculator.</span></p>
            <p>To unlock more features, upgrade to Premium today!</p>
            <p className="mt-2">With Premium, you’ll get:</p>
            <li>All system and material combinations</li>
            <li>Visual analytics, scenario analysis, and report generation</li>
            <li>Customized cases for customer pricing</li>
            <p className="mt-2">Visit <a href="https://store.eos.info/products/eos-cost-carbon-calculator?variant=44453425512714?utm_source=CCC_Tool" target="_blank" rel="noopener noreferrer" className="sign-up-text">our store</a> to upgrade your membership.</p>
            <a href="https://store.eos.info/products/eos-cost-carbon-calculator?variant=44453425512714?utm_source=CCC_Tool" target="_blank" rel="noopener noreferrer">
              <button className="button1 button2" >
                <p>Get Premium Access Now</p>
              </button>
            </a>
          </div>
        </div>
        <div className="col-lg-6 coverimage image3"></div>
      </div>
    </React.Fragment>
  )
}

export default PremiumRequestPage;